'use strict';

define('vb/private/stateManagement/scopeResolver',['vb/private/constants',
], (Constants) => {
  class ScopeResolver {
    constructor(map = {}) {
      Object.assign(this, map);
    }

    /**
     * Return true if the scope resolves to this.
     * @param  {String}  scopeName the name of the scope to test
     * @return {Boolean}           true is the scope resolves to this
     */
    isThis(scopeName) {
      return this[scopeName] === this[Constants.THIS_PREFIX];
    }
  }

  return ScopeResolver;
});

