'use strict';

define('vb/action/builtin/forkAction',[
  'vb/action/action',
  'vb/private/log',
],
(Action, Log) => {
  const logger = Log.getLogger('/vb/action/action/forkAction');

  /**
   * The fork action allows multiple action steps to be run in parallel (forking each set of action steps), then
   * having a common outcome that awaits and collects the results (joining each set back together).
   *
   * A fork action has an arbitrary set of outcomes whose action sub-chains will all run in parallel. A special
   * outcome 'join' will be followed once all the sub-chains complete processing.
   *
   * If successful, the result is a mapping from the outcome id's of the sub-chains to their outcome/result payload.
   * If unsuccessful, this will return a 'failure' outcome with the result being the outcome of the failed
   * forked sub-chain.
   */
  class ForkAction extends Action {
    constructor(id, label) {
      super(id, label);
      this.log = logger;
      this.actionChain = null;
      this.outcomesMap = null; // backward-compatibility, this should be removed
    }

    perform(parameters) {
      return new Promise((resolve) => {
        const results = {};

        // go through all the outcomes and run in parallel
        const ongoingPromises = [];

        const actionMap = (parameters && parameters.actions) || this.outcomesMap;
        // abort if the actions is an array
        if (!actionMap || typeof actionMap !== 'object' || Array.isArray(actionMap)) {
          const msg = `forkAction ${this.logLabel} has an invalid action parameter`;
          this.log.warn(msg);
          resolve(Action.createFailureOutcome('Error during ForkAction', new Error(msg), results));
          return;
        }

        Object.keys(actionMap).forEach((actionId) => {
          const action = actionMap[actionId];
          const actionPromise = this.actionChain.runActionStep(action);

          // keep track of results
          actionPromise.then((result) => {
            results[actionId] = result;
          });

          // capture the promise
          ongoingPromises.push(actionPromise);
        });

        // when all actions (and their steps) have been completed, return the result
        Promise.all(ongoingPromises)
          .then(() => {
            resolve(Action.createOutcome('join', results));
          })
          .catch((e) => {
            resolve(Action.createFailureOutcome('Error during ForkAction', e, results));
          });
      });
    }

    setContext(actionChain, outcomesMap) {
      this.actionChain = actionChain;
      this.outcomesMap = outcomesMap;
    }
  }

  return ForkAction;
});

