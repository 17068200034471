'use strict';

define('vbsw/private/plugins/sessionTrackingHandlerPlugin',['vbsw/api/fetchHandlerPlugin', 'vbsw/private/utils'], (FetchHandlerPlugin, Utils) => {
  /*
   * the request header for enabling session tracking, and must be set to 'enabled' on the service.
   * When 'enabled, the VB_SESSION_TRACKING_HEADER and VB_SESSION_TRACKING_HEADER are added to the requests.
   * example:
   *   "x-oracle-abcs-userid": "franky.fallout@abxyz.com",
   *   "x-oracle-abcs-sessionid": "1e26ce77-38b7-462a-969b-b9f3a029c0ca",
   *
   * VB_REQUEST_TRACKING_HEADER is never sent, it is always stripped from the request
   * see BUFP-23166 / BugDB: 28298568
  */
  const VB_REQUEST_TRACKING_HEADER = 'vb-day-session-tracking';

  // the name of the cookie for storing information about the tracked session
  const VB_SESSION_TRACKING_COOKIE = 'VBCS_SESSION_TRACKING';

  // the name of the header used to send session tracking information
  const VB_SESSION_TRACKING_HEADER = 'X-Oracle-ABCS-SessionId';

  // the name of the header used to send session tracking user id
  const VB_SESSION_TRACKING_USER_HEADER = 'X-Oracle-ABCS-UserId';

  // delimiter used to separate session guid and user id
  const VB_SESSION_TRACKING_HEADER_DELIMITER = '@@@';

  // default cookie duration in hours
  const DEFAULT_COOKIE_DURATION = 24;

  /**
   * Handler plugin for handling session tracking cookie.
   */
  class SessionTrackingHandlerPlugin extends FetchHandlerPlugin {
    constructor(context, params) {
      super(context, params);

      this.userId = params.userId;

      // computed cookie duration in milliseconds
      this.cookieDuration = (params.cookieDuration || DEFAULT_COOKIE_DURATION) * 60 * 60 * 1000;

      this.cachedSessionCookiePromise = null;
      this.refreshSessionCookiePromise = null;
    }

    // used by unit tests
    static get requestTrackingHeader() { return VB_REQUEST_TRACKING_HEADER; }
    static get sessionTrackingHeader() { return VB_SESSION_TRACKING_HEADER; }
    static get sessionTrackingUserHeader() { return VB_SESSION_TRACKING_USER_HEADER; }
    static get sessionTrackingHeaderDelimiter() { return VB_SESSION_TRACKING_HEADER_DELIMITER; }

    /**
     * Get the session tracking header.
     *
     * @param client the client associated with the main application
     * @returns {Promise} returning the cookie header value
     */
    getSessionTrackingHeader(client) {
      if (!this.cachedSessionCookiePromise) {
        this.cachedSessionCookiePromise = Utils.getCookie(client, VB_SESSION_TRACKING_COOKIE)
          .then((sessionCookie) => {
            if (!sessionCookie) {
              return this.createSessionTrackingCookie(client);
            }

            return sessionCookie;
          });
      }

      return this.cachedSessionCookiePromise.then((sessionCookie) => {
        // if a different user is logged in or the cookie has expired, refresh the session tracking cookie
        if (sessionCookie.userId !== this.userId ||
            sessionCookie.expires < Utils.getEpochTime(true)) {
          // use refreshSessionCookiePromise to prevent refreshing the session cookie multiple times
          if (!this.refreshSessionCookiePromise) {
            // recreate the session cookie
            this.refreshSessionCookiePromise = this.createSessionTrackingCookie(client).then(() => {
              // clear the cacheSessionCookiePromise so we can refetch it
              this.cachedSessionCookiePromise = null;

              // refetch the session cookie
              return this.getSessionTrackingHeader(client).then((sessionTrackingHeader) => {
                // clear refreshSessionCookiePromise for the next refresh
                this.refreshSessionCookiePromise = null;

                return sessionTrackingHeader;
              });
            });
          }

          return this.refreshSessionCookiePromise;
        }

        return sessionCookie.header;
      });
    }

    /**
     * Create a new session tracking cookie.
     *
     * @param client the client associated with the main application
     * @returns {Promise<{name: string, session, userId: *, header: string, expires: *}>}
     */
    createSessionTrackingCookie(client) {
      const newSessionCookie = {
        userId: this.userId,
        header: `${Utils.generateUID()}${VB_SESSION_TRACKING_HEADER_DELIMITER}${this.userId}`,
        expires: Utils.getEpochTime(true) + this.cookieDuration,
      };

      return Utils.setCookie(client, VB_SESSION_TRACKING_COOKIE, newSessionCookie)
        .then(() => newSessionCookie);
    }

    handleRequestHook(request, client) {
      const requested = request.headers.get(VB_REQUEST_TRACKING_HEADER);
      if (requested === 'enabled') {
        const headers = request.headers;

        return this.getSessionTrackingHeader(client).then((sessionTrackingHeader) => {
          if (sessionTrackingHeader) {
            // set the session track header
            // headers.set(VB_SESSION_TRACKING_HEADER, sessionTrackingHeader);

            // for now, we need to split the header into two separate headers, one for the
            // session id and the other for the user id until the new version of the engagement
            // cloud can support the single header
            const splitHeaders = sessionTrackingHeader.split(VB_SESSION_TRACKING_HEADER_DELIMITER);
            if (splitHeaders.length === 2) {
              headers.set(VB_SESSION_TRACKING_HEADER, splitHeaders[0]);
              headers.set(VB_SESSION_TRACKING_USER_HEADER, splitHeaders[1]);
            }
          }
        });
      }

      return Promise.resolve();
    }
  }

  return SessionTrackingHandlerPlugin;
});

