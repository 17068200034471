'use strict';

define('vb/private/action/actionHelper',['vb/private/constants'],
  (Constants) => {
    /**
    * This helper class is used by assign variable functions to get values from variable expressions and perform
    * pick operations
    */
    class ActionHelper {
      /**
       * Creates a new Context for an action so that additional actions within the action chain can be invoked.
       * @param {Object} availableContexts available contexts to create a new context with (required)
       * @param {Action} action the action for which we are creating a new context (required)
       * @param additionalContexts Additional contexts to add onto the available contexts
       */
      static createNewContext(availableContexts, action, additionalContexts = {}) {
        if (availableContexts === null || availableContexts === undefined) {
          throw new Error('availableContexts must be provided to create a new context ');
        }

        if (action === null || action === undefined) {
          throw new Error('action must be provided to create a new context ');
        }

        // copy the contexts (shallow clone)
        const contexts = availableContexts.clone();

        contexts[Constants.ContextName.CURRENT] = {};

        // add the new ones
        Object.entries(additionalContexts).forEach((entity) => {
          // eslint-disable-next-line prefer-destructuring
          contexts[entity[0]] = entity[1];
        });

        // if we have the original metadata key name, make an accessor on the available contexts with the given alias
        // currently this creates a top-level property with this name (ex. 'myAction.data' is bindable)
        const alias = action.alias || action.metadataKey;
        if (alias) {
          contexts.addAccessor(alias, () => contexts[Constants.ContextName.CURRENT]);
        }

        return contexts;
      }
    }

    return ActionHelper;
  });

