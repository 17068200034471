'use strict';

define('vb/private/types/capabilities/containsKeys',[
  'vb/private/log',
  'vb/private/types/dataProviderConstants',
  'vb/private/utils',
  'vb/private/types/capabilities/fetchContext',
  'vb/private/types/capabilities/fetchByKeysUtils',
  'vb/private/types/capabilities/noOpFetchByKeys',
  'vb/private/types/capabilities/fetchByKeys',
  'vb/private/types/capabilities/fetchBySingleKey'],
(Log, DPConstants, Utils, FetchContext, FetchByKeysUtils, NoOpFetchByKeys, FetchByKeys, FetchBySingleKey) => {
  const FETCH_BY_KEYS_CAPABILITY = 'fetchByKeys';

  /**
   * fetchByKeys data provider capability types supported
   */
  const FetchByKeysCapability = {
    FETCH_BY_KEYS_LOOKUP: 'lookup',
    FETCH_BY_KEYS_ITERATION: 'iteration',
  };

  const ContainsKeysParamsMap = {
    KEYS: 'keys',
  };
  const ContainsKeysResults = {
    CONTAINS_PARAMETERS: 'containsParameters',
    RESULTS: 'results',
  };

  /**
   * Object that implements the containsKeys contract of the JET oj.DataProvider.
   */
  class ContainsKeys {
    /**
     * constructor
     *
     * @param sdp
     * @param params {Object} see oj.FetchByKeysParameters. Contains the following properties
     *  - keys: Set<any> keys of the rows to fetch
     */
    constructor(fetchByKeysImpl, sdp, params) {
      this.fetchByKeysImpl = fetchByKeysImpl;
      this.sdp = sdp;
      this.fetchOptions = params;
      this.log = FetchContext.LOGGER;
      this.id = Utils.generateUniqueId();
    }

    /**
     * The containsKeys method implementation
     * @returns {Promise}
     */
    contains() {
      return Promise.resolve().then(() => {
        const { sdp } = this;
        const uniqueId = `${sdp.id} [${this.id}]`;

        const cap = sdp.getCapability(FETCH_BY_KEYS_CAPABILITY);
        if (cap && (cap.implementation === FetchByKeysCapability.FETCH_BY_KEYS_LOOKUP
          || cap.implementation === FetchByKeysCapability.FETCH_BY_KEYS_ITERATION)) {
          const sdpState = this.fetchByKeysImpl.sdpState;
          this.log.info('ServiceDataProvider', uniqueId, 'containsKeys called with options', this.fetchOptions,
            'and state :', sdpState);

          const promise = this.fetchByKeysImpl.fetch();
          return promise.then((results) => {
            const containsKeysResult = this.buildContainsKeysResult(results);
            this.log.info('ServiceDataProvider', uniqueId, 'containsKeys returned:', containsKeysResult);
            return containsKeysResult;
          });
          // const warn = `ServiceDataProvider (${uniqueId}): containsKeys called when fetchByKeys
          // capability is configured for iteration based implementation: ${this.fetchOptions}!`;
          // this.log.warn(warn);
        }

        // we should never get here because SDP will never call this class for iteration based
        // lookups
        return (new NoOpFetchByKeys(this.fetchOptions)).containsKeys();
      });
    }

    /**
     * Calls the super class fetch() and transforms the results to a form containsKeys expects.
     * @return {Object} result of type oj.ContainsKeysResults
     */
    buildContainsKeysResult(fetchByKeysResult) {
      const results = new Set();
      this.fetchOptions[ContainsKeysParamsMap.KEYS].forEach((key) => {
        if (fetchByKeysResult[ContainsKeysResults.RESULTS].get(key) != null) {
          results.add(key);
        }
      });
      return Promise.resolve({
        containsParameters: this.fetchByKeysImpl.getFetchOptionsForResponse(),
        results,
      });
    }
  }

  return ContainsKeys;
});

