define('vb/components/messagebar/loader',['ojs/ojcomposite', 'text!./view.html', './viewModel', 'text!./component.json', 'css!./styles'],
    (Composite, view, viewModel, metadataJson) => {
      const metadata = JSON.parse(metadataJson);
      Composite.register('oj-vb-message-bar', {
        view,
        viewModel,
        metadata,
      });
    });

