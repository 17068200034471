'use strict';

define('vb/action/builtin/openUrlAction',[
  'vb/action/action',
  'vb/private/stateManagement/router',
],
(Action, Router) => {
  /**
   * Open the specified resource in the current window or in a new window using the window.open() API
   * as defined at {@link https://developer.mozilla.org/en-US/docs/Web/API/Window/open|Window.open()}
   *
   * @param {String} url         The url to navigate to (required)
   * @param {Object} params      An object with each property is a URL parameters (optional)
   * @param {String} hash        The hash entry (optional)
   * @param {String} history     Effect on the browser history (optional)
   *                             This is only used when opening the resource in the same window.
   *                             Allowed values are 'replace' or 'push'. Default is 'push'.
   *                             If the value is 'replace', the current browser history entry is
   *                             replaced instead of pushed, meaning that the browser back button
   *                             will not go back to it.
   * @param {String} windowName  A name identifying the window as defined in window.open API (optional)
   *                             If not defined, the URL will be open in the current window otherwise
   *                             refer to the window.open API.
   *
   * Always return {'success'}.
   *
   * An example of entry to open the URL in a new window as seen in a page descriptor:
   *
   * "parameters": {
   *   "url": "http://mysite/index.html",
   *   "params": {
   *     "id": "{{ $variables.id }}",
   *   },
   *   "hash": "details",
   *   "name": "myOtherWindow",
   * }
   *
   */
  class OpenUrlAction extends Action {

    perform(parameters) {
      return Router.openUrl(parameters)
        .then(() => Action.createSuccessOutcome())
        .catch(e => Action.createFailureOutcome('Failed to open url', e));
    }
  }

  return OpenUrlAction;
});

