'use strict';

/**
 * oj-vb-fragment-param
 * @since  2201.0.0
 *
 * A composite component used to provide input params for a fragment loaded by oj-vb-fragment composite component
 * in a VB app.
 *
 * <code>
 * &lt;oj-vb-fragment-param name='nameOfFragmentParam' value='[[ valueOfFragmentParam ]]'>
 * &lt;/oj-vb-fragment-param>
 * </code>
 *
 */
define('vb/components/oj-vb-fragment-param/loader',[
  'ojs/ojcomposite',
  'text!./component.json',
  './fragmentParamViewModel',
], (Composite, metadata, viewModel) => {
  // const view = '<oj-bind-text value="[[ getParentProps(ko.contextFor($element.parentElement),'
  //   + ' ko.contextFor($element)) ]]"></oj-bind-text>';
  Composite.register('oj-vb-fragment-param', {
    view: '',
    viewModel,
    metadata: JSON.parse(metadata),
  });
});

