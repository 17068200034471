/* eslint max-classes-per-file: ["error", 2] */

'use strict';

define('vb/extensions/dynamic/private/types/serviceMetadataProviderDescriptor',[
  'vb/private/constants',
  'vb/private/utils',
  'vb/extensions/dynamic/private/types/abstractMetadataProviderDescriptor',
  'vb/extensions/dynamic/private/types/bridges/serviceMetadataProviderFactoryBridge',
],
(Constants, Utils, AbstractMetadataProviderDescriptor, ServiceMetadataProviderFactoryBridge) => {
  /**
   * A "metadata" variable for providing the metadata provider to JET dynamic forms/tables.
   * Provides ONLY the basic shaping metadata, based on openapi3.
   *
   * Does NOT provide any dynamic layout functionality.
   */
  class ServiceMetadataProviderDescriptor extends AbstractMetadataProviderDescriptor {
    /**
     * @override
     * @returns {MetadataProviderFactoryBridge}
     */
    // eslint-disable-next-line class-methods-use-this
    createFactoryBridge() {
      return new ServiceMetadataProviderFactoryBridge();
    }

    /**
     * @override
     * @returns {{type: {path: string, data: string}}}
     */
    // eslint-disable-next-line class-methods-use-this
    getTypeDefinition() {
      return {
        type: {
          endpoint: 'string',
        },
      };
    }
  }

  return ServiceMetadataProviderDescriptor;
});

