'use strict';

define('vb/helpers/extendedTypeHandler',[], () => {
  /**
   * Handler API allows extended types to call specific framework methods. The framework provides an implementation
   * for the handler for extended types.
   */
  class ExtendedTypeHandler {
    /**
     * Returns type information using the type definition provided and resolve externalized types, de-normalizing this
     * into a single structured type.
     * A type can be a primitive, i.e. "string", "boolean", or "number".
     *
     * It can also be an object. In this case, the type will be returned as an object, where the keys are the
     * names of the properties, and the values are the types for those keys. The types for keys can also be objects
     * or any other type.
     *
     * A type can also be an array, in which case it will be represented as a single item array where that item
     * describes the type of each item in the array. If the array is an array of primitives, that will be returned as
     * a string such as "string[]", "boolean[]", or "number[]".
     *
     * A type can be an extended type (meaning it is an instance of some class). In this case, the type is the string
     * representation of the module (i.e. "vb/ServiceDataProvider").
     *
     * A type can be a wildcard object. In this case the type is the string, "object". If it's an array
     * whose items can be wildcards, this can be explicitly represented as "object[]". Note that "object" can
     * also be an array or an object otherwise, although the platform will default it to an empty object if no
     * other default value is specified.
     *
     * A type can be 'any' wildcard type. A wildcard 'any[]' will be returned as is.
     *
     * This method returns the type as is
     * throw an
     * exception.
     * @param type
     * @param description
     * @returns {*}
     */
    // eslint-disable-next-line no-unused-vars,class-methods-use-this
    getType(type, description) {
      return type;
    }

    /**
     * Invoke an event listener on the current container using the name and the payload.
     *
     * If the current page/flow does not have a listener for the event, framework traverses up the containment
     * looking for additional listeners.
     *
     * @param {String} eventName
     * @param {*} eventPayload
     * @returns {Promise<void>} that resolves with the event handling based on the event behavior configured.
     */
    // eslint-disable-next-line no-unused-vars,class-methods-use-this
    invokeEvent(eventName, eventPayload) {
      return Promise.resolve();
    }
  }
  return ExtendedTypeHandler;
});

