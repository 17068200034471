/* eslint-disable class-methods-use-this */

'use strict';

define('vbc/private/trace/tracer',['vbc/private/utils', 'vbc/private/trace/spanContext'], (Utils, SpanContext) => {
  // Maximum number of trace records to buffer
  let tracerPromise;
  let logger;
  // Because of a circular dependency between Trace and Log, logger needs to be loaded on demand
  const getLogger = () => {
    if (!logger) {
      const Log = requirejs('vbc/private/log');
      logger = Log.getLogger('/vbc/private/trace/tracer');
    }
    return logger;
  };

  const noop = () => {};
  const noopTracer = {
    startSpan: noop,
    span: (opt, fn) => fn(),
    finishSpan: noop,
    shutdown: noop,
    inject: (req) => req,
    isBlacklisted: () => false,
    blacklist: noop,
  };

  /**
   * Used for OpenTracing log record and span management
   */
  class Tracer {
    constructor() {
      // a default tracer is a noop
      this.reset();
      this._blacklist = [];
    }

    /**
     * @param options tracer configuration - @see {@link Tracer#checkOptions}
     * @returns {Promise<any>} a promise to an initialized tracer. If tracer options pass a basic sanity check,
     * this will be a client trace Tracer. Otherwise this will be a noop tracer.
     * @see https://confluence.oraclecorp.com/confluence/display/MDO/Trace-Client+API
     */
    init(options) {
      if (!tracerPromise) {
        tracerPromise = Promise.resolve()
          .then(() => {
            if (Tracer.checkOptions(options)) {
              this.options = options;
              // a default tracer is a noop
              if (this.isTelemetryLoaded()) {
                // BUFP-42619: If the telemetry libs were loaded but the tracer
                // isn't initialized yet, it's possible this will return
                // null.  If so, enable tracing anyway and we'll check again
                // the first time a span is created.
                // eslint-disable-next-line
                this.tracer; // Prefetch the globalTracer
                this.isEnabled = true;
              }
            }
            getLogger().info('Tracer', this.isEnabled ? 'enabled' : 'disabled', 'on',
              globalThis.toString());
            return this;
          }).catch((err) => {
            getLogger().error('Failed to load tracer on', globalThis.toString(), err);
            this.reset();
          });
      }
      return tracerPromise;
    }

    /**
     * Ensure the telemetry lib is loaded
     */
    isTelemetryLoaded() {
      return globalThis.GlobalTracer !== undefined;
    }

    /**
     * Resolve the tracer object if it wasn't resolved by init()
     * @see https://jira.oraclecorp.com/jira/browse/BUFP-42619
     */
    get tracer() {
      if (this.isTelemetryLoaded()) {
        if (!this.globalTracer) {
          // Get the global tracer
          const tracer = globalThis.GlobalTracer.get();
          if (tracer) {
            // Cache if tracer was available
            this.globalTracer = tracer;
          } else {
            // Return noopTracer if tracer not loaded
            return noopTracer;
          }
        }
        return this.globalTracer;
      }
      // Return noopTracer if no telemetry
      return noopTracer;
    }

    /**
     * Resets tracer singleton so it can be reinitialized.  Note that this will not affect the state of the
     * underlying tracer (since JET et al. may be using it), just resets and decouples the VB wrapper.
     */
    reset() {
      tracerPromise = null;
      delete this.options;
      this.globalTracer = null;
      delete this.isEnabled;

      this._blacklist = [];
    }

    /**
     * @param options tracer configuration, containing:
     * <li>disabled</li> true, if tracing should be disabled for this application
     * <li>injectEnabled</li> true if the tracer should inject span context in outgoing REST calls
     * @see https://confluence.oraclecorp.com/confluence/display/MDO/FusionApps+Client+Logging+Configuration
     * @returns {boolean} true, if trace options pass a basic sanity check. False otherwise.
     */
    static checkOptions(options) {
      if (options && options.disabled) {
        getLogger().info('Tracer options check: tracing is disabled', options, 'on', globalThis.toString());
        return false;
      }
      return true;
    }

    /**
     * Creates and returns a client trace span.  Typically it is preferable to use span() instead
     * @param {Object} spanContext the SpanContext for the span
     * @returns {Object || undefined} span, if a valid span context was provided. Otherwise returns undefined.
     */
    startSpan(spanContext) {
      let span;
      if (this.isEnabled && spanContext instanceof SpanContext) {
        try {
          const spanOptions = spanContext.startSpanOptions();
          if (spanOptions) {
            span = this.tracer.startSpan(spanOptions);
          }
        } catch (err) {
          getLogger().error('Failed to start trace span', spanContext, 'on', globalThis.toString(), err);
        }
      }
      return span;
    }

    /**
     * Ends specified client trace span. If span is undefined, this is a noop.
     * @param {Object} span the span to close
     * @param {Object} spanContext the SpanContext for the span
     */
    finishSpan(span, spanContext) {
      try {
        if (this.isEnabled && span) {
          let spanOptions;
          if (spanContext instanceof SpanContext) {
            spanOptions = spanContext.endSpanOptions();
          }
          span.finish(spanOptions);
        }
      } catch (err) {
        getLogger().error('Failed to finish trace span', span, 'on', globalThis.toString(), err);
      }
    }

    /**
     * Executes a span with the given options, executing the spanContext.spanFunction within
     * a span's context and returning its return value, if any
     * @param {} spanContext Span context object
     * @returns The return value of the spanContext.spanFunction() property
     */
    span(spanContext) {
      if (spanContext instanceof SpanContext) {
        if (this.isEnabled) {
          try {
            const spanOptions = spanContext.startSpanOptions() || {};
            const fn = spanContext.spanFunction();
            if (spanOptions && fn) {
              return this.tracer.span(spanOptions, fn);
            }
          } catch (err) {
            getLogger().error('Failed to start trace span', spanContext, 'on', globalThis.toString(), err);
          }
        }

        // If disabled or error occurred, let function passthrough
        const fn = spanContext.spanFunction();
        if (fn) {
          return fn();
        }
      }
      return undefined;
    }

    /**
     * Injects span context headers into an outgoing fetch request, if applicable
     * @param {*} span Span whose context to inject
     * @param {*} request Request into which to inject it
     * @return Promise resolving to Request object with injected context
     */
    inject(request) {
      // TODO add explicit whitelisting
      if (this.options && this.options.injectEnabled) {
        if (!this.isBlacklisted(request)) {
          return new Promise((resolve) => {
            const newReq = this.tracer.inject(request.clone());
            resolve(newReq);
          });
        }
      }

      return Promise.resolve(request);
    }

    /**
     * Indicates whether the request has been blacklisted for trace context injection
     * @param {Request} request Request to check for blacklisting
     */
    isBlacklisted(request) {
      return this._blacklist.includes(request.url);
    }

    /**
     * Adds a url to the blacklist so injection won't be performed in the future
     * @param url URL to blacklist
     */
    blacklist(request) {
      this._blacklist.push(request.url);
    }
  }

  // return a singleton tracer
  return new Tracer();
});

