'use strict';

/**
 * Return a singleton storeManager. There two type of store manager, basic and undo.
 * The undo store has undo/redp support for time travel.
 * Which type to use could be controlled using an environment variable.
 * @return {StoreManager}
 */

define('vb/private/stateManagement/redux/storeManager',['vb/private/stateManagement/redux/basicStoreManager'],
  (BasicStoreManager) => new BasicStoreManager());

