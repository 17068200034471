'use strict';

define('vbsw/private/plugins/authPublicHandlerPlugin',['vbsw/api/fetchHandlerPlugin', 'urijs/URI'], (FetchHandlerPlugin, URI) => {
  // regular expressions for DT and RT
  const DT_REGEX = /\/ic\/builder\/(design|deployment)\/[\w\d]+\/(((\d+\.)+\d+)|(\d+))\/resources\/(data(mgr)?|vbprocess)(\/.+)?/; // eslint-disable-line max-len
  const RT_REGEX =
    /\/ic\/builder\/rt\/[\w\d]+\/(((\d+\.)+\d+)|(\d+)|(live))(\/resources\/(data|vbprocess)|(\/resources)?\/services)(\/.+)?/; // eslint-disable-line max-len

  /**
   * Handler plugin for appending Authorizaton:Public header.
   */
  class AuthPublicHandlerPlugin extends FetchHandlerPlugin {
    /**
     * Append the Authorization:Public header.
     *
     * @param request the request to which to append the header
     */
    handleRequestHook(request) {
      // only add the header for url matching the following patterns
      let isMatch = request.url.match(RT_REGEX) || request.url.match(DT_REGEX);

      // in IE11, the path comes through with the service double-dots, and flunks the regex
      if (!isMatch && request.url.indexOf('../') >= 0) {
        const altUrl = new URI(request.url).normalize().toString();
        isMatch = altUrl.match(RT_REGEX) || altUrl.match(DT_REGEX);
      }

      if (isMatch) {
        request.headers.set('Authorization', 'Public');
      }

      return Promise.resolve();
    }
  }

  return AuthPublicHandlerPlugin;
});


