'use strict';

define('vb/private/services/serviceConstants',[],() => {
  const ServiceConstants = {};

  /**
   * when using the ProtocolRegistry, these represent the property names for the two types of merged/resolved
   * information. This is meant as an outward-facing type, for ProtocolHandlers to understand, and use appropriately.
   *
   * The ProtocolHandler can resolve different protocols, via different declarations, but shared the concept of
   * "services", "backends", and "metadata" with the handlers.
   *
   * @type {{SERVICES: string, BACKENDS: string, METADATA: string}}
   */
  ServiceConstants.ExtensionTypes = {
    // definitions applicable at the service level; unique to each service.
    // including things like authentication, proxyUrls, etc.
    // the "headers" in this section are not merged with the backends', currently (bt may be in the future).
    SERVICES: 'services',

    // data-endpoint specific info; these are intended to be reusable between services, and thus,
    // should not contain things like proxyUrls (which contain service IDs), for example.
    // Typically, a catalog "service" would point to a catalog "backend", though a service metadata (swagger) might
    // point to a backend directly.
    BACKENDS: 'backends',

    // // this is specific to the fetch of the service metadata (openapi3).
    // METADATA: 'metadata',
  };

  ServiceConstants.VB_EXTENSIONS = 'x-vb';

  // name of the catalog.json property in the 'x-vb' extensions for defining authorization configuration
  ServiceConstants.AUTH_DECL_NAME = 'authentication';

  return ServiceConstants;
});

