'use strict';

define('vb/private/stateManagement/appPackageExtension',[
  'vb/helpers/mixin',
  'vb/private/constants',
  'vb/private/stateManagement/flowExtension',
  'vb/private/stateManagement/containerRjsInitMixin',
], (Mixin, Constants, FlowExtension, ContainerRjsInitMixin) => {
  /**
   * AppPackageExtension class
   */
  class AppPackageExtension extends Mixin(FlowExtension).with(ContainerRjsInitMixin) {
    constructor(extension, path, base, className = 'AppPackageExtension') {
      super(extension, path, base, className);
    }

    static get extensionClass() {
      return AppPackageExtension;
    }

    /**
     *
     * @type {String}
     */
    static get resourceSuffix() {
      return '-x.json';
    }

    /**
     * The default event prefix is the lowercase class name (see container.js) but for
     * appPackage extension we want to use the same event prefix as application
     *
     * @type {String}
     */
    // eslint-disable-next-line class-methods-use-this
    get eventPrefix() {
      return 'application';
    }

    /**
     * The name of the runtime environment function to be used to load the descriptor.
     *
     * @type {String} the descriptor loader function name
     */
    static get descriptorLoaderName() {
      return 'getAppUiExtensionDescriptor';
    }

    /**
     * The name of the runtime environment function to be used to load the module functions.
     *
     * @type {String} the module loader function name
     */
    static get functionsLoaderName() {
      return 'getAppUiExtensionFunctions';
    }

    /**
     * Enables implicit mapping of the resources folder for App UI extension.
     *
     * @type {boolean}
     */
    // eslint-disable-next-line class-methods-use-this
    get implicitResourceFolder() {
      return true;
    }

    /**
     * Returns a scope resolver map where keys are scope name ("page", "flow" or "application")
     * and value the matching objects. This is used to build the scopeResolver object.
     *
     * @private
     * @return {Object} an object which properties are scope
     */
    getScopeResolverMap() {
      return {
        [Constants.APP_UI_PREFIX]: this,
        [Constants.GLOBAL_PREFIX]: this.application,
      };
    }
  }

  return AppPackageExtension;
});

