'use strict';

define('vb/private/events/eventMonitorOptions',[
  'vbc/private/monitorOptions',
], (MonitorOptions) => {
  /**
   * Represents a log monitor options for a VB component and variable events. These options are used in
   * custom VB performance measures and open trace spans, if enabled.
   */
  class EventMonitorOptions extends MonitorOptions {
    constructor(eventType, eventName, eventPayload, container) {
      super(eventType);
      this.addMessage(eventName);
      this.addStartFields(() => ({
        trigger: eventPayload, // Tracer will parse into trigger.* fields
        container,
        eventName,
      }));
      this.addEndFields(() => ({
        trigger: eventPayload, // Tracer will parse into trigger.* fields
        container,
        eventName,
      }));
    }
  }

  return EventMonitorOptions;
});

