'use strict';

define('vb/extensions/process/action/builtin/getDeployedProcessAction',[
  'vb/extensions/process/action/builtin/processAction',
  'vb/extensions/process/helpers/processService',
], (
  ProcessAction,
  ProcessService) => {
  /**
   * Retrieves a deployed process.
   *
   * Details:
   *
   * <ul>
   *   <li>Either "alias" or "deployedProcessId" should be used, not both.</li>
   *
   *   <li>The outcome returned by invoking the action is an object
   *   like <i>{ name: "", result: { content: {}, info: {} }</i>, where:
   *   <ul>
   *     <li>The value of "name" is either "success" or "failure"</li>
   *     <li>The value of "content" is a JSON object</li>
   *     <li>The value of "info" is an object with additional information like the status and headers of
   *         any HTTP response handled by the action</li>
   *   </ul></li>
   * </ul>
   *
   * Examples:
   *
   * <pre>
   *  "action01": {
   *    "module": "vb/extensions/process/action/builtin/getDeployedProcessAction",
   *    "parameters": {
   *      "alias": "ProcessAlias",
   *      "showProcessInstanceCount": true,
   *      "extended": true
   *    }
   *  }
   * </pre>
   *
   * <pre>
   *  "action02": {
   *    "module": "vb/extensions/process/action/builtin/getDeployedProcessAction",
   *    "parameters": {
   *      "deployedProcessId": "player~!sada~~ASdasd",
   *      "showProcessInstanceCount": true,
   *      "extended": true
   *    }
   *  }
   * </pre>
   */
  class GetDeployedProcessAction extends ProcessAction {
    static endpoint() {
      return ProcessService.endpoint.deployedProcess;
    }

    static responseType() {
      return ProcessService.definition.extendedDeployedProcess;
    }

    static restActionIdentification() {
      return { id: 'getDeployedProcessAction', description: 'Get a deployed process' };
    }

    perform(parameters) {
      const uriParams = Object.assign({}, parameters);
      uriParams.aliasOrDeployedProcessId = uriParams.alias || uriParams.deployedProcessId;
      delete uriParams.alias;
      delete uriParams.deployedProcessId;

      const resultType = ProcessService.isRequestingExtendedPayload(uriParams) ?
        ProcessService.definition.extendedDeployedProcess :
        ProcessService.definition.deployedProcess;

      return this._send(uriParams, undefined, resultType);
    }
  }

  return GetDeployedProcessAction;
});

