'use strict';

define('vb/private/monitoring/fetchResponseMonitorOptions',[
  'vbc/private/monitorOptions',
], (MonitorOptions) => {
  /**
   * Monitor options for tracing rest responses
   */
  class FetchResponseMonitorOptions extends MonitorOptions {
    constructor(operationName, message, response) {
      super(operationName, message);
      this.addEndFields(() => ({
        response,
      }));
    }
  }

  return FetchResponseMonitorOptions;
});

