'use strict';

define('vb/extensions/dynamic/private/types/bridges/metadataProviderFactoryBridge',['vb/private/utils'], (Utils) => {
  /**
   * abstract base class.
   * Concrete classes subclass this to provide a 'bridge' between
   * the VB parameters (typically defined at the top-level of the "defaultValue"), and the JET parameters.
   *
   * a) translates any VB options to JET options
   * b) creates the (JET) metadata provider, using a (JET) metadata provider factory
   *
   */
  class MetadataProviderFactoryBridge {
    /**
     * The "bridge" is responsible for translating VB options to options usable by a JET metadata provider,
     * and create the appropriate JET provider factory.
     *
     * note that this duck-types the JET MetadataProviderFactory interface, but this is not strictly required;
     * this is only to allow using a JET factory directly, if no bridge is required.
     * This does not actually happen, in practice.
     *
     * @param options
     * @returns {Promise}
     */
    createMetadataProvider(options) {
      if (!this.delegatePromise) {
        this.delegatePromise = Utils.getResource(this.getDelegatePath(options))
          .then((DelegateClass) => new DelegateClass());
      }

      let delegateFactory;
      return this.delegatePromise
        .then((delegate) => {
          delegateFactory = delegate;
        })
        .then(() => this.translateOptions(options))
        .then((newOptions) => {
          // clean up the props before we pass to JET
          const cleanedOptions = Object.assign({}, newOptions);
          delete cleanedOptions.container;
          delete cleanedOptions.helper;
          delete cleanedOptions.layoutConfig;

          return delegateFactory.createMetadataProvider(cleanedOptions);
        });
    }


    /**
     * this is called from createMetadataProvider() to get the factory.
     *
     * subclasses must override, to provide the module path to the (JET, or duck-typed) MetadataProviderFactory
     */
    getDelegatePath() { // options) {
      throw new Error('MetadataProviderFactoryBridge: getDelegatePath() must be overridden by subclasses.');
    }

    /**
     * override this method
     * @param options
     * @returns {Promise}
     */
    // eslint-disable-next-line class-methods-use-this
    translateOptions(options) {
      return Promise.resolve(Object.assign({}, options));
    }

    /**
     * Called by the AbstractMetadataProviderDescriptor when the variable is disposed
     * override this method
     */
    // eslint-disable-next-line class-methods-use-this
    dispose() {
      // no-op
      // Used by subclass ServiceMetadataProviderFactoryBridge
    }
  }

  return MetadataProviderFactoryBridge;
});

