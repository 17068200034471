'use strict';

define('vb/private/services/fallbackServices',[
  'vb/private/services/services',
  'vb/private/constants',
  'vb/private/utils',
], (Services, Constants, Utils) => {
  /**
   * A Services implementation, that overrides the findDefinition.
   * When we don't have an explicit declaration for a given service name ("services"),
   * and we haven't found it in the catalog.json, use FallbackServices to look for an assumed definition.
   *
   * The (base) Application creates this, and includes this in its list of Services.
   *
   * There is one of these created; it returns a different default depending on whether
   * namespace is used to reference the endpoint.
   *
   * This fallback may also be used when there is not app-flow-x, which normally uses an ExtensionServices model,
   * built from the service files in the extension.
   *
   * dev note: this used to be done by 'replacing' Services.getDefaultServiceDeclaration() on the app Services,
   * but it made sense to promote this to a separate instance when we introduced extension services, in case we need
   * to change the precedence of the 'default' location.
   *
   * @param name
   * @returns {{path: string}}
   */
  class FallbackServices extends Services {
    constructor(options) {
      super(options);
      this._notFoundServiceSet = new Set();
    }

    loadServiceFromPath(serviceName, fname, declaredHeaders, serverVariables) {
      return super.loadServiceFromPath(serviceName, fname, declaredHeaders, serverVariables)
        .then((serviceDefinition) => {
          if (!serviceDefinition) {
            // We are caching the services that were not found so we don't keep trying to find then, issuing a
            // fetch request each time. We may need to beef this up if the customer is doing a force reload but
            // I think this is good for now.
            this._notFoundServiceSet.add(`${this.namespace}:${serviceName}`);
          }
          return serviceDefinition;
        });
    }

    _isNotFoundService(serviceId) {
      return this._notFoundServiceSet.has(serviceId);// `${namespace}:${serviceName}`);
    }

    /**
     * return a default path
     * @param {string} serviceId
     * @param {EndpointReference} endpointReference
     * @returns {Promise<{path: string}>}
     * @protected
     */
    FindServiceDeclaration(endpointReference /* , serverVariables */) {
      return Promise.resolve()
        .then(() => {
          if (endpointReference.isProgrammatic) {
            return null;
          }

          // the fallback should only be used when we can't make an explicit match;
          // for extensions, we should be able to, since we have a manifest, so it would be strange to
          // get into the first block below - ExtensionServices should have matched this before FallbackServices was
          // used.
          const namespace = endpointReference && endpointReference.namespace;
          const serviceId = endpointReference.getQualifiedServiceId(this.namespace);
          const name = endpointReference.serviceName;

          if (this._isNotFoundService(serviceId)) {
            return null;
          }

          // if we have a namespace other than 'base', and it was explicit, look for the service def in the extension.
          if (namespace && namespace !== Constants.ExtensionNamespaces.BASE) {
          // if (namespace) {
            // note: the !== namespace case currently assumes that the services/ is located in a "self" folder
            // at the root of the extension (or extension bundle), which in 20.10, means "self" is a peer of "base",
            // and a child of "<extension name>". For example, for the following in the extension navigator:
            // sources -> ui -> extension1 -> services... ==> "files" : [ "self/services/petstore/openapi3.json" ]

            let prefix;
            let namePath;
            if (this.namespace === namespace) {
              // try to honor relativePath if we're the same namespace
              prefix = this._relativePath;
              namePath = name;
            } else {
              prefix = namespace;
              namePath = `${Constants.ExtensionFolders.SELF}/${name}`;
            }

            prefix = Utils.addTrailingSlash(prefix);

            return {
              type: 'serviceMap',
              path: `${prefix}${FallbackServices.getPathSuffix(namePath)}`,
            };
          }

          return {
            type: 'serviceMap',
            path: `./${FallbackServices.getPathSuffix(name)}`,
          };
        });
    }

    /**
     *
     * normally for flows, paths that don't start with './' log a warning, and get prefixed with the
     * container's relative path, because flows aren't allowed to reference services outside of their folder.
     *
     * it our case, we are creating a default one, not a declared one, so skip the checks.
     *
     * @param {string} filename
     * @returns {string}
     * @override
     */
    getDefinitionPath(filename) {
      if (this.namespace !== Constants.ExtensionNamespaces.BASE) {
        // EXTENSION_PATH has an ending slash
        return `${Constants.EXTENSION_PATH}${filename}`;
      }
      return super.getDefinitionPath(filename);
    }

    /**
     * @param name
     * @returns {string}
     * @private
     */
    static getPathSuffix(name) {
      return `services/${name}/openapi3.json`;
    }
  }
  return FallbackServices;
});

