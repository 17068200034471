'use strict';

define('vb/private/stateManagement/context/pageBaseContext',[
  'vb/private/stateManagement/context/baseContext'],
(BaseContext) => {
  /**
   * set of properties to expose in $base for page extension
   */
  class PageBaseContext extends BaseContext {
    /**
     * @param page
     */
    constructor(page) {
      super(page);

      /**
       * $base.flow
       */
      Object.defineProperty(this, 'flow', {
        get() {
          return page.parent.expressionContext.baseContext;
        },
        enumerable: true,
        configurable: true,
      });
    }

    /**
     * $base.page
     */
    get page() {
      return this;
    }
  }

  return PageBaseContext;
});

