'use strict';

define('vb/private/services/operationRefEndpointProvider',[
  'vb/private/log',
  'vb/private/configLoader',
  'vb/private/services/operationRefEndpoint',
  'vb/private/services/services',
], (Log, ConfigLoader, OperationRefEndpoint, Services) => {
  //
  const logger = Log.getLogger('/vb/private/services/operationRefEndpointProvider');

  class OperationRefEndpointProvider {
    static getOperationRefUrl(operationRef) {
      return operationRef ? operationRef.split('#')[0] || '' : '';
    }

    // typical mapping object
    // {
    //   "urlToFetch": "http://localhost:1988/webApps/ifixitfaster/api/incidents?technician=hcr",
    //   "url": "http://localhost:1988/webApps/ifixitfaster/api",
    //   "id": "incidents",
    //   "nameForProxy": "incidents",
    //   "headers": {
    //   "vb-allow-anonymous-access": true
    // },
    //   "baseUrl": "",
    //   "serviceName": "incidents"
    // }"

    /**
     *
     * @param {{url: string, operationRef: string}} opRefInfo
     * @param {object} [container] - typically the container which defines the scope in which the endpoint is being
     *                                 invoked.
     * @param {string} [container.extensionId] - the container's extension Id (or namespace), which is used to
     *                                           set the endpoint reference 'containerNamespace' property (defaults
     *                                           to 'base' if not informed).
     * @returns {Promise<OperationRefEndpoint>}
     */
    static getEndpoint(opRefInfo, container /* , serverVariables */) {
      return OperationRefEndpointProvider.getMapping(opRefInfo)
        .then((mapping) => {
          if (mapping) {
            return new OperationRefEndpoint(mapping.serviceName, opRefInfo.url, opRefInfo.operationRef, container)
              .init();
          }

          // return one for the raw URL
          logger.info('Unable to map URL, using Endpoint with no Service', opRefInfo.url);
          return new OperationRefEndpoint(null, opRefInfo.url, opRefInfo.operationRef, container).init();
        });
    }

    /**
     * try to map the url to a catalog service, or already-opened openapi3/swagger
     * @param {{url: string, operationRef: string}} endpointReference
     * @returns {Promise<*>}
     */
    static getMapping(endpointReference) {
      // first try the url
      return ConfigLoader.urlMapper.getUrlMapping(endpointReference.url)
        .then((mapping) => {
          // if we didn't get a mapping, try to get a mapping from the operationRef, open the service,
          // and then retry the url
          if (!mapping) {
            // If we didn't find a mapping for the URL, we can try to find a catalog.json mapping for the operationRef,
            // which points to openapi3.  if we can open that, we may then be able to get a mapping for the url.
            // If we don't have a catalog.json mapping for the operationRef at this point, we will not try to
            // fetch the operationRef URL (it would likely fail, since we have no mapped credentials).

            const operationRefUrl = OperationRefEndpointProvider.getOperationRefUrl(endpointReference.operationRef);

            return ConfigLoader.urlMapper.getUrlMapping(operationRefUrl)
              .then((refMapping) => {
                if (refMapping && refMapping.serviceName) {
                  const services = OperationRefEndpointProvider
                    .createServicesModel(refMapping.serviceName, operationRefUrl);

                  return services.load()
                    .then((debug) => {
                      console.log(debug);
                      return ConfigLoader.urlMapper.getUrlMapping(endpointReference.url);
                    });
                }
                return null;
              });
          }
          return mapping;
        });
    }

    /**
     * creates a Services model, for the Endpoint that represents the raw, unmapped URL.
     * @param name
     * @param operationRefUrl
     * @returns {Services}
     */
    static createServicesModel(name, operationRefUrl) {
      return new Services({
        relativePath: '',
        serviceFileMap: { [name]: operationRefUrl },
        expressionContext: {},
        protocolRegistry: ConfigLoader.protocolRegistry,
      });
    }
  }

  return OperationRefEndpointProvider;
});

