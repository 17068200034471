/* eslint-disable class-methods-use-this, no-unused-vars */

'use strict';

define('vbsw/private/cacheStrategy',[], () => {
  /**
   * Defines hook points for caching and fetching application resources and RT scripts during ServiceWorker and
   * application lifecycle.
   * The default CacheStrategy does not cache any assets.
   */
  class CacheStrategy {
    constructor(config) {
      this.config = config;
    }

    /**
     * Determines whether service worker cache lookup should be performed for a given url
     * @param {*} url
     * @returns a {Promise<boolean>} a promise that resolves to a false value by default
     */
    shouldCheckCache(url) {
      return Promise.resolve(false);
    }

    /**
     * @param url the resource url to lookup in the cache
     * @param mode determines whether any optimizations should be performed during cache lookup
     * @returns {Promise<void>} a promise to a cached resource or undefined, if no resource matching given url
     * was found in the cache.
     */
    checkCache(url, mode) {
      return Promise.resolve();
    }

    /**
     *
     * @returns {Promise<void>} a promise to cache assets during ServiceWorker install event
     */
    cacheOnInstall() {
      return Promise.resolve();
    }

    /**
     * @returns {Promise<void>} a promise to cache assets on every request
     */
    cacheOnRequest() {
      return Promise.resolve();
    }

    /**
     * @param request the request to consider for dynamic caching
     * @returns {Promise<Response>} a promise to a response that was fetched and cached, or undefined, if response
     * was not fetched
     */
    fetchAndCache(request) {
      return Promise.resolve();
    }
  }
  return CacheStrategy;
});

