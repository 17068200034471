'use strict';

define('vb/action/builtin/ifAction',[
  'vb/action/action',
],
(Action) => {
  /**
   *
   */
  class IfAction extends Action {
    perform(parameters) {
      const condition = parameters.condition;

      return condition ? Action.createOutcome(Action.Outcome.TRUE, true)
                       : Action.createOutcome(Action.Outcome.FALSE, false);
    }
  }

  return IfAction;
});

