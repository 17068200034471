'use strict';

define('vb/action/builtin/restAction',[
  'vb/private/constants',
  'vb/action/action',
  'vb/private/utils',
  'vb/private/helpers/rest',
  'vb/private/stateManagement/stateUtils',
  'vb/private/action/assignmentHelper',
  'vb/private/log',
],
(Constants, Action, Utils, RestHelper, StateUtils, AssignmentHelper, Log) => {
  const logger = Log.getLogger('/vb/action/builtin/restAction');
  const DEFAULT_FILE_PART_NAME = 'file';

  /**
   * local utility function, to check if the body is an object with a File property
   * @param body
   * @returns {boolean}
   */
  function hasBlob(body) {
    if (body) {
      // eslint-disable-next-line no-prototype-builtins
      return Object.keys(body).some((prop) => Blob.prototype.isPrototypeOf(body[prop]));
    }
    return false;
  }

  /**
   *
   */
  class RestAction extends Action {
    constructor(id, label) {
      super(id, label);
      this.log = logger;
    }

    /**
     *
     * @param parameters
     * @returns {Promise} Outcome {name:"success"}, or {name:"failure"} for error codes,
     * or simply rejects the Promise.
     *
     * @param parameters
     */
    perform(parameters) {
      let resolvedType;

      // deal with the body, as it's dependent on the contentType (or fallback to requestType)
      return RestAction.createBody(parameters.contentType, parameters.requestType, parameters.body,
        parameters.filePath, parameters.fileContentType, parameters.filePartName)
        .then((body) => {
          const endpointName = parameters.endpoint;
          const hookHandler = parameters.hookHandler;
          const responseType = parameters.responseType;
          const responseFields = RestAction.getResponseFields(parameters);

          // if the 'responseFields' IS assigned with a valid value
          // - responseType should be ignored; it should NOT coerce the result value, or build the "?field=" query.
          //
          // if the 'responseFields' IS assigned but undefined/null/empty
          // - if 'responseType' is NOT assigned,  we should NOT do a fetch, and just return 'failure' result.
          // - if 'responseType' IS assigned, treat this as if 'responseFields' is not assigned, meaning,
          //   do a normal fetch, using 'responseType' for both the 'fields=' query, and response shaping.
          //
          // if 'responseFields' is not assigned, do a normal fetch;
          //  use 'responseType' for both the 'fields=' query, and response shaping.

          const responseTypeIsAssigned = Object.prototype.hasOwnProperty.call(parameters, 'responseType');
          const responseFieldsIsAssigned = Object.prototype.hasOwnProperty.call(parameters, 'responseFields');

          if (!responseTypeIsAssigned && responseFieldsIsAssigned && (!responseFields || !responseFields.length)) {
            return RestAction.createFailureOutcome(
              `RestAction: responseFields is assigned an invalid value: ${responseFields}`,
            );
          }

          resolvedType = responseFieldsIsAssigned ? undefined : this.getResolvedType(responseType);

          const requestTransformOptions = RestAction.getRequestTransformOptions(parameters.requestTransformOptions,
            resolvedType, responseFields);

          // when a custom hookHandler is provided by caller, it can also provide the RestHelper to use. Use that
          // if available, if not revert to using the default RestHelper
          const container = this.context && this.context.container; // used when 'endpoint' does not use a namespace
          const restHelper = hookHandler && typeof hookHandler.getRestHelper === 'function'
            ? hookHandler.getRestHelper(endpointName, container) : RestHelper.get(endpointName, container);

          const call = restHelper
            .serverVariables(parameters.serverVariables)
            .pathParameters(parameters.pathParams)
            .queryParameters(parameters.queryParams)
            .parameters(parameters.uriParams)
            .requestTransformationFunctions(parameters.requestTransformFunctions)
            .requestTransformationOptions(requestTransformOptions)
            .responseTransformationFunctions(parameters.responseTransformFunctions)
            .responseBodyFormat(parameters.responseBodyFormat);

          // register the hook handler if any
          if (hookHandler) {
            call.hookHandler(hookHandler);
          }

          // headers need to be added only if they exist
          const initConfiguration = {};
          const headers = RestAction.configureHeaders(parameters.headers, parameters.contentType);
          if (headers) {
            initConfiguration.headers = headers;
          }

          // signal used for aborting the request
          if (parameters.signal) {
            initConfiguration.signal = parameters.signal;
          }

          call.body(body);
          return call.initConfiguration(initConfiguration).fetch();
        })
        .then((result) => {
          // TODO handle non JSON response types
          let responseBody = result.body;

          // if we have a responseType, coerce the response body to that type
          if (result.response.ok && resolvedType) {
            // pick attributes from the return value into an object that matches the type
            const beforeCoercion = responseBody;
            responseBody = AssignmentHelper.coerceType(beforeCoercion, resolvedType);
            this.log.finer('Action', this.logLabel, 'response', beforeCoercion, 'was coerced to', responseBody);
          }

          const actionResult = {
            status: result.response.status,
            headers: result.response.headers,
            body: responseBody,
          };

          // if mirrorBrowserApiBehavior is true, include the ok flag to make it easier for the caller to
          // check the status
          if (this.options.mirrorBrowserApiBehavior) {
            actionResult.ok = result.response.ok;
            actionResult.statusText = result.response.statusText;
          }

          // return a success outcome if response.ok or mirrorBrowserApiBehavior is true
          if (result.response.ok || this.options.mirrorBrowserApiBehavior) {
            // same as Action, but adds a little extra
            return RestAction.createSuccessOutcome(actionResult);
          }

          return RestAction.createFailureOutcome(
            'Error response during RestAction', result.error || null, actionResult,
          );
        })
        .catch((err) => RestAction.createFailureOutcome('Exception during RestAction', err, null));
    }

    /**
     * if responseFields is an array of arrays, flatten it;
     * this is a convenience for multiple forms, to allow an array of form render fields
     * @param parameters
     * @returns {Array}
     */
    static getResponseFields(parameters) {
      // response fields may be either
      // - a ComponentFieldsAttributes
      // - an Array of ComponentFieldsAttributes (to allow multiple forms)
      // the multiple-forms case

      const responseFields = (parameters.responseFields || []);

      // this was a convenience for the multiple-form case; revisit this
      // const onlyArrays = (parameters.responseFields || []).filter(a => a && Array.isArray(a));
      // if (onlyArrays.length && Array.isArray(onlyArrays[0])) {
      //   // flatten the arrays into one array
      //   responseFields = responseFields.reduce((flatten, arr) => [...flatten, ...arr]);
      // }
      return responseFields;
    }

    /**
     * if the responseType refers to a Type, find it. Otherwise, the responseType itself is the type definition.
     * @param responseType
     */
    getResolvedType(responseType) {
      return responseType ? StateUtils.getType(`${this.id}:response`, { type: responseType },
        this.context.container.scopeResolver) : undefined;
    }

    /**
     * need to pass information to the 'select' transform
     * @param options
     * @param resolvedResponseType
     * @param responseFields
     * @returns {*}
     */
    static getRequestTransformOptions(options, resolvedResponseType, responseFields) {
      // if the caller supplied a options.select.type, use it, and ignore everything else.
      if (options && options.select && options.select.type) {
        return options;
      }

      // if responseType and/or additionalFields are set, we will set the options.select.type as a merge of the two
      const requestTransformOptions = options || {};
      if (resolvedResponseType || responseFields) {
        requestTransformOptions.select = requestTransformOptions.select || {};
        requestTransformOptions.select.type = requestTransformOptions.select.type || resolvedResponseType || {};
        requestTransformOptions.select.attributes = requestTransformOptions.select.attributes || responseFields;
      }
      return requestTransformOptions;
    }

    /**
     * depending on the 'type', we may process the body to shape it to match the type.
     *
     * possible 'contentType' values: any (mime) type
     *
     * when 'multipart/form-data': - if there is a 'body', uses the properties as the parts. If 'filePath',
     *  also adds that. and uses fileContentType (if provided), or tries it get the mime type from the file extension.
     *
     * contentType is used as the actual contentType header, unless one has not been set in the header parameters.
     *
     * if contentType is NOT set, but both the body is an object, and the filePath is set,
     * assume multipart/form-data (and ignore the deprecated 'requestType').
     *
     * If the body is a Blob/File object:
     * - if filePath is NOT set, just use the body as is, and do not set an explicit contentType;
     *    use the one passed in, if any.
     * - if the filePath IS set, default the contentType to mutlipart/form-data, and put each in a "filename" part.
     * - if the contentType is set to something other than multipart/form-data, and the filePath is set,
     *    IGNORE the body, and just use the filePath (and log a warning).
     *
     * If contentType is not set (and we're not inferring multipart/form-data from the use of 'filePath'),
     * look for the (deprecated) 'requestType'.
     * Possible 'requestType' values are:
     * 'json' - * deprecated * - leave body as-is
     * 'form' - * deprecated * - each property represents a section in a FormData
     * 'url' - * deprecated * 0 each property represents a section in a URLSearchParams
     *
     * note: if we decode we should use any of the deprecated 'requestType', the 'filePath is ignored.
     *
     * note 2: the checks for Blob.prototype.isPrototypeOf(body) appear to work on IE11 for File
     * (which uses the same fetch() polyfill we use on iOS)
     *
     * @param contentType actual mime type
     * @param requestType 'form', 'json', 'url', deprecated, kept for backward-compatibility
     * @param body
     * @param filePath
     * @param fileContentType optional, may be used used when contentType is multipart/form-data and filePath set
     * @returns {Promise}
     */
    static createBody(contentType, requestType, body, filePath, fileContentType, filePartName) {
      return Promise.resolve().then(() => {
        let useBodyAsIs = false;

        const partName = filePartName || DEFAULT_FILE_PART_NAME;

        if (body && Utils.isObject(body)) {
          // if no 'fileName' and no 'contentType', then use the body as-is
          if (!filePath && !contentType) {
            if (Blob.prototype.isPrototypeOf(body)) { // eslint-disable-line no-prototype-builtins
              useBodyAsIs = true;
            } else {
              // if we were not passed a Blob/File, also default to the deprecated type
              requestType = requestType || 'json'; // eslint-disable-line no-param-reassign
            }
          }

          // if the body is non-null, and filepath is used, and contentType isn't set, assume its multipart
          // note: this used to check for Object.keys(body).length
          if (filePath && body) {
            // eslint-disable-next-line no-param-reassign
            contentType = contentType || Constants.ContentTypes.MULTIPART;
          }

          // if content-type is 'mutipart/form-data', OR if we don't have a type, BUT we have a Blob/File property
          // create a FormData, UNLESS the body was passed as FormData;
          // if already FormData it should be complete,  don't add anything to it; use it as-is. (bufp-31091)
          const createFormDataForType = contentType === Constants.ContentTypes.MULTIPART && !(body instanceof FormData);

          if (createFormDataForType || (!contentType && hasBlob(body))) {
            // same as 'form', but also add the file if present
            const data = new FormData();

            // note: this does NOT support ArrayBuffer in conjunction with a filePath; create your own body object!
            // eslint-disable-next-line no-prototype-builtins
            if (Blob.prototype.isPrototypeOf(body)) {
              // if we were passed a Blob as the Body, put it in the FormData with the same default name
              data.append(partName, body, body.name);
            } else if (Array.isArray(body)) {
              logger.warn('Rest Action: does not support \'body\' as an array for \'multipart/form-data\', skipping.');
            } else {
              // otherwise, each property on the body is added, using the property name as the part name
              Object.keys(body || {}).forEach((key) => {
                const val = body[key];
                const name = val.name;
                // this is to workaround an issue with the polyfill, where
                // it automatically sets the file name to 'Blob' when making
                // a POST request with the formData
                // eslint-disable-next-line no-prototype-builtins
                if (Blob.prototype.isPrototypeOf(val) && name) {
                  data.append(key, val, name);
                } else {
                  data.append(key, val);
                }
              });
            }

            if (filePath) {
              // use fileContentType as the file mime type, since contentType === multipart/form-data
              return Utils.readBlob(filePath, fileContentType)
                .then((filedata) => {
                  if (filedata) {
                    data.append(partName, filedata, RestAction.shortFileName(filePath));
                  } else {
                    logger.warn(`empty data for file: ${filePath}`);
                  }
                  return data;
                });
            }
            return data;
          }

          // the 'old' types are 'json', 'url', 'form'... we should deprecate these in deference to a real type
          // these ignore the filename parameter for the 'old' types
          if (useBodyAsIs || requestType === 'json') {
            // just use the body we were given
            const bodyConverted = RestAction.convertFileIfNeeded(body);
            return Promise.resolve(bodyConverted);
          }

          if (requestType === 'form') {
            const data = new FormData();

            Object.keys(body).forEach((key) => {
              const val = body[key];
              data.append(key, val);
            });

            return Promise.resolve(data);
          }

          if (requestType === 'url') {
            const data = new URLSearchParams();

            Object.keys(body).forEach((key) => {
              const val = body[key];
              data.append(key, val);
            });

            return data;
          }
        }

        // if 'contentType' isn't one of the ones we looked for, assume its the file mime type if 'filePath' is passed
        // or if its undefined/null, try to get the type from the extension
        if (filePath) {
          if (contentType === Constants.ContentTypes.MULTIPART) {
            const data = new FormData();
            // use fileContentType as the file mime type, since contentType === multipart/form-data
            return Utils.readBlob(filePath, fileContentType)
              .then((filedata) => {
                data.append(partName, filedata, RestAction.shortFileName(filePath));
                return data;
              });
          }

          if (body) {
            logger.warn('Rest Action: \'filePath\' and \'body\' are set, but \'contentType\' is not set to',
              'multipart/form-data, ignoring the \'body\' parameter');
          }
          // use contentType as the file mime type
          return Utils.readBlob(filePath, contentType)
            .then((file) => RestAction.convertFileIfNeeded(file));
        }

        // no contentType, no filePath
        // TODO should we assert that the contentType is string?
        return body;
      });
    }

    /**
     * for Chrome, we need to convert to a Blob because of some Chrome issue in our service worker
     * that causes File reading to fail.
     *
     * This is only converted when NOT being put into FormData
     * @param value
     * @returns {*}
     */
    static convertFileIfNeeded(value) {
      // eslint-disable-next-line no-prototype-builtins
      if (File.prototype.isPrototypeOf(value)) {
        return Utils.fileToBlob(value);
      }
      return value;
    }

    /**
     *
     * @param headers
     * @param contentType
     */
    static configureHeaders(headers, contentType) {
      let h;
      if (headers) {
        h = headers;
      }
      // assume anything with a slash is a real mime type
      if (contentType && contentType.indexOf('/') > 0) {
        h = h || {};
        // do not override if already set. using Headers object to take care of header casing (same trick in rest.js)
        if (!new Headers(h).get(Constants.Headers.CONTENT_TYPE)) {
          h[Constants.Headers.CONTENT_TYPE] = contentType;
        }
      }
      return h;
    }

    static shortFileName(filePath) {
      return (filePath && filePath.substring(filePath.lastIndexOf('/') + 1));
    }

    setContext(context) {
      this.context = context;
    }

    /**
     * create a 'payload' for the success that matches the standard error 'payload' property,
     * so RestAction has a (somewhat) matching success/failure outcomes.
     * example outcome:
     * {
     *   name: "success",
     *   result: {
     *     body: {object},
     *     error: '',     // empty, not meaningful, just a placeholder
     *     message: {
     *       summary: '', // empty, not meaningful, just a placeholder
     *     },
     *     headers: {Headers},
     *     status: {number}
     *   }
     * }
     * @param result
     * @returns {Object}
     */
    static createSuccessOutcome(result) {
      const newResult = Object.assign({ error: null, message: { summary: '' } }, result);
      return Action.createSuccessOutcome(newResult);
    }

    /**
     * utility method to allow all failure handlers to expect a meaningful 'payload' and 'payload.body'.
     *
     * top-level properties match the success outcome; also has a payload, for backward compatibility
     *
     * {
     *   name: "failure",
     *   result: {
     *     error: {string},
     *     status: {number},
     *     body: {object},
     *     headers: {object|Headers}
     *     message: {
     *       summary: {string},
     *     },
     *     payload: {
     *       body: {object},
     *       headers: {object|Headers},
     *       status: {number}
     *     }
     *   }
     * }

     * @param summary
     * @param error
     * @param payload
     */
    static createFailureOutcome(summary, error, payload) {
      let pay = payload;
      const msg = (error && error.message) || error || summary || '';

      if (!pay) {
        // get the message from the JS Error; otherwise, try 'error' or 'summary'
        const body = msg;
        pay = {
          body,
          headers: {}, // @todo: revisit this, should this have a get(), like Headers?
          status: -1, // a non-HTTP status
        };
      } else {
        pay.body = pay.body || msg;
      }

      const outcome = Action.createFailureOutcome(summary, error, pay);
      outcome.result = Object.assign({
        status: pay && pay.status,
        headers: pay && pay.headers,
        body: pay && pay.body,
      }, outcome.result || {});

      return outcome;
    }
  }

  return RestAction;
});

