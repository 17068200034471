'use strict';

define('vb/action/builtin/fireNotificationEventAction',['vb/action/eventAction', 'vb/action/action', 'vb/private/log', 'vb/private/utils', 'vb/private/constants']
, (EventAction, Action, Log, Utils, Constants) => {
  const logger = Log.getLogger('/vb/private/stateManagement/fireNotificationEventAction');

  const Modes = {
    PERSIST: 'persist',
    TRANSIENT: 'transient',
  };

  class FireNotificationEventAction extends EventAction {
    /**
     *
     * @param parameters
     * These parameters are used in the payload of the event.
     * It is ultimately up to the listener to decide the meaning of the parameters, but suggested values are:
     * - summary: {string} event title/summary
     *  - message: {string}event text
     *  - displayMode: {string} 'persist', 'transient'
     *  - type: {string} 'error', 'warning', 'confirmation', 'info'
     *  - key: {string} optional, if not provided, a 9 character alphanumeric UID is generated randomly.
     * @returns {{name, result}|*}
     */
    perform(parameters) {
      const payload = FireNotificationEventAction.createPayload(parameters);

      logger.info('FireNotificationEventAction', payload.type, payload.displayMode);

      if (parameters && !EventAction.isValidTarget(parameters.target)) {
        return EventAction.createFailureOutcome(`invalid "target" for event action: ${parameters.target}`);
      }

      if (this.eventContainer) {
        // ignore returned Promise; we don't wait for event processing
        const promiseOrNull = EventAction.fireEvent(this.eventContainer, Constants.NOTIFICATION_EVENT, payload, parameters);
        return promiseOrNull ? EventAction.createSuccessOutcome() :
          EventAction.createFailureOutcome(`Unable to find target: ${parameters.target}`);
      }

      return EventAction.createFailureOutcome(`unable to fire ${Constants.NOTIFICATION_EVENT} event, no page context`);
    }

    /**
     * construct the payload, with appropriate defaults
     * @param options same as parameters for perform()
     * @returns {{summary: string, message: string, displayMode: string, type: (string|string), key: string}}
     */
    static createPayload(options) {
      return {
        summary: options.summary || '',
        message: options.message || '',
        displayMode: options.displayMode || Modes.PERSIST,
        type: options.type || Constants.MessageType.ERROR,
        key: options.key || Utils.generateUniqueId(),
      };
    }
  }

  return FireNotificationEventAction;
});

