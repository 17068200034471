'use strict';

define('vb/extensions/process/action/builtin/performTaskAction',[
  'vb/extensions/process/action/builtin/processAction',
  'vb/extensions/process/helpers/processService',
],
(
  ProcessAction,
  ProcessService) => {
  /**
   * Performs a task action.
   *
   * Details:
   *
   * <ul>
   *   <li>The "taskAction" object should have either the "id" property or the property pair "type" and "actionId".</li>
   *
   *   <li>The outcome returned by invoking the action is an object
   *   like <i>{ name: "", result: { content: {}, info: {} }</i>, where:
   *   <ul>
   *     <li>The value of "name" is either "success" or "failure"</li>
   *     <li>The value of "content" is a JSON object</li>
   *     <li>The value of "info" is an object with additional information like the status and headers of
   *         any HTTP response handled by the action</li>
   *   </ul></li>
   * </ul>
   *
   * Examples:
   *
   * <pre>
   *  "action01": {
   *    "module": "vb/extensions/process/action/builtin/performTaskAction",
   *    "parameters": {
   *      "taskId": "44333",
   *      "taskAction": {
   *        "id": "CUSTOM~APPROVE"
   *      }
   *    }
   *  }
   * </pre>
   *
   * <pre>
   *  "action02": {
   *    "module": "vb/extensions/process/action/builtin/performTaskAction",
   *    "parameters": {
   *      "taskId": "44333",
   *      "taskAction": {
   *        "type": "CUSTOM",
   *        "actionId": "APPROVE"
   *      }
   *    }
   *  }
   * </pre>
   */
  class PerformTaskAction extends ProcessAction {
    static endpoint() {
      return ProcessService.endpoint.performTaskAction;
    }

    static responseType() {
      return ProcessService.definition.task;
    }

    static restActionIdentification() {
      return { id: 'performTaskAction', description: 'Perform a task action' };
    }

    perform(parameters) {
      const uriParams = Object.assign({}, parameters);

      const body = {};
      body.taskAction = uriParams.taskAction;
      delete uriParams.taskAction;

      return this._send(uriParams, body);
    }
  }

  return PerformTaskAction;
});

