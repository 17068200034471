'use strict';

define('vbsw/private/pwa/appShell',[
  'vbsw/private/utils',
  'vbsw/private/pwa/pwaUtils',
  'vbc/private/log',
  'vbc/private/performance/performance',
], (Utils, PwaUtils, Log, Performance) => {
  const logger = Log.getLogger('/vbsw/private/pwa/appShell');

  const SKIP_CACHE_LOOKUP_PATTERN_KEY = 'skipCacheLookupPattern';

  const VB = [
    'lib/third-party-libs.js',
  ];

  const WEB_MANIFEST = 'manifest.json';

  /**
   * This class is used by the service worker to cache application shell and required js files.
   * While VB runtime scripts are predefined, the application shell content is specified
   * in appShellCache.json file. This file can also be used to add additional content to the cache, for
   * example, files fetched from locations other than application root.
   */
  class AppShell {
    /**
     * Create an instance of AppShell.
     *
     * @param fetchHandler the FetchHandler created  by the ServiceWorker
     */
    constructor(fetchHandler) {
      // FetchHandler is created by the service worker and passed in here. This approach will allow to test AppShell
      // using a mock FetchHandler
      this.fetchHandler = fetchHandler;
    }

    /**
     * Load JSON application shell cache file. The file should be in the following structure:
     * {
     * "appFiles" : [
     * ],
     *  "files" : [
     * ],
     * }
     * @param shellPath a location of appShellCache.json file, relative to SW scope. For example:
     * <i>./mobileApps/testApp/version_1552430187000/appShellCache.json</i>
     * @returns {Promise<{files: string[], appFiles: string[]}>} a promise to application shell cache file.
     * If application shell file fails to load,
     * promise rejects.
     */
    loadAppShellFile(shellPath) {
      return PwaUtils.loadAppShellFile(shellPath, this.fetchHandler.handleRequest, this.fetchHandler);
    }

    /**
     * Creates a list VB runtime files that should be cached in PWA cache. This includes:
     * - visual-runtime.js and visual-runtime.js.map (or debug versions of these)
     * - third-party-libs.js,
     *
     * @param modulePath location of VB runtime, for example: <i>https://static.oracle.com/cdn/vb/v19.1.3.4/</i>
     * @param debug a flag that indicates whether debug VB sources should be cached
     * @returns {Promise<string[]>} a promise to a list of VB files to cache
     */
    static getVBCacheList(modulePath = '', debug) {
      return Promise.resolve()
        .then(() => Utils.getResource(`${PwaUtils.postSlash(modulePath)}vbrtBundlesMap.js`))
        .then((rtBundles) => {
          let files = [];
          rtBundles.thirdparty.forEach((bundle) => files.push(`${bundle.name}.js`));
          files.push(...VB);
          files.push(`visual-runtime${debug ? '-debug' : ''}.js`);
          files.push(`visual-runtime${debug ? '-debug' : ''}.js.map`);
          files = files.map((f) => `${PwaUtils.postSlash(modulePath)}${f}`);
          return files;
        });
    }

    /**
     * Cache VB runtime files. This includes:
     * - visual-runtime.js and visual-runtime.js.map (or debug versions of these)
     * - third-party-libs.js,
     *
     * @param modulePath location of VB runtime, for example: <i>https://static.oracle.com/cdn/vb/v19.1.3.4/</i>
     * @param debug a flag that indicates whether debug VB sources should be cached
     * @returns {Promise<void>} a promise to cache VB files
     */
    cacheVB(modulePath = '', debug) {
      const cacheMark = ['appShell.cacheVB'];
      return Promise.resolve()
        .then(() => Performance.markStart(cacheMark))
        .then(() => AppShell.getVBCacheList(modulePath, debug))
        .then((files) => this.cache(files))
        .then((cached) => {
          Performance.markEnd(cacheMark);
          return cached;
        });
    }

    /**
     * Creates a list of application shell files that should be cached in PWA cache.
     * @param appFiles a list of application shell files to cache
     * @param appPath relative to scope, location of application assets. For example: <i>mobileApps/testApp/</i>
     * @param baseUrlToken used for versioning of application artifacts. For example: <i>version_1552430187000</i>
     * @returns {Promise<string[]>} a promise to a list of application shell files to cache
     */
    static getAppFilesCacheList(appFiles = [], appPath, baseUrlToken) {
      return Promise.resolve()
        .then(() => {
          const files = [];
          // add (versioned) application artifacts
          if (appFiles) {
            const path = PwaUtils.constructScopeRelativeResourcePath(appPath, baseUrlToken);
            files.push(...appFiles.map((file) => `${path}${file}`));
          }
          return files;
        });
    }

    /**
     * Caches application shell.
     * @param {string[]} appFiles a list of application shell files to cache
     * @param {string} appPath relative to scope, location of application assets.
     * For example: <i>mobileApps/testApp/</i>
     * @param {string} baseUrlToken used for versioning of application artifacts.
     * For example: <i>version_1552430187000</i>
     * @returns {Promise<void | any[]>} a promise to cache application shell files
     */
    cacheAppFiles(appFiles = [], appPath, baseUrlToken) {
      const cacheMark = ['appShell.cacheAppShell'];
      return Promise.resolve()
        .then(() => Performance.markStart(cacheMark))
        .then(() => AppShell.getAppFilesCacheList(appFiles, appPath, baseUrlToken))
        .then((files) => this.cacheApplicationAssets(files))
        .then((cached) => {
          Performance.markEnd(cacheMark);
          return cached;
        });
    }

    /**
     * Creates a list of application's unversioned assets.
     * @param appPath relative to scope, location of application assets. For example: <i>mobileApps/testApp/</i>
     * @returns {Promise<string[]>} a promise to a list of application's unversioned assets
     */
    static getUnversionedAssetsList(appPath) {
      return Promise.resolve()
        .then(() => {
          const files = [];
          const path = PwaUtils.constructScopeRelativeResourcePath(appPath);
          // add request for application start url, for example:
          // eslint-disable-next-line max-len
          // https://kipling-vbdemo.uscom-central-1.oraclecloud.com/ic/builder/design/FixitFastOfflineOOW_IC_AMC/1.0/mobileApps/FixitFast/
          logger.info('PWA: getUnversionedAssetsList (', path, ')');
          files.push(path);
          files.push(`${path}${WEB_MANIFEST}`);
          return files;
        });
    }

    /**
     * Cache start url and other unversioned assets, such as web manifest (manifest.json)
     * @param appPath relative to scope, location of application assets. For example: <i>mobileApps/testApp/</i>
     * @returns {Promise<void | any[]>} a promise to cache application's start url and web manifest
     */
    cacheUnversionedAssets(appPath) {
      return AppShell.getUnversionedAssetsList(appPath).then((files) => this.cacheApplicationAssets(files));
    }

    /**
     * @param {string[]} files a list of urls to cache
     * @returns {Promise<any[]>} a promise to fetch and cache requests
     * @see https://filipbech.github.io/2017/02/service-worker-and-caching-from-other-origins
     */
    cacheFiles(files = []) {
      return Promise.resolve()
        .then(() => {
          logger.info('PWA: cacheFiles()');
          return files.map((f) => fetch(f).then((response) => this.fetchHandler.stateCache.putOpaque(f,
            response)));
        })
        .then((cachePromises) => Promise.all(cachePromises));
    }

    /**
     * Invoke FetchHandler/Cache API to add files to the service worker state cache.
     *
     * @param {string[]} files an array of url's to cache
     * @returns {Promise<>} a promise to cache specified files
     */
    cache(files = []) {
      return Promise.resolve()
        .then(() => {
          const cachePromises = files.map((f) => this.fetchHandler.cacheFile(f)
            .catch((error) => {
              logger.error('PWA: cache', f, error);
            }));
          return Promise.all(cachePromises);
        });
    }

    /**
     * Invoke FetchHandler/Cache API to add files to the service worker application cache.
     *
     * @param files an array of url's to cache
     * @returns {Promise<any[]>} a promise to cache specified files
     */
    cacheApplicationAssets(files) {
      return Promise.resolve()
        .then(() => {
          if (files && files.length > 0 && this.fetchHandler.appCache !== undefined) {
            const cachePromises = files.map((f) => this.fetchHandler.appCache.add(f)
              .catch((error) => {
                logger.error('PWA: cacheApplicationAssets', f, error);
              }));
            return Promise.all(cachePromises);
          }
          logger.info('PWA: cacheApplicationAssets(): no files cached');
          return Promise.resolve([]);
        });
    }

    cacheSkipCacheLookupPattern(appShell) {
      return Promise.resolve()
        .then(() => {
          if (appShell.skipCacheLookupPattern) {
            return this.fetchHandler.stateCache.put(SKIP_CACHE_LOOKUP_PATTERN_KEY, appShell.skipCacheLookupPattern);
          }
          return undefined;
        });
    }

    getSkipCacheLookupPattern() {
      return this.fetchHandler.stateCache.get(SKIP_CACHE_LOOKUP_PATTERN_KEY);
    }

    /**
     * A promise to cache application shell files and VB runtime
     *
     * @param {Object} config the service worker configuration
     * @param {string} config.shellPath
     * @param {string} config.appPath
     * @param {string} config.baseUrlToken
     * @param {string} config.modulePath
     * @param {boolean} config.debug
     * @returns {Promise<void>} a promise to cache all required application files for offline access that never rejects.
     * The resolved value is undefined.
     */
    cacheAppShell({
      shellPath, appPath, baseUrlToken, modulePath, debug = false,
    }) {
      return Promise.resolve()
        .then(() => {
          logger.info('PWA: cacheAppShell()');
          return this.cacheVB(modulePath, debug);
        })
        .then(() => this.cacheUnversionedAssets(appPath))
        .then(() => this.loadAppShellFile(shellPath))
        .then((appShell) => {
          if (appShell) {
            const cacheSkipCacheLookupPatternPromise = this.cacheSkipCacheLookupPattern(appShell);
            const cacheAppFilesPromise = this.cacheAppFiles(appShell.appFiles, appPath, baseUrlToken);
            const cacheFilesPromise = this.cacheFiles(appShell.files);
            return Promise.all([cacheAppFilesPromise, cacheFilesPromise, cacheSkipCacheLookupPatternPromise]);
          }
          throw new Error('cacheAppShell(): No app shell');
        })
        .catch((error) => {
          logger.error('PWA: cacheAppShell(', shellPath, ')', error);
          // there is nothing the caller can do when caching fails, so just return undefined
          return undefined;
        });
    }
  }
  return AppShell;
});

