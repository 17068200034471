'use strict';

define('vb/private/action/assignmentUtils',[], () => {
  /**
   * Builtin assign variable utils class for use in expressions used with assignVariablesAction. This class' methods are
   * primarily used by page authors to do something like -
   * $application.builtinUtils.assignmentUtils.assignValue(value)
   */
  class AssignmentUtils {
    /**
     * returns the params as the new source value to assign to target.
     * @param {AssignmentHelper} helper
     * @param {any} defaultValue
     * @param {Array<object>} params
     * @property params.instance <optional> if not set the constructorParams is used to create a new instance
     * @property params.constructorParams
     */
    static assignValue(helper, defaultValue, params) {
      return params;
    }
  }

  return AssignmentUtils;
});

