'use strict';

define('vb/private/action/assignmentHelper',['vb/binding/expression', 'vb/private/utils', 'vb/private/stateManagement/stateUtils'],
  (Expression, Utils, StateUtils) => {
    /**
     * This helper class is used by assign variable functions to get values from variable expressions and perform
     * pick operations
     */
    class AssignmentHelper {
      constructor(availableContexts, targetType) {
        this.targetType = targetType;

        // the get method is defined here to protect access to the availableContexts
        /**
         * Gets a variable from a scope by its string representation. The returned variable value will
         * be unwrapped by calling Utils.cloneObject.
         *
         * @param expr string representation of a variable
         */
        this.get = expr => Utils.cloneObject(Expression.createFromString(expr, availableContexts)());
      }

      /**
       * Assigns properties from one or more sources to the target if and only if the property already exists on the
       * target. The sources are processed in the order they are defined.
       *
       * If target is null, any empty target value will be created based on the target's type. If the target is not
       * null, it will be cloned and the sources will be assigned into the clone. In either case, this value will be
       * returned as the result.
       *
       * NOTE: This method will directly write into the target if the target is an object or array. It
       * is the responsibility of the caller to pass in a target that can be written into. For example,
       * if target is a wrapped variable value, it should be unwrapped by using Utils.cloneObject before
       * passing it into this method.
       *
       * @param target the target of the pick operation
       * @param sources the sources to be picked into the target in the order thye are defined
       * @returns {*}
       */
      pick(target, ...sources) {
        const result = sources.reduce(
          (accumulator, currentValue) => this.pickRecursive(accumulator, currentValue, this.targetType),
          target);

        return result;
      }

      /**
       * Recursively pick properties from the source to the target.
       *
       * @param target target of the pick operation
       * @param source source of the pick operation
       * @param targetPrototype used for picking array elements
       * @returns {*}
       * @private
       */
      pickRecursive(target, source, targetType) {
        // throw an error if assigning an array or primitive to a target with object type
        if (targetType === 'object') {
          if (Array.isArray(source)) {
            throw new Error('Cannot assign an array to a target with \'object\' type.');
          }

          if (!Utils.isObject(source)) {
            throw new Error('Cannot assign a primitive value to a target with \'object\' type.');
          }
        }

        const isSourceObject = Utils.isObject(source);
        const isSourceArray = Array.isArray(source);

        // if targetType is any or object, merge if both target and source are object (not array),
        // otherwise, directly assign source to target
        if (targetType === 'any' || targetType === 'object') {
          if (isSourceObject && Utils.isObject(target)) {
            return Utils.cloneObject(source, target);
          }
          return source;
        }

        const isTargetObject = Utils.isObjectType(targetType);
        const isTargetArray = Utils.isArrayType(targetType);
        let targetValue = target;

        if (isTargetArray) {
          // initialize the targetValue if necessary
          targetValue = targetValue || [];

          const rowType = Utils.getArrayRowType(targetType);
          const sourceArr = isSourceArray ? source : [source];

          sourceArr.forEach((sourceRow) => {
            // pass in null for target and let pickRecursive figure how to create the row
            targetValue.push(this.pickRecursive(null, sourceRow, rowType));
          });
        } else if (isTargetObject && isSourceObject) {
          // initialize the targetValue if necessary
          targetValue = targetValue || StateUtils.buildVariableDefaults(null, null, targetType);

          Object.keys(targetType).forEach((key) => {
            if (Object.prototype.hasOwnProperty.call(source, key)) {
              targetValue[key] = this.pickRecursive(targetValue[key], source[key], targetType[key]);
            } else if (!Object.prototype.hasOwnProperty.call(targetValue, key)) {
              // if targetValue[key] doesn't exist, add it and set it to undefined
              targetValue[key] = undefined;
            }
          });
        } else if (!Utils.isObjectOrArray(targetType) && !Utils.isObjectOrArray(source)) {
          // target is a primitive, coerce the source to the target type
          return AssignmentHelper.coerceType(source, targetType);
        } else if (Utils.isInstanceType(targetType)) {
          return source;
        }

        return targetValue;
      }

      /**
       * Coerce value to the given type.
       *
       * @param value the value to coerce
       * @param type the type to coerce into
       * @returns {*}
       */
      static coerceType(value, type) {
        // don't coerce null, undefined or expression
        if (value === undefined || value === null || typeof value === 'function') {
          return value;
        }

        // coerce string type
        if (type === 'string') {
          return String(value);
        }

        // coerce boolean type
        if (type === 'boolean') {
          if (typeof value === 'string' &&
            (value.toLocaleLowerCase() === 'false' || value === '0')) {
            return false;
          }

          return Boolean(value);
        }

        // coerce number type
        if (type === 'number') {
          return Number(value);
        }

        // if type is an instance type, e.g., vb/ServiceDataProvider, don't coerce the value
        if (Utils.isInstanceType(type)) {
          return value;
        }

        // for object or array type, use AssignmentHelper.pick to do the coercion
        const helper = new AssignmentHelper(null, type);
        return helper.pick(null, value);
      }
    }

    return AssignmentHelper;
  });

