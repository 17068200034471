'use strict';

define('vb/private/stateManagement/eventBehaviorMixin',[
  'vb/private/constants',
], (Constants) => {
  /**
   * Mixin that is used by the layout and fragment and their extension containers to determine
   * whether the event propagation behavior is supported.
   */
  const EventBehaviorMixin = (superclass) => class extends superclass {
    /**
     * Called to determine if propagation behavior is supported by the given container.
     * @param type of propagation behavior being checked for. See Constants.EventPropagationBehaviors enum.
     * @param eventDef event definition
     * @return {boolean} true if behavior matches the type and the container allows the requested type
     */
    // eslint-disable-next-line class-methods-use-this
    allowsEventPropagation(type, eventDef) {
      switch (type) {
        case Constants.EventPropagationBehaviors.CONTAINER:
          // event can bubble up to container only if the type requested matches the behavior and event is declared
          return eventDef.propagationBehavior === Constants.EventPropagationBehaviors.CONTAINER && eventDef.isDeclared;

        case Constants.EventPropagationBehaviors.SELF:
        default:
          return false; // propagating event outside of self is disallowed;
      }
    }
  };

  return EventBehaviorMixin;
});

