/* eslint-disable space-before-function-paren,no-useless-concat,default-case,object-shorthand,max-classes-per-file */

'use strict';

define('vb/private/services/ramp/filterCriterionUtils',['vb/private/log', 'urijs/URI', 'vb/private/types/dataProviderConstants'], (Log, URI, DPConstants) => {
  const FILTER_OPS_MAP = (() => {
    const obj = {};

    (DPConstants.CapabilityValues.FILTER_OPERATORS_OPS).forEach((o) => {
      const oKey = o.replace('$', '').toUpperCase();
      obj[oKey] = o; return obj;
    });

    return obj;
  })();

  /**
   * FilterCriterionUtils provides convenience methods for parsing filterCriterion
   * @constructor
   */
  class FilterCriterionUtils {
    static encodeValue(value) {
      if (typeof value === 'string') {
        return `'${value}'`;
      }
      return value;
    }

    static getFilterOpsMap() {
      return FILTER_OPS_MAP;
    }

    /**
     * returns true if filtercriterion 'value' is undefined.
     * @param val
     * @returns {boolean}
     */
    static isEmptyValue(val) {
      return (val === undefined);
    }

    /**
     * Escape single quotes in a string value
     * @param value
     * @returns value
     */
    static escapeSingleQuotes(value) {
      // The value passed here would be directly consumed by SQL query hence escaping single quotes by doubling them up
      const str = (typeof value === 'string') && value.replace(/'/g, '\'\'');
      return typeof str === 'string' ? str : value;
    }

    /**
     * returns true for supported JET operators. See oj.AttributeFilterOperator for full list.
     * @param aop
     * @returns {boolean}
     */
    static isAttributeOperator(aop) {
      return (aop === FILTER_OPS_MAP.EQ
        || aop === FILTER_OPS_MAP.NE
        || aop === FILTER_OPS_MAP.GT
        || aop === FILTER_OPS_MAP.GE
        || aop === FILTER_OPS_MAP.LT
        || aop === FILTER_OPS_MAP.LE
        || aop === FILTER_OPS_MAP.SW
        || aop === FILTER_OPS_MAP.EW
        || aop === FILTER_OPS_MAP.CO
        || aop === FILTER_OPS_MAP.PR);
    }

    /**
     * returns true for unary operators
     * @param aop
     * @returns {boolean}
     */
    static isUnaryAttributeOperator(aop) {
      return aop === FILTER_OPS_MAP.PR;
    }

    /**
     * Returns true if it's an attribute criterion with 3 properties - op, attribute and value, where op is one of the
     * supported attribute operators, attribute is valid string and value is non-empty for non-unary operators.
     * A 'criteria' property of non-zero length returns false.
     * @param acion
     * @returns {*|boolean}
     */
    static isAttributeCriterion(acion) {
      return (acion && typeof acion === 'object'
        && FilterCriterionUtils.isAttributeOperator(acion.op) && acion.attribute
        && (FilterCriterionUtils.isUnaryAttributeOperator(acion.op)
          || !FilterCriterionUtils.isEmptyValue(acion.value))
        && (!acion.criteria || (Array.isArray(acion.criteria) && acion.criteria.length === 0)));
    }

    static wrapInParentheses(queryExpr) {
      return `(${queryExpr})`;
    }

    /**
     * builds a ramp attribute query for a valid filter attribute criterion.
     * @param attrCriterion
     * @param wrap
     * @returns {string}
     */
    static buildAttributeQuery(attrCriterion, wrap) {
      if (!FilterCriterionUtils.isAttributeCriterion(attrCriterion)) {
        return '';
      }

      let opValue = '';
      const op = attrCriterion.op;
      const value = FilterCriterionUtils.escapeSingleQuotes(attrCriterion.value);
      const attribute = attrCriterion.attribute;

      switch (op) {
        case FILTER_OPS_MAP.EQ:
          opValue = (value === null) ? 'is null' : `= ${FilterCriterionUtils.encodeValue(value)}`;
          break;
        case FILTER_OPS_MAP.NE:
          opValue = `!= ${FilterCriterionUtils.encodeValue(value)}`;
          break;
        case FILTER_OPS_MAP.GT:
          opValue = `> ${FilterCriterionUtils.encodeValue(value)}`;
          break;
        case FILTER_OPS_MAP.GE:
          opValue = `>= ${FilterCriterionUtils.encodeValue(value)}`;
          break;
        case FILTER_OPS_MAP.LT:
          opValue = `< ${FilterCriterionUtils.encodeValue(value)}`;
          break;
        case FILTER_OPS_MAP.LE:
          opValue = `<= ${FilterCriterionUtils.encodeValue(value)}`;
          break;
        case FILTER_OPS_MAP.SW:
          opValue = `LIKE '${value}%'`;
          break;
        case FILTER_OPS_MAP.EW:
          opValue = `LIKE '%${value}'`;
          break;
        case FILTER_OPS_MAP.CO:
          opValue = `LIKE '%${value}%'`;
          break;
        case FILTER_OPS_MAP.PR:
          opValue = 'is not null';
          break;
        default:
          break;
      }

      if (opValue) {
        const expr = `${attribute} ${opValue}`;
        return (wrap) ? FilterCriterionUtils.wrapInParentheses(expr) : expr;
      }
      return '';
    }

    /**
     * returns true for supported compound operators $and and $or
     * @param cop
     * @returns {*|boolean}
     */
    static isCompoundOperator(cop) {
      return cop && (cop === FILTER_OPS_MAP.AND || cop === FILTER_OPS_MAP.OR);
    }

    /**
     * returns true if compound criterion has one criteria and no compound op, or a valid conjunction op
     * @param sccion
     * @returns {boolean}
     */
    static isSingularCompoundCriterion(sccion) {
      if (!sccion.op || FilterCriterionUtils.isCompoundOperator(sccion.op)) {
        return (sccion.criteria && Array.isArray(sccion.criteria) && sccion.criteria.length === 1)
          && !sccion.attribute && !sccion.value;
      }
      return false;
    }

    /**
     * returns true if compound criterion has more than one criteria and no compound op, or a valid conjunction op
     */
    static isPluralCompoundCriterion(pccion) {
      if (!pccion.op || FilterCriterionUtils.isCompoundOperator(pccion.op)) {
        return pccion.criteria && Array.isArray(pccion.criteria) && pccion.criteria.length > 1
          && !pccion.attribute && !pccion.value;
      }
      return false;
    }

    /**
     * return true if the compound criterion has an 'op' property that uses one of the supported compound operators
     * and the 'criteria' property is an array of attribute criterion.
     * Other attribute criterion properties like 'attribute' and 'value' must be falsey. Example this will pass
     * {
     *   criteria: [ {op, '$eq', attribute: 'fo', 'value: 'bar'} ],
     *   value: '',
     *   attribute: ''
     * }
     * @param ccion
     * @returns {boolean}
     */
    static isCompoundCriterion(ccion) {
      let isCC = true;

      if (ccion && typeof ccion === 'object'
        && (FilterCriterionUtils.isSingularCompoundCriterion(ccion)
          || FilterCriterionUtils.isPluralCompoundCriterion(ccion))) {
        ccion.criteria.forEach((cion) => {
          if (isCC
            && !FilterCriterionUtils.isTextCriterion(cion)
            && !FilterCriterionUtils.isAttributeCriterion(cion)
            && !FilterCriterionUtils.isCompoundCriterion(cion)) {
            isCC = false;
          }
        });
      } else {
        isCC = false;
      }
      return isCC;
    }

    /**
     * builds a compound ramp query combining individual ramp attribute queries using a valid ramp compound operator.
     * @param compOp
     * @param query
     * @param subExpr
     * @returns {string}
     */
    static buildCompoundQuery(compOp, query, subExpr) {
      let rampOp;
      let q = query;

      switch (compOp) {
        case FILTER_OPS_MAP.OR:
          rampOp = ' or ';
          break;
        case FILTER_OPS_MAP.AND:
        default:
          rampOp = ' and ';
          break;
      }

      if (q && rampOp) {
        q = q + rampOp + subExpr;
      } else {
        q = `${subExpr}`;
      }
      return q;
    }

    static buildQueryExpression(c, ficr, query, wrapInParen) {
      let q = query || '';
      let wrap = wrapInParen || false;

      if (FilterCriterionUtils.isCompoundCriterion(ficr)) {
        ficr.criteria.forEach((attrCriterion, index, arr) => {
          if (arr.length > 1) {
            wrap = true;
          }
          q = FilterCriterionUtils.buildCompoundQuery(ficr.op, q,
            FilterCriterionUtils.buildQueryExpression(c, attrCriterion, '', wrap));
          if (index === arr.length - 1 && wrap) {
            q = FilterCriterionUtils.wrapInParentheses(q);
          }
        });
      } else if (FilterCriterionUtils.isAttributeCriterion(ficr)) {
        q = FilterCriterionUtils.buildAttributeQuery(ficr, wrap);
      }
      return q;
    }

    static isTextCriterion(acion) {
      return (acion && typeof acion === 'object'
        && !FilterCriterionUtils.isEmptyValue(acion.text));
    }
  }

  return FilterCriterionUtils;
});

