'use strict';

define('vb/extensions/dynamic/private/helpers/referenceMetadataProviderHelper',[
  'vb/extensions/dynamic/private/helpers/dataDescriptionMetadataProviderHelper',
], (DataDescriptionMetadataProviderHelper) => {
  /**
   * This helper is used to fetch layout reference. Here is an example:
   *
   * ref: {
   *   businessObject: 'employees',
   *   extensionId: 'ext-layout'
   * }
   *
   * This translates to loading layout artifacts under dynamicLayouts/self/employees from
   * the ext-layout extension.
   */
  class ReferenceMetadataProviderHelper extends DataDescriptionMetadataProviderHelper {
    static get(options, vbContext, container) {
      const helperOptions = DataDescriptionMetadataProviderHelper.getHelperConfiguration();

      const opts = Object.assign({}, options, { helper: helperOptions });

      return new ReferenceMetadataProviderHelper(opts, vbContext, container).init();
    }

    constructor(options, vbContext, container) {
      super(options, vbContext, container);

      // set up the extensionId from which the reference layout will be fetched
      this.extensionId = options.ref.extensionId || container.extensionId;
    }

    /**
     * Calculate the layout path from the reference.
     *
     * @returns {Promise<string>}
     * @private
     */
    _calcLayoutPath() {
      return Promise.resolve(`${this.getLayoutRoot()}${this.options.ref.businessObject}/`);
    }
  }

  return ReferenceMetadataProviderHelper;
});

