'use strict';

define('vb/private/services/serviceProvider',[],
  () => {
    /**
     * Class representing a Service definition that can be registered via code.
     * Usage - Services.addServiceProvider
     */
    class ServiceProvider {
      constructor(serviceName, servicePath, definition) {
        this.serviceName = serviceName;
        this.servicePath = servicePath;
        this.definition = definition;
      }

      /**
       * Returns the service name
       * @returns {String}
       */
      getServiceName() {
        return this.serviceName;
      }

      /**
       * Symbolic path now, not used to resolve the file.
       * @returns {String}
       */
      getServiceFilePath() {
        return this.servicePath;
      }

      /**
       * Should return the JSON representation of the service swagger/openApi3 file
       */
      getDefinition() {
        return this.definition;
      }
    }

    return ServiceProvider;
  });

