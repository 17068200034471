/* eslint-disable max-classes-per-file */

'use strict';

define('vb/private/services/readers/openApi2Object',[
  'vb/private/constants',
  'vb/private/services/serviceUtils',
  'vb/private/services/readers/openApiObjectCommon',
], (
  Constants,
  ServiceUtils,
  OpenApiObjectCommon,
) => {
  /**
   * Swagger 2.0 ('Swagger' is really meant to refer to the UI tooling
   */
  class OpenApi2OperationObject extends OpenApiObjectCommon.OperationObject {
    /**
     * abstraction of 2.0 "produces"
     * https://github.com/OAI/OpenAPI-Specification/blob/master/versions/2.0.md#swagger-object
     * @returns {*|Array}
     * @override
     */
    getResponseContentTypes() {
      return this.produces || this.openApi.definition.produces || [];
    }

    /**
     * abstraction of 2.0 "consumes"
     * https://github.com/OAI/OpenAPI-Specification/blob/master/versions/2.0.md#swagger-object
     * @returns {*|Array}
     * @override
     */
    getRequestContentTypes() {
      return this.consumes || this.openApi.definition.consumes || [];
    }
  }

  /**
   *
   */
  class OpenApi2Object extends OpenApiObjectCommon {
    constructor(definition, context = {}) {
      super(definition, context);
      this.definitionUrl = context.definitionUrl;
    }

    /**
     * factory method for v2 Operation Object
     * @param name
     * @param pathObject
     * @param operationObjectKey
     * @returns {OpenApi2OperationObject}
     * @override
     */
    createOperationObjectObject(name, pathObject, operationObjectKey) {
      const operationObjectDefinition = pathObject[operationObjectKey];
      return new OpenApi2OperationObject(name, pathObject, operationObjectDefinition, this);
    }

    /**
     * abstract the VBCS 'servers' extension, which was introduced early to help with the eventual 3.0 support.
     * @returns {*}
     */
    getServers() {
      if (!this._servers) {
        const servers = this.getExtensions().servers;

        if (servers && servers.length > 0) {
          this._servers = servers.map((server) => {
            const newServer = Object.assign({}, server);
            delete newServer.url;
            newServer.getUrl = () => server.url;
            return newServer;
          });
        } else {
          let uriPrefix = this.definition.host || '';
          if (uriPrefix) {
            let preferredScheme;

            if (Array.isArray(this.definition.schemes)) {
              const schemesToLower = this.definition.schemes.map((scheme) => scheme.toLowerCase());

              // 1) if there is an HTTPS in “schemes”, use that. otherwise,
              // 2) if there is an HTTP in “schemes”, use that. otherwise,
              // 3) we still have the first protocol, OR we default to HTTP is there was no first one
              if (schemesToLower.indexOf(OpenApiObjectCommon.Protocol.HTTPS) >= 0) {
                preferredScheme = OpenApiObjectCommon.Protocol.HTTPS;
              } else if (schemesToLower.indexOf(OpenApiObjectCommon.Protocol.HTTP) >= 0) {
                preferredScheme = OpenApiObjectCommon.Protocol.HTTP;
              } else {
                preferredScheme = schemesToLower[0];
              }
            }

            // ... and default to http
            preferredScheme = preferredScheme || OpenApiObjectCommon.Protocol.HTTP;
            uriPrefix = `${preferredScheme}://${uriPrefix}`;
          }

          const basePath = this.definition.basePath === Constants.PATH_SEPARATOR
            ? ''
            : (this.definition.basePath || '');
          let url = `${uriPrefix}${basePath}`;
          if (this.definitionUrl) {
            url = ServiceUtils.toAbsoluteServerUrl(this.definitionUrl, url);
          }

          this._servers = [{
            getUrl: () => url,
          }];
        }
      }
      return this._servers;
    }
  }

  return OpenApi2Object;
});

