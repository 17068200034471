'use strict';

define('vb/errors/httpError',[], () => {
  const ERROR_CODE = {
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
  };

  const STATUS_CODE_PROPERTY = 'statusCode';

  /**
   * HttpError class extend Error and support a HTTP error status code
   */
  class HttpError extends Error {
    constructor(statusCode, cause, message = cause.message, ...params) {
      super(message, ...params);
      // Make statusCode and cause 2 read-only properties
      Object.defineProperties(this, {
        [STATUS_CODE_PROPERTY]: {
          get: () => statusCode,
          enumerable: true,
        },
        cause: {
          get: () => cause,
          enumerable: true,
        },
      });

      // Maintains proper stack trace for where our error was thrown (only available on V8)
      if (Error.captureStackTrace) {
        Error.captureStackTrace(this, HttpError);
      }
    }

    isUnauthorized() {
      return this[STATUS_CODE_PROPERTY] === ERROR_CODE.UNAUTHORIZED;
    }

    isForbidden() {
      return this[STATUS_CODE_PROPERTY] === ERROR_CODE.FORBIDDEN;
    }

    isFileNotFound() {
      return this[STATUS_CODE_PROPERTY] === ERROR_CODE.NOT_FOUND;
    }

    static isHttpError(error) {
      return error && !!error[STATUS_CODE_PROPERTY];
    }

    /**
     * Return true if this error indicate a file not found error.
     * This is meant to be used for checking error duck typing HttpError so it checks
     * the statusCode property.
     *
     * @param  {Error}  error the error to check
     * @return {Boolean}      true is the error is file not found
     */
    static isFileNotFound(error) {
      return error && error[STATUS_CODE_PROPERTY] === ERROR_CODE.NOT_FOUND;
    }
  }

  return HttpError;
});

