'use strict';

define('vb/private/services/definition/serviceProviderFactory',[
  'vb/private/log',
  'vb/private/services/serviceProvider',
  'vb/private/services/definition/openApiServiceDefFactory',
], (
  Log,
  ServiceProvider,
  OpenApiServiceDefFactory,
) => {
  const logger = Log.getLogger('/vb/private/services/definition/ServiceProviderFactory');

  const TYPE = 'serviceProvider';

  /**
   * Class responsible for loading ServiceDefinitions from ServiceProviders registered via Services.
   */
  class ServiceProviderFactory extends OpenApiServiceDefFactory {
    constructor(services, options) {
      super(services, options);

      /**
       * serviceName => ServiceProvider
       */
      this._serviceProviderMap = {};
    }

    /**
     * @override
     */
    findDeclaration(endpointReference) {
      return this._serviceProviderMap[endpointReference.serviceName];
    }

    /**
     * @override
     */
    getAllServiceIds() {
      return Object.keys(this._serviceProviderMap).map((serviceName) => `${this.services.namespace}:${serviceName}`);
    }

    /**
     * @override
     */
    loadDefinition(endpointReference, declaration) {
      return Promise.resolve()
        .then(() => {
          // We don't need the catalog info and requestInit for service providers right now.
          const serviceProvider = declaration.serviceProvider;
          if (this.services.isPathAllowed(serviceProvider.getServiceFilePath())) {
            return OpenApiServiceDefFactory.getOpenApi(serviceProvider.getDefinition())
              .then((openApi) => openApi && this.createServiceDefinition(
                serviceProvider.getServiceName(),
                serviceProvider.getServiceFilePath(),
                { chain: [] },
                openApi,
                null,
              ));
          }
          return null;
        });
    }

    //------------------------------------------------------------------------------

    /**
     * Add an instance of ServiceProvider that needs to be registered to provide the service
     * @param serviceProvider
     */
    addServiceProvider(serviceProvider) {
      if (serviceProvider instanceof ServiceProvider) {
        const serviceName = serviceProvider.getServiceName();

        // if the provider or the service def has already been registered or loaded...
        if (this._serviceProviderMap[serviceName]) {
          const error = new Error(`The serviceProvider with name ${serviceName} is already registered.`);
          logger.error(error);
          throw error;
        }

        this._serviceProviderMap[serviceName] = {
          serviceProvider,
          type: TYPE,
        };
      } else {
        logger.error('Given serviceProvider is not an instance of ServiceProvider');
      }
    }
  }

  ServiceProviderFactory.TYPE = TYPE;

  return ServiceProviderFactory;
});

