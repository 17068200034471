'use strict';

define('vb/types/eventTargetMixin',[], () => {
  /**
   * Simple implementation of EventTarget that allows callers to register event listeners
   * for Data provider events.
   * The EventTarget interface is here: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget
   *
   * This is defined as a mixin so that sub classes can mixin this class with other classes to essentially inherit from
   * multiple classes. A class can use this mixin by importing the 'vb/helpers/mixin' module and defining their class
   * in the following way:
   *
   * <code>
   *   class MyClass extends Mix(BaseType).with(EventTargetMixin)
   * </code>
   *
   * where 'Mix' is the module name for the 'vb/helpers/mixin' module,
   * 'BaseType' is the base or root class that you want to start with. If a BaseType is not needed then use this
   * convention - class MyClass extends Mix().with(EventTargetMixin)
   * 'EventTargetMixin' is this class that extends from 'BaseType' and would be the superclass of 'MyType'.
   *
   * So MyType extends ExtendedType extends BaseType. Using the above approach allows a custom extended type to extend
   * from multiple classes.
   */
  const EventTargetMixin = (superclass) => class extends superclass {
    addEventListener(eventType, listener) {
      if (!this._eventListeners) {
        this._eventListeners = [];
      }
      this._eventListeners.push({
        type: eventType.toLowerCase(),
        listener,
      });
    }

    removeEventListener(eventType, listener) {
      if (this._eventListeners) {
        this._eventListeners.forEach((l, index) => {
          if (l.type === eventType && l.listener === listener) {
            this._eventListeners.splice(index, 1);
          }
        });
      }
    }

    removeAllEventListeners() {
      this._eventListeners = null;
    }

    dispatchEvent(evt) {
      if (this._eventListeners) {
        let returnValue;
        this._eventListeners.forEach((l) => {
          if (l.type === evt.type) {
            returnValue = l.listener.apply(this, [evt]);
            if (returnValue === false) {
              return false;
            }
          }
          return true;
        });
      }
      return true;
    }
  };

  return EventTargetMixin;
});

