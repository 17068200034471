'use strict';

define('vb/extensions/dynamic/private/types/bridges/jsonMetadataProviderFactoryBridge',[
  'vb/private/utils',
  'vb/extensions/dynamic/private/types/bridges/metadataProviderFactoryBridge',
], (Utils, MetadataProviderFactoryBridge) => {
  /**
   * Used by JsonMetadataProviderDescriptor, to create the JET metadata provider.
   *
   * Concrete classes subclass this to provide a 'bridge' between
   * the VB parameters (typically defined at the top-level of the "defaultValue"), and the JET parameters.
   *
   */
  class JsonMetadataProviderFactoryBridge extends MetadataProviderFactoryBridge{
    /**
     *
     */
    getDelegatePath() { // options) {
      return 'oj-dynamic/providers/JsonMetadataProviderFactory';
    }
  }

  return JsonMetadataProviderFactoryBridge;
});

