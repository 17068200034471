/* eslint-disable class-methods-use-this,max-classes-per-file */

'use strict';

define('vb/private/events/transformEventBehavior',[
  'vb/private/events/eventBehavior',
  'vb/private/constants',
  'vb/private/log',
  'vb/action/action',
], (EventBehavior, Constants, Log, Action) => {
  const logger = Log.getLogger('/vb/private/events/transformEventBehavior');
  /**
   * TransformEventBehavior, behavior = "transform"
   */
  class TransformEventBehavior extends EventBehavior {
    /**
     * override, to return the actual result, instead of undefined
     * @param functionWrappers
     * @returns {Promise<{ result: * }>}
     * @override
     */
    execute(functionWrappers) {
      logger.info('Triggering declared event, name:', this.name, 'behavior:',
        this.behavior, 'payloadType:', this.payloadType);

      return this.executeInternal(functionWrappers)
        .then((result) => ({ [EventBehavior.ResultProperties.RETURN]: result }));
    }

    /**
     * call the (curried) invokeEvent functions serially, and also call the chains called within
     * each invokeEvent serially.
     *
     * Before calling each chain, $previous is added to the expressionContexts, to be optionally passed to
     * the chain, as defined by the declared listener. (@see TransformEventBehavior.callChainFunctions)
     *
     * When assigning 'previous' from the result of an invokeEvent, we need to ignore any that return
     * the 'no listener' marker.
     *
     * The result is a Promise, resolved with a value that is coerced to the resultType.
     * @param functionWrappers
     * @private
     * @override
     */
    executeInternal(functionWrappers) {
      // an initial, valid, empty result to start with
      let previousValidResult = {
        name: Action.Outcome.SUCCESS,
        result: undefined,
      };

      return functionWrappers
        .reduce((p, wrapper) => Promise.resolve(p)
          .then((result) => {
            previousValidResult = TransformEventBehavior
              .resultToPrevious(result, this.returnType, previousValidResult);
            return wrapper.fnc(this, previousValidResult);
          }),
        Promise.resolve())
        // handle the result of the reduce()
        .then((finalResult) => TransformEventBehavior
          .resultToPrevious(finalResult, this.returnType, previousValidResult));
    }

    /**
     * called during execute(), serialized, and passed the previous result (if any).
     * The functions should be passed the expression context.
     * Container curries the other required parameters (id, params).
     * The function signature should be: fnc()
     *
     * @param container
     * @param chainFunctionWrappers Array<{chainId: string, fnc: function}
     * @param contexts
     * @param previousResult
     * @returns {Promise<{ name: string, result: * }>}
     * @override
     * @private
     */
    callChainFunctionsInternal(container, chainFunctionWrappers, contexts, previousResult) {
      const expressionContexts = contexts;
      expressionContexts[Constants.ContextName.PREVIOUS] = previousResult;

      // as if we came from an action chain for the initial iteration through reduce()
      const initialResult = {
        name: Action.Outcome.SUCCESS,
        result: contexts[Constants.ContextName.PREVIOUS],
      };

      return chainFunctionWrappers
        .reduce((p, wrapper) => Promise.resolve(p)
          .then((result) => {
            expressionContexts[Constants.ContextName.PREVIOUS] = TransformEventBehavior
              .resultToPrevious(result, this.returnType);
            return wrapper.fnc(expressionContexts);
          }),
        Promise.resolve(initialResult));
    }

    /**
     * convert the result of a listener invocation (a chain) to the value used for $previous.
     * if it is NOT "success", $previous will be undefined
     * @param result
     * @param type
     * @param lastPrevious the last valid result, meaning, the last result that was not a 'no listener' marker
     * @returns {*}
     */
    static resultToPrevious(result, type, lastPrevious) {
      if (result) {
        if (result.name === Action.Outcome.SUCCESS) {
          return EventBehavior.shape(result.result, type);
        }
        if (result === Constants.NO_EVENT_LISTENER_RESPONSE) {
          return lastPrevious;
        }
      }
      return undefined;
    }
  }

  return TransformEventBehavior;
});

