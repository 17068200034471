'use strict';

define('vb/private/stateManagement/pageInExtension',[
  'vb/private/stateManagement/page',
  'vb/private/constants',
], (Page, Constants) => {
  /**
   * PageInExtension class
   * A class for page with resources defined in an extension
   */
  class PageInExtension extends Page {
    constructor(pageInfo, parent, path, className = 'PageInExtension') {
      super(pageInfo.id, parent, path, className);

      // Override value of extension defined in Container
      this._extension = pageInfo.extension;
    }

    /**
     * Override value defined in Container
     * @type {String}
     */
    get resourceLoc() {
      // ui/self/{path}
      return `${Constants.DefaultPaths.UI}${Constants.ExtensionFolders.SELF}/${this.path}`;
    }

    /**
     * The default event prefix is the lowercase class name (see container.js) but for
     * packagePage we want to use the same event prefix as page
     *
     * @type {String}
     */
    // eslint-disable-next-line class-methods-use-this
    get eventPrefix() {
      return 'page';
    }

    /**
     * Check that a file exist in the this App UI
     * Use the files list provided by he extension
     * Throws a HttpError if the file does not exist
     *
     * @param {String} fileExt the file extension of the resource to check
     */
    checkResourceExist(fileExt) {
      this.extension.fileExists(`${this.resourceLoc}${this.fullName}.${fileExt}`);
    }

    descriptorLoader(resourceLocator) {
      // make sure the extension is initialized otherwise the requirejs mapping might not be defined
      // and the resource will no be found when in "Play" mode.
      return this.extension.init().then(() => {
        this.checkResourceExist('json');
        return super.descriptorLoader(resourceLocator);
      });
    }

    functionsLoader(resourceLocator) {
      // make sure the extension is initialized otherwise the requirejs mapping might not be defined
      // and the resource will no be found when in "Play" mode.
      return this.extension.init().then(() => {
        this.checkResourceExist('js');
        return super.functionsLoader(resourceLocator);
      });
    }

    templateLoader(resourceLocator) {
      // make sure the extension is initialized otherwise the requirejs mapping might not be defined
      // and the resource will no be found when in "Play" mode.
      return this.extension.init().then(() => {
        this.checkResourceExist('html');
        return super.templateLoader(resourceLocator);
      });
    }
  }

  return PageInExtension;
});

