'use strict';

// Boss Transforms constants
define('vb/private/services/boss/bossTransformsConstants',[], () => {
  /**
   * Constants
   */
  const Constants = {};

  /**
   * Common Constants
   */
  Constants.Common = Object.freeze({
    PROPERTIES: 'properties',
    DATA_TYPE_STRING: 'string',
    DATA_TYPE_ANY: 'any',
    DOUBLE_QUOTES: '\'\'',
    NULL: 'null',
    MAX_LIMIT: 1000,
    METHOD_TYPE_GET: 'GET',
  });

  /**
   * Capability types
   */
  Constants.CapabilityType = Object.freeze({
    FETCH_BY_KEYS: 'fetchByKeys',
    // Boss framework supported parameters are prefixed with underscore. It distinguishes them
    // from the user defined parameters.
    FILTER: '_filter',
    LIMIT: '_limit',
    OFFSET: '_offset',
    FIELDS: '_fields',
    // To conform to Spectra standard, support for $sortBy has been introduced in the Boss RestAPIs.
    SORT_BY: '_sortBy',
  });

  /**
   * Capability Type values
   */
  Constants.CapabilityValues = Object.freeze({
    FETCH_FIRST_IMPLEMENTATION_ITERATION: 'iteration',
    FETCH_BY_KEYS_IMPLEMENTATION_LOOKUP: 'lookup',
    FETCH_BY_KEYS_MULTI_KEY_LOOKUP_YES: 'yes',
    SORT_DIRECTION_ASCENDING: 'ascending',
    SORT_ORDER_ASC: ':asc',
    SORT_ORDER_DESC: ':desc',
    ATTRIBUTE_X_SORTABLE_PROP: 'x-sortable',
    ATTRIBUTE_X_SEARCHABLE_PROP: 'x-searchable',
    ATTRIBUTE_X_PRIMARY_KEY: 'x-primaryKey',
    VB_TEXT_FILTER_ATTRS: 'vb-textFilterAttributes',
    SENSITIVITY_ACCENT: 'accent',
    IN_QUERY: 'query',
  });

  /**
   * Delimiters
   */
  Constants.Delimiters = Object.freeze({
    AMPERSAND: '&',
    QUESTION_MARK: '?',
    DOLLAR: '$',
    PERCENTAGE: '%',
    TILDE: '~',
    COMMA: ',',
  });

  /**
   * Operators supported by Boss RestAPIs
   */
  Constants.Operators = Object.freeze({
    SCIM: {
      $eq: '=', $ne: '!=', $gt: '>', $ge: '>=', $lt: '<', $le: '<=', $sw: '$sw', $ew: '$ew', $co: '$co', $pr: '$pr',
    },
    Comparison: {
      in: 'IN', like: 'LIKE', isNull: 'IS NULL', isNotNull: 'IS NOT NULL',
    },
    Logical: {
      or: { op: '$or', val: 'or' }, and: { op: '$and', val: 'and' }, exists: { op: '$exists', val: 'exists' },
    },
  });

  /**
   * RegExp
   */
  Constants.RegExp = Object.freeze({
    CI_FLAG: 'i', // Case insensitive search flag
    QUERY_DELIMITER_GROUP: '([?&])',
    QUERY_PARAM_KEY_GROUP: '(?:\\$|%24)',
    QUERY_PARAM_VALUE_GROUP: '=.*?(&|$)',
    MATCH1: '$1',
    MATCH2: '$2',
    SINGLE_QUOTE: /'/g,
  });

  return Constants;
});

