'use strict';

define('vb/private/services/boss/bossTransforms',[
  'vb/private/services/boss/responseTransforms',
  'vb/private/services/boss/requestTransforms',
  'vb/private/services/boss/metadataTransforms'],
(ResponseTransforms, RequestTransforms, MetadataTransforms) => {
  /**
   * Mark the functions as built-in by attaching 'doesQueryEncoding' property to them.
   *
   * @param functionMap an object representing the transformation functions
   */
  function tagFunctionsAsBuiltIn(functionMap) {
    const fnMap = functionMap;
    Object.keys(fnMap || {}).forEach((key) => { fnMap[key].doesQueryEncoding = true; });
  }

  // BOSS Request, Response and  Metadata transforms
  const request = RequestTransforms.toObject();
  const response = ResponseTransforms.toObject();
  const metadata = MetadataTransforms.toObject();

  // Tag each as a built-in function
  tagFunctionsAsBuiltIn(request);
  tagFunctionsAsBuiltIn(response);
  tagFunctionsAsBuiltIn(metadata);

  // Return BOSS transforms
  return {
    request,
    response,
    metadata,
  };
});

