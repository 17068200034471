'use strict';

define('vb/private/stateManagement/instanceFactoryVariable',['knockout',
  'vb/private/stateManagement/variable',
  'vb/private/constants',
  'vb/private/utils',
  'vb/binding/expression',
  'vb/private/stateManagement/context/variableBridge',
  'vb/private/log',
  'signals',
  'vb/private/utils',
  'vb/private/constants',
], (ko, Variable, Constants, Utils, Expression, VariableBridge) => {
  // eslint-disable-next-line no-useless-escape
  const MODULE_CLASS_PATTERN = /<(.*?)>/;

  /**
   * convenience func to update the prevValue with the instance and the constructorParams
   * @param prevValueIS
   * @param newInstance
   * @param newConstructorParams
   * @return {Object} the old value that was replaced
   */
  const UPDATE_PREV_VALUE_FUNC = (prevValueIS, newInstance, newConstructorParams) => {
    const prevValueInStore = prevValueIS;
    const oldInstance = prevValueInStore.instance;
    const oldCP = Utils.cloneObject(prevValueInStore.constructorParams);

    // update
    prevValueInStore.instance = newInstance;
    // clone the constructorParams first to break out of ko observables that might potentially pick any future changes
    // to referenced observables
    // const deepValue = Utils.deepResolve(Utils.cloneObject(newConstructorParams), { freeze: false });
    const clonedCP = Utils.cloneObject(newConstructorParams);
    prevValueInStore.constructorParams = clonedCP;

    return { instance: oldInstance, constructorParams: oldCP };
  };

  /**
   * A variable whose type refers to any generic module that returns a class of the type.
   * A unique instance of the type is created using its constructor (new a/genericType()) when the variable is
   * created, and every time any of its 'constructorParams' changes.
   * In the below example if 'foo' and 'bar' properties change, a new instance is created.
   *
   * The InstanceFactory variable has the following definition -
   * {
   *   "genericTypeVar": {
   *     "type": "vb/InstanceFactory<a/genericType>",
   *     "constructorParams": {
   *       "foo" : "{{ $variables.foo }},
   *       "bar": "bar"
   *     }
   *   }
   * }
   *
   * TODO: using a factory modules and methods are not supported yet. Also async factory methods are also not supported
   *
   * The value of the variable is an Object containing 2 properties - instance and constructorParams
   *
   * The instance is stored in redux as is and any internal state changes are not tracked. For example if the
   * instance has some internal property 'lucy' that changes VB does not create a new instance, because 'lucy' is
   * not listed under constructorParams.
   * It is possible for callers to directly set an instance, which will cause the constructorParams to get
   * out-of-sync. It's important that both be kept in sync especially if value needs to be persisted.
   *
   * A new instance will be created in the following cases:
   * - at init time, VB variable creates an instance using the constructorParams
   * - page author mutates a referenced variable in the constructorParams using assignVariablesAction.
   * - page author mutates the constructorParams directly using writable expressions. This does not work as expected
   *   due to stale data issues and array wrappers and has been disabled for now.
   * - page author can call resetVariablesAction on the instance factory type variable, which will reset the
   * variable to its original state
   * - page author updates the instance directly (i.e. in JS)
   *
   * Calling methods on the instance can be done using callVariableMethodAction. Example,
   * "parameters": {
   *   "variable": "$page.variables.incidentsListLDPV",
   *   "method": "getCapability",
   *   "params": [ "sort" ]
   * }
   */
  class InstanceFactoryVariable extends Variable {
    constructor(scope, name, namespace, type, defaultValue, initialValue, descriptor, variableDef) {
      super(scope, name, namespace, type, defaultValue, initialValue, descriptor);
      this.variableDef = variableDef;
      this.dependencies = descriptor.dependencies;
      this.instanceFactoryTypeClass = undefined;
      this.prevValueWrapped = undefined;
      this.variableBridge = undefined;
      // empty array is assumed for constrcutorParams when none specified so reducer (see createReducer) pushes []
      // as the default value to store
      this.defaultValue = { constructorParams: defaultValue || [] };
      // initialValue can come in various forms
      // - as Array, usually constructorParams (default case, persisted value). Instance prop can never be an array
      // - as Object with both instance and constructorParams properties (example caller provides a factory variable)
      // - as Object which is just the instance (example where caller passes in just the instance)
      // Note: last 2 are most common in fragments.
      if (initialValue && !(Array.isArray(initialValue))) {
        // initialValue is assumed to the constructorParams value; if a reference is provided instead, store that in
        // a this.initialInstanceValue
        this.initialValue = initialValue.constructorParams || [];
        // either we are passed an Object containing an instance prop or we are passed the instance directly
        this.instanceInitialValue = initialValue.instance
          || (!initialValue.constructorParams ? initialValue : undefined);
        if (this.instanceInitialValue) {
          this.log.finer('variable', this.name, 'was provided an instance of type',
            this.instanceInitialValue.constructor.name, 'as its initial value');
        }
      } else {
        this.initialValue = initialValue || [];
      }

      // TODO: limit writeback via expressions to properties
      this.writableOptions = { propertiesWritable: Constants.VariableWritablePropertyOptions.NONE };
      this.typeClassification = Constants.VariableClassification.INSTANCE_FACTORY;
    }

    /**
     * overridden to return the initial value to store in redux, which for instance factory variable includes the
     * constructorParams and the instance (yet to be created).
     * @see Variable#createReducer
     * @return {*}
     */
    getReducerInitialValue() {
      return { instance: this.instanceInitialValue, constructorParams: this.initialValue };
    }

    /**
     * Create a redux action to be used in a call to the redux store.dispatch. This method is called from
     * setValue(). Always return a massaged value that includes the updated instance. Generally setValue() for
     * instanceFactoryType variables is always the instance.
     *
     * @param  {Object} value the new value for the variable
     * @returns {{variable: Variable, type: string, value: *}}
     */
    createUpdateAction(value) {
      const rawStoreValue = this.getValueFromStore(true);
      if (rawStoreValue) {
        rawStoreValue.instance = value.instance;
        if (value.constructorParams) {
          rawStoreValue.constructorParams = value.constructorParams;
        }
      }
      return { type: Variable.UPDATE_ACTION_TYPE, variable: this, value: rawStoreValue };
    }

    /**
     * Returns a Promise that resolves when variable is fully initialized and active. When the variable has
     * dependencies then it waits to activate to complete on all variables it dependents on before initiating
     * activation.
     * @param currentScope
     * @param {Object} availableContexts
     * @return {Promise<*>} resolves when variable is active. Waits for referenced variables to be active before the
     * creating instance of current variable
     */
    activate(currentScope, availableContexts) {
      return this.activateAsync(currentScope, availableContexts);
    }

    /**
     * Activates variable asynchronously by loading referenced variables in order of dependency
     * @param currentScope
     * @param availableContexts
     * @return {*|Promise<unknown>}
     * @private
     */
    activateAsync(currentScope, availableContexts) {
      this.activatePromise = this.activatePromise
        || this.activateReferencedVariables(currentScope, availableContexts).then(() => {
          // all referenced variables must be fully active at this point
          const currentValue = this.getValueFromStore();
          const { instance, constructorParams } = currentValue || {};
          if (instance) {
            // During activation if an instance is already provided then use that instead of creating a new one.
            this.lifecycleStage = Constants.VariableLifecycleStage.ACTIVE;
            this.log.finer('activated variable', this.name, 'using the instance that was provided');
            return Promise.resolve();
          }
          return this.createInstanceAsync(constructorParams).then((newInstance) => {
            this.lifecycleStage = Constants.VariableLifecycleStage.ACTIVE;
            this.setValue({ instance: newInstance });
            this.log.finer('activated variable', this.name);
          });
        });
      return this.activatePromise;
    }

    /**
     * Locate the referenced variables, activate them and return when all is resolved
     * @param currentScope
     * @param availableContexts
     * @return Promise<any> resolves when all referenced variables are active
     * @private
     */
    activateReferencedVariables(currentScope, availableContexts) {
      const referencedVars = (this.dependencies && this.dependencies.variables) || [];
      if (referencedVars.length > 0) {
        const suppVarsPromises = [];
        referencedVars.forEach((sVar) => {
          const refVar = sVar;
          if (refVar) {
            const scope = availableContexts[refVar.scopeName] || currentScope;
            const variable = scope.getVariable(refVar.variableName, Constants.VariableNamespace.VARIABLES);
            refVar.variable = variable;
            suppVarsPromises.push(variable.activate(currentScope, availableContexts));
          }
        });
        return Promise.all(suppVarsPromises);
      }
      return Promise.resolve();
    }

    /**
     * returns the serialized state for this variable. Discard any properties that are expression that might be other
     * instance factory types.
     * @return {*}
     */
    serialize() {
      // TODO:  are there issues with dropping instances entirely?
      const REPLACER_FUNC = (k, v) => ((Utils.isCloneable(v) || Utils.isPrimitive(v)) ? v : undefined);
      const currentValue = this.getValueFromStore();
      const constructorParams = currentValue && currentValue.constructorParams;

      return JSON.stringify(constructorParams, REPLACER_FUNC);
    }

    /**
     * Instance Factory types need to store both the instance and the constructorParams in redux so
     * ko subscriptions work when dependents change. The value returned by this computed is an Object containing
     * both the instance and the constructorParams.
     * @param raw
     */
    getComputedValueObservable(raw = false) {
      return ko.computed(() => {
        // this computed is called both when retrieving the value of the variable and also when a referenced variable
        // changes on the constructorParams. For the latter case we diff the latest value in store with the last known
        // value (of constructorParams). If there is a diff then we create a new instance, update store and return
        // new value. We only do this after an instance has been created post-activate.

        let valueInStoreWrapped = this.getValueFromStore(raw);

        if (this.prevValueWrapped && this.prevValueWrapped.constructorParams) {
          if (this.prevValueWrapped.instance === valueInStoreWrapped.instance) {
            // It's unclear if a diff of the constructorParams is needed before creating a new instance as the computed
            // value is being re-evaluated because ko detected a change in the constructorParams

            // TODO: can a ko computed be async to resolve its value?!
            const newInstance = this.createInstance(valueInStoreWrapped.constructorParams);
            const oldValue = UPDATE_PREV_VALUE_FUNC(this.prevValueWrapped,
              newInstance, valueInStoreWrapped.constructorParams);

            // this would update the instance in the store
            const updateStoreValue = this.createUpdateAction({ instance: newInstance });
            this.log.finer('Updating variable', this.name, 'with a new instance value,', updateStoreValue);
            this.scope.store.dispatch(updateStoreValue);
            if (this.scope.silent === false) {
              // re-fetch new value from store
              valueInStoreWrapped = this.getValueFromStore(raw);
              this.dispatchChangeEvent(oldValue, valueInStoreWrapped);
            }
          } else {
            UPDATE_PREV_VALUE_FUNC(this.prevValueWrapped,
              valueInStoreWrapped.instance, valueInStoreWrapped.constructorParams);
          }
        }

        // to update instance during activation
        if (!this.prevValueWrapped && valueInStoreWrapped.instance) {
          this.prevValueWrapped = this.prevValueWrapped || {};
          UPDATE_PREV_VALUE_FUNC(this.prevValueWrapped, valueInStoreWrapped.instance,
            valueInStoreWrapped.constructorParams);
          // re-fetch wrapped value from store
          valueInStoreWrapped = this.getValueFromStore(raw);
        }

        this.triggerObservable();
        return valueInStoreWrapped;
      });
    }

    /**
     * called in the following situations:
     * i. when the instance is set as is (during activation or when caller sets it using assignVars / component
     *    writebacks?)
     * ii. when the constructorParams is changed (during assignVars, or using property writebacks)
     * iii. when both are set.
     * iv. when a fragment param is updated, it's possible that we get the instance directly instead of coming
     * wrapped in an object - { instance : <instance> }
     * @param value
     */
    setValueInternal(value) {
      // if the scope has not yet been hooked up with the store, simply change the initialValue
      // this can occur in a personalization variable if retrieval of the data happens quickly
      if (!this.scope.store) {
        this.initialValue = value;
        return;
      }

      const val = this.fixupValue(value);

      const currentValue = this.getValueFromStore();
      const unwrappedValue = Variable.unwrapAndClone(val);
      if (!unwrappedValue.instance || (unwrappedValue.instance === currentValue.instance)) {
        // when the instance in store is the same as the instance in value, or when value has no instance, then create
        // a new instance using the constructorParams. Assume for now that constructorParams have changed. Ideally
        // we need to diff to make sure there are changes before updating redux.
        // TODO: sanity check constructorParams are valid!
        const wrappedCP = this.getWrappedValue(unwrappedValue.constructorParams
          || currentValue.constructorParams);
        const newInstance = this.createInstance(wrappedCP);
        unwrappedValue.instance = newInstance;
      }

      const updateActionResult = this.createUpdateAction(unwrappedValue);
      const finalStoreValue = updateActionResult.value;

      // Only log when level is finer. This is to avoid fetching the value unnecessarily.
      if (this.log.isFiner) {
        if (ko.isObservable(value)) {
          this.log.finer('Updating variable', this.name, 'to an expression =', finalStoreValue());
        } else {
          this.log.finer('Updating variable', this.name, 'to', finalStoreValue);
        }
      }

      // update the store and event
      this.scope.store.dispatch(updateActionResult);
      if (this.scope.silent === false) {
        // we need to re-fetch the variable otherwise we will not evaulate expressions
        // contained within it (then freeze it to make sure no one attempts to write to it)
        const newValue = this.getValue();
        this.dispatchChangeEvent(currentValue, newValue);
      }
    }

    /**
     * Ensures that value is wrapped in a literal Object with instance or constructorParams or both. If we get an
     * Object it is assumed to be an instance and array, constructorParams
     * @param {import("knockout").observableArray|Object|Array} value
     * @return { {instance?: Object|*, constructorParams?: Array|*} } value wrapped in the right properties.
     */
    // eslint-disable-next-line class-methods-use-this
    fixupValue(value) {
      if (Array.isArray(value)) {
        return { constructorParams: ko.isObservable(value) ? value() : value };
      }
      // guard against false-ys; if we are here then we really need an instance!!
      if (value && !value.instance && !value.constructorParams) {
        return { instance: ko.isObservable(value) ? value() : value };
      }

      return value;
    }

    /**
     * Creates new instance of the 'type' class
     * @param {Object} constructorParams
     *
     * @returns {Promise} resolves with the activated instance
     * @private
     */
    createInstanceAsync(constructorParams) {
      return Promise.resolve().then(() => {
        // when instance type is not specified with vb/InstanceFactory the type name is assumed to be the instance type
        const matchType = this.type.match(MODULE_CLASS_PATTERN);
        if (Array.isArray(matchType) && matchType[1]) {
          this.type = matchType[1];
        } else {
          // when variable references a type in different scope and constructorType property for the type specifies just
          // vb/InstanceFactory, we revert to using the type name as the type. But strip the scope prefix before using
          // the same
          const parts = Utils.parseQualifiedIdentifier(this.variableDef.type);
          if (parts) {
            this.type = `${parts.main}/${parts.suffix}`;
          }
        }

        return Utils.getResource(this.type).then((RealTypeClass) => {
          this.instanceFactoryTypeClass = RealTypeClass;
          const args = constructorParams || this.initialValue;
          const instance = this.createInstance(args);
          const activatePromise = this.variableBridge ? this.variableBridge.activateAsync() : Promise.resolve();
          return activatePromise.then(() => (instance));
        });
      });
    }

    /**
     * creates instance synchronously using either constructor or factory method
     * @param args
     *
     * @return the instance of the type
     */
    createInstance(args) {
      const type = this.type;
      const factoryMethod = this.createMethod;
      const RealClazz = this.instanceFactoryTypeClass;
      let clazzInstance;

      if (!factoryMethod) {
        clazzInstance = new RealClazz(...args);
      } else {
        clazzInstance = RealClazz[this.createMethod].call(null, ...args);
      }
      this.addContextToVBTypes(clazzInstance);
      const methodStr = this.createMethod ? `factory method ${this.createMethod}` : 'constructor';
      if (this.log.isFiner) {
        this.log.finer(' Created new instance for variable', this.name, 'of type', type, 'using', methodStr,
          'and args', ...args);
      }
      return clazzInstance;
    }

    /**
     * For VB types provide a VariableBridge that allows these types to access internal VB methods.
     * @param clazzInstance
     */
    addContextToVBTypes(clazzInstance) {
      switch (this.type) {
        case 'vb/MultiServiceDataProvider2':
        case 'vb/ServiceDataProvider2':
          this.variableBridge = new VariableBridge(this.scope, clazzInstance);
          clazzInstance.setVariableBridge(this.variableBridge);
          break;
        default:
          break;
      }
    }

    /**
     * overridden so property handler can be setup for the instance property alone.
     * @param parentObject
     * @param propKey
     * @param propertyHandler
     * @param root
     */
    setupPropertyHandler(parentObject, propKey, propertyHandler, root) {
      const propValue = parentObject[propKey];

      if (propKey === 'instance' && propValue && Variable.isPrototypeInstance(propValue)) {
        propertyHandler.call(this, parentObject, propKey, propValue);
      } else {
        super.setupPropertyHandler(parentObject, propKey, propertyHandler, root);
      }
    }
  }
  return InstanceFactoryVariable;
});

