'use strict';

define('vb/private/types/dataProviders/multiServiceDataProviderUtils',['knockout',
  'vb/private/types/dataProviderConstants',
  'vb/private/types/dataProviders/serviceDataProviderUtils',
  'vb/private/types/capabilities/noOpFetchFirst',
  'vb/private/types/capabilities/noOpFetchByKeys',
  'vb/private/types/capabilities/noOpFetchByOffset',
], (ko, DPConstants, SDPUtils, NoOpFetchFirst, NoOpFetchByKeys, NoOpFetchByOffset) => {
  class MultiServiceDataProviderUtils {
    /**
     * defines the fetchFirst methods on the MDP instance
     * @param multiDPInstance
     */
    static initFetchFirstMethods(multiDPInstance) {
      const mdp = multiDPInstance;
      /**
       * delegate to referenced SDP for fetchFirst
       * @param params
       */
      mdp.fetchFirst = (params) => {
        // we have to delay getting the value of the this DP because the value is not
        // fully resolved at the time init() is called. The value parameter is a computed
        // but calling value() does not resolve the value of referenced variables because
        // at the time init() is called we have not fully resolved all variables. Variable
        // value resolution does not use Promises today.
        const sdp = MultiServiceDataProviderUtils.getDataProviderByType(mdp,
          DPConstants.DataProviderFetchType.FETCH_FIRST);
        if (sdp) {
          // register a listener to be notified of lifecycle events on SDP variable
          sdp.getLifecycleStageChangedSignal()
            .add(mdp.getVariableStageChangeCallback(DPConstants.DataProviderFetchType.FETCH_FIRST));
          return sdp.fetchFirst(params);
        }
        return (new NoOpFetchFirst(params)).fetchFirst();
      };

      /**
       * delegates to the getCapability() on the 'fetchFirst' dataProvider always, if the
       * MultiServiceDataProvider was configured with the capability.
       * If fetchFirst is not set on the MultiSDP then the other fetch capabilities
       * configured will set this up.
       * @param capability
       */
      mdp.getCapability = (capability) => {
        const sdp = MultiServiceDataProviderUtils.getDataProviderByType(mdp,
          DPConstants.DataProviderFetchType.FETCH_FIRST);
        return (sdp && sdp.getCapability(capability)) || null;
      };

      /**
       * delegates to the getTotalSize on the 'fetchFirst' dataProvider always.
       * @return Promise.<number>
       */
      mdp.getTotalSize = () => {
        const sdp = MultiServiceDataProviderUtils.getDataProviderByType(mdp,
          DPConstants.DataProviderFetchType.FETCH_FIRST);
        return (sdp && sdp.getTotalSize());
      };

      /**
       * delegates to isEmpty() on the fetchFirst dataProvider
       */
      mdp.isEmpty = () => {
        const sdp = MultiServiceDataProviderUtils.getDataProviderByType(mdp,
          DPConstants.DataProviderFetchType.FETCH_FIRST);
        return (sdp && sdp.isEmpty());
      };
    }

    /**
     * Defines the fetchByKeys methods on the mdp instance
     * @param multiDPInstance
     */
    static initFetchByKeysMethods(multiDPInstance) {
      const mdp = multiDPInstance;
      if (typeof mdp.fetchFirst !== 'function') {
        // setup a noop fetchFirst. This is because the JET DataProvider contract always
        // expects that a fetchFirst() method be present.
        // TODO: revisit this decision
        mdp.fetchFirst = (params) => (new NoOpFetchFirst(params)).fetchFirst();
      }

      /**
       * delegates to referenced SDP for fetchByKeys
       * @param params
       */
      mdp.fetchByKeys = (params) => {
        const sdp = MultiServiceDataProviderUtils.getDataProviderByType(mdp,
          DPConstants.DataProviderFetchType.FETCH_BY_KEYS);
        if (sdp) {
          sdp.getLifecycleStageChangedSignal()
            .add(mdp.getVariableStageChangeCallback(DPConstants.DataProviderFetchType.FETCH_BY_KEYS));
          return sdp.fetchByKeys(params);
        }
        return (new NoOpFetchByKeys(params)).fetchByKeys();
      };

      /**
       * delegates to referenced SDP for fetchByKeys
       * @param params
       */
      mdp.containsKeys = (params) => {
        const sdp = MultiServiceDataProviderUtils.getDataProviderByType(mdp,
          DPConstants.DataProviderFetchType.FETCH_BY_KEYS);
        if (sdp) {
          return sdp.containsKeys(params);
        }
        return (new NoOpFetchByKeys(params).containsKeys());
      };

      if (typeof mdp.getCapability !== 'function') {
        mdp.getCapability = (capability) => {
          const sdp = MultiServiceDataProviderUtils.getDataProviderByType(mdp,
            DPConstants.DataProviderFetchType.FETCH_BY_KEYS);
          return (sdp && sdp.getCapability(capability)) || null;
        };
      }
    }

    /**
     * defines the fetchByOffset methods on the mdp instance
     * @param mdpInstance
     */
    static initFetchByOffsetMethods(mdpInstance) {
      const mdp = mdpInstance;
      if (typeof mdp.fetchFirst !== 'function') {
        // setup a noop fetchFirst. This is because the JET DataProvider contract always
        // expects that a fetchFirst() method be present.
        // TODO: revisit this decision
        mdp.fetchFirst = (params) => (new NoOpFetchFirst(params)).fetchFirst();
      }

      /**
       * delegates to referenced SDP for fetchByOffset
       * @param params
       */
      mdp.fetchByOffset = (params) => {
        const sdp = MultiServiceDataProviderUtils.getDataProviderByType(mdp,
          DPConstants.DataProviderFetchType.FETCH_BY_OFFSET);
        if (sdp) {
          sdp.getLifecycleStageChangedSignal()
            .add(mdp.getVariableStageChangeCallback(DPConstants.DataProviderFetchType.FETCH_BY_OFFSET));
          return sdp.fetchByOffset(params);
        }
        return (new NoOpFetchByOffset(params)).fetchByOffset();
      };

      if (typeof mdp.getCapability !== 'function') {
        mdp.getCapability = (capability) => {
          const sdp = MultiServiceDataProviderUtils.getDataProviderByType(mdp,
            DPConstants.DataProviderFetchType.FETCH_BY_OFFSET);
          return (sdp && sdp.getCapability(capability)) || null;
        };
      }
    }

    /**
     * Checks if the capability is actually implemented on the dataProvider. If the
     * dataProvider is not specified this logs error and returns false;
     * If both capability and dataProvider are provided, then asks the underlying DataProvider
     * implementation if it truly matches the capability that this type was configured with.
     * This is to guard against incorrect configuration like
     *  - someSDP variable has fetchFirst capability, but the multiSDP variable has
     *    - no mapping for 'fetchFirst' property or
     *    - 'fetchByKeys' property on this variable maps to someSDP variable that has no
     *    fetchByKeys support
     * @param mdp multiSDP instance
     * @param options
     * @param options.capability
     * @param options.dataProvider
     * @return boolean
     */
    static implementsCapability(mdp, options) {
      let result = false;
      const dp = options.dataProvider;
      const cap = options.capability;
      if (!dp || !SDPUtils.isServiceDataProviderInstanceValid(dp)) {
        mdp.log.error('unable to verify if capability', cap,
          'supported, since the dataProvider is not the expected type or its configuration is invalid!');
        return false;
      }
      switch (cap) {
        case DPConstants.CapabilityType.FETCH_BY_KEYS:
          if ((typeof dp.getCapability === 'function')) {
            const fetchByKeysCap = dp.getCapability(cap) || {};
            const impl = fetchByKeysCap[DPConstants.CapabilityKeys.FETCH_BY_KEYS_IMPLEMENTATION];
            if (impl) {
              // supports iteration or lookup
              result = true;
            }
          }
          break;

        case DPConstants.CapabilityType.FETCH_BY_OFFSET:
          if ((typeof dp.getCapability === 'function')) {
            const fetchByOffsetCap = dp.getCapability(cap) || {};
            const impl = fetchByOffsetCap[DPConstants.CapabilityKeys.FETCH_BY_OFFSET_IMPLEMENTATION];
            if (impl) {
              // supports iteration or randomAccess
              result = true;
            }
          }
          break;

        case DPConstants.CapabilityType.FETCH_FIRST:
        default:
          if ((typeof dp.getCapability === 'function')) {
            const fetchFirstCap = dp.getCapability(cap) || {};
            if (fetchFirstCap[DPConstants.CapabilityKeys.FETCH_FIRST_IMPLEMENTATION]
              === DPConstants.CapabilityValues.FETCH_FIRST_IMPLEMENTATION_ITERATION) {
              result = true;
            }
          }
          break;
      }

      return result;
    }


    /**
     * This is a rudimentary check to ensure that there is at least one dataProvider mapping present in the MultiSDP
     * configuration.
     * @param dataProviders
     * @returns {boolean}
     * @private
     * @static
     */
    static isConfigurationValid(dataProviders) {
      return (dataProviders
        && (dataProviders.fetchFirst || dataProviders.fetchByOffset || dataProviders.fetchByKeys));
    }

    /**
     * Returns the dataProvider instance associated for a fetch capability. We expect that at least one fetch
     * capability property be mapped.
     * @param mdp instance of MDP that references other SDP instances
     * @param capability
     * @param value
     * @returns {*}
     */
    static getDataProviderByType(mdp, capability, value) {
      const { dataProviders } = value || mdp.getValue();
      let result;
      const cap = capability || DPConstants.CapabilityType.FETCH_FIRST;
      const options = { capability: cap, dataProvider: dataProviders[cap] };
      if (MultiServiceDataProviderUtils.implementsCapability(mdp, options)) {
        result = dataProviders[cap];
      }

      return result;
    }

    /**
     * Returns a Map of SDP references by fetch capability type.
     * @param mdp
     * @returns {{}}
     */
    static getSDPReferencesByType(mdp) {
      const value = mdp.getValue();
      const { dataProviders } = value;
      const sdpRefsByType = {};
      let sdp;

      if (dataProviders.fetchFirst) {
        sdp = MultiServiceDataProviderUtils.getDataProviderByType(mdp,
          DPConstants.DataProviderFetchType.FETCH_FIRST, value);
        if (sdp) {
          sdpRefsByType[DPConstants.DataProviderFetchType.FETCH_FIRST] = sdp;
        }
      }
      if (dataProviders.fetchByKeys) {
        sdp = MultiServiceDataProviderUtils.getDataProviderByType(mdp,
          DPConstants.DataProviderFetchType.FETCH_BY_KEYS, value);
        if (sdp) {
          sdpRefsByType[DPConstants.DataProviderFetchType.FETCH_BY_KEYS] = sdp;
        }
      }
      if (dataProviders.fetchByOffset) {
        sdp = MultiServiceDataProviderUtils.getDataProviderByType(mdp,
          DPConstants.DataProviderFetchType.FETCH_BY_OFFSET, value);
        if (sdp) {
          sdpRefsByType[DPConstants.DataProviderFetchType.FETCH_BY_OFFSET] = sdp;
        }
      }

      return sdpRefsByType;
    }
  }

  return MultiServiceDataProviderUtils;
});

