'use strict';

define('vb/extensions/dynamic/private/models/emptyContextModel',[
  'vb/private/stateManagement/context/extensionContext',
], (ExtensionContext) => {
  const expressionContext = {
    variables: {},
    metadata: {},
    constants: {},
    chains: {},
    functions: {},
    listeners: {},
    translations: {},
  };

  /**
   * EmptyContextModel class
   * duck-types the Page & Layout's getViewModel() method.
   * Used to insure we always have a non-null expression context for extensions.
   *
   * This is implemented as a Singleton
   *
   */
  class EmptyContextModel {
    /**
     * return an empty, but non-null, expression context
     * @returns {*}
     */
    // eslint-disable-next-line class-methods-use-this
    getViewModel() {
      return ExtensionContext.getAvailableContexts({
        scope: {},
        bundles: {},
        application: {
          expressionContext,
        },
        expressionContext: Object.assign({
          base: expressionContext,
          extension: expressionContext,
        }, expressionContext),
        base: {
          application: {
            expressionContext,
          },
          expressionContext,
        },
      });
    }
  }

  return new EmptyContextModel();
});

