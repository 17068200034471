'use strict';

define('vb/extensions/dynamic/private/types/heterogeneousMetadataProviderDescriptor',[
  'vb/private/utils',
  'vb/private/log',
  'vb/extensions/dynamic/private/types/abstractMetadataProviderDescriptor',
  'vb/extensions/dynamic/private/types/bridges/helperMetadataProviderFactoryBridge',
],
(Utils,
  Log,
  AbstractMetadataProviderDescriptor,
  HelperMetadataProviderFactoryBridge) => {
  // const logger = Log.getLogger('/vb/extensions/dynamic/private/types/heterogeneousMetadataProviderDescriptor');
  /**
   *
   */
  class HeterogeneousMetadataProviderDescriptor extends AbstractMetadataProviderDescriptor {
    /**
     * A "metadata" variable for providing the metadata provider to JET dynamic forms/tables.
     * Provides the basic shaping metadata (openapi3 or JSON), as well as the additional 'dynamic' layout resources.
     *
     * Has a 'provider' property that is the JET provider.
     *
     * @override
     * @returns {MetadataProviderFactoryBridge}
     */
    // eslint-disable-next-line class-methods-use-this
    createFactoryBridge() {
      return new HelperMetadataProviderFactoryBridge();
    }

    /**
     * creates an 'options' object that is used both by the FactoryMetadataProviderDescriptor, and
     * the VB metadata provider helper.
     *
     * Properties used by FactoryMetadataProviderDescriptor:
     *
     * factory:      path to the JET (or VB proxy) metadata provider factory
     * helper.class: path for the VB metadata provider helper, passed to the Metadata provider
     *
     * properties used by the ConfigurableMetadataProviderHelper
     * helper.root:  base path for the resources used by the metadata provider helper; the "page" folder
     *
     * no descriptors are configured; this helper is only used to create other helpers for based on the discriminator.
     *
     * @param value {object}
     * @param container {Container}
     * @returns {Object}
     */
    createOptions(value) {
      let options = super.createOptions(value);

      if (!options.factory) {
        if (!this.container) {
          throw new Error('Missing container when creating MetadataProvider');
        }

        // no descriptors for this helper! its just used to create other helpers.
        const descriptors = {};
        options = Object.assign({}, options, {
          factory: 'oj-dynamic/providers/HeterogeneousMetadataProviderFactory',
          helper: {
            class: 'vb/extensions/dynamic/private/helpers/configurableMetadataProviderHelper',
            root: this.container.getResourceFolder(),
            descriptors,
          },
        });
      }

      return options;
    }

    /**
     * @override
     * @returns {{type: {discriminator: string}}}
     */
    // eslint-disable-next-line class-methods-use-this
    getTypeDefinition() {
      return {
        type: {
          discriminator: 'string',
        },
      };
    }
  }

  return HeterogeneousMetadataProviderDescriptor;
});

