'use strict';

define('vbsw/private/plugins/resourceChangedPlugin',[
  'vbsw/api/fetchHandlerPlugin',
  'vbsw/private/utils',
  'vbc/private/log',
],
(FetchHandlerPlugin, Utils, Log) => {
  /**
   * Notes:
   *
   * this plugin is responsible for handling the requests and responses to the VB server,
   * making sure it has the required request header to identify the current client app version,
   * and checking if the server responds with a 400 and a header indicating that the app resources have changed.
   *
   * When this happens, a message is sent, with the url, error from the server, and the header value.
   */
  const logger = Log.getLogger('/vb/private/plugins/resourceChangedPlugin');

  // this is added to all requests to the VB server (if BASE_URL_TOKEN exists).
  const VB_VERSION_ID_HEADER = 'x-vb-application-version'; // can't use "vb-", tidy/authPostProcessorPlugin remove those

  // this will be in the response, if the app has been re-staged (boolean)
  const VB_CHANGED_HEADER = 'x-vb-changed-header';

  // BUFP-33076; when reloading a PWA that has been restaged, app-flow.json and app.css are requested by the
  // old service worker, and thus those have the old header value, and the app gets stuck, and the service worker never
  // gets updated. Skipping those files allows PWA to get the newer service worker and resources.
  const DEFAULT_SKIP_REGEX = /(app-flow.json|\.css)$/;

  class ResourceChangedPlugin extends FetchHandlerPlugin {
    constructor(context, params = {}) {
      super(context);
      this.contextRoot = params.contextRoot || '/';
      // if versionId is not truthy, this plugin will not do anything
      this.versionId = params.versionId;

      // allow for an override, but should never happen (and don't document)
      this.skipRegex = params.skipRegex || DEFAULT_SKIP_REGEX;

      // similar to csrfTokenHandlerPlugin, we need to make sure we only check resources in the app
      // bufp-38121: need to skip BO's in other apps:
      // for example, we would want to skip this URL (assuming 'notThisApp' is, well, not this app:
      // https://masterdev-vboci.integration.test.ocp.oc-test.com/ic/builder/rt/notThisApp/live/resources/data/AnonRef
      const reg = Utils.getRegexForAppScope(this.fetchHandler.scope, this.fetchHandler.config);
      this.scopeRegEx = new RegExp(reg);
    }


    /**
     * sets a special header on all requests to the VB server, which contains a app version identifier
     * @param request
     * @returns {Promise.<T>}
     */
    handleRequestHook(request) {
      return Promise.resolve().then(() => {
        if (this.shouldProcess(request)) {
          request.headers.set(VB_VERSION_ID_HEADER, this.versionId);
        }
        return null;
      });
    }


    /**
     * looks for responses from the VB server, for a special header indicating the app has been restaged/republished
     * @param response
     * @param origRequest
     * @param request
     * @param client
     * @returns {*}
     */
    handleResponseHook(response, origRequest, request, client) {
      return Promise.resolve().then(() => {
        if (this.shouldProcess(request)) {
          return ResourceChangedPlugin.checkIfResourceChanged(request.url, response, client);
        }
        return false;
      });
    }

    /**
     * should we handle this request or response?
     * @param request
     * @returns {*|boolean}
     * @private
     */
    shouldProcess(request) {
      return this.versionId && request.url.match(this.scopeRegEx) && !this.skipRegex.test(request.url);
    }

    /**
     *
     * @param url
     * @param response
     * @param client
     */
    static checkIfResourceChanged(url, response, client) {
      return Promise.resolve().then(() => {
        const hasChangedValue = response.headers.get(VB_CHANGED_HEADER);
        if (response.status === 400 && hasChangedValue && hasChangedValue !== 'false') {
          return ResourceChangedPlugin.fireResourceChangeEvent(url, response, client);
        }
        return null;
      });
    }

    /**
     * fire an event that the Application listens for
     * @param originalUrl
     * @param response
     * @param client
     * @returns {Promise.<TResult>}
     * @private
     */
    static fireResourceChangeEvent(originalUrl, response, client) {
      const clone = response.clone();

      return clone.text()
        .then((body) => ({
          method: 'vbResourceChanged',
          args: [originalUrl, body, response.headers.get(VB_CHANGED_HEADER)],
        }))
        .then((msg) => Utils.postMessage(client, msg))
        .then((result) => {
          logger.fine('fireResourceChangeEvent message complete');
          return result;
        })
        .catch((error) => {
          logger.error('fireResourceChangeEvent message error', error);
          return false;
        });
    }
  }

  return ResourceChangedPlugin;
});

