'use strict';

define('vb/action/builtin/logoutAction',[
  'vb/action/action',
  'vb/private/stateManagement/router',
],
(Action, Router) => {
  /**
   * Launch the logout process as defined in the Security Provider implementation.
   * It invokes the handleLogout function on the Security Provider with the logoutUrl
   * argument.
   *
   * @param {String} logoutUrl the URL to navigate to in order to log out
   *
   * Returns a 'failure' outcome in case of error or the outcome 'success' with the payload true if
   * navigation to the login page was successful.
   */
  class LogoutAction extends Action {

    perform(parameters) {
      const securityProvider = this.context.application.securityProvider;

      if (!securityProvider) {
        return Action.createFailureOutcome('Security Provider missing.');
      }

      try {
        securityProvider.handleLogout(parameters.logoutUrl);
        return Action.createSuccessOutcome();
      } catch (error) {
        return Action.createFailureOutcome('Error during logout operation', error);
      }
    }

    setContext(context) {
      this.context = context;
    }
  }

  return LogoutAction;
});

