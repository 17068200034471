/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */

'use strict';

define('vbc/private/performance/reporter',['vbc/private/constants'], (Constants) => {
  // Because of a circular dependency between Performance and Log, logger needs to be loaded on demand
  let logger;
  const getLogger = () => {
    if (!logger) {
      const Log = requirejs('vbc/private/log');
      logger = Log.getLogger('/vbc/private/performance/reporter');
    }
    return logger;
  };

  let loggingEnabled;
  const isLoggingEnabled = () => {
    if (loggingEnabled === undefined) {
      loggingEnabled = getLogger().isEnabled(Constants.Severity.INFO);
    }
    return loggingEnabled;
  };

  /**
   * A reporter that does nothing but is always enabled
   */
  class Reporter {
    // eslint-disable-next-line no-unused-vars
    report(name, entries) {
    }

    // eslint-disable-next-line
    isEnabled() {
      return true;
    }
  }

  /**
   * A reporter that logs performance category to the console at the info level
   */
  class ConsoleReporter extends Reporter {
    /**
     * @param {boolean} logObject if true, object will be logged directly in console regardless
     * of <code>console.table</code> support
     * @see Log.table
     */
    constructor(logObject = true) {
      super();
      this.logObject = logObject;
    }

    report(name = '', entries = []) {
      getLogger().info(`${name}:`);
      const obj = {};
      entries.forEach((e) => Object.assign(obj, e));
      getLogger().table({
        severity: Constants.Severity.INFO,
        groupTitle: name,
        obj,
        logObject: this.logObject,
      });
    }

    isEnabled() {
      return isLoggingEnabled();
    }
  }

  /**
   * A reporter that logs performance category to the console at the info level, using console.table for each
   * performance entry.
   */
  class TableConsoleReporter extends ConsoleReporter {
    constructor() {
      super(false);
    }
  }

  Reporter.CONSOLE_REPORTER = new ConsoleReporter();
  Reporter.TABLE_CONSOLE_REPORTER = new TableConsoleReporter();

  return Reporter;
});

