/* eslint max-classes-per-file: ["error", 2] */

'use strict';

define('vb/private/utils/intersectionObserverUtils',[], () => {
  const interObserverConfig = {
    rootMargin: '0px 0px 50px 0px',
    threshold: 0,
  };

  /**
   * To track when a fragment starts to intersect with visible bounding rect, so loading of fragments can be done
   * lazily.
   */
  class IntersectionObserverUtils {
    constructor() {
      /**
       * @private
       * @type {IntersectionObserver}
       */
      this.observer = undefined;
    }

    /**
     * Creates an IntersectionObserver using the root element
     * @param root if root not provided assumes default viewPort
     * @return {IntersectionObserver}
     */
    // eslint-disable-next-line class-methods-use-this
    createObserver(root) {
      let obsConfig = interObserverConfig;

      if (root) {
        obsConfig = Object.assign({}, interObserverConfig, { root });
      }
      return new IntersectionObserver((entries, self) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log(`ojModule ${entry.target} is in the viewport!`);
            // Stop watching and load the image
            self.unobserve(entry.target);
          }
        });
      }, obsConfig);
    }

    /**
     * Apply style to ojModule chrome
     * @param ojm
     */
    static applyStyle(ojm) {
      const style = ojm.getAttribute('style');
      const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
      ojm.setAttribute('style', `${style}; border: 3px dashed ${randomColor}`);
    }

    /**
     * when a ojModule connects the view to the DOM the vbModuleConfig gets notified it adds to IntersectionObserver
     * the DOM node (for oj-module).
     * root element to start observing
     * @param rootElement must be provided if a new observer has to be created
     * @return {IntersectionObserver|IntersectionObserver}
     */
    getIntersectionObserver(rootElement) {
      if (!this.observer && rootElement) {
        this.observer = this.observer || this.createObserver(rootElement);
        Object.freeze(this.observer);
      }
      return this.observer;
    }

    clearIntersectionObserver() {
      this.observer = undefined;
    }
  }

  return new IntersectionObserverUtils(); // singleton
});

