define('vb/components/messagebar/viewModel',['knockout', 'text!./messageBar.html'],
  function (ko, MessageBarHtml) {
    // simple logger
    const logger = {
      error: console.error
    };

    var Constants = {
      DEFAULT_DURATION: 3000,

      DEFAULT_WRAPPER_CLASS: 'vb-messages',

      SINGLE_MESSAGE_ELEMENT_CLASS: '.messagebar-confirmation-error-note',
      TEXT_ELEMENT_CLASS: '.messagebar-confirmation-error-note-text',
      CLOSE_ELEMENT_CLASS: '.messagebar-confirmation-error-note-close',
      COUNTER_ELEMENT_CLASS: 'messagebar-confirmation-error-note-counter',
      CONFIRMATION_CSS_CLASS: 'confirmation-note',
      ERROR_CSS_CLASS: 'error-note',
      WARNING_CSS_CLASS: 'warning-note',
      INFO_CSS_CLASS: 'info-note',
      SLIDE_UP_CLS: 'messagebar-slide-up',
      SLIDE_DOWN_CLS: 'messagebar-slide-down',
      CHECKSUM_ATTR: 'data-note-checksum',
      ImageClass: {
        'error-note': 'oj-fwk-icon-status-error',
        'warning-note': 'oj-fwk-icon-status-warning',
        'confirmation-note': 'oj-fwk-icon-status-confirmation',
        'info-note': 'oj-fwk-icon-status-info'
      },
    };




    /** simple function queue, one for all instances of this control **/
    var PromiseQueue = {
      promises: [],

      currentPromise: null,

      queueTask: function (fnc) {
        PromiseQueue.promises.push(fnc());
        if (!PromiseQueue.currentPromise) {
          PromiseQueue.run();
        }
      },

      run: function () {
        PromiseQueue.currentPromise = PromiseQueue.promises.shift();

        if (PromiseQueue.currentPromise) {
          PromiseQueue.currentPromise.catch(function (e) {
            logger.error(e);
          }).then(function () {
            PromiseQueue.run();
          });
        }
      }
    };

    /**
     * viewModel constructor
     * @param context
     */

    function model(context) {
      var self = this;
      var element = context.element;

      self.id = element.id || self.randomString();

      self.duration = Constants.DEFAULT_DURATION;

      self.autocloseDefaults = {
        error: false,
        warn: false,
        info: false,
        confirmation: true
      };

      // add the default class
      var cls = element.className || "";
      if (cls.indexOf(Constants.DEFAULT_WRAPPER_CLASS) <= 0) {
        element.className = cls + ' ' + Constants.DEFAULT_WRAPPER_CLASS;
      }


      context.props.then(function(properties) {
        if (properties.duration) {
          self.duration = properties.duration;
        }

        if (properties.autoclose) {
          Object.keys(properties.autoclose).forEach(function(key) {
            if (properties.autoclose[key] !== undefined && properties.autoclose[key] !== null) {
              self.autocloseDefaults[key] = properties.autoclose[key];
            }
          });
        }
      });

      // ========= public API methods ===============





      self.closeAll = function (cls) {
        cls = cls || Constants.SLIDE_DOWN_CLS;
        var elements = document.querySelectorAll('.' + cls);
        if (elements) {
          elements.forEach(function (e) {
            self.closeNote(e);
          })
        }
      };


      /**
       *
       * @param type
       * @param message
       * @param autoclose
       * @param action
       * @returns {*}
       */
      self.show = function (type, message, autoclose, action) {
        switch(type) {
          case 'error': return self.showErrorNote(message, autoclose, action);
          case 'warn':
          case 'warning': return self.showWarningNote(message, autoclose, action);
          case 'info': return self.showInfoNote(message, autoclose, action);
          case 'success':
          case 'confirmation': return self.showConfirmationNote(message, autoclose, action);
          default:
            logger.error('invalid type passed to oj-vb-message-bar:', type);
        }
      };

      /**
       * Creates a new error note.
       *
       * @param {string} message
       * @param autoclose
       * @param {Object} action {label: '', action: function} add a button with this action to the message
       * @returns {module:api/js/UI~Closeable} object containing method close()
       *                                       for closing the opened note
       */
      self.showErrorNote = function (message, autoclose, action) {
        if (autoclose === null || autoclose === undefined) {
          autoclose = self.autocloseDefaults.error;
        }
        return self.showClosableNote(message, Constants.ERROR_CSS_CLASS, autoclose, action);
      };

      /**
       * Creates a new warning note.
       *
       * @param {string} message
       * @param autoclose
       * @param {Object} action {label: '', action: function} add a button with this action to the message
       * @returns {module:api/js/UI~Closeable} object containing method close()
       *                                       for closing the opened note
       */
      self.showWarningNote = function (message, autoclose, action) {
        if (autoclose === null || autoclose === undefined) {
          autoclose = self.autocloseDefaults.warn;
        }
        return self.showClosableNote(message, Constants.WARNING_CSS_CLASS, autoclose, action);
      };

      /**
       * Create a new confirmation note.
       *
       * @param {string} message
       * @param {Object} action {label: '', action: function} add a button with this action to the message
       * @returns {module:api/js/UI~Closeable} object containing method close()
       *                                       for closing the opened note
       */
      self.showConfirmationNote = function (message, autoclose, action) {
        if (autoclose === null || autoclose === undefined) {
          autoclose = self.autocloseDefaults.confirmation;
        }

        return self.showClosableNote(message, Constants.CONFIRMATION_CSS_CLASS, autoclose, action);
      };

      /**
       * Create a new info note.
       *
       * @param {string} message
       * @param {Object} action {label: '', action: function} add a button with this action to the message
       * @returns {module:api/js/UI~Closeable} object containing method close()
       *                                       for closing the opened note
       */
      self.showInfoNote = function (message, autoclose, action) {
        if (autoclose === null || autoclose === undefined) {
          autoclose = self.autocloseDefaults.info;
        }

        return self.showClosableNote(message, Constants.INFO_CSS_CLASS, autoclose, action);
      };


      // ========= public API methods end ===============


      /**
       * check if the DOM is ready; we block the task queue until it is
       */
      if (document.readyState !== "complete") {
        //Add onload or DOMContentLoaded event listeners here: for example
        PromiseQueue.queueTask(function () {
          return new Promise(function (resolve) {
            window.addEventListener("onload", function () {
              resolve();
            }, false);
          });
        });
      }


      /**
       * the parent element of all message elements - the application defined this element in its UI
       * @returns {Element}
       */
      self.getWrapperElement = function () {
        return element;
      };


      // ========== private methods ==================


      self.getCloseableObject = function (id, action) {
        var actions = Array.isArray(action) ? action : (action) ? [action] : null;
        // add close method to the actions so we can close notification also from the actions callbacks
        if (actions) {
          actions.forEach(function (act, index) {
            actions[index].close = function () {
              var element = document.querySelector('#' + id);
              if (element) {
                self.closeNote(element);
              }
            };
          });
        }
        return {
          close: function () {
            var element = document.querySelector('#' + id);
            if (element) {
              self.closeNote(element);
            }
          },
          action: actions
        };
      };


      self.showClosableNote = function (message, type, autoclose, action) {

        var id = 'message-' + self.randomString(5);
        var closeableObject = self.getCloseableObject(id, action);

        var noteChecksum = 0;
        if (message) {
          var args = [message, type, action, autoclose];
          noteChecksum = self.checksum(JSON.stringify(args));
        }
        // bail out if there is exactly same note displayed already
        if (self.checkForSameNote(noteChecksum)) {
          return closeableObject;
        }

        PromiseQueue.queueTask(function () {
          return new Promise(function (resolve) {

            var appendedElement = self.addMessageToDom(id, closeableObject, message, noteChecksum, type);

            window.setTimeout(function () {
              self.slideDown(appendedElement);
            }, 1);

            if (autoclose) {
              window.setTimeout(function () {
                self.closeNote(appendedElement);
              }, self.duration);
            }

            resolve();
          });
        });

        return closeableObject;
      };


      self.closeNote = function (element) {
        // remove the checksum first
        element.firstChild.setAttribute(Constants.CHECKSUM_ATTR, null);
        self.slideUp(element);
        setTimeout(function () {
          if (element.parentNode) {
            element.parentNode.removeChild(element);
          }
        }, 1000);
      };


      self.addMessageToDom = function (id, closeableObject, message, checksum, type) {
        var noteElement = self.getWrapperElement();

        // insert the html
        var appendedElement = document.createElement('div');
        appendedElement.innerHTML = MessageBarHtml;
        appendedElement.id = id;

        appendedElement.className = Constants.SLIDE_UP_CLS + ' ' + appendedElement.className;
        var beforeNode = noteElement.firstChild;

        appendedElement = noteElement.insertBefore(appendedElement, beforeNode);

        // bind it as it contains oj-button
        var msgElem = document.getElementById(id);
        if (!msgElem) {
          logger.error('did not find msgElem: ' + id);
        }

        ko.applyBindings(closeableObject, msgElem);

        appendedElement.querySelector(Constants.CLOSE_ELEMENT_CLASS).style.display = 'block';
        appendedElement.querySelector(Constants.TEXT_ELEMENT_CLASS).textContent = message;
        appendedElement.querySelector('.messagebar-confirmation-error-note').setAttribute(Constants.CHECKSUM_ATTR, checksum);

        var imageElement = appendedElement.querySelector('.messagebar-confirmation-error-note-image');
        imageElement.className = imageElement.className + ' ' + Constants.ImageClass[type];


        // set the action buttons icon, class if applicable
        // if (closeableObject.action) {
        //   closeableObject.action.forEach(function(act, index) {
        // todo: skip 'interesting' button support for now
        //   });
        // }

        var note = appendedElement.querySelector(Constants.SINGLE_MESSAGE_ELEMENT_CLASS);
        var cls = note.className;
        note.setAttribute('class', cls + ' messagebar-' + type);

        return appendedElement;
      };


      self.checksum = function (str) {
        var hash = 0;
        var char;

        // prepend the id
        str = self.id + str;

        for (var i = 0; i < str.length; i++) {
          char = str.charCodeAt(i);
          hash = ((hash << 5) - hash) + char;
          hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
      };


      /**
       * looks for an existing checksum, and increases the count if there is one
       * @param hash
       * @returns {boolean}
       */
      self.checkForSameNote = function (hash) {
        var selector = Constants.SINGLE_MESSAGE_ELEMENT_CLASS + '[' + Constants.CHECKSUM_ATTR + '="' + hash + '"]';
        var existing = document.querySelector(selector);
        if (existing) {
          // add/increase the counter bubble
          self.increaseNoteCounter(existing);
          return true;
        }
        return false;
      };


      /**
       * increase the count for the same checksum
       * @param element
       */
      self.increaseNoteCounter = function (element) {
        var counter = element.querySelector('.' + Constants.COUNTER_ELEMENT_CLASS);

        var text = element.querySelector(Constants.TEXT_ELEMENT_CLASS);
        if (counter) {
          // increase counter
          counter.textContent = ((parseInt(counter.textContent, 10) + 1));
        } else {
          // add new counter
          var newCounter = document.createElement('div');
          newCounter.innerHTML = '<div class="' + Constants.COUNTER_ELEMENT_CLASS + '">2</div>';
          text.parentNode.insertBefore(newCounter, text);
        }
      };


      self.slideUp = function (element) {
        self.slide(element, Constants.SLIDE_UP_CLS, Constants.SLIDE_DOWN_CLS);
      };

      self.slideDown = function (element) {
        self.slide(element, Constants.SLIDE_DOWN_CLS, Constants.SLIDE_UP_CLS);
      };

      self.slide = function (elem, toAdd, toRemove) {
        var classes = (' ' + elem.className + ' ').replace(/[\n\t\r]/g, " ");
        // Remove the previous class (if present) and add the new class
        elem.className = (classes.replace(' ' + toRemove + ' ', '') + ' ' + toAdd + ' ').trim();
        return false;
      };

      // used for message bar ids
      self.randomString = function () {
        var firstPart = (Math.random() * 46656) | 0;
        var secondPart = (Math.random() * 46656) | 0;
        firstPart = ('000' + firstPart.toString(36)).slice(-3);
        secondPart = ('000' + secondPart.toString(36)).slice(-3);
        return firstPart + secondPart;
      };
    }
    return model;
  });

