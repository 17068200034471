/* eslint-disable prefer-promise-reject-errors */

'use strict';

/**
 * do not add direct dependencies here, that will load JET; see getStateManagement/getServicesManager
 */

define('vb/private/helpers/runtimeManager',[
  'vb/private/utils',
  'vb/private/constants',
  'vb/private/history',
  'vb/private/stateManagement/stateMonitor',
  'vb/private/stateManagement/navigationContext',
],
(Utils, Constants, History, StateMonitor, NavigationContext) => {
  /**
   * Container type constants
   * @type {{APPLICATION: string, PAGE: string}}
   *
   * TODO: remove this if we remove all xxxActivePagexxx methods
   * TODO: remove all trace of the active page concept and page ready concept.
   *       Users should rely on the containerReady API
   */
  const CONTAINER_TYPE = {
    PAGE: '__VB_PAGE_CONTAINER__',
  };

  // the application path is always ''
  const APPLICATION_PATH = '';

  /**
   * This class is used by the DT to manage the RT to perform actions such as refreshing the current page or
   * navigating to a page.
   *
   * NOTE: This class should not be loaded by the RT.
   */
  class RuntimeManager {
    constructor() {
      // make sure all ready flags default to false
      this.readyFlags = {
        [CONTAINER_TYPE.PAGE]: false,
      };

      this.readyPromises = {};
      this.readyPromiseResolvers = {};
      this.readyTimeout = 0;

      this.stateChangeListener = (state, container) => {
        this.handleStateChange(state, container);
      };

      // register a state change listener on the StateMonitor
      StateMonitor.addStateChangeListener(this.stateChangeListener);
    }

    /**
     * Return a promise that will resolve when the application is loaded and ready. If a timeout is set via
     * setReadyTimeout, the promise will be rejected if the application is not ready within the time limit.
     *
     * @returns {Promise}
     */
    applicationReady() {
      return this.containerReady(APPLICATION_PATH);
    }

    /**
     * Return a promise that will resolve when the container of the given type is loaded and ready. If a timeout is
     * set via setReadyTimeout, the promise will be rejected if the container is not ready within the time limit.
     *
     * @param path the full path of a container
     * @returns {Promise}
     */
    containerReady(path) {
      if (!this.readyPromises[path]) {
        if (this.readyFlags[path]) {
          this.readyPromises[path] = Promise.resolve(true);
        } else {
          this.readyPromises[path] = new Promise((resolve, reject) => {
            // remember resolve so handleStateChange can resolve the promise when the container is ready
            this.readyPromiseResolvers[path] = resolve;

            // reject the promise if the container is not loaded within the timeout
            if (this.readyTimeout > 0) {
              setTimeout(reject, this.readyTimeout);
            }
          });
        }
      }

      return this.readyPromises[path];
    }

    /**
     * Set the timeout in milliseconds for applicationReady and pageReady. If the application or page is not ready
     * within in the time limit, the promise will be rejected.
     *
     * @param timeout timeout in miliseconds
     */
    setReadyTimeout(timeout) {
      this.readyTimeout = timeout;
    }

    /**
     * Perform a full refresh of the current identified by the given path. The container can be either
     * a page or a flow.
     *
     * @param path the full path of a container
     * @returns {Promise}
     */
    refreshContainer(path) {
      return this.containerReady(path).then(() => this.getStateManagement()).then(([Router, Application, Page]) => {
        const container = Router.getContainer(Application, path);

        if (container instanceof Page) {
          const page = container;

          // dispose the page
          page.dispose();

          // Reset the state pagePath so that refresh does not unecessarily sync the page
          // pagePath will be set again once the page is refreshed.
          History.setPagePath('');

          // refresh the page
          return this.refreshPage(page)
          // It is very important to use the new page created by refreshPage because
          // page (the old one) is disposed.
            .then((newPage) => {
              const navContext = new NavigationContext(newPage);
              return Application.getLeafPageInstance(page.getNavPath(), navContext);
            })
            .then((leafPage) => leafPage.rootRouter.go(leafPage.getNavPath()));
        }

        return Promise.reject(`Unknown container for ${path}`);
      });
    }

    /**
     * Refresh the descriptor of the container identified by the given path. The container can be either
     * a page or a flow.
     *
     * @param path the full path of a container
     * @returns {Promise}
     */
    refreshContainerDescriptor(path) {
      return this.containerReady(path).then(() => this.getStateManagement()).then(([Router, Application, Page]) => {
        const container = Router.getContainer(Application, path);

        if (container instanceof Page) {
          const page = container;

          // clear the cached page descriptor and dispose the page scope
          page.loadPagePromise = null;
          page.loadMetadataPromise = null;
          page.loadBundlesPromise = null;
          page.loadDescriptorPromise = null;
          page.loadAndStartPromise = null;

          // allow the scope to be recreated
          page.initializePromise = null;
          page.viewModelPromise = null;

          // Clear extension cache
          page.extensionsArray = [];
          page.extensions = {};

          // dispose fragments as page descriptor is being cleared without the page itself being disposed
          Object.keys(page.fragments).forEach((fragId) => {
            const frag = page.fragments[fragId];
            if (frag) {
              frag.disposeScope();
            }
          });
          // Dispose of layout scopes in the page
          page.layouts.forEach((layout) => layout.disposeScope());
          page.disposeScope();

          // Clear the availableContexts so that anything derived from expressionContext is derived again.
          // e.g. $translations is only constructed on the first access to the property in availableContexts.
          page.availableContexts = null;

          // refresh the page
          return this.refreshPage(page);
        }

        return Promise.reject(`Unsupported container for ${path}`);
      });
    }

    /**
     * Refresh the custom functions of the container identified by the given path. The container can be either
     * a page or a flow.
     *
     * @param path the full path of a container
     * @returns {Promise}
     */
    refreshContainerFunctions(path) {
      return this.containerReady(path).then(() => this.getStateManagement()).then(([Router, Application, Page]) => {
        const container = Router.getContainer(Application, path);

        if (container instanceof Page) {
          const page = container;

          // clear the cached functions
          page.loadFunctionsPromise = null;

          // allow the scope to be recreated
          page.initializePromise = null;
          page.viewModelPromise = null;
          page.disposeScope();

          // refresh the page
          return this.refreshPage(page);
        }

        return Promise.reject(`Unsupported container for ${path}`);
      });
    }

    /**
     * Refresh the page templage of the container identified by the given path. The container must be a page.
     *
     * @param path the full path of a container
     * @returns {Promise}
     */
    refreshContainerTemplate(path) {
      return this.containerReady(path).then(() => this.getStateManagement()).then(([Router, Application, Page]) => {
        const container = Router.getContainer(Application, path);

        if (container instanceof Page) {
          const page = container;

          // clear the cached page template
          page.loadPagePromise = null;
          page.loadHtmlPromise = null;
          page.viewModelPromise = null;

          // refresh the page
          return this.refreshPage(page);
        }

        return Promise.reject(`Unsupported container for ${path}`);
      });
    }


    /**
     * reload the bundles for the given container, and rebuild the expression binding context
     * @param path
     */
    refreshContainerBundles(path) {
      let container;
      return this.containerReady(path).then(() => this.getStateManagement()).then(([Router, Application, Page]) => {
        container = Router.getContainer(Application, path);

        if (container) {
          container.loadBundlesPromise = null;
          if (container instanceof Page) {
            // allow the scope to be recreated
            container.initializePromise = null;
            container.viewModelPromise = null;
          }
          // do NOT dispose of scope here - we are just reloading translations!  container.disposeScope();
          return container.loadTranslationBundles();
        }
        throw new Error(`Unsupported container for ${path}`);
      });
    }

    /**
     * Navigate to the page specified by the given page path.
     * The page path has to be absolute. By example to navigate to
     * an other top level page, the path will be /pageId. To navigate
     * to a nested page: /pageId/flowId/pageId
     * The first slash is optional.
     *
     * Return a promise that resolve as soon as the navigation is executed.
     * This is not when the page is ready. In order to know when the page is
     * ready, navigateToPage should be followed by containerReady(pagePath).
     *
     * @param  {String} pagePath the path of the page to navigate
     * @param  {Array<key, value>} parameters  a map of page input parameters
     * @return {Promise} a Promise that resolves to true if the navigation was successfull
     */
    navigateToPage(pagePath, parameters) {
      // TODO If would make sense to return the containerReady promise.
      return this.applicationReady().then(() => this.getStateManagement())
        // Always navigate relative from the root (app flow)
        // The promise returned resolves with no arguments
        .then(([Router, Application]) => Router.queueNavigate(Application, {
          page: pagePath,
          params: parameters,
          operation: Constants.NavigateOperation.PAGE_OLD,
        }).then((result) => (result && result.navigated === true)));
    }

    // private methods

    /**
     * Refresh the given page instance.
     *
     * @param {Page} page the page instance to reload
     * @return {Promise} a promise resolving to the new page instance
     * @private
     */
    refreshPage(page) {
      const path = page.getNavPath();
      const flow = page.parent;

      // reset the page ready state
      this.setContainerReady(path, false);

      // Mutates parent ojModule in order to reload the view
      page.resetParentModuleConfig();

      // set the lifecycleState to refreshing to prevent the page from getting disposed by ojModule
      page.lifecycleState = Constants.ContainerState.REFRESHING; // eslint-disable-line no-param-reassign

      // allow the page to be restarted
      page.started = false; // eslint-disable-line no-param-reassign

      const navContext = new NavigationContext(flow);

      return flow.loadPageFromInfo({ id: page.id }, navContext).then((newPage) => {
        newPage.enter();

        // if we're reusing the same page instance, we need to reset the lifecycleState back to refreshing to
        // prevent the page from getting disposed by ojModule since enter sets the state to entered
        if (newPage === page) {
          newPage.lifecycleState = Constants.ContainerState.REFRESHING; // eslint-disable-line no-param-reassign
        }

        return newPage;
      });
    }

    /**
     *
     * @param {string} id
     * @returns {Promise<Service>}
     */
    // eslint-disable-next-line class-methods-use-this
    refreshService(id) {
      return this.getServicesManager()
        .then(ServicesManager => ServicesManager.disposeService(id))
        .then(services => services.load([id]));
    }

    /**
     * disposes the CatalogHandler, but does not reload it until the service layer needs it
     * @returns {Promise}
     */
    // eslint-disable-line class-methods-use-this
    refreshServiceExtensionCatalog() {
      return this.getStateManagement()
        .then(([, Application]) => Application.protocolRegistry && Application.protocolRegistry.disposeCatalog());
    }

    /**
     * TODO
     * @param flow
     */
    refreshFlow(flow) { // eslint-disable-line no-unused-vars,class-methods-use-this
    }

    /**
     * Dispose this instance.
     */
    dispose() {
      // remove the stateChangeListener from StateMonitor
      StateMonitor.removeStateChangeListener(this.stateChangeListener);
    }

    /**
     * Handle stage changes reported by StateMonitor.
     *
     * @param state new state
     * @private
     */
    handleStateChange(state, container) {
      if (state === StateMonitor.RuntimeState.CONTAINER_ACTIVATED) {
        this.setContainerReady(container.getNavPath(), true);
      }
    }

    /**
     * Toggle the application ready flag.
     *
     * @param flag flag to set
     * @private
     */
    setApplicationReady(flag) {
      this.setContainerReady(APPLICATION_PATH, flag);
    }

    /**
     * Toogle the page ready flag.
     *
     * @param flag flag to set
     * @private
     */
    setPageReady(flag) {
      this.setContainerReady(CONTAINER_TYPE.PAGE, flag);
    }

    /**
     * Toggle the container ready flag for the given type.
     *
     * @param path the full path of a container
     * @param flag flag to set
     * @private
     */
    setContainerReady(path, flag) {
      // return if there's no change
      if (this.readyFlags[path] === flag) {
        return;
      }

      this.readyFlags[path] = flag;

      if (flag) {
        // resolve the readyPromise
        if (this.readyPromiseResolvers[path]) {
          this.readyPromiseResolvers[path]();
          this.readyPromiseResolvers[path] = null;
        }
      } else {
        this.readyPromiseResolvers[path] = null;
        this.readyPromises[path] = null;
      }
    }


    /**
     * utility function make sure anything that depends on JET is loaded as late as possible.
     * Is it required that any bundle overrides defined for the built-in JET translations be configured
     * BEFORE JET is loaded!
     *
     * defer loading the router until necessary, so JET isn't pulled in before its needed
     * @returns {Promise}
     */
    // eslint-disable-next-line class-methods-use-this
    getStateManagement() {
      this.stateManagementPromise = this.stateManagementPromise || Utils.getResources([
        'vb/private/stateManagement/router',
        'vb/private/stateManagement/application',
        'vb/private/stateManagement/page',
      ]);

      return this.stateManagementPromise;
    }

    /**
     * utility function make sure anything that depends on JET is loaded as late as possible.
     * Is it required that any bundle overrides defined for the built-in JET translations be configured
     * BEFORE JET is loaded!
     *
     * @returns {Promise}
     */
    // eslint-disable-next-line class-methods-use-this
    getServicesManager() {
      this.serviceManagerPromise = this.serviceManagerPromise
        || Utils.getResource('vb/private/services/servicesManager');

      return this.serviceManagerPromise;
    }
  }

  return new RuntimeManager();
});

