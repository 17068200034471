'use strict';

define('vb/private/stateManagement/context/fragmentExtensionContext',[
  'vb/private/stateManagement/context/extensionContext',
  'vb/private/stateManagement/context/fragmentBaseContext',
  'vb/private/constants',
], (ExtensionContext, FragmentBaseContext, Constants) => {
  /**
   * Set of properties to expose in fragment extension container
   */
  class FragmentExtensionContext extends ExtensionContext {
    /**
     * BaseContextType property: The type of the baseContext object used for $base in expression.
     * This is used by super class ContainerContext to construct the baseContext object.
     */
    static get BaseContextType() {
      return FragmentBaseContext;
    }

    /**
     * see ExtensionContext
     *
     * @param extension
     * @returns {Object}
     */
    static getAvailableContexts(extension) {
      const availableContexts = super.getAvailableContexts(extension);

      // Remove properties added by container context that should not be part of the
      // fragment context.
      delete availableContexts.$application;
      delete availableContexts.$metadata;

      Object.defineProperties(availableContexts, {
        // $extension.path
        $extension: {
          enumerable: true,
          configurable: true,
          value: {
            get [Constants.PATH_VARIABLE]() {
              // For v1 extension, the absoluteUrl already ends with self whereas for v2, it doesn't
              // end with ui/self so we need to append it.
              // TODO: Is there a better way to do this?
              const absoluteUrl = extension.absoluteUrl;
              if (absoluteUrl.endsWith(`${Constants.ExtensionFolders.SELF}/`)) {
                return absoluteUrl;
              }
              return `${absoluteUrl}${Constants.DefaultPaths.UI}${Constants.ExtensionFolders.SELF}/`;
            },
          },
        },
        // $fragment.variables is an alias of $variables
        $fragment: {
          enumerable: true,
          configurable: true,
          get() {
            return extension.expressionContext;
          },
        },
      });

      return availableContexts;
    }
  }

  return FragmentExtensionContext;
});

