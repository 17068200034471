'use strict';

define('vb/action/builtin/callVariableMethodAction',[
  'vb/action/action',
  'vb/private/utils',
  'vb/private/stateManagement/stateUtils',
  'vb/private/log',
  'vb/private/constants',
  'vb/action/builtin/assignVariablesAction',
],
(Action, Utils, StateUtils, Log, Constants, AssignVariablesAction) => {
  /**
   * Invoke a method on a variable of instanceFactory type.
   *
   * The parameters to this action are as follows:
   *
   * variable: a variable, e.g., $page.variables.foo
   * method: the method to invoke
   * params: the parameters to the method
   */
  class CallVariableMethodAction extends AssignVariablesAction {
    constructor(id, label) {
      super(id, label);
      this.log = Log.getLogger('/vb/action/action/CallVariableFunctionAction');
    }

    /**
     * returned Promise is resolved with Outcome (success or failure), or rejected with a message
     * @param parameters
     * @returns {Promise}
     */
    perform(parameters) {
      const variable = parameters.variable;
      const method = parameters.method;
      const params = parameters.params;

      // locate variable and instance property on it
      const targetInfo = this.analyzeTargetExpr(variable,
        { availableContexts: this.availableContexts });
      const instanceFactoryVar = targetInfo.typeClassification === Constants.VariableClassification.INSTANCE_FACTORY;

      if (!instanceFactoryVar) {
        const msg = `Action ${this.logLabel} cannot be used with non instance factory variable ${variable}`;
        this.log.error(msg);
        return Promise.reject(msg);
      }
      const varValue = targetInfo.rootValue;
      if (!varValue) {
        const msg = `Action ${this.logLabel} could not read the value of variable ${variable}`;
        this.log.error(msg);
        return Promise.reject(msg);
      }
      const instance = varValue.instance;
      if (!instance || !method || typeof instance[method] !== 'function') {
        const msg = `Action ${this.logLabel} cannot call method ${method} on variable ${variable}`;
        this.log.error(msg);
        return Promise.reject(msg);
      }

      if (params && !Array.isArray(params)) {
        const msg = `Action ${this.logLabel}: Inputs to CallVariableFunctionAction`
          + ' should be an array, even if it is a single item.';
        this.log.error(msg);
        return Promise.reject(msg);
      }

      const clonedParams = [];
      if (params) {
        Utils.cloneObject(params, clonedParams);
      }

      const func = instance[method];
      let ret;
      try {
        ret = func.apply(instance, clonedParams);
      } catch (e) {
        const msg = `Action ${this.logLabel}: error calling function: ${method}(${params})`;
        this.log.error(msg, e);
        return Promise.resolve(Action.createFailureOutcome(msg, e));
      }

      return Promise.resolve(ret)
        .then((result) => Action.createSuccessOutcome(result))
        .catch((e) => {
          const msg = `Action ${this.logLabel}: error calling function: ${method}(${params || ''})`;
          return Action.createFailureOutcome(msg, e);
        });
    }


    /**
     * Inject the available contexts for expression evaluations.
     *
     * @param availableContexts the available contexts such as $application, $page, etc
     */
    setAvailableContext(availableContexts) {
      this.availableContexts = availableContexts;
    }
  }

  return CallVariableMethodAction;
});

