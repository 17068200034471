'use strict';

define('vbsw/private/cacheStrategyFactory',[
  'vbsw/private/cacheStrategy',
  'vbsw/private/pwa/pwaCacheStrategy',
], (CacheStrategy, PwaCacheStrategy) => {
  /**
   * A factory to create a CacheStrategy for caching based on the ServiceWorker configuration.
   */
  class CacheStrategyFactory {
    static createCacheStrategy(fetchHandler) {
      const config = fetchHandler.config;
      if (config && !config.disabled && config.isPwa) {
        return new PwaCacheStrategy(fetchHandler);
      }
      return new CacheStrategy(config);
    }
  }
  return CacheStrategyFactory;
});


