'use strict';

define('vb/helpers/navigate',[
  'vb/private/stateManagement/router',
], (Router) => {
  /**
   * A set of routines related to navigation and used by built-in actions.
   */
  class Navigate {
    /**
     * Navigate to an external site with given a set of input parameters.
     *
     * @param {String}            url     The url to navigate to (required)
     * @param {Array<key, value>} params  A map of URL parameters (optional)
     * @param {String}            hash    The hash entry (optional)
     * @param {String}            history Effect on the browser history. Allowed value are 'replace' or 'push'.
     *                                    If the value is 'replace', the current browser history entry is replace,
     *                                    meaning that back button will not go back to it.
     *                                    (optional and default is push)
     */
    static toUrl(options) {
      Router.navigateToExternal(options);
    }

    /**
     * Calculate the URL of a page or flow given its id path like /pageId/flowId/pageId
     *
     * @param  {string} path the path of a page or flow
     * @return {string}      the URL to this page or flow
     */
    static getUrlFromPath(path) {
      return Router.getUrlFromPath(path);
    }
  }

  return Navigate;
});

