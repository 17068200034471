'use strict';

define('vb/private/monitoring/activateMonitorOptions',[
  'vbc/private/monitorOptions',
], (MonitorOptions) => {
  /**
   * Monitor options for container activation event
   */
  class activateMonitorOptions extends MonitorOptions {
    /**
     * @param  {String}     eventType
     * @param  {String}     msg
     * @param  {Container}  container
     */
    constructor(eventType, msg, container) {
      super(eventType, msg);
      this.addStartFields(() => ({
        container,
      }));
      this.addEndFields(() => ({
        container,
      }));
    }
  }

  return activateMonitorOptions;
});

