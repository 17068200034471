'use strict';

define('vb/action/builtin/navigateBackAction',[
  'vb/action/action',
],
(Action) => {
  /**
   * Navigate back to the previous page in browser history with input parameters.
   *
   * @param {Object} options - The set of options for the navigateBack action
   * @param {Array<string, string>} options.params - The input parameters for the page
   * the action is going back to
   * @return {Object} Always return a success outcome.
   */
  class NavigateBackAction extends Action {
    perform(parameters) {
      this.helpers.navigateBack(parameters);
      return Action.createSuccessOutcome();
    }
  }

  return NavigateBackAction;
});

