'use strict';

define('vb/private/services/readers/openApiObjectFactory',[
  'urijs/URI',
  'vb/private/utils',
  'vb/private/services/readers/openApi2Object',
  'vb/private/services/readers/openApi3Object',
], (
  URI,
  Utils,
  OpenApi2Object,
  OpenApi3Object,
) => {
  /**
   * Creates a callback function that can be used to load service metadata documents.
   * By default it delegates the call to the Services. If Services are not available
   * in the context, it loads service metadata documents by directly calling
   * RuntimeEnvironment.
   *
   * @param {Object} context
   * @param {Object} [context.initParams]
   * @param {String} [context.definitionUrl]
   * @param {Services} [context.services]
   * @returns {(string) => Promise<Object>}
   */
  const createLoaderFunc = ({ initParams = {}, definitionUrl, services }) => {
    if (services) {
      // TODO: support relative references:
      // https://swagger.io/specification/#relative-references-u-r-i
      // "relative references are resolved using the URLs defined in the Server Object as a Base URL."
      return (path) => services.fetchServiceDefinition(path, initParams);
    }

    // fallback: loader function without caching by Services
    const loaderFunc = (path) => Utils.getRuntimeEnvironment()
      .then((env) => {
        const url = path.startsWith('text!') ? path : URI(path).hash('').normalizePath().toString();
        return env.getServiceDefinition(url, initParams);
      });
    return loaderFunc;
  };

  /**
   *
   */
  class OpenApiObjectFactory {
    /**
     * @typedef {OpenApi3Object|OpenApi2Object} OpenApiObjectCommon
     */

    /**
     * Context object passed to the factory when creating new OpenApi Object.
     *
     * @typedef {Object} FactoryContext
     * @property {Object} initParams
     * @property {string} definitionUrl
     * @property {Services} services
     */

    /**
     * return a model for the definition
     * @param definition the JSON object
     * @param context abstraction of Application context. optional, used for variables substitution.
     *        One of the used properties for context is 'definitionUrl' whose value should be the
     *        url (or path) from which the definition was obtained.
     * @returns {OpenApiObjectCommon}
     */
    static get(definition, context = {}) {
      context.serviceMetadataLoader = createLoaderFunc(context);
      if (definition.openapi) {
        return new OpenApi3Object(definition, context);
      }
      return new OpenApi2Object(definition, context);
    }
  }

  return OpenApiObjectFactory;
});

