'use strict';

define('vb/action/builtin/switchAction',[
  'vb/action/action',
],
(Action) => {
  /**
   * outcome will be the condition as a string, or 'default' if the condition is undefined or null.
   *
   */
  class SwitchAction extends Action {
    /**
     * @param parameters
     *   caseValue: expression, the resulting 'case'.
     *   possibleValues {Array} optional, an array of possible values for 'caseValue', as strings. If provided,
     *     the case will be 'default' if it does not appear in this list.
     * @returns {{name, result}|*}
     */
    perform(parameters) {
      const cases = parameters.possibleValues;
      const cse = parameters.caseValue;

      let caseStr = (cse === undefined || cse === null) ? 'default' : `${cse}`;

      if (cases && cases.length && cases.indexOf(caseStr) < 0) {
        caseStr = 'default';
      }

      return Action.createOutcome(caseStr);
    }
  }

  return SwitchAction;
});

