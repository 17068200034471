'use strict';

define('vb/private/monitoring/loadMonitorOptions',[
  'vbc/private/monitorOptions',
], (MonitorOptions) => {
  /**
   * Monitor options for container load event
   */
  class LoadMonitorOptions extends MonitorOptions {
    constructor(eventType, msg, container) {
      super(eventType, msg);
      this.addStartFields(() => ({
        container,
      }));
      this.addEndFields(() => ({
        container,
      }));
    }
  }

  return LoadMonitorOptions;
});

