'use strict';

define('vb/private/translations/bundleV2Extension',[
  'vb/private/log',
], (
  Log,
) => {
  const logger = Log.getLogger('/vb/private/translations/bundleV2Extension');

  /**
   * BundleV2Extension
   *
   */
  class BundleV2Extension {
    constructor(extension, path, base) {
      this.base = undefined; // Avoid ts(2339) error
      this.resourceLoc = undefined; // Avoid ts(2339) error

      // Readonly properties for safety
      Object.defineProperties(this, {
        // a reference to the base object
        base: {
          value: base,
          enumerable: true,
        },
        extension: {
          value: extension,
          enumerable: true,
        },
        resourceLoc: {
          value: `${extension.baseUrl}${path}`,
          enumerable: true,
        },
        path: {
          value: path,
          enumerable: true,
        },
      });
    }

    /**
     */
    load() {
      const runtimeEnvironment = this.base.application.runtimeEnvironment;
      const path = this.resourceLoc;
      return runtimeEnvironment.getV2Strings(path, this.base.declaration)
        .then(({ functions }) => {
          this.stringFunctions = functions;
          return this;
        })
        .catch((error) => {
          logger.error(`error loading v2 bundle extension ${this.resourceLoc}`, error);
        });
    }

    getStringFunctions() {
      return this.stringFunctions || {};
    }
  }

  return BundleV2Extension;
});

