'use strict';

define('vb/action/builtin/geolocationAction',['vb/action/action', 'vb/private/log'], (Action, Log) => {
  const logger = Log.getLogger('/vb/action/builtin/geolocationAction');

  /**
   * Provides access to geographical location information associated with the hosting device.
   * This action requires user's consent and, as a best practice, should only be fired on user gesture.
   * Doing so will allow users to more easily associate the system permission prompt for access with the action
   * they just initiated.
   * <br>
   * Following parameters correspond to
   * [PositionOptions]{@link https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions}:
   *  - [maximumAge]{@link https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions/maximumAge}, default: 0
   *  - [timeout]{@link https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions/timeout}, default: Infinity
   *  - [enableHighAccuracy]{@link https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions/enableHighAccuracy},
   *  default: false
   *
   * On mobile devices, <i>enableHighAccuracy</i> should be set to true in order to use GPS sensors.
   * <br>
   *  If geolocation API is supported in the browser, a
   *  [Position]{@link https://developer.mozilla.org/en-US/docs/Web/API/Position} that represents the position
   *  of the device at a given time is returned. Latitude and longitude can be accessed from position's
   *  [Coordinates]{@link https://developer.mozilla.org/en-US/docs/Web/API/Coordinates} as follows:
   *  - <i>[[ $chain.results.getCurrentLocation.coords.latitude ]]</i>
   *  - <i>[[ $chain.results.getCurrentLocation.coords.longitude ]]</i>
   *  where <i>getCurrentLocation</i> is a geolocationAction.
   * <br>
   *  If geolocation is not supported by the browser, or a parameter with a wrong type is detected, a failure outcome
   *  is returned.
   *  If a [PositionError]{@link https://developer.mozilla.org/en-US/docs/Web/API/PositionError} occurs when obtaining
   *  geolocation, a failure outcome with
   *  [PositionError.code]{@link https://developer.mozilla.org/en-US/docs/Web/API/PositionError/code} payload is
   *  returned. Possible PositionError.code values are:
   *  - 1 - PositionError.PERMISSION_DENIED
   *  - 2 - PositionError.POSITION_UNAVAILABLE
   *  - 3 - PositionError.TIMEOUT
   *  <br>
   *  For every failure, a descriptive error message can be obtained from the action chain. For example:
   *  <i>[[ $chain.results.getCurrentLocation.error.message ]]</i>
   */
  class GeolocationAction extends Action {
    constructor(id, label) {
      super(id, label);
      this.log = logger;
    }

    /**
     * @param parameters
     * @returns {Promise} Outcome {name:"success"}, or {name:"failure"} for PositionError, or when geolocation
     * is not supported, or parameter error is detected.
     */
    // eslint-disable-next-line class-methods-use-this
    perform(parameters) {
      if (!navigator.geolocation) {
        const msg = 'Geolocation is not supported in the browser';
        return Action.createFailureOutcome(msg, new Error(msg));
      }

      // PositionOptions: https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions
      const options = {};
      const maximumAge = parameters.maximumAge; // eslint-disable-line prefer-destructuring
      const timeout = parameters.timeout; // eslint-disable-line prefer-destructuring
      const enableHighAccuracy = parameters.enableHighAccuracy; // eslint-disable-line prefer-destructuring

      // Make sure that parameters, if specified, are of correct types
      if (!(this.checkParam(maximumAge, 'number', 'maximumAge') &&
          this.checkParam(timeout, 'number', 'timeout') &&
          this.checkParam(enableHighAccuracy, 'boolean', 'enableHighAccuracy'))) {
        return Action.createFailureOutcome(this.errorMessage, new TypeError(this.errorMessage));
      }

      if (maximumAge) {
        // maximumAge = maximum age for a possible previously-cached position.
        // 0 = must return the current position, not a prior cached position
        options.maximumPage = maximumAge;
      }
      if (timeout) {
        // timeout = how long does the device have, in milliseconds to return a result?
        // The default value is set to Infinity, meaning that getCurrentPosition() might never return
        options.timeout = timeout;
      }
      if (enableHighAccuracy) {
        // enableHighAccuracy = should the device take extra time or power to return a really accurate result,
        // or should it give you the quick (but less accurate) answer?
        options.enableHighAccuracy = enableHighAccuracy;
      }

      return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition(
          // success callback
          (position) => {
            resolve(Action.createSuccessOutcome(position));
          },
          // failure callback
          (positionError) => {
            this.log.error(`(${this.logLabel}): ${positionError.message}`);
            resolve(Action.createFailureOutcome(positionError.message, positionError, positionError.code));
          },
          options
        );
      });
    }

    /**
     * Checks whether a parameter, if defined, is of an expected type. Sets this.errorMessage on type mismatch
     * and logs an error. For example:
     * <i>
     * (getCurrentLocation_r65qq5d23): Invalid GeolocationAction parameter 'enableHighAccuracy' : yes
     * </>
     * @param param parameter value to check
     * @param expectedType the expected type
     * @param paramName parameter name for error message
     * @returns {boolean} true, if no type errors are found, false otherwise.
     */
    checkParam(param, expectedType, paramName) {
      if (param && typeof param !== expectedType) { // eslint-disable-line valid-typeof
        this.errorMessage = `Invalid GeolocationAction parameter '${paramName}' : ${param}`;
        this.log.error(`(${this.logLabel}): ${this.errorMessage}`);
        return false;
      }
      return true;
    }
  }

  return GeolocationAction;
});

