'use strict';

define('vb/private/stateManagement/shellPageInfo',[
], () => {
  /**
   * A class used to describe the shell page
   *
   * @property {String} id
   * @property {Boolean} excludeFromUrl
   * @property {Extension | undefined } extension the extension object that contain this shell page
   *
   */
  class ShellPageInfo {
    /**
     *
     * @param {Application} application
     * @param {String} appUiId
     */
    constructor(application, appUiId) {
      // By default the shell page comes from the default page of the application
      this.id = application.definition.defaultPage.id;

      // but if this is an App UI, the shell page could be the hostRootPage property
      if (appUiId) {
        this.appUiId = appUiId;
        const info = application.appUiInfos.getInfo(appUiId);
        if (info) {
          const hostRootPage = info.hostRootPage;
          if (hostRootPage) {
            this.id = hostRootPage;
            // also keep track of the extension since the shell could be in the extension
            this.extension = application.appUiInfos.getExtension(appUiId);
          }
        }
      }

      // always skips the shell page in the URL
      this.excludeFromUrl = true;
    }
  }

  return ShellPageInfo;
});

