'use strict';

define('vb/private/ui/responsiveUtils',[
  'vb/private/log',
  'ojs/ojresponsiveutils',
  'ojs/ojresponsiveknockoututils',
], (Log, ojResponsiveUtils, ojResponsiveKnockoutUtils) => {
  const logger = Log.getLogger('/vb/private/responsiveUtils');

  /**
   * utilities for responsive UI related APIs that we expose in the binding syntax
   */
  class ResponsiveUtils {
    /**
     * expose some media query observables, created using JET utilities
     * @returns {Object} contains map of query keys to observables, plus 'screenRange'
     */
    static getResponsiveObservablesInterface() {
      const responsiveUtilsInterface = {};

      // add the JET queries, by keys in FRAMEWORK_QUERY_KEY
      Object.keys(ojResponsiveUtils.FRAMEWORK_QUERY_KEY).forEach((queryKeyId) => {
        const queryKey = ojResponsiveUtils.FRAMEWORK_QUERY_KEY[queryKeyId];
        const query = ojResponsiveUtils.getFrameworkQuery(queryKey);
        if (query) {
          const observable = ojResponsiveKnockoutUtils.createMediaQueryObservable(query);

          // expose the raw observable
          responsiveUtilsInterface[queryKeyId] = observable;
        } else {
          logger.fine('unable to create media query observable for',
            'ojResponsiveUtils.FRAMEWORK_QUERY_KEY', queryKeyId);
        }
      });

      // @deprecated - kept for backward compatibility
      // try/catch added for unit tests, JET throws an exception if all media queries return false (seems excessive).
      try {
        responsiveUtilsInterface.screenRange = ojResponsiveKnockoutUtils.createScreenRangeObservable();
      } catch (e) {
        logger.warn('error calling ojResponsiveKnockoutUtils.createScreenRangeObservable:', e);
      }

      const nameMap = {
        SM_UP: 'smUp',
        MD_UP: 'mdUp',
        LG_UP: 'lgUp',
        XL_UP: 'xlUp',
        SM_ONLY: 'smOnly',
        MD_ONLY: 'mdOnly',
        LG_ONLY: 'lgOnly',
      };

      Object.keys(nameMap).forEach((key) => {
        // wrap the existing observable with accessors, so parentheses are not needed in expressions
        // make sure it exists; guard against name changes
        if (responsiveUtilsInterface[key]) {
          Object.defineProperty(responsiveUtilsInterface, nameMap[key], {
            get: () => responsiveUtilsInterface[key](),
            enumerable: true,
            configurable: true,
          });
        }
      });


      // prevent '$application.responsive' from being modified, but allow the observables to change
      Object.seal(responsiveUtilsInterface);

      return responsiveUtilsInterface;
    }
  }

  return ResponsiveUtils;
});

