'use strict';

define('vb/action/builtin/resetDirtyDataStatusAction',[
  'vb/action/action',
  'vb/private/log',
], (Action, Log) => {
  const logger = Log.getLogger('/vb/action/builtin/resetDirtyDataStatusAction');

  /**
   * Re-sets the dirty data state of all tracked variables in the current container's
   * scope and all of the contained containers and their extensions.
   */
  class ResetDirtyDataStatusAction extends Action {
    constructor(id, label) {
      super(id, label);
      this.log = logger;
    }

    /**
     * Sets the current available context.
     * @param context
     */
    setContext(context) {
      this.context = context;
    }

    /**
     * Re-sets the dirty data state of variables being tracked in the current
     * container's scope and all of the contained containers including their extensions.
     * @param parameters
     * @returns always returns a Success outcome
     */
    // eslint-disable-next-line no-unused-vars
    perform(parameters) {
      const currentContainer = this.context.container;
      currentContainer.resetAllDirtyData();
      return Action.createSuccessOutcome();
    }
  }

  return ResetDirtyDataStatusAction;
});

