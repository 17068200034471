'use strict';

define('vb/private/types/arrayDataManager',[
  'vb/private/types/utils/dataProviderUtils',
  'vb/private/types/dataProviderConstants',
], (DataUtils, DPConstants) => {
  /**
   * The mode under which the data provider event is raised.
   * - 'default' meaning only raise the event without mutating data property
   * - 'deprecated' old behavior where both observable and data property are kept in sync
   *
   * @type {{DEFAULT: string, DEPRECATED: string}}
   */
  const DATAPROVIDER_EVENT_MODES = {
    DEFAULT: 'default',
    DEPRECATED: 'deprecated',
  };

  const getKeysLength = (keys) => keys && (keys instanceof Set ? keys.size : keys.length);

  /**
   * Manages all array data operations for ArrayDataProvider types - adds, updates, deletes.
   */
  class ArrayDataManager {
    /**
     * @param {ArrayDataProvider | ArrayDataProvider2 } adp instance of ArrayDataProvider or ArrayDataProvider2
     * @param {Array} data
     * @param {String} mode
     *  - 'default' meaning update the data observable
     *  - 'deprecated' old behavior where both observable and data property are kept in sync in
     *  tandem. This is only used by vb/ArrayDataProvider for backwards compatibility. This mode
     *  is used to determine whether we need to update the data property directly, without going
     *  through the variable setValue, which left the variable in an inconsistent state. We were
     *  doing it for some historic reason - BUFP-15413?. But we can't afford to break customers
     *  who are relying on this behavior >:( when using fireDataProviderEventAction by itself to
     *  mutate vb/ArrayDataProvider. In general we recommend they use assignVariablesAction to
     *  mutate ADP data
     */
    constructor(adp, data, mode = 'default') {
      this.adp = adp;
      this.reInit(data, mode);
    }

    /**
     * returns the supported event modes when the data property is mutated.
     * @return {{DEFAULT: string, DEPRECATED: string}}
     * @see ArrayDataProvider and ArrayDataProvider2
     * @constructor
     */
    static get DATAPROVIDER_EVENT_MODES() {
      return DATAPROVIDER_EVENT_MODES;
    }

    /**
     *
     * @param dataObs
     * @param {String} mode
     *  - 'default' meaning raise the event without mutating data property
     *  - 'deprecated' old behavior where both observable and data property are kept in sync in
     *  tandem. This is only used by vb/ArrayDataProvider for backwards compatability
     */
    reInit(dataObs, mode) {
      this.dataArray = dataObs;
      this.mode = mode;
      this.dataProp = this.mode === DATAPROVIDER_EVENT_MODES.DEPRECATED && this.adp.getValue().data;
    }

    /**
     * Appends the specified item both to the data observable as well as the variable property
     * @param item
     */
    appendToData(item) {
      this.dataArray.push(item);
      if (this.isDeprecatedMode()) {
        this.dataProp.push(item);
      }
    }

    /**
     * Apply all data mutations on the data array that is part of this event payload.
     * @param event
     * @return {boolean}
     */
    applyDataMutations(event) {
      let dataMutated = false;
      let data;
      let keys;
      let keysLength; // keys can be a Set or Array (Set not supported in page model type)
      let indexes;
      const adm = this;

      // process removes before adds so all the data is present before applying updates
      if (event.detail.remove) {
        const REMOVE = event.detail.remove;
        ({ data } = REMOVE);
        keys = REMOVE.keys || (REMOVE.metadata && REMOVE.metadata.map((value) => value.key));
        ({ indexes } = REMOVE);
        keysLength = getKeysLength(keys);

        // generally either keys or indices to be present. If neither is present but data is, then
        // we use the data to locate items to delete
        if ((!keys || keysLength === 0) && (!indexes || indexes.length === 0)
          && (data && data.length > 0)) {
          keys = adm.getKeysFromData(data);
          keysLength = getKeysLength(keys);
          if (!keysLength) {
            indexes = adm.getIndexesFromData(data);
          }
        }

        if (keys && keysLength > 0) {
          dataMutated = adm.removeDataAtKeys(keys, data) ? true : dataMutated;
        } else if ((indexes && indexes.length > 0)) {
          dataMutated = adm.removeDataAtIndexes(indexes, data) ? true : dataMutated;
        }
      }

      if (event.detail.add) {
        const ADD = event.detail.add;
        ({ data } = ADD);
        keys = ADD.keys || (ADD.metadata && ADD.metadata.map((value) => value.key));
        keysLength = getKeysLength(keys);
        ({ indexes } = ADD);
        const { afterKeys } = ADD;
        const { addBeforeKeys } = ADD;

        // data is absolutely required. keys or indexes can be provided for added data. When
        // indexes are provided it needs to match the data. keys is a unique Set.
        // For primitive data, the keys should be the same as indexes, and reflect indices of the
        // items in the final list. Example if the ADD event was raised to insert 2 items -
        // { data: ['a1','b0'], keys: [0, 2] }, the result of the add
        // yields ['a1', 'a', 'b0', 'b'], where previously it was ['a', 'b'].

        if (data && data.length > 0) {
          if (keys && keysLength > 0 && keysLength <= data.length) {
            // determine where to insert new items in the following order
            // - first check addBeforeKeys,
            // - then indexes,
            // then afterKeys (deprecated),
            // otherwise add to the end
            if (addBeforeKeys && addBeforeKeys.length > 0) {
              dataMutated = adm.insertAtAddBeforeKeys(addBeforeKeys, data) ? true : dataMutated;
            } else if (indexes && indexes.length > 0) {
              // use indexes if present; it takes precedence over afterKeys
              dataMutated = adm.insertAtIndexes(indexes, data) ? true : dataMutated;
            } else if (afterKeys && afterKeys.length > 0) {
              dataMutated = adm.insertAtAfterKeys(afterKeys, data) ? true : dataMutated;
            } else {
              dataMutated = adm.insertAtEnd(data) ? true : dataMutated;
            }
          } else if (indexes && indexes.length > 0 && indexes.length === data.length) {
            dataMutated = adm.insertAtIndexes(indexes, data) ? true : dataMutated;
          } else {
            dataMutated = adm.insertAtEnd(data) ? true : dataMutated;
          }
        }
      }

      if (event.detail.update) {
        const UPDATE = event.detail.update;
        ({ data } = UPDATE);
        keys = UPDATE.keys || (UPDATE.metadata && UPDATE.metadata.map((value) => value.key));
        ({ indexes } = UPDATE);
        keysLength = getKeysLength(keys);

        // either keys or indexes is provided along with data. if neither keys nor indexes
        // is provided then we attempt to locate keys from data.
        if (data && data.length > 0) {
          if ((!keys || keysLength === 0) && (!indexes || indexes.length === 0)) {
            keys = adm.getKeysFromData(data);
            keysLength = getKeysLength(keys);
            if (!keysLength) {
              indexes = adm.getIndexesFromData(data);
            }
          }

          // when indexes is present use that to update first, especially when the item updated
          // is a result of a move.
          if ((indexes && indexes.length > 0 && indexes.length === data.length)) {
            dataMutated = adm.updateDataAtIndexes(indexes, data) ? true : dataMutated;
          } else if (keys && keysLength > 0 && keysLength === data.length) {
            dataMutated = adm.updateDataAtKeys(keys, data) ? true : dataMutated;
          }
        }
      }

      return dataMutated;
    }

    /**
     * whether running in deprecated mode
     * @return {boolean}
     * @private
     */
    isDeprecatedMode() {
      return this.mode === DATAPROVIDER_EVENT_MODES.DEPRECATED;
    }

    /**
     * Locates the index of the data row with specified key in the ADP data observable. The key
     * field is known by the idAttribute set. If not set this method returns -1.
     * @param key
     * @returns {number}
     */
    getIndexByKey(key) {
      let index = -1;
      const d = this.dataArray;
      const idAttr = this.adp.options[DPConstants.DataProviderIdAttributeProperty.KEY_ATTRIBUTES];
      if (idAttr) {
        const idHelper = DataUtils.getIdAttributeHelper(idAttr);
        if (d && idHelper) {
          d.some((item, i) => {
            if (idHelper.compare(idHelper.getKey(item), key)) {
              index = i;
              return true;
            }
            return false;
          });
        }
      }
      return index;
    }

    /**
     * Returns keys for the data or null if the keys cannot be completely determined
     * @param {Array} data
     * @return Set of keys or null
     */
    getKeysFromData(data) {
      if (data) {
        const keyAttrsProp = DPConstants.DataProviderIdAttributeProperty.KEY_ATTRIBUTES;
        const idHelper = DataUtils.getIdAttributeHelper(this.adp.options[keyAttrsProp]);
        // getKeys() returns null if idHelper does not support this capability
        const keys = idHelper.getKeys(data);
        const keysLen = getKeysLength(keys);

        if (keysLen <= data.length) {
          return keys;
        }
      }
      return null;
    }

    /**
     * returns indexes for the data, only if the idAttribute is set to '@index'
     * @param data
     * @private
     */
    getIndexesFromData(data) {
      const keyAttrsProp = DPConstants.DataProviderIdAttributeProperty.KEY_ATTRIBUTES;
      const isIndex = this.adp.options[keyAttrsProp]
        || this.adp.options[keyAttrsProp] === DPConstants.DataProviderIdAttribute.AT_INDEX;
      if (data && isIndex) {
        const idHelper = DataUtils.getIdAttributeHelper(this.adp.options[keyAttrsProp]);
        return idHelper.getIndices(data);
      }
      return null;
    }

    /**
     * Returns data item that matches the key or undefined if no match.
     * @param {*} key
     * @param {Array} data
     */
    getDataItemForKey(key, data) {
      let item;
      if (data) {
        const keyAttrsProp = DPConstants.DataProviderIdAttributeProperty.KEY_ATTRIBUTES;
        const idHelper = DataUtils.getIdAttributeHelper(this.adp.options[keyAttrsProp]);
        if (data && idHelper) {
          data.some((it) => {
            if (idHelper.compare(idHelper.getKey(it), key)) {
              item = it;
              return true;
            }
            return false;
          });
        }
      }
      return item;
    }

    /**
     * Inserts data at the specified indexes. Invalid indexes integers less than 0 are skipped
     * with error.
     * @param {Array} indexes array of indices to insert the data items at
     * @param {Array} data corresponding array of data items to insert at the indexes
     * @returns {boolean}
     */
    insertAtIndexes(indexes, data) {
      let insertedData;
      const skippedIndices = [];
      const indexedData = {};
      indexes.forEach((index, i) => {
        indexedData[index] = data[i];
      });
      // TODO: why are we reverse sorting first? Not sure but it doesn't work
      // indexes.sort((a, b) => b - a); // reverse sort indices first and then insert in array
      indexes.forEach((idx) => {
        const item = indexedData[idx];
        if (idx >= 0) {
          this.spliceData(idx, 0, item);
          insertedData = insertedData || {};
          insertedData[idx] = item;
        } else if (idx === -1) {
          // insert at end
          this.appendToData(item);
          insertedData = insertedData || {};
          insertedData[idx] = item;
        } else {
          skippedIndices.push(idx);
        }
      });
      if (skippedIndices.length > 0) {
        this.adp.log.error('skipped adding items at the specified indices', skippedIndices,
          'because the \'indexes\' are not valid');
      }
      if (!insertedData && data) {
        this.adp.log.finer('unable to add items', data, 'at the indexes', indexes);
      } else {
        this.adp.log.finer('added items at indexes', Object.keys(insertedData),
          'with values', Object.values(insertedData));
      }
      return !!insertedData;
    }

    /**
     * For every data item inserts data immediately before the corresponding afterKey
     * location. If afterKey cannot be found then data insertion is skipped
     *
     * @param {Set} afterKeys Set of afterKeys where data needs to be inserted.
     * @param data to insert
     * @returns {boolean}
     */
    insertAtAfterKeys(afterKeys, data) {
      const insertIndexes = [];
      afterKeys.forEach((afterKey) => {
        const insertIndex = this.getIndexByKey(afterKey);
        insertIndexes.push(insertIndex < 0 ? -1 : insertIndex);
      });
      return this.insertAtIndexes(insertIndexes, data);
    }

    /**
     * For every data item inserts data immediately before the corresponding addBeforeKey
     * location. If addBeforeKey cannot be found then data insertion is skipped
     *
     * @param {Array} addBeforeKeys array of keys for items located after the items involved in the
     * operation. If null and index not specified then insert at the end.
     * @param data to insert
     * @returns {boolean}
     */
    insertAtAddBeforeKeys(addBeforeKeys, data) {
      const insertIndexes = [];
      addBeforeKeys.forEach((beforeKey) => {
        const insertIndex = this.getIndexByKey(beforeKey);
        insertIndexes.push(insertIndex < 0 ? -1 : insertIndex);
      });
      return this.insertAtIndexes(insertIndexes, data);
    }

    /**
     * Inserts data to the end of the data observable array
     * @param {Array} dataToAdd
     * @return {boolean}
     */
    insertAtEnd(dataToAdd) {
      let dataMutated = false;
      // append data to the end;
      dataToAdd.forEach((item) => {
        this.appendToData(item);
        dataMutated = true;
      });

      return dataMutated;
    }

    /**
     * Removes the data items located at the indexes from the data observable
     * @param {Array} indexes array of indices to remove
     * @param {Array|*} dataToRemove corresponding array of data items to remove. generally keys
     * to remove is what we look for and dataToRemove is optional
     * @returns {boolean}
     */
    removeDataAtIndexes(indexes, dataToRemove) {
      let removedData;
      let skippedIndexes;

      if (indexes) {
        indexes.sort((a, b) => b - a); // reverse sort indices first and then splice array
        indexes.forEach((idx) => {
          if (this.dataArray[idx]) {
            this.spliceData(idx, 1);
            removedData = removedData || [];
            removedData.push(idx);
          } else {
            skippedIndexes = skippedIndexes || [];
            skippedIndexes.push(idx);
          }
        });
      }

      if (skippedIndexes) {
        this.adp.log.error('skipped removing items because an item could not be located at the specified indexes:',
          skippedIndexes);
      }
      if (!removedData) {
        this.adp.log.finer('unable to remove items', dataToRemove, 'as they no longer exist');
      } else {
        this.adp.log.finer('removed items at indexes', removedData);
      }
      return !!removedData;
    }

    /**
     * Removes the first data item with a key that matches each k in keysToRemove.
     * @param {Set} keysToRemove set of keys to remove from the data observable
     * @param {Array} dataToRemove
     * @return {boolean}
     */
    removeDataAtKeys(keysToRemove, dataToRemove) {
      const indicesToRemove = [];
      keysToRemove.forEach((key) => {
        const removeIndex = this.getIndexByKey(key);
        if (removeIndex >= 0) {
          indicesToRemove.push(removeIndex);
        }
      });
      return this.removeDataAtIndexes(indicesToRemove, dataToRemove);
    }

    /**
     * splices item from dataObs (which is the observable)
     * @param start
     * @param deleteCount
     * @param item
     */
    spliceData(start, deleteCount, item) {
      if (item) {
        if (this.dataArray[start] === undefined) {
          this.dataArray[start] = item;
        } else {
          this.dataArray.splice(start, deleteCount, item);
        }
        if (this.isDeprecatedMode()) {
          this.dataProp.splice(start, deleteCount, item);
        }
      } else if (this.dataArray[start] !== undefined) {
        this.dataArray.splice(start, deleteCount);
        if (this.isDeprecatedMode()) {
          this.dataProp.splice(start, deleteCount);
        }
      }
    }

    /**
     * Updates the data observable using the first data that matches the specified key, for
     * every k in keysToUpdate
     * @param {Set | Array} keysToUpdate Set of keys to update; it can be array because we
     * currently don't
     * support Set as a type in page model.
     * @param {Array} dataToUpdate
     * @return {boolean}
     */
    updateDataAtKeys(keysToUpdate, dataToUpdate) {
      let updatedData;
      let skippedKeys;
      const isKeysASet = keysToUpdate instanceof Set;
      keysToUpdate.forEach((key, i) => {
        // if keys is a Set locate data item with a key match. data at index i only works if keys is
        // an Array
        const item = isKeysASet ? this.getDataItemForKey(key, dataToUpdate) : dataToUpdate[i];
        const curItemIndex = this.getIndexByKey(key);
        if (curItemIndex >= 0) {
          this.spliceData(curItemIndex, 1, item);
          updatedData = updatedData || {};
          updatedData[curItemIndex] = item;
        } else {
          skippedKeys = skippedKeys || [];
          skippedKeys.push(key);
        }
      });

      if (skippedKeys) {
        this.adp.log.error('unable to update items because an item with the keys',
          skippedKeys, 'could not be located');
      }
      if (!updatedData) {
        this.adp.log.finer('unable to update items', dataToUpdate, 'with these keys', keysToUpdate);
      } else {
        this.adp.log.finer('updated items at indexes', Object.keys(updatedData),
          'with values', Object.values(updatedData));
      }
      return !!updatedData;
    }

    /**
     * Updates the data items located at index using matching item in data
     * @param {Array} indexesToUpdate
     * @param {Array} dataToUpdate
     * @return {boolean}
     */
    updateDataAtIndexes(indexesToUpdate, dataToUpdate) {
      let updatedData;
      const skippedIndices = [];
      const indexedData = {};

      indexesToUpdate.forEach((index, i) => {
        indexedData[index] = dataToUpdate[i];
      });

      indexesToUpdate.sort((a, b) => b - a); // reverse sort indices first and then splice array
      indexesToUpdate.forEach((idx) => {
        if (this.dataArray[idx]) {
          this.spliceData(idx, 1, indexedData[idx]);
          updatedData = updatedData || {};
          updatedData[idx] = indexedData[idx];
        } else {
          skippedIndices.push(idx);
        }
      });
      if (skippedIndices.length > 0) {
        this.adp.log.error('skipped updating items because no item exists at indexes',
          skippedIndices);
      }
      if (!updatedData && dataToUpdate) {
        this.adp.log.finer('unable to update items', dataToUpdate, 'at these indexes', indexesToUpdate);
      } else {
        this.adp.log.finer('updated items at indexes', Object.keys(updatedData),
          'with values', Object.values(updatedData));
      }
      return !!updatedData;
    }
  }

  return ArrayDataManager;
});

