'use strict';

define('vb/types/factories/multiServiceDataProviderFactory',['vb/types/factories/typeFactory',
  'vb/private/types/dataProviders/dynamicMultiServiceDataProvider'], (TypeFactory, DynamicMultiServiceDataProvider) => {
  /**
   * A type factory implementation for the builtin type MultiServiceDataProvider
   */
  class MultiServiceDataProviderFactory extends TypeFactory {
    /**
     * Creates a standalone MultiServiceDataProvider instance that is initialized with the options that the caller
     * provides. The instance is a standalone and is not backed by a variable backed by a variable / redux framework
     * that manages its state. The instance merely fetches the data from Rest and returns the result.
     *
     * @param {object|{}} options used to instantiate the MultiServiceDataProvider with, usually contains these
     * properties
     * @property  {object} options.dataProviderOptions - options used to instantiate the service data provider instance.
     *  For a list of supported properties refer to the vb/MultiServiceDataProvider documentation. Some exceptions
     *  are -
     *  a. no expressions are allowed for properties, values must be primitives or Object / Array literals.
     *  b. properties that take SDP references must be provided an instance created using the
     *  ServiceDataProviderFactory.createInstance method.
     *
     * @property {object} options.serviceOptions - options used to instantiate the RestHelper with. RestHelper is
     * created internally by the SDP instance to fetch data
     *
     * @property {object} options.vbContext - options that provide the 'vb' context for the current call (this object
     *                                     is typically provided by a VB API or callback mechanism). If this object is
     *                                     not available, pass in an object with the format
     *                                     <i>{ extensionId: string }</i>, in which 'extensionId' is the id of the
     *                                     extension <b>executing</b> the code.
     *
     * @returns Promise<DynamicMultiServiceDataProvider> resolves with a DynamicMultiServiceDataProvider standalone
     * instance
     *
     * @todo need to work with JET to define a context, if possible, to determine which extension
     * this is being used in (if any).
     */
    // eslint-disable-next-line no-unused-vars
    static createInstance(options = {}) {
      const { dataProviderOptions, serviceOptions, vbContext } = options;
      return Promise.resolve(new DynamicMultiServiceDataProvider(dataProviderOptions, serviceOptions, vbContext));
    }
  }

  return MultiServiceDataProviderFactory;
});

