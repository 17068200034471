'use strict';

define('vb/private/types/capabilities/serviceTransformsHookHandler',['vb/helpers/restHookHandler', 'vb/private/types/utils/serviceDataProviderRestHelperFactory'],
  (RestHookHandler, SDPRestHelperFactory) => {
    /**
     * A HookHandler to set request/response transform options and functions on the RestHelper, and
     * process response transforms part of the response data.
     */
    class ServiceTransformsHookHandler extends RestHookHandler {
      constructor(fetchContext) {
        super();
        /**
         * @private
         */
        this.context = fetchContext;
      }

      /**
       * Creates a custom RestHelper.
       * @param endpointName
       * @container container
       * @returns {OperationRefRestHelper}
       */
      // eslint-disable-next-line class-methods-use-this
      getRestHelper(endpointName, container) {
        return SDPRestHelperFactory.get(endpointName, container);
      }

      /**
       * Called before a fetch request is made. The request has not yet been created, nor have the
       * request transformation functions been called. This is a good place to set the request
       * and response transform options/functions on the RestHelper
       *
       * @param restHelper the rest instance performing the fetch
       * @return Promise<*>
       */
      handlePreFetchHook(restHelper) {
        return Promise.resolve(this.setupTransforms(restHelper));
      }

      /**
       * Called with the results of the fetch. This will contain both the response as well
       * as the results of response transformation functions.
       *
       * @param result The return value of the rest helper
       * @return Promise that resolves when transforms results have been processed successfully.
       */
      handlePostFetchHook(result) {
        return this.processResponseTransforms(result.transformResults);
      }

      /**
       * Delegates to the FetchContext to setup the request and response transform options/functions
       * on the restHelper before a fetch call.
       *
       * @param rest the rest helper instance
       * @private
       */
      setupTransforms(rest) {
        this.context.setupTransforms(rest);
      }

      /**
       * Delegates to the FetchContext object to process the response transforms after a
       * successful fetch.
       * @param transformResults results returned after running response transforms
       * @return Promise that resolves when transforms results have been processed successfully.
       * @private
       */
      processResponseTransforms(transformResults) {
        return this.context.processResponseTransforms(transformResults);
      }
    }

    return ServiceTransformsHookHandler;
  });

