'use strict';

define('vbsw/private/stateCache',[], () => {
  // suffix used to ensure the namespace is unique
  const NAMESPACE_SUFFIX = '_namespace';

  /**
   * Cache used to store the state of the service worker, e.g., plugin urls and offline handler, etc. It uses the cache
   * created by the service worker.
   */
  class StateCache {
    constructor(serviceWorkerCache, namespace) {
      this.swCache = serviceWorkerCache;

      // namespace is used to group a set of cached data into a single cached object
      this.namespace = namespace ? `${namespace}${NAMESPACE_SUFFIX}` : null;

      // used in emulation mode when the service worker cache is not available
      this.memoryCache = {};
    }

    /**
     * Create a namespaced cache off of the current cache.
     *
     * @param namespace namespace for the new cache
     * @returns {StateCache}
     */
    createNamespacedCache(namespace) {
      return new StateCache(this.swCache, namespace);
    }

    /**
     * Retrieve the cached data for the given url. If the cache is namespaced, the namespace will first be used
     * to retrieve the cached data and the actual data will be indexed using the given url.
     *
     * @param url the url for the cached data
     * @returns {Promise}
     */
    get(url) {
      // use the namespace to retrieve the data if exists
      const cacheUrl = this.namespace || url;

      return this.getInternal(cacheUrl).then((data) => {
        if (data && this.namespace) {
          return data[url];
        }

        return data;
      });
    }

    /**
     * Retrieve the data for the given url from the underlying cache.
     *
     * @param url the url for the cached data
     * @returns {Promise}
     * @private
     */
    getInternal(url) {
      if (this.swCache) {
        return this.swCache.match(url).then((response) => {
          if (response) {
            return response.json();
          }

          return undefined;
        });
      }

      // else look up from the memory cache
      return Promise.resolve(this.memoryCache[url]);
    }

    /**
     * Cache the data at the given url. If the cache is namespaced, the namespace will first be used
     * to retrieve the cached data and the data will be added to the cached data and written back to the cache.
     *
     * @param url the url for where to cache the data
     * @param data the data to cache
     * @returns {Promise<void>|Promise<R>|Promise.<T>}
     */
    put(url, data) {
      if (this.namespace) {
        // first get the cached data using the namespace
        return this.getInternal(this.namespace).then((cachedData) => {
          const dataToCache = cachedData || {};

          // add the new data to the cached data
          dataToCache[url] = data;

          // write the updated cached data back
          return this.putInternal(this.namespace, dataToCache);
        });
      }

      return this.putInternal(url, data);
    }

    /**
     * A version of put that works with opaque responses by putting them directly in a cache.
     * @param url the url for where to cache the data
     * @param data the data to cache
     * @returns {Promise<void>}
     */
    putOpaque(url, data) {
      if (this.swCache) {
        return this.swCache.put(url, data).catch((err) => {
          console.log('putOpaque', url, 'failed with', err);
        });
      }
      return Promise.resolve();
    }

    /**
     * Put the data at the given url into the underlying cache.
     *
     * @param url the url for where to cache the data
     * @param data the data to cache
     * @returns {Promise}
     * @private
     */
    putInternal(url, data) {
      if (this.swCache) {
        const response = new Response(JSON.stringify(data));
        return this.swCache.put(url, response).catch((err) => {
          console.log('putInternal', url, 'failed with', err);
        });
      }

      // else put it in the memory cache
      this.memoryCache[url] = data;

      return Promise.resolve();
    }

    getResponse(url, options) {
      if (this.swCache) {
        return this.swCache.match(url, options);
      }
      return Promise.resolve();
    }

    add(url) {
      if (this.swCache) {
        return this.swCache.add(url).catch((err) => {
          console.log('add', url, 'failed with', err);
          StateCache.checkQuota();
        });
      }
      return Promise.resolve();
    }

    /**
     * Delete the cached data matching the given url. If the cache is namespaced, the namespace will first be used
     * to retrieve the cached data and the data will be deleted from it and then the cached data will be written
     * back to the cache.
     *
     * @param url the url for the cached data to delete
     * @returns {Promise}
     */
    delete(url) {
      if (this.namespace) {
        // first get the cached data using the namespace
        return this.getInternal(this.namespace).then((cachedData) => {
          const dataToCache = cachedData || {};

          // delete the data using the given url
          delete dataToCache[url];

          // write the updated cached data back
          return this.putInternal(this.namespace, dataToCache);
        });
      }

      return this.deleteInternal(url);
    }

    /**
     * Delete the cached data matching the given url from the underlying cache.
     *
     * @param url the url for the cached data to delete
     * @returns {Promise}
     * @private
     */
    deleteInternal(url) {
      if (this.swCache) {
        return this.swCache.delete(url);
      }

      // else delete it from the memory cache
      delete this.memoryCache[url];

      return Promise.resolve();
    }
    /**
     * Clear the cache. Note that this method is only supported if the cache is namespaced. All the cached data will
     * be deleted using the namespace.
     *
     * @returns {Promise}
     */
    clear() {
      if (this.namespace) {
        return this.deleteInternal(this.namespace);
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('StateCache.clear is not supported for a cache without a namespace.');
    }

    /**
     * @returns {Promise<boolean>} a promise to true, if cache is non empty, a promise
     * to false otherwise.
     */
    isEmpty() {
      return Promise.resolve(this.swCache.keys())
        .then((keys) => {
          if (Array.isArray(keys) && keys.length > 0) {
            return false;
          }
          return true;
        });
    }

    static checkQuota() {
      if ('storage' in navigator && 'estimate' in navigator.storage) {
        navigator.storage.estimate().then(({
          usage, quota,
        }) => {
          console.log('checkQuota: Using', usage, 'out of', quota, 'bytes.');
        });
      }
    }
  }
  return StateCache;
});

