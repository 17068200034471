'use strict';

define('vb/private/pwa/jetCache',[
  'ojs/ojcontext',
  'ojs/ojthemeutils',
  'vb/private/utils',
  'vbc/private/pwa/pwaUtils',
  'vbc/private/log',
  'vbc/private/monitorOptions',
], (ojContext, ThemeUtils, Utils, PwaUtils, Log, MonitorOptions) => {
  const logger = Log.getLogger('/vb/private/pwa/jetCache');

  const JET_THEME_REDWOOD = 'redwood';
  const WEB_TARGET_PLATFORM = 'web';
  const ALL_TARGET_PLATFORM = 'all';
  const JET_THEMES = [
    'alta-web',
    'alta-ios',
    'alta-android',
    'alta-windows',
    `${JET_THEME_REDWOOD}-${ALL_TARGET_PLATFORM}`];
  const JET_RESOURCES_PATH = 'metadata/components/jetResources.json';
  const JET_BUNDLES_CONFIG = 'default/js/bundles-config';

  //
  // Cache bundles specified in https://static.oracle.com/cdn/jet/v7.2.0/default/js/bundles-config-debug.js
  // that would not get cached as a result of component imports, because they are required by every VB
  // application. These get loaded early during main.init() before SW is registered, so these requests
  // cannot be intercepted by pwaCacheStrategy at that time. But by the time jetCache is called,
  // pwaCacheStrategy will intercept these requests and add them to the cache.
  //
  const JET_MIN = [
    'default/js/min/oj3rdpartybundle.js', // https://jira.oraclecorp.com/jira/browse/BUFP-30744
    'default/js/min/ojcorebundle.js', // https://jira.oraclecorp.com/jira/browse/BUFP-30744
    '3rdparty/require/require.js',
  ];

  const JS_MIN_REGEX = /js\/min/i;
  const JS_DEBUG = 'js/debug';
  const CACHE_JET_MONITOR = MonitorOptions.SPAN_NAMES.CACHE_JET_MONITOR;
  const JET_CACHING_MESSAGE = 'Jet caching';

  /**
   * This class is responsible for preloading all the JET resources required by the application. The actual caching
   * is done by the Service Worker, which is configured to recognize JET requests and adding them to the cache
   * as part of fetch processing.
   */
  class JetCache {
    /**
     * After the page loads, fetch all the jet resources required by an application.
     * @returns {Promise} a Promise to cache JET resources
     */
    static cacheJetWhenPageLoads() {
      const busyContext = ojContext.getPageContext().getBusyContext();
      let totalTime;
      return busyContext.whenReady()
        .then(() => {
          const mo = new MonitorOptions(CACHE_JET_MONITOR, JET_CACHING_MESSAGE);
          return logger.monitor(mo, (finish) => {
            totalTime = finish;
            const vbConfig = window.vbInitConfig;
            if (vbConfig) {
              const shellPath = vbConfig.SERVICE_WORKER_CONFIG && vbConfig.SERVICE_WORKER_CONFIG.shellPath;
              const jetPath = `${vbConfig.JET_CDN_PATH}${vbConfig.JET_CDN_VERSION}/`;
              return JetCache.cacheJet(shellPath, jetPath, vbConfig.DEBUG)
                .then((jetCachePromiseResult) => {
                  logger.info('PWA: cacheJetWhenPageLoads() succeeded', totalTime());
                  return jetCachePromiseResult;
                });
            }
            logger.info('PWA: cacheJetWhenPageLoads() no config found', totalTime());
            return undefined;
          });
        })
        .catch((error) => {
          // If JET caching fails, there is not much we can do
          logger.error(error);
          logger.info('PWA: cacheJetWhenPageLoads() failed', totalTime(error));
        });
    }

    /**
     * Performs a fetch for specified JET resources in order to force caching.
     * @param {string[]} resources an array JET resources to fetch
     * @param {string} jetPath JET cdn location, for example: <i>https://static.oracle.com/cdn/jet/v7.0.0/</i>
     * @param {ConstrainBoolean} debug flag indicates whether minified JET scripts should be cached
     * @returns {Promise<>} a promise to fetch JET resources
     */
    static fetchJetResources(resources = [], jetPath = '', debug) {
      return Promise.resolve()
        .then(() => {
          const files = [];
          const jetCdn = PwaUtils.postSlash(jetPath);
          let customJet = resources;
          if (debug) {
            customJet = resources.map((f) => f.replace(JS_MIN_REGEX, JS_DEBUG));
          }
          files.push(...customJet.map((f) => `${jetCdn}${f}`));
          const fetchPromises = files.map((f) => fetch(f)
            .then((response) => {
              // opaque responses result in status = 0;
              if (!(response.ok || response.type === 'opaque')) {
                logger.error(`Fetch error for ${response.url}`);
              }
              return response;
            }));
          return Promise.all(fetchPromises);
        });
    }

    /**
     * If an application is using one of the supported JET themes, theme name will be returned. If an application
     * is using a custom theme, returns undefined.
     * @returns {undefined|*} JET theme or undefined
     */
    static getTheme() {
      const themeName = ThemeUtils.getThemeName();
      const themeTargetPlatform = ThemeUtils.getThemeTargetPlatform();
      const jetTheme = `${themeName}-${themeTargetPlatform}`;
      if (JET_THEMES.includes(jetTheme)) {
        // for a web platform or redwood, theme name should be alta (or redwood), not alta-web
        return (themeTargetPlatform === ALL_TARGET_PLATFORM || themeTargetPlatform === WEB_TARGET_PLATFORM)
          ? themeName
          : jetTheme;
      }
      // for custom themes, return undefined since these themes won't be available on JET CDN
      return undefined;
    }

    /**
     * @param {String} jetPath the location of the jet resources
     * @returns {Promise<string[]>}} an array of theme based resources required by an application, or an empty array
     * if an application is using a custom theme
     */
    static getThemeBasedResources(jetPath) {
      return fetch(`${jetPath}${JET_RESOURCES_PATH}`)
        .then((response) => response.json())
        .then((jsonContent) => jsonContent.jetResources[JetCache.getTheme()])
        .catch(() => {
          logger.warn(`PWA: getThemeBasedResources() fetch failed for JET meta-data - ${jetPath}${JET_RESOURCES_PATH}`);
          return [];
        });
    }

    /**
     * Even though OPT (Offline Persistence Toolkit) is mainly used by the Service Worker thread, and it is loaded
     * by the Service Worker, it needs to be cached because it is required by app-flow.js, where OfflineHandler
     * is defined. And app-flow.js is also loaded by the main thread. So when the page is reloaded offline,
     * it won't load unless everything required by app-flow.js is in the cache.
     * In addition, OPT cannot benefit from cache on fetch because app-flow.js is loaded before SW is registered.
     * More general, everything that app references via require should be cached. Even though RT supports it
     * via appShellCache.json, there is no support, currently, to edit this file in DT.
     * @param {*} jetPath JET cdn location, for example: <i>https://static-stage.oracle.com/cdn/jet/12.0.0-alpha.3/</i>
     * @param {*} debug flag indicates whether minified JET scripts should be cached
     * @returns {Promise} a Promise to a list of OPT resources (bundles) that should be added to PWA cache extracted
     * from JET's build manifest (bundles-config.json). For example:
     * [
     *  '3rdparty/persist/min/offline-persistence-toolkit-core-1.5.6.js',
     *  '3rdparty/persist/min/offline-persistence-toolkit-pouchdbstore-1.5.6.js',
     *  '3rdparty/persist/min/offline-persistence-toolkit-arraystore-1.5.6.js',
     *  '3rdparty/persist/min/offline-persistence-toolkit-localstore-1.5.6.js',
     *  '3rdparty/persist/min/offline-persistence-toolkit-filesystemstore-1.5.6.js',
     *  '3rdparty/persist/min/offline-persistence-toolkit-responseproxy-1.5.6.js'
     * ]
     */
    static getOptResources(jetPath, debug = false) {
      const opt = [];
      return Promise.resolve()
        .then(() => {
          // https://static-stage.oracle.com/cdn/jet/12.0.0-alpha.3/default/js/bundles-config.json
          const request = new Request(`${jetPath}${JET_BUNDLES_CONFIG}.json`);
          return fetch(request);
        })
        .then((result) => {
          if (result && result.ok) {
            return result.text();
          }
          // eslint-disable-next-line max-len
          throw new Error(`getOptResources(${jetPath}, ${debug}): invalid response: ${result ? result.status : result}`);
        })
        .then((text) => {
          if (text) {
            return JSON.parse(text);
          }
          throw new Error(`getOptResources(${jetPath}, ${debug}}): result.text is empty`);
        })
        .then((bundlesConfig) => {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < bundlesConfig.bundles.length; i++) {
            const bundle = bundlesConfig.bundles[i];
            // relevant bit of bundles-config.json's that we're parsing here:
            // {
            //   "name": "offline-persistence-toolkit-core-1.5.6",
            //   "path": {
            //     "debug": "3rdparty/persist/debug",
            //     "min": "3rdparty/persist/min"
            //   },
            //   "include": [
            //     ...
            //   ]
            // },
            if (bundle.path.debug === '3rdparty/persist/debug') {
              opt.push(`${debug ? bundle.path.debug : bundle.path.min}/${bundle.name}.js`);
            }
          }
          return opt;
        });
    }

    /**
     *  Cache JET bundles and files required by a VB application. This includes:
     * - require.js
     * - bundles-config.js (or bundles-config-debug.js)
     * - JET modules specified in appShellCache.json
     * - JET resources and theme based resources specified in appShellCache.json
     *
     * @param shellPath a location of appShellCache.json file, relative to SW scope. For example:
     * <i>./mobileApps/testApp/version_1552430187000/appShellCache.json</i>
     * @param jetPath JET cdn location, for example: <i>https://static.oracle.com/cdn/jet/v7.0.0/</i>
     * @param debug flag indicates whether minified JET scripts should be cached
     * @returns {Promise<any>} a promise to cache JET resources required by an application or an empty promise if
     *  application is offline or shellPath is not specified.
     */
    static cacheJet(shellPath, jetPath, debug = false) {
      if (!PwaUtils.isOnline() || !PwaUtils.isPwaShellPath(shellPath)) {
        return Promise.resolve();
      }
      logger.info('PWA: cacheJet()');
      return Promise.resolve()
      //
      // As specified in vbInitConfig, shellPath is relative to SW scope, for example:
      // https://vbmasterdev-vbcsqatest.uscom-central-1.c9dev1.oc9qadev.com/ic/builder/rt/xyz/1.0/
      // But when fetching from the UI thread, requests are relative to web app:
      // https://vbmasterdev-vbcsqatest.uscom-central-1.c9dev1.oc9qadev.com/ic/builder/rt/xyz/1.0/mobileApps/abc/
      //
      // So this file needs to be fetched relative to SW scope.
      //
        .then(() => PwaUtils.getServiceWorkerScope())
        .then((scope) => {
          // empty scope is fine
          if (scope || scope === '') {
            const path = `${scope}${shellPath}`;
            logger.info(`PWA: cacheJet() loading shell cache from ${path}`);
            return PwaUtils.loadAppShellFile(path, fetch);
          }
          logger.warn('PWA: cacheJet() no service worker');
          return undefined;
        })
        .then((appShell) => {
          if (appShell) {
            const promises = [JetCache.getThemeBasedResources(jetPath), JetCache.getOptResources(jetPath, debug)];
            return Promise.all(promises)
              .then(([themeResources, optResources]) => {
                // JET modules & their dependencies will be added to the cache as a result of calling require.
                // Any fetch requests resulting from require calls will be intercepted by SW and cached.
                // However, since these modules were potentially loaded already, and require won't issue a fetch request
                // once a module has been loaded, they might not result in cache updates
                //
                // Calling requirejs.undef does not solve the problem because dependencies don't get undefined.
                // For example, un defining ojs/ojvalidation-datetime
                // does not nuke https://static.oracle.com/cdn/jet/v8.1.0/default/js/min/ojvalidator-daterestriction.js
                // and https://static.oracle.com/cdn/jet/v8.1.0/default/js/min/ojconverter-datetime.js
                const jetCachePromises = [];
                jetCachePromises.push(Utils.getResources(appShell.jetModules));
                const jetResources = [];
                jetResources.push(...JET_MIN);
                jetResources.push(...optResources);
                jetResources.push(`${JET_BUNDLES_CONFIG}${debug ? '-debug' : ''}.js`);

                if (Array.isArray(themeResources)) {
                  jetResources.push(...themeResources);
                }
                jetCachePromises.push(JetCache.fetchJetResources(jetResources, jetPath, debug));
                return Promise.all(jetCachePromises);
              });
          }
          return undefined;
        });
    }
  }

  return JetCache;
});

