'use strict';

define('vb/private/vx/appUiInfos',['vb/private/log'], (Log) => {
  const logger = Log.getLogger('/vb/private/vx/AppUiInfos');

  /**
   * Convert a navigation container path (flowId/pageId/flowId/...) to a resource path
   * (flows/flowId/pages/pageId-page/flows/flowId/...)
   *
   * @param      {Object}  appUiInfo      The App UI info from the digest
   * @param      {String}  containerPath  The container path
   * @return     {String}  the resource path
   */
  const containerPathToResourcePath = (appUiInfo, containerPath) => {
    let type;
    let resourcePath = '';

    // If this App UI has a defaultPage instead of a defaultFlow, the first segment of the path is a page
    if (appUiInfo.defaultPage && !appUiInfo.defaultFlow) {
      type = 'flows';
    }

    containerPath.split('/').forEach((segment) => {
      if (segment) {
        if (type === 'flows') {
          type = 'pages';
          // eslint-disable-next-line no-param-reassign
          segment = `${segment}-page`;
        } else {
          type = 'flows';
        }

        resourcePath = `${resourcePath}/${type}/${segment}`;
      }
    });

    if (resourcePath[0] === '/') {
      resourcePath = resourcePath.substring(1);
    }

    return resourcePath;
  };

  /**
   * Search an array of paths for a path matching the resource path.
   * Because of the flow mapping in flow.json, the resourcePath could be made
   * of multiple section of path in the array.
   *
   * @param      {Array<String>} paths
   * @param      {String}   resourcePath
   * @return     {boolean}  true if the resourcePath is found
   */
  const findPath = (paths, resourcePath) => {
    const arrayLength = paths.length;
    let i = 0;
    let leftOver;
    while (i < arrayLength) {
      if (resourcePath.startsWith(paths[i])) {
        leftOver = resourcePath.substring(paths[i].length);
        if (leftOver === '') {
          return true;
        }

        // Remove starting /
        leftOver = leftOver.substring(1);
      }
      i += 1;
    }

    // Each leftOver correspond to flow mapping. If valid, it should show up
    // as an element of the navigable array.
    if (leftOver) {
      return findPath(paths, leftOver);
    }

    return false;
  };

  /**
   * A class to handle the info of each App UI coming from the digest
   */
  class AppUiInfos {
    constructor() {
      this.info = {};
      /**
       * An object to store the App UI extension content. The keys are App UI ids.
       * It is initialized when the application loads using getAppPackages()
       * @type {Object}
       */
      this.extension = {};
      /**
       * An object to map App UI URL id to App UI id
       * @type {Object}
       */
      this.urlIdToAppId = {};

      this.log = logger;
    }

    static getAppUiInfoType() {
      return {
        id: 'string',
        urlId: 'string',
        displayName: 'string',
        description: 'string',
        defaultPage: 'string',
        defaultFlow: 'string',
        applicationStripe: 'string',
        pillarTheme: 'string',
        pillarThemeMode: 'string',
        icon: 'string',
        usage: 'string',
        menuDisplayName: 'string',
        extensible: 'boolean',
      };
    }

    /**
     * Add an App UI
     * @param {String} id the App UI id
     * @param {Extension} extension the extension where this App UI is defined
     * @param {Object} appUiInfo the App UI info when it exist
     */
    add(id, extension, appUiInfo = { id }) {
      this.info[id] = appUiInfo;
      if (this.extension[id]) {
        // WARNING: when multiple extension defined the same App UI the last one wins
        this.log.warn('App UI', id, 'content has been redefined by extension', extension.id);
      } else {
        this.log.info('Found App UI', id, 'in extension', extension.id);
      }
      this.extension[id] = extension;

      if (!appUiInfo.urlId) {
        // eslint-disable-next-line no-param-reassign
        appUiInfo.urlId = id;
      }
      this.urlIdToAppId[appUiInfo.urlId] = id;
    }

    /**
     * Return true if there is an App UI
     * @return {Boolean}
     */
    hasAppUI() {
      return this.getAppUiIds().length > 0;
    }

    /**
     * Return true if an App UI exist given its id
     * @param  {String} id
     * @return {Boolean} true if exist
     */
    exist(id) {
      return !!this.info[id];
    }

    /**
     * Return an Array with all the ids of existing App UIs
     * @return {Array<String>}
     */
    getAppUiIds() {
      return Object.keys(this.info);
    }

    /**
     * Return the info of a App UI given its id
     * @param  {String} id the App UI id
     * @return {Object}    the info
     */
    getInfo(id) {
      return this.info[id];
    }

    /**
     * Given an App UI id, return the extension that defines it
     * @param  {String} id the App UI id
     * @return {Object}    the extension where this App UI is defined
     */
    getExtension(id) {
      return this.extension[id];
    }

    /**
     * Retrieve the App UI id from the App UI URL id
     * @param  {String} urlId
     * @return {String}
     */
    getAppUiIdFromUrlId(urlId) {
      return this.urlIdToAppId[urlId];
    }

    /**
     * Retrieve the App UI URL id from the App UI id
     * @param  {String} appId
     * @return {String}
     */
    getUrlIdFromAppId(appId) {
      return appId ? this.info[appId].urlId : appId;
    }

    /**
     * Determines whether the page path matching the navigation options is navigable.
     *
     * @param {Object}  the navigation options coming from the navigate action
     */
    // eslint-disable-next-line class-methods-use-this, no-unused-vars
    validateNavigation(options) {
      /* Remove App UI navigation validation due FA failures
      const appUiInfo = this.getInfo(options.application);
      let resourcePath;

      if (options.page) {
        let path = options.page;
        let flow;

        // Build the navigation by combining the page and the flow.
        // When the flow is not specified, the default flow is used.
        if (options.flow) {
          flow = options.flow;
          if (appUiInfo.defaultPage) {
            flow = `${appUiInfo.defaultPage}/${flow}`;
          }
        } else if (appUiInfo.defaultFlow) {
          flow = appUiInfo.defaultFlow;
        }

        if (flow) {
          path = `${flow}/${path}`;
        }

        // Convert the container path to a resource path
        resourcePath = containerPathToResourcePath(appUiInfo, path);
      } else if (options.flow) {
        if (appUiInfo.defaultFlow) {
          // The default flow is always navigable
          if (options.flow === appUiInfo.defaultFlow) {
            return;
          }
          resourcePath = `flows/${options.flow}/${options.flow}-flow`;
        } else if (appUiInfo.defaultPage) {
          resourcePath = `pages/${appUiInfo.defaultPage}-page/flows/${options.flow}/${options.flow}-flow`;
        }
      } else {
        // Top level App UI are always navigable
        return;
      }

      // The information about which page is navigable is stored in the digest using
      // an array of resource path. Note that when flows are referenced using path mapping,
      // a resource path can be made of multiple section of navigable paths.
      const navigableArray = appUiInfo.navigable;
      if (Array.isArray(navigableArray) && findPath(navigableArray, resourcePath)) {
        return;
      }

      // the page is not navigable, throw an error to cancel the navigation
      throw new Error(`Navigation to page "${options.page}", flow "${options.flow}" of `
        + `App UI "${options.application}" is not enabled.`);
    */
    }
  }

  return AppUiInfos;
});

