'use strict';

define('vb/private/stateManagement/packageFlow',[
  'vb/helpers/mixin',
  'vb/private/stateManagement/flow',
  'vb/private/stateManagement/packageContainerMixin',
  'vb/private/stateManagement/packageAndExtensionContainerMixin',
  'vb/private/stateManagement/packagePage',
  'vb/private/constants',
], (Mixin, Flow, PackageContainerMixin, PackageAndExtensionContainerMixin, PackagePage, Constants) => {
  /**
   * PackageFlow class
   * A class for flow object defined in an App UI
   */
  class PackageFlow extends Mixin(Flow).with(PackageContainerMixin, PackageAndExtensionContainerMixin) {
    constructor(id, parent, path, className = 'PackageFlow') {
      super(id, parent, path, className);

      if (parent.package) {
        // Override value defined in Container
        this._extension = parent.package.extension;
      }
    }

    /**
     * @type {PackagePage}
     */
    static get PageClass() {
      return PackagePage;
    }

    /**
     * @type {PackageFlow}
     */
    static get FlowClass() {
      return PackageFlow;
    }

    /**
     * Override value defined in Container
     * @type {String}
     */
    get resourceLoc() {
      return `${Constants.DefaultPaths.UI}${Constants.ExtensionFolders.SELF}/${this.path}`;
    }

    /**
     * The default event prefix is the lowercase class name (see container.js) but for
     * packageFlow we want to use the same event prefix as flow
     *
     * @type {String}
     */
    // eslint-disable-next-line class-methods-use-this
    get eventPrefix() {
      return 'flow';
    }

    /**
     * The application owning this flow, for regular flow it's the application, for packageFlow
     * it's the appPackage
     * @type {Flow}
     */
    get owningApp() {
      return this.package;
    }

    /**
     * Check that a file exist in the this App UI
     * Use the files list provided by he extension
     * Throws a HttpError if the file does not exist
     *
     * @param {String} fileExt the file extension of the resource to check
     */
    checkResourceExist(fileExt) {
      this.extension.fileExists(`${this.resourceLoc}${this.fullName}.${fileExt}`);
    }

    descriptorLoader(resourceLocator) {
      return Promise.resolve().then(() => {
        // App UIs are using extension with a manifest containering a list of files,
        // so we can check if the file exist before doing a fetch that may fail.
        this.checkResourceExist('json');
        return super.descriptorLoader(resourceLocator);
      });
    }

    functionsLoader(resourceLocator) {
      return Promise.resolve().then(() => {
        this.checkResourceExist('js');
        return super.functionsLoader(resourceLocator);
      });
    }
  }

  return PackageFlow;
});

