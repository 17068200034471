'use strict';

define('vbc/private/performance/immediateCategory',['vbc/private/performance/performanceCategory'], (PerformanceCategory) => {
  const EMPTY_ENTRIES = (format) => format([]);
  /**
   * A PerformanceCategory implementation that is capable of delivering entries immediately, without waiting
   * on a promise
   */
  class ImmediateCategory extends PerformanceCategory {
    /**
     * @param {string} name category name
     * @param {function} entriesNowCallback a callback to get a list of performance category entries,
     * optionally formatted.
     */
    constructor(name, entriesNowCallback) {
      super(name, (format) => Promise.resolve(this.getEntriesNow(format)));
      if (typeof entriesNowCallback === 'function') {
        this.entriesNowCallback = entriesNowCallback;
      } else {
        this.entriesNowCallback = EMPTY_ENTRIES;
      }
    }

    /**
     * @param {function} formatCallback formats each category entry individually
     * @returns {Array} a list of (optionally formatted) entries for this category or an empty array,
     * if no entriesNowCallback has been specified
     */
    getEntriesNow(formatCallback = ((e) => e)) {
      return this.entriesNowCallback(formatCallback);
    }
  }
  return ImmediateCategory;
});

