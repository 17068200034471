'use strict';

define('vb/private/stateManagement/fragmentHolderExtensionMixin',[
  'vb/helpers/mixin',
  'vb/private/stateManagement/fragmentHolderBaseMixin',
  'vb/private/constants',
  'vb/private/utils',
], (Mixin, FragmentHolderBaseMixin, Constants, Utils) => {
  /**
   * FragmentHolderExtensionMixin class is a base mixin for all container extensions that reference fragments.
   * This would be page and layout extensions.
   */
  const FragmentHolderExtensionMixin = (superclass) => class extends Mixin(superclass).with(FragmentHolderBaseMixin) {
    /**
     * A extension page (or layout) can only reference a fragment defined in base extension. It's not possible for an
     * extension page/layout to include 'local' fragments (local to the current extension). At the moment only new
     * pages / layout (under self) in an extension can define and use local fragments.
     * Example for fragments defined under <ext-A>/.../ui/self/fragments,
     *   (1) extension page template defined under <ext-B>/.../ui/ext-A/.../page-templates.html can reference fragment
     *   from ext-A or some upstream extension
     *   (2) new page in ext-B can reference ext-A fragments or local fragments. This class is not involved for a new
     *   page
     *
     * Similarly,
     *   (3) extension layout template defined under <ext-B>/dynamicLayouts/ext-A/.../layout-x.html can
     *   reference fragment from ext-A or some other upstream extension
     *   (4) new layout in ext-B, defined under <ext-B>/dynamicLayouts/self/.../layout.html can also reference
     *   ext-A:fragment, but this class is not involved for a new layout case
     *
     * @return {Promise} that resolves with the (async) loaded class module. PackageFragmentReferenceable
     */
    getFragmentClass() {
      // to avoid cyclic dependency
      const modulePath = 'vb/private/stateManagement/packageFragmentReferenceable';
      if (!this.loadFragmentModulePromise) {
        this.loadFragmentModulePromise = Utils.getResource(modulePath);
      }

      return this.loadFragmentModulePromise;
    }

    getParentOrBaseOfExtension() {
      return this.base;
    }
  };
  // mixinBehaviors(FragmentHolderExtensionMixin, FragmentHolderBaseMixin);
  return FragmentHolderExtensionMixin;
});

