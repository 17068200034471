/* eslint-disable class-methods-use-this,max-classes-per-file */

'use strict';

define('vb/private/events/notifyEventBehavior',[
  'vb/private/constants',
  'vb/private/log',
  'vb/action/action',
  'vb/private/action/assignmentHelper',
  'vb/private/events/eventBehavior',
], (Constants, Log, Action, AssignmentHelper, EventBehavior) => {
  /**
   * NotifyEventBehavior
   * call event listeners, don't wait for any responses.
   */
  class NotifyEventBehavior extends EventBehavior {
    /**
     * override for 'notify' so the fireCustomEventAction doesn't wait for the listeners to finish/resolve.
     * @param functionWrappers
     * @returns {Promise}
     * @private
     * @override
     */
    executeInternal(functionWrappers) {
      super.executeInternal(functionWrappers);
      return Promise.resolve(null);
    }
  }

  return NotifyEventBehavior;
});

