/* eslint-disable max-classes-per-file */

'use strict';

define('vbc/private/performance/webVitalsCategory',[
  'vbc/private/utils',
  'vbc/private/performance/performanceCategory',
  'vbc/private/trace/tracer',
  'vbc/private/trace/spanContext',
], (Utils, PerformanceCategory, Tracer, SpanContext) => {
  const WEB_VITALS_CATEGORY_NAME = 'web vitals (ms)';

  class WebVital {
    constructor(reportHandler) {
      reportHandler(this.onReport.bind(this));
    }

    /**
     * @returns {Object} a web vitals metric, or example:
     *  {name: "TTFB",
     *   value: 947.4049999989802,
     *   delta: 947.4049999989802,
     *   entries: Array(1),
     *   id: "v1-1607453629257-9515794308663"}
     * or undefined if no metric has been reported yet
     * @see https://github.com/GoogleChrome/web-vitals#metric
     */
    getMetric() {
      return this.metric;
    }

    onReport(metric) {
      this.metric = metric;
      // https://jira.oraclecorp.com/jira/browse/DTA-4027 addresses better message format
      const spanContext = new SpanContext().addOperationName(`metric/${metric.name}`);
      const metricMsg = { msg: metric };
      spanContext.addStartSpan(() => metricMsg);

      spanContext.addEndSpan(() => metricMsg);
      const span = Tracer.startSpan(spanContext);
      if (span) {
        span.finish();
      }
    }
  }

  /**
   * A performance category representing Web Vitals.
   * @see https://web.dev/vitals/
   * @see https://github.com/GoogleChrome/web-vitals
   *
   * This category works on UI thread only, and returns empty entries on a worker thread
   */
  class WebVitalsCategory extends PerformanceCategory {
    /**
     * @param {Object} webVitals a WebVitals implementation, if available
     * @see https://github.com/GoogleChrome/web-vitals
     */
    constructor(webVitals) {
      super(WEB_VITALS_CATEGORY_NAME, (format) => Promise.resolve(this.getEntriesNow(format)));
      this.vitals = [];

      this.addWebVitalsPromise = Promise.resolve()
        .then(() => {
          if (Utils.isWorkerThread()) {
            return null;
          }
          // a dynamic loading of web-vitals is required to avoid loading web-vitals on the sw thread
          const getWebVitalsPromise = webVitals ? Promise.resolve(webVitals) : Utils.getResource('web-vitals');

          return getWebVitalsPromise
            .then((wv) => {
              this.vitals.push(new WebVital(wv.getCLS));
              this.vitals.push(new WebVital(wv.getFCP));
              this.vitals.push(new WebVital(wv.getFID));
              this.vitals.push(new WebVital(wv.getLCP));
              this.vitals.push(new WebVital(wv.getTTFB));
            });
        });
    }

    /**
     * @param {function} formatCallback formats each category entry individually
     * @returns {Promise} a promise to list of (optionally formatted) web vital entries or a promise to an empty array,
     * if no entriesCallback has been specified or this is executed on a service worker thread
     */
    getEntries(formatCallback = ((e) => e)) {
      return this.addWebVitalsPromise
        .then(() => {
          if (Utils.isWorkerThread()) {
            return PerformanceCategory.EMPTY_ENTRIES_PROMISE(formatCallback);
          }
          const formattedMetrics = [];
          this.vitals.forEach((v) => {
            const m = v.getMetric();
            if (m) {
              // For CLS the value is first multiplied by 1000 for greater precision
              // See https://developers.google.com/codelabs/chrome-web-vitals-js#3
              const value = Math.round(m.name === 'CLS' ? m.delta * 1000 : m.delta);
              const metric = { [m.name]: Math.round(value) };
              formattedMetrics.push(metric);
            }
          });
          return formattedMetrics;
        });
    }
  }
  WebVitalsCategory.WEB_VITALS_CATEGORY_NAME = WEB_VITALS_CATEGORY_NAME;
  return WebVitalsCategory;
});

