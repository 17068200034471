'use strict';

define('vb/action/builtin/takePhotoAction',[
  'knockout',
  'vb/private/utils',
  'vb/action/action',
  'ojs/ojmenu',
  'ojs/ojoption',
],
(ko, Utils, Action) => {
  const MEDIA_TYPES = ['image', 'video', 'audio'];

  /**
   * Invokes the HtmlMediaCapture API to take pictures and/or choose images from the system's
   * image library.
   *
   * The parameters to this action are as follows:
   *  mediaType - Controls the type of media. Valid values are &lt;image&gt; and &quot;video&quot;
   *
   * Outcome from this action is file - a Blob that contains the &quot;image&quot;.
   */
  class TakePhotoAction extends Action {
    /**
     * returned Promise is resolved with Outcome (success or failure), or rejected with a message.
     *
     * @param parameters
     * @returns {Promise}
     */
    // eslint-disable-next-line class-methods-use-this
    perform(parameters) {
      // Use Html media Capture
      // FYI: https://www.w3.org/TR/html-media-capture/
      return TakePhotoAction.performHtmlMediaCapture(parameters);
    }

    /**
     * Internal method to do image capture using HtmlMediaCapture. This creates an html input file element and triggers
     * the action by click() method. Once the File comes back from the open dialog, it is wrapped into a Blob and
     * resolved as &quot;file&quot; outcome.
     *
     * @private
     * @param accept - calculated values based on mediaType parameter. This is set as accept attribute of file element.
     * @returns {Promise}
     */
    static webPerform(accept) {
      return new Promise((resolve) => {
        // just invoke input element
        const fileElement = document.createElement('input');
        fileElement.type = 'file';
        fileElement.accept = accept;

        // For the file input element, onchange is not triggered if user cancels the file dialog. But the value is set
        // to null. The following onfocus() is a workaround to identify the cancel on file dialog.
        // In document.onfocus check if the file element value is null, if so the dialog was cancelled.
        // But there is some delay on setting the file element value by browsers. This 1000ms delay solves it.
        const bfocus = document.body.onfocus;
        document.body.onfocus = () => {
          setTimeout(() => {
            document.body.onfocus = bfocus; // restore document.body focus handler
            if (!fileElement.value) { // cancel clicked
              resolve(Action.createFailureOutcome('Take Photo Action cancelled'));
            }
          }, 1000);
        };

        fileElement.onchange = () => {
          document.body.onfocus = bfocus; // restore document.body focus handler
          // ServiceWorker thread cannot access File of Main thread. It needs to be converted into a Blob.
          const fileBlob = Utils.fileToBlob(fileElement.files[0]);
          resolve(Action.createSuccessOutcome({ file: fileBlob }));
        };

        fileElement.click();
      });
    }

    /**
     *
     * @private
     * @param params
     * @returns {Promise}
     */
    static performHtmlMediaCapture(params) {
      let mediaType = params.mediaType;
      if (!mediaType) {
        // WebApps or PWA did not have TakePhoto Action.
        // Either a MobileApp is getting executed in PWA mode or WebApp did not set mediaType.
        mediaType = 'image'; // Use default mediaType
      }
      mediaType = mediaType.toLowerCase();

      const accept = `${MEDIA_TYPES.includes(mediaType) ? mediaType : 'image'}/*`;

      return TakePhotoAction.webPerform(accept);
    }
  }

  return TakePhotoAction;
});

