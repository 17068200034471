/* eslint-disable max-classes-per-file */

'use strict';

define('vb/private/action/baseChainRunner',['ojs/ojcontext', 'vb/private/stateManagement/stateUtils', 'vb/private/constants',
  'vb/private/debug/actionChainDebugStream', 'vb/private/action/actionChainUtils',
  'vb/private/stateManagement/scopeResolver'],
(ojContext, StateUtils, Constants, ActionChainDebugStream, ActionChainUtils, ScopeResolver) => {
  const logger = ActionChainUtils.getLogger();

  // bufp-17642: list of dollar-variables to skip when injecting the context for the current page into the
  // context for the chain. These should only be available to the event listener, and passed to chains if needed.
  // note: this '$current' is the one from the JET event listener, and NOT the one from forEachAction.
  const EXCLUDED_CHAIN_SCOPE_VARS = [
    Constants.ContextName.EVENT,
    Constants.ContextName.BINDING_CONTEXT,
    '$current', // not using constant, so it is not mistaken for the 'forEachAction' one
    Constants.ContextName.PREVIOUS,
  ];

  /**
   * Base class for an action chain runner.
   */
  class BaseChainRunner {
    constructor(chainId) {
      this.chainId = chainId;
    }

    /**
     * Starts an action chain and returns the promise that will resolve to the outcome of the last
     * executed action of the chain.
     *
     * @param params Set of parameters to match the variables of the action chain
     * @param scopes Scopes in context for the chain
     * @param context Internal objects like actions, application, and flow needed by a builtin action
     * @param {Object} options
     * @param options.rethrowError if true, rethrow error instead of firing a notification event
     * @param options.coerceResultToOutcome if true, wrap the result of a JS chain in a success outcome
     * @returns {Promise.<*>}
     */
    // @ts-ignore
    run(params = {}, context, scopes, { rethrowError, coerceResultToOutcome } = {}) {
      const executionContext = this.createExecutionContext(context, scopes);

      return Promise.resolve()
        .then(() => {
          if (!this.chainId) {
            throw new Error('Required \'id\' parameter missing for starting action chain.');
          }

          // use targetScopeResolver which should be available if the chain is called by callChainAction,
          // otherwise, get the scope resolver from the calling container
          const scopeResolver = context.targetScopeResolver || context.container.scopeResolver;

          // handle application, flow or page level action chains
          // Resolve the scope (application, flow, page) from the actionId if one exist
          // and get the action metadata.
          return this.resolveChain(scopeResolver)
            .then((chainSource) => {
              if (!chainSource) {
                throw new Error(`Action chain ${this.chainId} does not exist.`);
              }

              const actionChain = this.createChain(chainSource);
              const inputParamValues = StateUtils.deepEval(params, scopes);

              // add a busy state to JET's busy context which is used by webdriverjs tests to wait for
              // activities in the page to quiet down
              const busyContext = ojContext.getPageContext()
                .getBusyContext();
              const busyStateResolver = busyContext.addBusyState({ description: `chainId: ${this.chainId}` });

              const debugStream = ActionChainUtils.getDebugStream(executionContext);

              debugStream.start(inputParamValues);
              return actionChain.run(executionContext, inputParamValues)
                .then((result) => {
                  // if coerceResultToOutcome is true, wrap result in a success outcome
                  const returnValue = coerceResultToOutcome ? this.coerceResultToOutcome(result) : result;

                  debugStream.end(returnValue);
                  return returnValue;
                })
                .finally(() => {
                  // run any registered 'finally' cleanup tasks
                  BaseChainRunner.runFinallyCallbacks(executionContext);
                  busyStateResolver();
                });
            });
        })
        .catch((error) => {
          logger.error('Chain', this.chainId, 'failed.', error);
          return this.handleError(error, executionContext, rethrowError);
        });
    }

    /**
     * Resolve and load the action chain identified by the given chainId.
     *
     * @param scopeResolver used to resolve the scope of the action chain, e.g., flow:myChain
     * @returns {Promise}
     */
    resolveChain(scopeResolver = new ScopeResolver()) {
      return Promise.resolve().then(() => {
        const result = BaseChainRunner.parseChain(this.chainId);
        const scope = scopeResolver[result[0]];

        if (scope) {
          return scope.loadChain(result[1], this);
        }

        return null;
      });
    }

    /**
     * Load the source file for the chain located at the given chainPath.
     *
     * @param chainPath location of the source file for the chain
     * @returns {Promise}
     */
    // eslint-disable-next-line no-unused-vars,class-methods-use-this
    loadChainSource(chainPath) {
      throw new TypeError('Subclass needs to override loadChainSource method.');
    }

    /**
     * Parse a string chain id with the format "scope:id" and return an array where
     * 1st element is the scope, 2nd is the id.
     * If the expression only contains an id, return the value 'this' for the first
     * element.
     *
     * @param  {String} chainId
     * @return {Array} array 1st element is the scope, 2nd is the id
     */
    static parseChain(chainId) {
      const parts = chainId.split(':');

      // If the scope is not part of the expression, insert 'this' at the
      // beginning of the array
      if (parts.length === 1) {
        parts.unshift(Constants.THIS_PREFIX);
      }

      return parts;
    }

    /**
     * Use the given chainId and scopeResolver to look up the container in which the action chain is defined.
     *
     * @param chainId chain id, e.g., fooChain, page:fooChain, flow:fooChain or application:fooChain
     * @param scopeResolver used to resolve the container
     * @returns {*}
     */
    static resolveContainer(chainId, scopeResolver = new ScopeResolver()) {
      const result = this.parseChain(chainId);

      return scopeResolver[result[0]];
    }

    /**
     * Create an instance of action chain using chainSource.
     *
     * @param chainSource a code-base action chain class or a JSON descriptor
     * @returns {*}
     */
    // eslint-disable-next-line no-unused-vars,class-methods-use-this
    createChain(chainSource) {
      throw new TypeError('Subclass needs to override createChain method.');
    }

    /**
     * Creates the execution context used to invoke an action chain.
     *
     * @param context contains information about the calling containers
     * @param callingContexts the available scopes from the calling container
     * @returns {Object}
     */
    createExecutionContext(context = {}, callingContexts = {}) {
      const executionContext = {};
      const callingContainer = context.container;
      const callingScopeResolver = callingContainer && callingContainer.scopeResolver;
      let targetContainer;
      let targetScopeResolver;
      let scopes = callingContexts;

      // make sure we get the correct scopes in which the chain will be executed
      if (this.chainId && callingScopeResolver) { // make sure we don't break tests
        // use the scopeResolver to look up the container in which the chain is defined
        targetContainer = BaseChainRunner.resolveContainer(this.chainId, callingScopeResolver);

        // will be used to resolve chains called from the target container via callChainAction
        targetScopeResolver = targetContainer.scopeResolver;

        // get the available scopes from the container if it's different from the calling container
        // NOTE: Since this breaks existing FA apps, the decision is made to only enable this
        // new behavior for app uis to allow more time for app developers to fix their apps.
        const isAppUi = targetContainer.package || (targetContainer.base && targetContainer.base.package);
        if (!isAppUi) {
          targetContainer = callingContainer;
        }

        // If target container is different from the calling container, we need to use the scopes
        // from the target container.
        if (targetContainer !== callingContainer) {
          scopes = targetContainer.getAvailableContexts();
        }
      }

      const debugStream = new ActionChainDebugStream(BaseChainRunner.extractChainId(this.chainId), executionContext);

      const internalContext = Object.assign({},
        context,
        {
          chainId: this.chainId,
          callingContexts,
          targetScopeResolver,
          targetContainer,
          internalContext: {},
          finallyCallbacks: [],
          debugStream,
        });
      executionContext[Constants.CHAIN_INTERNAL_CONTEXT] = internalContext;

      // cannot use Object.assign, need to copy getters; some values might not have been created yet (page.pageScope)
      Object.getOwnPropertyNames(scopes).forEach((key) => {
        // skip ones that are specific to the eventListener declaration, and should not be available in the chain
        const descriptor = Object.getOwnPropertyDescriptor(scopes, key);

        if (EXCLUDED_CHAIN_SCOPE_VARS.indexOf(key) === -1) {
          // guard against scope contexts that attempt to override our chain variables
          if (!Object.prototype.hasOwnProperty.call(executionContext, key)) {
            Object.defineProperty(executionContext, key, descriptor);
          }
        } else {
          //  don't expose, but make it available to actions that need it
          Object.defineProperty(internalContext.internalContext, key, descriptor);
        }
      });

      return executionContext;
    }

    /**
     * Coerce the result of an action chain into a success outcome.
     *
     * @param result the result to coerce into a success outcome
     * @returns {{result: *, name: string}}
     */
    // eslint-disable-next-line class-methods-use-this,no-unused-vars
    coerceResultToOutcome(result) {
      throw new TypeError('Subclass needs to override coerceResultToOutcome method.');
    }

    /**
     * Implement error handling behavior for the chain.
     *
     * @param error the error to be reported
     * @param context the context object for the chain
     * @param rethrowError if true, rethrow the error
     * @returns {Promise}
     */
    // eslint-disable-next-line class-methods-use-this,no-unused-vars
    handleError(error, context, rethrowError) {
      throw new TypeError('Subclass needs to override handleError method.');
    }

    /**
     * Determine if we need to explicitly trigger a ResourceChangedEvent. The default behavior is no.
     */
    // eslint-disable-next-line class-methods-use-this
    shouldTriggerResourceChangedEvent() {
      return false;
    }

    /**
     * Extract the chain id from the given id which can be prefixed, e.g., application:fooAction or flow:fooAction.
     *
     * @param id the id, e.g., fooChain, application:fooChain or flow:fooChain
     * @returns {*|string}
     */
    static extractChainId(id) {
      const idParts = id.split(':');
      return idParts.length === 1 ? idParts[0] : idParts[1];
    }

    /**
     * Run any registered callbacks
     *
     * If a callback returns a Promise, it must resolve/reject before the next callback is called.
     * A rejected Promise does not stop callback processing.
     */
    static runFinallyCallbacks(executionContext) {
      const internalContext = ActionChainUtils.getInternalContext(executionContext);
      const { finallyCallbacks } = internalContext;

      let promise = Promise.resolve(); // initial promise
      finallyCallbacks.forEach((callback) => {
        promise = promise.then(() => {
          if (typeof callback.fnc === 'function') {
            try {
              logger.info('calling', callback.name, 'callback for action', callback.id);
              // allow callback fnc to optionally return a Promise
              return Promise.resolve(callback.fnc());
            } catch (e) {
              logger.error('error in Action callback for', callback.id, ':', callback.name, e);
            }
          }
          return null; // not used;
        });
      });
    }
  }

  return BaseChainRunner;
});

