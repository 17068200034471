'use strict';

define('vb/private/services/definition/extensionServiceMapFactory',[
  'vb/private/services/definition/serviceMapFactory',
], (
  ServiceMapFactory,
) => {
  /**
   * Class responsible finding and loading ServiceDefinitions from extension's service map
   */
  class ExtensionServiceMapFactory extends ServiceMapFactory {
    constructor(services, options) {
      super(services, options);

      const serviceFileMap = this._serviceFileMap;

      // Updates service declarations from the extension's service map
      const extensionServiceMap = options.extensionServiceMap;
      if (extensionServiceMap) {
        Object.keys(extensionServiceMap).forEach((name) => {
          const serviceId = name;
          if (!serviceFileMap[serviceId]) {
            serviceFileMap[serviceId] = {
              path: extensionServiceMap[name],
              type: ServiceMapFactory.TYPE,
            };
          }
        });
      }
    }
  }

  return ExtensionServiceMapFactory;
});

