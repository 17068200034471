'use strict';

define('vb/private/vx/v1/extension',[
  'vb/private/vx/baseExtension',
  'vb/private/constants',
], (BaseExtension, Constants) => {
  class Extension extends BaseExtension {
    constructor(def, registry) {
      // In v1 baseUrl is defined under ui
      super(def, def.ui && def.ui.baseUrl, registry);
    }

    getAbsoluteUrl() {
      // In v1 'ui' is not part of the base path
      return `${this.baseUrlDef}/${Constants.ExtensionFolders.SELF}/`;
    }
  }

  return Extension;
});

