'use strict';

define('vbsw/private/plugins/abstractAuthHandlerPlugin',['vbsw/api/fetchHandlerPlugin', 'vbc/private/constants'], (FetchHandlerPlugin, CommonConstants) => {
  const VB_PROXY = 'proxyUrl';
  const VB_PROXY_URLS = 'proxyUrls';
  const VB_TOKEN_RELAY_URLS = 'tokenRelayUrls';

  const VB_HTTPS_PREFIX = /^https/;

  /**
   * Abstract Handler plugin interpreting the authentication configuration so it can be shared between the
   * DT and the RT, a sublcass is required for all cases.
   */
  class AbstractAuthHandlerPlugin extends FetchHandlerPlugin {
    /**
     * @param request the request which is being modified
     * @return The string key to look up the correct proxy or tokenRelay URL, which might be either
     *  static of dynamic depending on whether this is invoke from the RT or DT.
     */
    // eslint-disable-next-line no-unused-vars,class-methods-use-this
    deriveUrlKey(request) {
      throw new Error('Should be implemented by specific subclass');
    }

    /**
     * Interpret the authentication configuration
     *
     * @param request the request to which to modify
     * @returns {Promise}
     */
    handleRequestHook(request) {
      return Promise.resolve().then(() => {
        // get the url for the token relay service from the request header,
        // bail if nothing found
        const infoExtension = JSON.parse(request.headers.get(CommonConstants.Headers.VB_INFO_EXTENSION));
        if (!infoExtension) {
          return request;
        }

        const protocol = request.headers.get(CommonConstants.Headers.PROTOCOL_OVERRIDE_HEADER);

        // Process token relay if required
        //
        const tokenRelayUrls = infoExtension[VB_TOKEN_RELAY_URLS];
        let tokenRelayUrl;
        if (tokenRelayUrls) {
          tokenRelayUrl = tokenRelayUrls[this.deriveUrlKey(request)];
        }

        // Process proxy if avaliable
        //
        const proxyUrls = infoExtension[VB_PROXY_URLS];
        let proxyUrl = proxyUrls && proxyUrls[this.deriveUrlKey(request)];
        if (!proxyUrl) {
          // Legacy fallback
          proxyUrl = infoExtension[VB_PROXY];
        }


        const inheritAuthentication = infoExtension[CommonConstants.Headers.INHERIT];
        const authentication = infoExtension[CommonConstants.Headers.VB_DT_AUTHENTICATION];

        // implicit flow is disabled if inherit is set to false in dt-serviceAuthentication
        // the by DT extension in getServiceExtensionOverride
        const inheritDisabled = authentication && authentication.inherit === false;


        if (inheritAuthentication && !inheritDisabled) {
          // To allow BUFP-23476 only do implicit flow if not overridden by service connection
          // authentication and not authentication header has already been set.
          request.headers.set('vb-inherit-authentication', 'not-handled');

          // If a fallback is provided write that down
          if (proxyUrl) {
            request.headers.set('vb-inherit-authentication-fallback', proxyUrl);
          }
        } else if (tokenRelayUrl) {
          request.headers.set('vb-token-relay-url', tokenRelayUrl);
          // Provide override for manual configuration of authentication
          if (authentication) {
            request.headers.set('vb-token-relay-authentication', JSON.stringify(authentication));
          }
        } else if (proxyUrl) {
          const proxyPrefix = proxyUrl.endsWith('/') ?
            proxyUrl.substring(0, proxyUrl.length - 1) :
            proxyUrl;

          let suffix = request.url.replace(':/', '');
          if (protocol) {
            suffix = suffix.replace(VB_HTTPS_PREFIX, protocol);
          }

          console.log('Before fetch handler plugin');

          return FetchHandlerPlugin.getRequestConfig(request, this.fetchHandler).then((config) => {
            const newRequest = this.fetchHandler.createRequest(`${proxyPrefix}/${suffix}`, config);

            // If this is a DT request we might be overriding the headers

            if (authentication && authentication.type !== 'direct') {
              newRequest.headers.set('Breeze-Design-Whitelist', 'true');

              Object.entries(authentication).reduce(
                (headers, [key, value]) => {
                  headers.set(`Breeze-${key}`, value);
                  return headers;
                }, newRequest.headers);
            }

            return newRequest;
          });
        } else {
          // GNDN Direct
        }

        return request;
      });
    }
  }


  return AbstractAuthHandlerPlugin;
});

