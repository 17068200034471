'use strict';

define('vb/helpers/actionHelpers',[
  'vb/private/stateManagement/router',
  'vb/private/constants',
], (Router, Constants) => {
  /**
   * A set of helper routines that require a context and are used by built-in actions.
   * The context is hidden using a closure.
   *
   */
  class ActionHelpers {
    constructor(context) {
      if (!context) {
        return;
      }

      Object.assign(this, {
        /**
         * Navigate to a page with input parameters.
         * This action is deprecated in favor of navigate action below
         * See https://confluence.oraclecorp.com/confluence/display/ABCS/VB+navigate+action
         *
         * @param {Object} options
         * @param {String} options.page The destination page path (required)
         * @param {Object<String, String>} options.params A map of URL parameters (optional)
         * @param {String} options.history Effect on the browser history. Allowed value
         * are 'replace', 'skip' or 'push'. If the value is 'replace', the current browser
         * history entry is replaced, meaning that back button will not go back to it.
         * If the value is 'skip', the URL is left untouched. (optional and default is push)
         * @return {Promise} a Promise that resolves to an object with a property named
         * 'navigated' which value is true or false depending on the outcome of
         * the navigation.
         */
        navigateToPage(options) {
          return this.navigate(Object.assign(options, { operation: Constants.NavigateOperation.PAGE_OLD }));
        },

        /**
         * Navigate action
         *
         * @param {Object} options
         * @param {String} options.page - the path to the destination page. The path is absolute starting
         * at the application or relative to the current page. When use in combination with flow or App UI,
         * the path cannot be absolute and it navigates to the page relative to the flow or App UI.
         * @param {String} options.flow - the id of the destination flow. Change the content of the flow displayed
         * in the current page. When used in combination with a page property, navigates to the page in that flow.
         * @param {String} options.application - the id of the destination App UI. Change which App UI
         * is displayed in the host application. When used in combination with a page and flow property, navigates
         * to the page in that App UI.
         * @param {Object<String, String>} options.params - A map of URL parameters. Same as in the navigateToPage
         * action (optional)
         * @param {String} options.history - Effect on the browser history. Allowed value are 'replace', 'skip' or
         * 'push'. If the value is 'replace', the current browser history entry is replaced, meaning that back
         * button will not go back to it. If the value is 'skip', the URL is left untouched.
         * Same as in the navigateToPage action(optional and default is push)
         * @return {Promise} a Promise that resolves to an object with a property named 'navigated' which value is
         * true if a navigation occurred. If navigation is not allowed it returns a failure outcome
         */
        navigate(options) {
          return Router.queueNavigate(context.container, options);
        },

        /**
         * Navigate back to the previous page in browser history with input parameters.
         *
         * @param {Object} options - The set of options for the navigateBack action
         * @param {Array<string, string>} options.params - The input parameters for the page
         * the action is going back to
         */
        navigateBack(options) {
          // Pass the current page instance
          Router.navigateBack(context.container, options);
        },
      });
    }
  }

  return ActionHelpers;
});

