'use strict';

define('vb/private/stateManagement/context/baseContext',[
  'vb/private/constants'],
(Constants) => {
  /**
   * Set of properties to expose in $base
   */
  class BaseContext {
    /**
     * @param container
     */
    constructor(container) {
      const propDescriptors = {
        /**
         * $base.application
         */
        application: {
          get() {
            // make sure we get the baseContext from the correct container
            const app = container.package || container.application;
            return app.expressionContext.baseContext;
          },
          enumerable: true,
          configurable: true,
        },

        /**
         * $base.global
         */
        global: {
          get() {
            return container.application.expressionContext.baseContext;
          },
          enumerable: true,
          configurable: true,
        },

        /**
         * $base.variables
         */
        [Constants.VariableNamespace.VARIABLES]: {
          get() {
            return container.export[Constants.VariableNamespace.VARIABLES];
          },
          enumerable: true,
          configurable: true,
        },

        /**
         * $base.constants
         */
        [Constants.VariableNamespace.CONSTANTS]: {
          get() {
            return container.export[Constants.VariableNamespace.CONSTANTS];
          },
          enumerable: true,
          configurable: true,
        },

        /**
         * $base.enums
         */
        enums: {
          get() {
            return container.export.enums;
          },
          enumerable: true,
          configurable: true,
        },
      };

      Object.defineProperties(this, propDescriptors);

      // The getVariable function is needed for the assignVariable action to work
      this.getVariable = (name, namespace) => {
        let variable;

        // only expose variables that are defined in the interface section.
        if (this[namespace]) {
          const properties = Object.keys(this[namespace]);
          if (properties.indexOf(name) >= 0) {
            variable = container.scope.getVariable(name, namespace);
          }
        }
        return variable;
      };
    }

    dispose() {
      // Delete every property because components sometime holding onto those properties preventing
      // the parent object from getting garbage collected.
      // eslint-disable-next-line guard-for-in, no-restricted-syntax
      for (const prop in this) { delete this[prop]; }
    }
  }

  return BaseContext;
});

