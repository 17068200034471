'use strict';

define('vb/components/list-item/loader',[
  'ojs/ojcomposite',
  'text!./component.json',
  'text!./listItem.html',
  'css!./listItem.css',
], (Composite, metadata, view) => {
  Composite.register('oj-vb-list-item', {
    view,
    metadata: JSON.parse(metadata),
  });
});

