'use strict';

/* global BarcodeDetector */

define('vb/action/builtin/barcodeAction',['vb/action/action', 'vb/private/log'], (Action, Log) => {
  const logger = Log.getLogger('/vb/action/builtin/barcodeAction');
  const NOT_SUPPORTED_MSG = 'Shape Detection API is not supported in the browser';
  // detector should be reused because it can allocate and hold significant resources.
  let defaultDetector;

  /**
   * BarcodeAction enables web applications to decode information from QR codes and barcodes, such as urls,
   * wi-fi connections and contact information.
   *
   * BarcodeAction has two parameters:
   * <li><i>image</i> an image object (either a CanvasImageSource, Blob, ImageData or an <img> element) to decode
   * <li><i>formats</i> an (optional) a series of barcode formats to search for. For example, one, or more of
   * the following:
   * ['aztec', 'code_128', 'code_39', 'code_93', 'codabar', 'data_matrix', 'ean_13', 'ean_8', itf', 'pdf417',
   * 'qr_code', 'upc_a', 'upc_e']
   * Not all formats may be supported on all platforms.
   * If formats is not specified, the browser will search all supported formats, so limiting the search to a particular
   * subset of supported formats is likely to provide better performance.
   * <li>convertBlob</li> an optional boolean parameter indicating whether an automatic conversion from Blob input
   * to ImageBitmap should be performed. This is a workaround for Chrome's bug:
   * {@link https://bugs.chromium.org/p/chromium/issues/detail?id=1048379}
   * If conversion fails, an error will be thrown. This will happen, for example, when Blob does not contains a valid
   * image source.
   * @see [BarcodeDetectorOptions]{@link https://wicg.github.io/shape-detection-api/#dictdef-barcodedetectoroptions}
   *
   * An example below illustrates BarcodeAction metadata for reading QRCode from an HTML image element:
   * <pre>
   *   "parameters": {
   *   "image": "[[ document.querySelector('#qrcode') ]]",
   *   "formats": "[[ [ 'qr_code' ] ]]",
   *   "convertBlob": true
   *   },
   * </pre>
   * A success outcome will include <code>DetectedBarcode</code> object as a result. <code>DetectedBarcode</code>
   * has a rawValue property that corresponds to the decoded string.
   * @see [DetectedBarcode]{@link https://wicg.github.io/shape-detection-api/#detectedbarcode}
   *
   * A failure outcome is returned when browser does not support Shape Detection API, or a specified format is not
   * supported.
   *
   * @see {@link https://wicg.github.io/shape-detection-api/#barcode-detection-api}
   */
  class BarcodeAction extends Action {
    static get NOT_SUPPORTED_MSG() {
      return NOT_SUPPORTED_MSG;
    }

    constructor(id, label) {
      super(id, label);
      this.log = logger;
    }

    /**
     * @param parameters
     * @returns {Promise} Outcome {name: "success"}, once barcode detection completed or {name: "failure"},
     * when BarcodeDetector is not supported by the browser, or a specified format is not supported.
     */
    // eslint-disable-next-line class-methods-use-this
    perform(parameters) {
      if (!('BarcodeDetector' in window && 'getSupportedFormats' in BarcodeDetector)) {
        return Action.createFailureOutcome(NOT_SUPPORTED_MSG, new Error(NOT_SUPPORTED_MSG));
      }
      const image = parameters.image;
      const formats = parameters.formats;
      if (!this.checkImage(image)) {
        this.log.error(`(${this.logLabel}): ${this.errorMessage}`);
        return Action.createFailureOutcome(this.errorMessage, new TypeError(this.errorMessage));
      }

      return BarcodeDetector.getSupportedFormats()
        .then((supportedFormats) => {
          // check whether format is supported
          const supported = this.checkFormats(supportedFormats, formats);
          if (supported) {
            let outcome;
            const barcodeDetector = this.getBarcodeDetector(formats);
            return this.optionallyConvertBlob(image, parameters.convertBlob)
              .then((imageSource) => barcodeDetector.detect(imageSource))
              .then((barcodes) => {
                if (barcodes && barcodes.length > 0) {
                  // return DetectedBarcode as a result. Clients can access url as $chain.results.barcodeAction.rawValue
                  outcome = Action.createSuccessOutcome(barcodes[0]);
                } else if (this.options.mirrorBrowserApiBehavior) {
                  // If mirrorBrowserApiBehavior is true, return a success outcome with an empty object payload
                  outcome = Action.createSuccessOutcome({});
                } else {
                  const msg = 'No barcodes detected';
                  outcome = Action.createFailureOutcome(msg, new Error(msg));
                }
                return outcome;
              });
          }
          const msg = `${formats} ${(formats && formats.length > 1) ? 'are' : 'is'} not supported by the browser`;
          return Action.createFailureOutcome(msg, new Error(msg));
        })
        .catch((error) => {
          this.log.error(`(${this.logLabel}): ${error.message}`);
          return Action.createFailureOutcome(error.message, error, error.code);
        });
    }

    /**
     * Creates a BarcodeDetector corresponding to specified formats. A default detector is cached upon creation for
     * reuse.
     * @param formats an array of requested formats
     * @returns {BarcodeDetector} a BarcodeDetector object
     */
    // eslint-disable-next-line class-methods-use-this
    getBarcodeDetector(formats) {
      let barcodeDetector;
      if (formats === undefined) {
        if (defaultDetector === undefined) {
          defaultDetector = new BarcodeDetector();
        }
        barcodeDetector = defaultDetector;
      } else {
        barcodeDetector = new BarcodeDetector({ formats });
      }
      return barcodeDetector;
    }

    /**
     * Detects missing image parameter.
     * @see https://web.dev/shape-detection/
     * BarcodeDetector.detect() takes an ImageBitmapSource as an input (that is, either a CanvasImageSource, a Blob,
     * or ImageData), however, strict type checking is not enforced here, because detect() method will fail anyway
     * given an invalid input.
     *
     * @param {*} image
     */
    checkImage(image) {
      if (!image) {
        this.errorMessage = 'BarcodeAction image parameter must be specified';
        return false;
      }
      return true;
    }

    /**
     * Verifies whether specified formats parameter is valid. A valid formats parameter is either undefined,
     * or it is an array of individual formats that are supported by the browser.
     * @param supportedFormats an array of formats supported by the browser. For example:
     * ['aztec', 'code_128', 'code_39', 'code_93', 'codabar', 'data_matrix', 'ean_13', 'ean_8', itf', 'pdf417',
     * 'qr_code', 'upc_a', 'upc_e']
     * @param formats formats requested by the BarcodeAction
     * @returns {boolean|*}
     */
    // eslint-disable-next-line class-methods-use-this
    checkFormats(supportedFormats, formats) {
      if (Array.isArray(formats)) {
        if (formats.length === 0) {
          // at least one format must be specified
          return false;
        }
        return formats.reduce((acc, curr) => acc && supportedFormats.find((f) => f === curr), true);
      }
      return true;
    }

    // eslint-disable-next-line class-methods-use-this
    optionallyConvertBlob(image, convertBlob) {
      if (convertBlob) {
        return window.createImageBitmap(image);
      }
      return Promise.resolve(image);
    }
  }
  return BarcodeAction;
});

