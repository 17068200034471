'use strict';

/* eslint-disable no-param-reassign */

define('vb/extensions/process/private/types/processDataProvider',[
  'vb/extensions/process/helpers/processService',
  'vb/private/types/serviceDataProvider',
], (
  ProcessService,
  ServiceDataProvider) => {
  const OBJECT_TYPE = 'objectType';

  const OBJECT_TYPE_DEPLOYED_PROCESS = 'deployedProcess';
  const OBJECT_TYPE_PROCESS_INSTANCE = 'processInstance';
  const OBJECT_TYPE_TASK = 'task';

  const addQueryParamIfNeeded = (url, queryParamName, queryParamValue) => {
    const startParam = url.indexOf('?') > 0 ? '&' : '?';
    return url.indexOf(`${queryParamName}=`) < 0 ? `${url}${startParam}${queryParamName}=${queryParamValue}` : url;
  };

  const defaultRequestPagination = (configuration, options) => {
    let newUrl = configuration.url;
    newUrl = addQueryParamIfNeeded(newUrl, 'limit', options.size);
    newUrl = addQueryParamIfNeeded(newUrl, 'offset', options.offset);
    configuration.url = newUrl;
    return configuration;
  };

  const defaultResponsePagination = (result) => {
    const totalResults = {};
    const body = result.body;
    if (body) {
      if (body.totalResults) {
        totalResults.totalSize = body.totalResults;
      }
      totalResults.hasMore = !!body.hasMore;
    }
    return totalResults;
  };

  const handleProcessObjectType = (variableDef, value, endpoint, responseType, excludeRequestPagination) => {
    if (variableDef && variableDef.defaultValue && !variableDef.defaultValue.responseType) {
      variableDef.defaultValue.responseType = responseType;
    }

    value.endpoint = value.endpoint || endpoint;
    value.itemsPath = value.itemsPath || 'items';
    value.idAttribute = value.idAttribute || 'id';
    value.responseType = value.responseType || responseType;

    Object.assign(value.uriParameters, value.parameters);

    if (excludeRequestPagination !== true) {
      value.transforms.request.paginate = value.transforms.request.paginate || defaultRequestPagination;
    }
    value.transforms.response.paginate = value.transforms.response.paginate || defaultResponsePagination;
  };

  /**
   * Builtin implementation of Process Cloud Servoce DataProvider for VBCS.
   */
  class ProcessDataProvider extends ServiceDataProvider {
    init(id, variableDef, value) {
      const objectType = value[OBJECT_TYPE];
      switch (objectType) {
        case OBJECT_TYPE_DEPLOYED_PROCESS:
          handleProcessObjectType(
            variableDef,
            value,
            ProcessService.endpoint.deployedProcessCollection,
            ProcessService.definition.extendedDeployedProcessCollection,
            true);
          break;

        case OBJECT_TYPE_PROCESS_INSTANCE:
          handleProcessObjectType(
            variableDef,
            value,
            ProcessService.endpoint.processInstanceCollection,
            ProcessService.definition.processInstanceCollection);
          break;

        case OBJECT_TYPE_TASK:
          handleProcessObjectType(
            variableDef,
            value,
            ProcessService.endpoint.taskCollection,
            ProcessService.definition.taskCollection);
          break;

        default:
          // ignore
      }

      super.init(id, variableDef, value);
      this.definition = variableDef;
    }

    getTypeDefinition(variableDef) {
      const typeDefinition = super.getTypeDefinition(variableDef);
      typeDefinition.type[OBJECT_TYPE] = 'string';
      typeDefinition.type.parameters = 'object';
      return typeDefinition;
    }
  }

  return ProcessDataProvider;
});

