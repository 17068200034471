'use strict';

define('vb/extensions/dynamic/private/helpers/baseHelper',['signals'], (signals) => {
  /**
   * provides a simple pub/sub for exposing properties to DT.
   */
  class BaseHelper {
    constructor() {
      this.propChanged = new signals.Signal();
    }

    /**
     * @param name property name
     * @param value {*}
     * @protected
     */
    notify(name, value) {
      this.propChanged.dispatch(name, value);
    }

    /**
     * add a listener function for any properties we need to expose to DT
     * @param listener function<{ name: string, value: *}>
     */
    addListener(listener) {
      this.propChanged.add(listener);
    }

    /**
     * remove listeners
     */
    dispose() {
      this.propChanged.removeAll();
    }
  }

  return BaseHelper;
});

