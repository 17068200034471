'use strict';

define('vb/private/types/utils/serviceDataProviderRestHelperFactory',[
  'vb/private/helpers/restHelperFactory',
  'vb/private/types/utils/serviceDataProviderRestHelper',
], (RestHelperFactory, ServiceDataProviderRestHelper) => {
  class ServiceDataProviderRestHelperFactory extends RestHelperFactory {
    /**
     * This is (currently) a PRIVATE factory for internal use
     *
     * Creates ServiceDataProviderRestHelper instance.
     * @param endpointReference {string|object}
     * @param container {Container|undefined}
     *
     * @returns {ServiceDataProviderRestHelper}
     */
    static get(endpointReference, container) {
      // eslint-disable-next-line no-underscore-dangle
      return RestHelperFactory._createRestHelper(ServiceDataProviderRestHelper, endpointReference, container);
    }
  }
  return ServiceDataProviderRestHelperFactory;
});

