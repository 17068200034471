/* eslint-disable max-len */

'use strict';

define('vbsw/private/headerUtils',['vbsw/private/constants'], (Constants) => {
  // this is a list of headers that the VB proxy does not prefixed, because
  // the browser will set these, if these have not already been set.
  // the proxy is respecting only the unprefixed version of these, to prevent malicious overrides, etc.
  // taken from breeze/server/src/main/java/com/oracle/breeze/authorization/proxy/AuthResourceVersion.java
  // ALM: https://alm.oraclecorp.com/mcs/#projects/buf/scm/mcs_buf.git/blob/breeze/server/src/main/java/com/oracle/breeze/authorization/proxy/AuthResourceVersion.java?revision=master
  // (with duplicates from the forbidden list below removed).
  const browserSuppliedHeaders = [
    'Accept',
    'Accept-Language',
    'Content-Type', // wasn't in the java at the time of this list, but should have been
    'Content-Language',
    'Content-Encoding',
    'ETag',
    'If-Match',
    'If-None-Match',
    'Cache-Control',
    'Vary',
    'Last-Modified',
    'If-Modified-Since',
    'If-Unmodified-Since',
    'Expires',
    'Prefer',
    // adding this here, for convenience
    'vb-.*', // exclude all VB headers from processing (they will eventually get deleted before fetch())
  ];

  // https://tools.ietf.org/html/rfc7240
  // forbidden headers that cannot be set programmatically
  const forbiddenHeaders = [
    'Accept-Charset',
    'Accept-Encoding',
    'Access-Control-Request-Headers',
    'Access-Control-Request-Method',
    'Connection',
    'Content-Length',
    'Cookie',
    'Cookie2',
    'Date',
    'DNT',
    'Expect',
    'Host',
    'Keep-Alive',
    'Origin',
    'Proxy-.*',
    'Sec-.*',
    'Referer',
    'TE',
    'Trailer',
    'Transfer-Encoding',
    'Upgrade',
    'Via',
  ];

  // combine the lists above, for the complete list of headers to NOT prefix
  const regexpParts = [...browserSuppliedHeaders, ...forbiddenHeaders];

  const PROXY_HEADER_EXCLUSION_REGEX = new RegExp(`^(${regexpParts.join('|')})$`, 'i');

  // these headers may have been added after the original request by plugins downstream of
  // authPreprocessorHandlerPlugin, but also (may) need to be prefixed.
  const FA_VB_TRANSACTION_HEADERS = /^x-oracle.*/i;

  /**
   *
   */
  class HeaderUtils {
    //
    static needsPrefixForProxy(name, originalNames) {
      return (!PROXY_HEADER_EXCLUSION_REGEX.test(name)
        && (FA_VB_TRANSACTION_HEADERS.test(name) || originalNames.indexOf(name) >= 0));
    }

    /**
     * modifies headers names in the Request.
     * the caller should call needsPrefixForProxy() to call prefixHeaderForProxy conditionally.
     * @param request {Request} headers may be modified.
     * @param name {string} header name
     *
     * @returns {boolean} true if the header name was changed
     */
    static prefixHeaderForProxy(request, name) {
      const newName = `${Constants.HEADER_PROXY_HEADER_PREFIX}${name}`;
      const value = request.headers.get(name);
      request.headers.set(newName, value);
      request.headers.delete(name);
    }
  }

  // for tests
  HeaderUtils.browserSuppliedHeaders = browserSuppliedHeaders;


  return HeaderUtils;
});

