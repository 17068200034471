'use strict';

define('vb/private/stateManagement/switcherAppUiMixin',[
  'vb/helpers/mixin',
  'vb/private/stateManagement/switcherPageMixin',
  'vb/private/stateManagement/switcherFlowMixin',
], (Mixin, SwitcherPageMixin, SwitcherFlowMixin) => {
  /**
   * Mixin to be used with App UIs running in a switcher
   * It modifies the original behavior of the App UI to run in a oj-vb-switcher component
   */
  const SwitcherAppUiMixin = (superclass) => class extends superclass {
    constructor(extension, appUiInfo, page, className) {
      super(extension, appUiInfo, page, className);

      this._rootPage = page;

      this.parent.initRouter();
    }

    get rootPage() {
      return this._rootPage;
    }

    get switcher() {
      return this._rootPage.switcher;
    }

    get rootRouter() {
      return this._rootPage.rootRouter;
    }

    /**
     * When a page is created in a switcher, mix in the switcher functionality
     * @param {Object} pageInfo an object with an id property for the page id
     * @param {String} path the relative path of the page resource (optional)
     */
    createPage(pageInfo, path) {
      return new (Mixin(this.constructor.PageClass).with(SwitcherPageMixin))(pageInfo.id, this, path);
    }

    /**
     * When a flow is created in the switcher, mix in the switcher functionality
     *
     * @param   {String}  id  the flow id
     * @param   {Container}  container  the container of the new flow
     * @return  {Flow} a flow instance
     */
    createFlow(id, container) {
      const FlowClass = this.constructor.FlowClass;

      // To calculate the location of this new flow we need to retrieve the first flow that contains
      // it. It is either the container itself (case of an App UI without a root page) or the first
      // flow up in the container parent hierarchy
      const parentFlow = (container instanceof FlowClass) ? container : container.getParentFlow();

      const path = parentFlow.calculateRequirePath(id);

      // Construct a Flow (either Flow or PackageFlow depending the environment) with the behavior
      // required for working in the switcher component.
      return new (Mixin(FlowClass).with(SwitcherFlowMixin))(id, container, path);
    }

    /**
     * @type {!String}
     */
    get urlId() {
      // App UIs in switcher don't use the URL id, so override the AppPackage behavior of
      // returning the appUiInfo.urlId
      return this._id;
    }

    // eslint-disable-next-line class-methods-use-this
    updateCurrentAppUiBuiltinVariable() {
      // no-op, the global current App UI is not the App Ui in the switcher
    }

    isEmbeddable() {
      // Only App UI marked with navigation = 'embeddable' can be embedded in a switcher
      const { navigation } = this.definition;
      return (navigation && navigation.embeddable === 'enabled');
    }
  };

  return SwitcherAppUiMixin;
});

