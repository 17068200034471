/* eslint-disable class-methods-use-this,max-classes-per-file */

'use strict';

define('vb/private/events/notifyWaitEventBehavior',[
  'vb/private/events/eventBehavior',
], (EventBehavior) => {
  /**
   * NotifyWaitEventBehavior, behavior = "notifyAndWait"
   */
  class NotifyWaitEventBehavior extends EventBehavior {
    /**
     * call invokeEvent in sequence
     * @param functionWrappers
     * @override
     * @private
     */
    executeInternal(functionWrappers) {
      return functionWrappers
        .reduce((p, wrapper) => Promise.resolve(p)
          .then(() => Promise.resolve(wrapper.fnc(this))
            .then((r) => r)),
        Promise.resolve());
    }
  }

  return NotifyWaitEventBehavior;
});

