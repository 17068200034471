/* eslint-disable class-methods-use-this, prefer-destructuring */

'use strict';

//
// performance is a global that is defined on window:
//   https://developer.mozilla.org/en-US/docs/Web/API/Window/performance
// and on WorkerGlobalScope:
//   https://developer.mozilla.org/en-US/docs/Web/API/WorkerGlobalScope
// which is a parent of ServiceWorkerGlobalScope:
//   https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerGlobalScope
// Since this code runs both on UI thread and SW thread, it can't use window.performance or else it would break in SW,
// where window is not defined.
//

define('vbc/private/performance/performance',[
  'vbc/private/performance/performanceCategory',
  'vbc/private/performance/immediateCategory',
  'vbc/private/performance/webVitalsCategory',
  'vbc/private/performance/performanceReport',
  'vbc/private/performance/reporter',
], (PerformanceCategory, ImmediateCategory, WebVitalsCategory, PerformanceReport, Reporter) => {
  const MARK_START = '_vbMarkStart';
  //
  // Entry types for PerformanceMark, PerformanceMeasure, PerformanceNavigationTiming and PerformanceResourceTiming
  //
  const MARK_TYPE = 'mark';
  const MEASURE_TYPE = 'measure';
  const NAVIGATION_TYPE = 'navigation';
  const RESOURCE_TYPE = 'resource';
  //
  // Common property names between (old) performance.timing (PerformanceTiming) and
  // performance.getEntriesByType('navigation')[0](PerformanceNavigationTiming) excluding unloadEventEnd and
  // unloadEventStart that happen before navigationStart
  // See https://developer.mozilla.org/en-US/docs/Web/API/PerformanceTiming
  // See https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming
  //
  const COMMON_PROPS = ['connectEnd', 'connectStart', 'domComplete', 'domContentLoadedEventEnd',
    'domContentLoadedEventStart', 'domInteractive', 'domainLookupEnd', 'domainLookupStart', 'domainLookupEnd',
    'fetchStart', 'loadEventEnd', 'loadEventStart', 'requestStart', 'responseEnd', 'responseStart'];

  // Default size of the resource timing buffer
  // From https://developer.mozilla.org/en-US/docs/Web/API/Performance/setResourceTimingBufferSize:
  // A browser's recommended resource timing buffer size is at least 150
  const DEFAULT_RESOURCE_BUFFER_SIZE = 150;

  // Because of a circular dependency between Performance and Log, logger needs to be loaded on demand
  let logger;
  const getLogger = () => {
    if (!logger) {
      const Log = requirejs('vbc/private/log');
      logger = Log.getLogger('/vbc/private/performance/performance');
    }
    return logger;
  };

  /**
   * Performance class with lazily initialized properties that correspond to the following performance categories:
   * - info: general information about this application
   * - navigation: entries corresponding to
   * [Navigation Timing Level 2]{@link https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming}
   * or
   * [PerformanceTiming]{@link https://developer.mozilla.org/en-US/docs/Web/API/PerformanceTiming} API on browsers
   * that don't support PerformanceNavigationTiming.
   * - custom: custom navigation entries such as time to first byte
   * - timestamps: timestamp of important VB events, such as application's vbEnter
   * - durations - time spent on processing VB actions, action chains and fetch calls
   * - resources - 10 most expensive resources loaded by this application. This is either determined by time spent
   * loading this resource (on all browsers), or, resource size (on browser that support it)
   */
  class Performance {
    /**
     * @param {Object} options
     * @param {number} options.resourceTimingBufferSize the size of the resource buffer for resource timing entries.
     * By default, it is 300.
     * @param {number} options.maxResEntries the maximum number of resource entries to log. If not specified,
     * it will be the same as resourceTimingBufferSize
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Performance/setResourceTimingBufferSize
     */
    constructor({
      resourceTimingBufferSize = DEFAULT_RESOURCE_BUFFER_SIZE,
      maxResEntries = resourceTimingBufferSize,
    }) {
      if (!performance) {
        getLogger().warn('Browser does not support Web Performance');
        return;
      }

      // performance.addEventListener is not supported on IE
      if (typeof performance.setResourceTimingBufferSize === 'function') {
        performance.setResourceTimingBufferSize(resourceTimingBufferSize);
      }
      // on Firefox ServiceWorker performance.addEventListener('resourcetimingbufferfull') syntax fails.
      performance.onresourcetimingbufferfull = this.onResourceBufferFull.bind(this);

      Performance.INFO = new ImmediateCategory('info', this.getInfo.bind(this));
      Performance.NAVIGATION = new ImmediateCategory('navigation (ms)', this.getNavigation.bind(this));
      Performance.CUSTOM = new PerformanceCategory('custom (ms)', this.getCustom.bind(this));
      Performance.TIMESTAMPS = new ImmediateCategory('timestamps (ms)', this.getTimestamps.bind(this));
      Performance.DURATIONS = new ImmediateCategory('durations (ms)', this.getDurations.bind(this));
      Performance.RESOURCES_BY_DURATION = new ImmediateCategory('resorcesByDuration (ms)',
        this.getResourcesByDuration.bind(this));
      Performance.RESOURCES_BY_SIZE = new ImmediateCategory('resourcesBySize (Kb)',
        this.getResourcesBySize.bind(this));
      Performance.WEB_VITALS = new WebVitalsCategory();

      this.categories = [];
      this.categories.push(Performance.INFO);
      this.categories.push(Performance.NAVIGATION);
      this.categories.push(Performance.CUSTOM);
      this.categories.push(Performance.TIMESTAMPS);
      this.categories.push(Performance.DURATIONS);
      this.categories.push(Performance.RESOURCES_BY_DURATION);
      this.categories.push(Performance.RESOURCES_BY_SIZE);
      this.categories.push(Performance.WEB_VITALS);

      this.maxResEntries = maxResEntries;
      this.resourceTimingBufferSize = resourceTimingBufferSize;
    }

    getCategories() {
      return this.categories;
    }

    /**
     * Event listener for 'resourcetimingbufferfull' browser event. All existing resource timing entries are logged
     * and cleared and resource timing buffer size is set to <i>resourceTimingBufferSize</>.
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Performance/resourcetimingbufferfull_event
     */
    onResourceBufferFull() {
      if (performance) {
        getLogger().warn('Resource timing buffer full');
        this.logResources();
        if (performance.getEntriesByType) {
          getLogger().info('Clearing', performance.getEntriesByType(RESOURCE_TYPE).length, 'resource entries');
        }
        // Remove all of the resource timing entries
        performance.clearResourceTimings();
        // Set the new size at resourceTimingBufferSize resource timing entries (PerformanceEntry objects)
        performance.setResourceTimingBufferSize(this.resourceTimingBufferSize);
      }
    }

    getReport() {
      if (!this.report) {
        this.report = new PerformanceReport();
      }
      return this.report;
    }

    /**
     * Log performance audits directly to the console.
     */
    log() {
      this.getReport().reportEachCategory(this.categories);
    }

    logTable() {
      // when using console.table(), there is no need for any formatting
      this.getReport().reportEachCategory(this.categories, Reporter.TABLE_CONSOLE_REPORTER, undefined);
    }

    logCustom() {
      this.getReport().reportEachCategory([Performance.CUSTOM]);
    }

    logTimestamps() {
      this.getReport().reportEachCategory([Performance.TIMESTAMPS]);
    }

    logDurations() {
      this.getReport().reportEachCategory([Performance.DURATIONS]);
    }

    logResources() {
      this.getReport().reportEachCategory([Performance.RESOURCES_BY_DURATION, Performance.RESOURCES_BY_SIZE]);
    }

    logVitals() {
      this.getReport().reportEachCategory([Performance.WEB_VITALS]);
    }

    logVB() {
      this.logDurations();
      this.logTimestamps();
    }

    /**
     * Determines if performance measuring should be enabled for a given performance config. For example:
     * <pre>
     *    PERFORMANCE_CONFIG: {
     *       resourceTimingBufferSize: 200,
     *       disabled: false,
     *   }
     * </pre>
     * means that performance should be enabled.
     * Performance measuring is disabled by default, but in an existing config it can also be disabled
     * by setting <i>disabled</i> property to false:
     * <pre>
     *    PERFORMANCE_CONFIG: {
     *       disabled: false,
     *   }
     * </pre>
     * @param config PERFORMANCE_CONFIG specified in window.vbInitConfig
     * @returns {boolean} true, if measuring performance should be enabled, false otherwise. For an undefined config, or
     * for a config that does have <i>disabled</i> property set to true, performance is disabled. For all other cases,
     * including an empty PERFORMANCE_CONFIG object, performance measuring is enabled.
     */
    static isEnabled(config) {
      //
      // Cannot use config && ... here, because it gets mimified to:
      // return e && !e.disabled && void 0 !== performance;
      // which, for undefined config, evaluates to undefined and not false, and that breaks unit test in test-release
      //
      return !!(config !== undefined && config !== null
        && (config.disabled ? config.disabled === false : true)
        && performance !== undefined);
    }

    /**
     * If performance is enabled by the specified configuration and browser support for <i>performance</i> API,
     * adds <i>perf</i> property to the context object.
     *
     * @param context the object to add <i>perf</i> property to. This is typically <i>window.vb</i>, or
     * <i>globalThis.vb</i> for the ServiceWorkerGlobalContext
     * @param config the performance configuration
     */
    static init(context, config) {
      if (context && Performance.isEnabled(config)) {
        Performance.enabled = true;
        const perf = new Performance(config);
        Object.defineProperty(context, 'perf', {
          enumerable: true,
          configurable: true,
          value: perf,
        });
        Performance.perf = perf;
      } else {
        Performance.enabled = false;
      }
    }

    /**
     * Creates a named timestamp in the browser's performance entry buffer between the navigation start time and
     * the current time, if performance is enabled.
     *
     * @param {string[]} name entries corresponding to the name of the timestamp, for example: 'app', 'beforeEnter'
     * @see {@link Performance.isEnabled()}
     */
    static timestamp(...name) {
      if (Performance.enabled && name && Array.isArray(name) && name.length > 0) {
        performance.mark(name.join(':'));
      }
    }

    /**
     * Creates a mark in the browser's performance entry buffer with the given name, if performance is enabled.
     *
     * @param {string[]} name an array of entries corresponding to the name of the mark, for example,
     * ['fetchHandler.', 'install']
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Performance/mark
     * @see {@link Performance.isEnabled()}
     */
    static markStart(name = []) {
      if (Performance.enabled && name && Array.isArray(name) && name.length > 0) {
        performance.mark(`${name.join(':')}:${MARK_START}`);
      }
    }

    /**
     * Creates a named measure in a browser's performance entry buffer between the start mark with a matching name:
     * 'name' + _vbMarkStart and the current time, if performance is enabled.
     *
     * @param {string[]} name an array of entries corresponding to the name of the mark, for example,
     * ['fetchHandler.', 'install']
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Performance/measure
     * @see {@link Performance.isEnabled()}
     */
    static markEnd(name = []) {
      if (Performance.enabled && name && Array.isArray(name) && name.length > 0 && performance.getEntriesByName) {
        const markName = name.join(':');
        // create a measure from start mark to now
        const startMarkName = `${markName}:${MARK_START}`;
        // make sure the start mark still exits
        const startMark = performance.getEntriesByName(startMarkName, MARK_TYPE);
        if (startMark && startMark[0]) {
          performance.measure(markName, startMarkName);
          performance.clearMarks(startMarkName);
        }
      }
    }

    /**
     * Removes performance entries of type 'mark' and 'measure', since the last leaf page load
     * @param {boolean} clearResourceTimings if true, also removes 'resource' type entries
     */
    static clear(clearResourceTimings = false) {
      if (Performance.enabled) {
        performance.clearMeasures();
        performance.clearMarks();
        delete performance.onresourcetimingbufferfull;
        if (clearResourceTimings && Performance.perf) {
          Performance.perf.onResourceBufferFull(this);
        }
      }
    }

    /**
     * Returns a PerformanceNavigationTiming for browsers that support it. Otherwise, on IE and Safari, a
     * deprecated PerformanceTiming object is returned, or undefined, if neither is supported, or this is
     * a Service Worker thread.
     *
     * @returns {Object}
     * a performance navigation timing entry
     * @see https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming
     * @see https://developer.mozilla.org/en-US/docs/Web/API/PerformanceTiming
     */
    static getPerformanceTimingEntry() {
      let entry;
      let entries = [];
      if (performance.getEntriesByType) {
        entries = performance.getEntriesByType(NAVIGATION_TYPE);
        if (entries && entries.length > 0) {
          entry = entries[0];
        }
      }
      // fallback for legacy performance API
      if (entries.length === 0 && performance.timing) {
        entry = performance.timing;
      }
      return entry;
    }

    /**
     * Navigation start is the start point of a new page load as far as performance tools are concerned.
     * It is actually the moment just before a new page is requested.
     * For the legacy API, this corresponds to performance.timing.navigationStart. For v2 PerformanceNavigationTiming
     * API, it is PerformanceNavigationTiming.startTime, which is 0.
     *
     * @param entry a performance navigation entry
     * @returns {number} a navigation start time used to calculate custom performance entries
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/PerformanceTiming/navigationStart
     */
    static getNavigationStart(entry) {
      // In the legacy PerformanceTiming API, all entries are in epoch time.
      let navigationStart = 0;
      if (entry) {
        if (entry.navigationStart !== undefined) {
          navigationStart = entry.navigationStart;
        } else if (entry.startTime) {
          navigationStart = entry.startTime;
        }
      }
      return navigationStart;
    }

    /**
     * @param {function} f a format callback to apply to each entry
     * @returns {Array} a list of generic information about this performance audit, such as location and user agent.
     */
    getInfo(f) {
      const info = [];
      // On IE/Edge, performance.getEntriesByType('navigation')[0].name returns 'document', which is not a good name
      // so use window.location.href instead. Except can't use window on SW thread, it has to be globalThis
      info.push(f({ name: globalThis.location.href }));
      info.push(f({ userAgent: navigator.userAgent }));
      info.push(f({ globalObject: globalThis.toString() }));
      return info;
    }

    /**
     * @param {function} f a format callback to apply to each entry
     * @returns {Array} a list of navigation timings for this application, such as domInteractive or fetchStart
     *
     * @see {@link Performance.getCommonEntryNames()}
     * @see https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming
     * @see https://developer.mozilla.org/en-US/docs/Web/API/PerformanceTiming
     */
    getNavigation(f) {
      const navigation = [];
      const timingEntry = Performance.getPerformanceTimingEntry();

      if (timingEntry) {
        const navigationStart = Performance.getNavigationStart(timingEntry) || 0;
        // Add all entries common between (old) performance.timing (PerformanceTiming API) and
        // (new) performance.getEntriesByType('navigation')[0](PerformanceNavigationTiming API),
        // adjusting legacy entries for navigation start.
        COMMON_PROPS.forEach((prop) => {
          // adjust all entries that were represented in epoch time
          const propTime = timingEntry[prop] - navigationStart;
          const o = {};
          o[prop] = Math.round(propTime);
          navigation.push(f(o));
        });
      }
      return navigation;
    }

    /**
     * @param {function} f a format callback to apply to each entry
     * @returns {Promise} a promise to a list of calculated performance measures, such as:
     *
     * - connectTime
     * - domLoadTime
     * - domParsingTime
     * - renderTime
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/PerformanceLongTaskTiming
     */
    getCustom(f) {
      const custom = [];
      return Promise.resolve()
        .then(() => {
          const e = Performance.getPerformanceTimingEntry();
          if (e) {
            custom.push(f({ connectTime: Math.round(e.responseEnd - e.requestStart) }));
            custom.push(f({ domLoadTime: Math.round(e.domContentLoadedEventEnd - e.fetchStart) }));
            custom.push(f({ domParsingTime: Math.round(e.domContentLoadedEventEnd - e.domInteractive) }));
            custom.push(f({ renderTime: Math.round(e.domComplete - e.domContentLoadedEventEnd) }));
          }
          return custom;
        })
        .catch((error) => {
          getLogger().warn(error);
          return custom;
        });
    }

    /**
     * @param {function} f a format callback to apply to each entry
     * @return {Array} a list of all custom VB performance measures that were added since the last call
     * to {@link clear()}
     *
     * @see {@link markStart(name)}
     * @see {@link markEnd(name)}
     */
    getDurations(f) {
      const durations = [];
      if (performance.getEntriesByType) {
        const entries = performance.getEntriesByType(MEASURE_TYPE);
        if (entries) {
          entries.forEach((entry) => {
            const o = {};
            o[entry.name] = Math.round(entry.duration);
            durations.push(f(o));
          });
        }
      }
      return durations;
    }

    /**
     * @param {function} f a format callback to apply to each entry
     * @returns {Array} a list of all custom VB timestamps that were added since the last call to {@link clear()}
     *
     * @see {@link timestamp(name)}
     */
    getTimestamps(f) {
      const timestamps = [];
      if (performance.getEntriesByType) {
        const entries = performance.getEntriesByType(MARK_TYPE);
        if (entries) {
          entries.forEach((entry) => {
            // marks ending with MARK_START are used to measure duration and should be ignored here
            if (!entry.name.endsWith(MARK_START)) {
              const o = {};
              o[entry.name] = Math.round(entry.startTime);
              timestamps.push(f(o));
            }
          });
        }
      }
      return timestamps;
    }

    /**
     * @param {function} f a format callback to apply to each entry
     * @return {Array} For browsers that support PerformanceResourceTiming API's encodedBodySize, a list
     * of properties corresponding to maxResEntries biggest resources since the resource timing buffer has been cleared.
     * For other browsers, an empty array is returned.
     * Resource size is specified in Kb. Resources with size that rounds to zero are not included.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/PerformanceResourceTiming/encodedBodySize
     * @see {@link new Performance(maxResEntries)}
     */
    getResourcesBySize(f) {
      const resources = [];
      if (performance.getEntriesByType) {
        const resEntries = performance.getEntriesByType(RESOURCE_TYPE);
        // encodedBodySize is not universally supported
        // https://developer.mozilla.org/en-US/docs/Web/API/PerformanceResourceTiming/encodedBodySize
        // entries are sorted by size, if available
        const resEntry = resEntries[0];
        if (resEntry && resEntry instanceof PerformanceResourceTiming && resEntry.encodedBodySize !== undefined) {
          resEntries.sort((/** @type PerformanceResourceTiming */ a, /** @type PerformanceResourceTiming */ b) => {
            if (a.encodedBodySize < b.encodedBodySize) {
              return 1;
            }
            return -1;
          });
          resEntries.slice(0, resEntries.length > this.maxResEntries ? this.maxResEntries : resEntries.length)
            .forEach((/** @type PerformanceResourceTiming */ entry) => {
              const size = Math.round(entry.encodedBodySize / 1024);
              // encodedBodySize for resource fetched on SW thread is showing up as 0, so don't include it
              if (size > 0) {
                const o = {};
                o[entry.name] = Math.round(size);
                resources.push(f(o));
              }
            });
        }
      }
      return resources;
    }

    /**
     * @param {function} f a format callback to apply to each entry
     * @return {Array} a list of properties corresponding to maxResEntries resources that took longest to load
     * for this application, since the resource timing buffer has been cleared.
     * Duration is specified in milliseconds.
     *
     * See {@link https://developer.mozilla.org/en-US/docs/Web/API/PerformanceResourceTiming}
     */
    getResourcesByDuration(f) {
      const resources = [];
      if (performance.getEntriesByType) {
        const resEntries = performance.getEntriesByType(RESOURCE_TYPE);
        if (resEntries) {
          resEntries.sort((a, b) => {
            if (a.duration < b.duration) {
              return 1;
            }
            return -1;
          });
          resEntries.slice(0, resEntries.length > this.maxResEntries ? this.maxResEntries : resEntries.length)
            .forEach((entry) => {
              // if a resource is fetched multiple times, use the longest duration (for now)
              if (!resources[entry.name] || resources[entry.name] < entry.duration) {
                const o = {};
                o[entry.name] = Math.round(entry.duration);
                resources.push(f(o));
              }
            });
        }
      }
      return resources;
    }
  }

  Performance.perf = undefined;

  Performance.enabled = undefined;

  Performance.WEB_VITALS = undefined;
  Performance.RESOURCES_BY_SIZE = undefined;
  Performance.RESOURCES_BY_DURATION = undefined;
  Performance.DURATIONS = undefined;
  Performance.TIMESTAMPS = undefined;
  Performance.CUSTOM = undefined;
  Performance.NAVIGATION = undefined;
  Performance.INFO = undefined;
  return Performance;
});

