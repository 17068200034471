'use strict';

define('vb/extensions/process/action/builtin/startProcessAction',[
  'vb/extensions/process/action/builtin/processAction',
  'vb/extensions/process/helpers/processService',
], (
  ProcessAction,
  ProcessService) => {
  /**
   * Starts a process.
   *
   * Details:
   *
   * <ul>
   *   <li>Either "alias" or "deployedProcessId" should be used, not both.</li>
   *
   *   <li>The outcome returned by invoking the action is an object
   *   like <i>{ name: "", result: { content: {}, info: {} }</i>, where:
   *   <ul>
   *     <li>The value of "name" is either "success" or "failure"</li>
   *     <li>The value of "content" is a JSON object</li>
   *     <li>The value of "info" is an object with additional information like the status and headers of
   *         any HTTP response handled by the action</li>
   *   </ul></li>
   * </ul>
   *
   * Examples:
   *
   * <pre>
   *  "action01": {
   *    "module": "vb/extensions/process/action/builtin/startProcessAction",
   *    "parameters": {
   *      "alias": "ProcessAlias",
   *      "interfaceId": "start",
   *      "arguments": {
   *        "reason": "customer visit",
   *        "amount": 400
   *      }
   *    }
   *  }
   * </pre>
   *
   * <pre>
   *  "action02": {
   *    "module": "vb/extensions/process/action/builtin/startProcessAction",
   *    "parameters": {
   *      "deployedProcessId": "player~!sada~~ASdasd",
   *      "interfaceId": "start",
   *      "arguments": {
   *        "reason": "customer visit",
   *        "amount": 400
   *      }
   *    }
   *  }
   * </pre>
   */
  class StartProcessAction extends ProcessAction {
    static endpoint() {
      return ProcessService.endpoint.startProcess;
    }

    static responseType() {
      return ProcessService.definition.processInstance;
    }

    static restActionIdentification() {
      return { id: 'startProcessAction', description: 'Start a process' };
    }

    perform(parameters) {
      const uriParams = Object.assign({}, parameters);
      uriParams.aliasOrDeployedProcessId = uriParams.alias || uriParams.deployedProcessId;
      delete uriParams.alias;
      delete uriParams.deployedProcessId;

      const body = {};
      body.interface = uriParams.interface;

      if (body.interface) {
        delete uriParams.interface;
      } else {
        body.interface = {};

        body.interface.id = uriParams.interfaceId;
        delete uriParams.interfaceId;

        const argumentsProperty = 'arguments';
        body.interface[argumentsProperty] = uriParams[argumentsProperty];
        delete uriParams[argumentsProperty];
      }

      return this._send(uriParams, body);
    }
  }

  return StartProcessAction;
});

