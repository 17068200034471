'use strict';

define('vb/extensions/protocol/vbExtensionHandler',[
  'vb/private/utils',
  'vb/private/constants'],
(Utils, Constants) => {
  /**
    * inner utility to create the object with a 'url' property
    * ('type', 'name', and 'extensions' are also included for completeness, but not used).
    * @param type
    * @param url
    * @param namespace {string}
    * @returns Object of type {name: {string}, type: {string}, extensions: {object}, url: {string}}
    * @private
    */
  // no extensions, just a new URL
  const createProtocolObject = (type, url, namespace) => ({
    namespace,
    type: 'vb-extension',
    name: type,
    extensions: {},
    url: Utils.removeTrailingSlash(url), // remove trailing slash
  });

  class VbExtensionHandler {
    constructor(config, activeProfile, tenantConfig) {
      this.config = config || {};
      this.initConfig = this.config.initConfig || {};

      this.applicationPath = VbExtensionHandler.appendSegments(this.initConfig.VB_SERVER,
        this.initConfig.CONTEXT_ROOT,
        this.initConfig.APP_URL_PREFIX,
        this.initConfig.APP_ID,
        activeProfile
          ? `${this.initConfig.APP_VERSION};profile=${encodeURIComponent(activeProfile)}`
          : this.initConfig.APP_VERSION);

      if (tenantConfig) {
        this.idcsPath = tenantConfig.idcsInfo && tenantConfig.idcsInfo.hostName;
      }
    }

    /**
     * nothing to dispose
     */
    // eslint-disable-next-line class-methods-use-this
    dispose() {
    }

    /**
     * always use this handler
     * @returns {boolean}
     */
    static shouldInstall(/* config */) {
      return true;
    }

    /**
     * create the object that the ProtocolRegistry expects
     * @param url {string}
     * @param namespace {string} not used here
     * @param parsedInfo {object} from URIjs
     * @return {Promise}
     */
    getResolvedObject(url, namespace, parsedInfo /* , serverVariables */) {
      // examples
      //   vb-extension://tenant/idcs/oauth2/v1/userinfo
      //   vb-extension://application/resources/process/tasks

      return Promise.resolve()
        .then(() => {
          const host = parsedInfo.hostname;
          switch (host) {
            case 'tenant': {
              if (this.idcsPath && parsedInfo.path && parsedInfo.path.startsWith('/idcs')) {
                const resolvedURL = VbExtensionHandler.appendToPath(this.idcsPath, parsedInfo.path.substring(5));
                return createProtocolObject(host, resolvedURL, namespace);
              }

              return null;
            }

            case 'application': {
              const resolvedURL = VbExtensionHandler.appendToPath(this.applicationPath, parsedInfo.path);
              return createProtocolObject(host, resolvedURL, namespace);
            }

            default:
              return null;
          }
        });
    }

    /**
     * inner utility to create the object with a 'url' property
     * ('type', 'name', and 'extensions' are also included for completeness, but not used).
     * @param type
     * @param url
     * @param namespace {string}
     * @returns Object of type {name: {string}, type: {string}, extensions: {object}, url: {string}}
     * @private
     * @deprecated
     */
    static _createProtocolObject(type, url, namespace) {
      return createProtocolObject(type, url, namespace);
    }

    static appendToPath(path = Constants.PATH_SEPARATOR, segment) {
      if (!segment) {
        return path;
      }
      path = Utils.removeTrailingSlash(path); // eslint-disable-line no-param-reassign
      if (segment.startsWith(Constants.PATH_SEPARATOR)) {
        segment = segment.substring(1); // eslint-disable-line no-param-reassign
      }
      return path + Constants.PATH_SEPARATOR + segment;
    }

    static appendSegments(...segments) {
      return segments.length > 0
        ? segments.reduce((lastValue, currentSegment) => VbExtensionHandler.appendToPath(lastValue, currentSegment))
        : undefined;
    }
  }

  VbExtensionHandler.PROTOCOLS = ['vb-extension'];

  return VbExtensionHandler;
});

