/* eslint-disable no-param-reassign, class-methods-use-this */

'use strict';

define('vb/private/vx/baseExtensionRegistry',[
  'vb/private/vx/extensionAdapterFactory',
  'vb/private/vx/appUiInfos',
  'vb/private/configLoader',
  'vb/private/constants',
  'vb/private/utils',
  'urijs/URI',
  'vb/private/log',
], (ExtensionAdapterFactory, AppUiInfos, ConfigLoader, Constants, Utils, URI, Log) => {
  const logger = Log.getLogger('/vb/private/vx/baseExtensionRegistry');

  /**
   * Given 2 arrays, replace or append element from source array to
   * target array when the id of the element matches
   *
   * @param  {Array} sourceArray
   * @param  {Array} targetArray
   */
  const replaceOrAppendToArray = (sourceArray, targetArray) => {
    if (Array.isArray(sourceArray) && Array.isArray(targetArray)) {
      sourceArray.forEach((info) => {
        let index = 0;
        // Search for the object in targetArray with info.id
        for (; index < targetArray.length; index += 1) {
          if (targetArray[index].id === info.id) {
            break;
          }
        }

        // Either replace or append it with the source value
        targetArray.splice(index, 1, info);
      });
    }
  };

  /**
   * A class to retrieve the extensions for the current application from the extension manager
   * The extension manager URL is defined in the app-flow.json under the extension property.
   */
  class BaseExtensionRegistry {
    /**
     * This is called from ConfigLoader.js
     *
     * @param  {function} applicationConfigCallback a callBack that returns a promise to the application config
     * @param  {function} runtimeEnvironmentCallback a callBack that returns a promise to the runtime environment
     */
    constructor(applicationConfigCallback, runtimeEnvironmentCallback) {
      this.applicationConfigCallback = applicationConfigCallback;
      this.runtimeEnvironmentCallback = runtimeEnvironmentCallback;

      this.fetchManifestPromise = null; // Initialized in subclass initiateLoadManifest()
      this.loadExtensionsPromise = null; // Initialized in getExtensions()

      /**
       * A map of extension object keyed by their id
       * @type {Object}
       */
      this.extensions = {};

      this.log = logger;
    }

    static get extensionManagerVersion() {
      throw Error('need to override extensionManagerVersion');
    }

    /**
     * Initialize the extension registry. This consist of defining promise responsible
     * to load the manifest
     * @return {Promise}
     */
    initialize() {
      return this.initiateLoadManifest().then((result) => {
        if (result === false) {
          // If initiateLoadManifest return false, it means there is no extension manager defined
          // for this application so the manifest is an empty array.
          this.loadExtensionsPromise = Promise.resolve([]);
          this.log.info('No extension registry defined.');
        }
      });
    }

    /**
     * Load extension manager and design time manifest and merge them
     * Only called by subclass
     * @return {Promise<Object>} a promise to a manifest
     */
    _loadManifest() {
      return Promise.resolve().then(() => {
        const promises = [
          this.fetchManifestPromise,
          this.runtimeEnvironmentCallback().then((re) => re.getExtensionManifest()),
        ];
        // Load the manifest from the extension manager and from DT and replace
        // the extensions and requirejsInfo with the ones from the DT manifest.
        return Promise.all(promises).then((manifests) => {
          const manifest = manifests[0];
          const dtManifest = manifests[1];
          if (dtManifest) {
            // Replace or append manifest extensions using DT manifest
            replaceOrAppendToArray(dtManifest.extensions, manifest.extensions);

            // Replace or append manifest requirejsInfo using DT extensions requirejsInfo
            replaceOrAppendToArray(dtManifest.requirejsInfo, manifest.requirejsInfo);

            // Replace or append manifest appUiInfo using DT extensions appUiInfo
            replaceOrAppendToArray(dtManifest.appUiInfo, manifest.appUiInfo);
          }

          // Remove the loading promise since it's not needed anymore
          this.fetchManifestPromise = null;

          return manifest;
        });
      });
    }

    /**
     * Retrieve the adapter to be used to load the extensions
     * If no extension manager is defined, return an undefined adapter
     * @return {Promise} a promise that resolve to an adapter
     */
    getExtensionAdapter() {
      return ExtensionAdapterFactory.get(this.constructor.extensionManagerVersion);
    }

    /**
     * Calculate the base path of the extended resource given the container
     * @param  {String} path
     * @param  {Container} container
     * @return {String}
     */
    getBasePath(path, container = { extensionId: '' }) {
      return `${container.extensionId}/${path}`;
    }

    /**
     * Calculate the base path for ui type of resource (stuff under ui/)
     * @param  {String} path
     * @param  {Container} container
     * @return {String}
     */
    // eslint-disable-next-line no-unused-vars
    getBasePathForUi(path, container) {
      // implemented by subclass
    }

    /**
     * Calculate the base path for layout type of resource (stuff under dynamicLayouts/)
     * @param  {String} path
     * @param  {Container} container
     * @return {String}
     */
    // eslint-disable-next-line no-unused-vars
    getBasePathForLayout(path, container) {
      // implemented by subclass
    }

    /**
     * Get the base path for the given container. If the container is a layout,
     * getBasePathForLayout will be called, otherwise, getBasePathForUi is called.
     *
     * @param path
     * @param container
     * @returns {string}
     */
    getBasePathForContainer(path, container) {
      return container.name === 'layout' ? this.getBasePathForLayout(path, container)
        : this.getBasePathForUi(path, container);
    }

    /**
     * Loads all the extension for a specific container given its path. It returns a promise
     * that resolves in an array of extensions object, either PageExtension or FlowExtension.
     * @param  {String} path the path of the object for which we are looking for extensions
     * @param  {Container} container the container for which the extension is being loaded
     * @return {Promise} a promise to an array of extension objects
     */
    loadContainerExtensions(path, container) {
      return this.getExtensions()
        .then((extensions) => {
          if (extensions.length === 0) {
            return [];
          }

          const promises = [];
          const basePath = this.getBasePathForContainer(path, container);
          const Clazz = container.constructor.extensionClass;
          // container name may not necessarily be the actual resourceName. So use the resourceName property instead
          const extPath = `${basePath}${container.resourceName}${Clazz.resourceSuffix}`;

          // Traverse the array of extension from first to last. The extension manager is responsible
          // for properly ordering this array of extensions given the dependencies in the extension manager.
          extensions.forEach((extension) => {
            const files = extension.files || [];

            // If the manifest contains an extension for this artifact, creates an extension object for it
            if (files.indexOf(extPath) >= 0) {
              // TODO: reduce params?
              const ext = new (Clazz)(extension, basePath, container);
              const promise = ext.load().then(() => ext);
              promises.push(promise);
            }
          });

          // All files are then loaded in parallel
          return Promise.all(promises);
        });
    }

    /**
     * Retrieve a map of AppUiInfo for all the App UI available in all the extensions
     * The map is populated only by the v2 implementation
     * @return {Promise} a promise that resolve with a map of AppUiInfo
     */
    getAppUiInfos() {
      return Promise.resolve(new AppUiInfos());
    }

    /**
     * Returns a promise that resolves with an array of extensions or an empty array
     * @return {Promise<Array>} a promise that resolve to an array of extension
     */
    getExtensions() {
      this.loadExtensionsPromise = this.loadExtensionsPromise || this.getLoadManifestPromise().then((manifest) => {
        const extensions = [];

        (manifest.extensions || []).forEach((definition) => {
          const extensionId = definition.id;
          const extension = this.createExtension(definition,
            manifest.appUiInfo && manifest.appUiInfo[extensionId],
            manifest.bundlesInfo && manifest.bundlesInfo[extensionId],
            manifest.bundledResources && manifest.bundledResources[extensionId],
            manifest.components && manifest.components[extensionId]);

          if (!extension.isValid()) {
            this.log.error('Invalid manifest for extension:', extensionId, 'version:', extension.version);
          } else {
            extensions.push(extension);
            this.extensions[extensionId] = extension;
          }
        });

        const promises = extensions
          // if this extension extends the application, load the bundle or
          // create a require mapping so the resources are available.
          // Extensions with only App UI definition are loaded on demand.
          .filter((extension) => extension.extendsBaseArtifact())
          .map((extension) => extension.init());

        return Promise.all(promises).then(() => extensions);
      });

      return this.loadExtensionsPromise;
    }

    /**
     * Look up the extension identified by id.
     *
     * @param id extension id
     * @returns {Extension}
     */
    getExtensionById(id) {
      return this.extensions[id];
    }

    /**
     * Create a dependency graph from the given extension as the root.
     *
     * @param extensions all extension
     * @param extension the root extension
     * @returns {Array<Object>}
     */
    // eslint-disable-next-line no-unused-vars
    getExtensionDependencies(extensions, extension) {
      // implemented by subclass
      return [];
    }

    /**
     * Add requirejs mappings
     * @param {Object} paths
     */
    addRequireMapping(paths) {
      ConfigLoader.setConfiguration({ paths });
    }

    /**
     * path prefix of the designed layout file. always starts with 'dynamicLayouts/' and
     * ends with '/layout'.
     *
     * This method calls the service to get the layout templates
     *
     * @param {string} pathPrefix
     * @param {Array<object>} descriptors an array of descriptors describing the files expected
     * @see loadLayoutMaps()
     * @returns {Promise}
     */
    loadLayoutExtensions(pathPrefix, descriptors = [], container = {}) {
      return this.getExtensions()
        .then((extensions) => this.getExtensionDependencies(extensions,
          { id: container.extensionId }))
        .then((dependencies) => {
          // pathPrefix already contains 'dynamicLayouts/'
          const basePath = this.getBasePathForLayout(pathPrefix, container);

          const toLoadMap = this.createLayoutMaps(dependencies, basePath);

          const promises = this.loadLayoutMaps(toLoadMap, descriptors);

          return Promise.all(promises)
            .then((all) => {
              const map = {};
              all.forEach((loadedInfo) => {
                if (loadedInfo) {
                  map[loadedInfo.name] = map[loadedInfo.name] || {};
                  map[loadedInfo.name][loadedInfo.type] = loadedInfo.contents;

                  // also keep a map of 'paths' for each object
                  // eslint-disable-next-line no-underscore-dangle
                  map[loadedInfo.name]._extInfo = map[loadedInfo.name]._extInfo || { paths: {} };
                  // eslint-disable-next-line no-underscore-dangle
                  map[loadedInfo.name]._extInfo.paths[loadedInfo.type] = loadedInfo.loaderPath;
                }
              });

              // now convert the map to a list

              const list = [];
              Object.keys(map).forEach((name) => {
                list.push(Object.assign({ name }, map[name]));
              });
              return list;
            })
            .catch((err) => {
              // for now, catch the error, and return an empty array
              this.log.error('Problem loading layout extensions, returning no extensions', err);
              return [];
            });
        });
    }

    /**
     * Creates an ExtensionServices Object for the application extension, and creates a name/file map
     * from the contents of the extension.
     *
     * @param {String} extensionId
     * @param {Object} options the standard options used to construct a Services object.
     *
     * @returns {Promise}
     */
    loadServicesModel(extensionId, options) {
      // populated with the services we can find on the extension manifest
      const extensionServiceMap = {};

      return this.getExtensions()
        .then((extensions) => {
          const ext = extensions.find((ex) => ex.id === extensionId);
          if (ext) {
            ext.files.forEach((file) => {
              let name;
              let path;

              const match = file.match(this.constructor.serviceRegex);
              // [0] is the whole match, [1] is the first (and only) group
              if (match) {
                path = match[0];
                name = match[1];
              }

              if (name && path) {
                // we need to check if the extension has an explicit serviceFileMap declaration to ensure we are
                // not replacing it
                const declaredPath = options && options.serviceFileMap && options.serviceFileMap[name];
                if (!declaredPath) {
                  extensionServiceMap[name] = path;
                } else if (declaredPath !== path) {
                  // it would be weird to declare a path for a service that doesn't match its name,
                  // if one that did match its name already existed.
                  this.log.warn('Extension', extensionId, 'contains service metadata ', path,
                    '. The declared file will be used instead: ', declaredPath);
                }
              }
            });
          } else {
            // this should never happen
            this.log.warn('Unable to find extension services for extension, continuing: ', ext);
          }
          return this.findCatalog(ext);
        })
        .then((catalogPath) => {
          const optionsClone = Object.assign({ extensionServiceMap }, options);

          if (catalogPath) {
            optionsClone.extensions = optionsClone.extensions || {};
            optionsClone.extensions.catalogPaths = {
              [extensionId]: catalogPath,
            };
          }

          // ExtensionServices need to be loaded later than this module because
          // it forces JET to load before ojL10n is setup in ConfigLoader
          return Utils.getResource('vb/private/services/extensionServices')
            .then((ExtensionServices) => new ExtensionServices(optionsClone));
        });
    }

    /**
     * if there is a catalog.json in self/, returns the vx-mapped path to the file
     * ex:  vx/ext2/self/services/catalog.json
     * @param {Object} extension
     * @returns {string|undefined}
     */
    findCatalog(extension) {
      let found;
      extension.files.some((file) => {
        if (this.constructor.catalogRegex.test(file)) {
          found = `${Constants.EXTENSION_PATH}${extension.id}/${file}`;
        }
        return !!found;
      });
      return found;
    }

    /**
     * iterate all extension files, grouping layout files by path prefix, then with extension as key
     *
     * {
     *   'dynamicLayouts/base/foo': {
     *     'ExtA': {
     *       id: 'ExtA',
     *       name: 'ExtA/1.0',
     *       files: ['layout-x.json']
     *     }
     *   },
     *   'ExtB': {
     *     id: 'ExtB',
     *     name: 'ExtB/1.0.1',
     *     files: ['layout-x.json', 'layout-x.js']
     *   }
     * }
     * @param {Array} extDependencies a graph of extension dependencies
     * @param {String} basePath
     * @returns {Object}
     * @private
     */
    createLayoutMaps(extDependencies, basePath) {
      const toLoadMap = {};
      extDependencies.forEach((dependency) => {
        const { extension } = dependency;
        const files = extension.files || [];

        // need to know if the requirejs 'vx' mapping is absolute, because requirejs will
        // need the '.js' extension for sources when it is absolute
        const baseUrlDef = extension.baseUrlDef;

        // create a map of paths (without filename) to a list of files
        // Do an exact match without filename, so /foo/bar/leads matches /foo/bar/leadslayout-x.json,
        // but does NOT match /foo/bar/leads/child/address/layout-x.json
        // This basePath can come from multiple places, including legacy declaration; so add slash just in case.
        const baseURI = new URI(Utils.addTrailingSlash(basePath));
        const matchingFiles = files.filter((file) => baseURI.equals(new URI(file).filename('')));

        matchingFiles.forEach((file) => {
          const parts = file.split('/');
          const filename = parts.pop();
          const key = parts.join('/');

          toLoadMap[key] = toLoadMap[key] || {};
          toLoadMap[key][extension.id] = toLoadMap[key][extension.id] || {
            id: extension.id,
            name: extension.id,
            baseUrlDef,
            files: [],
          };
          toLoadMap[key][extension.id].files.push(filename);
        });

        // recursively look up files from the dependent extensions
        const depBasePath = this.getBasePathForLayout(basePath, { extensionId: extension.id });
        Object.assign(toLoadMap, this.createLayoutMaps(dependency.dependencies, depBasePath));
      });

      return toLoadMap;
    }

    /**
     * given a map created from createLayoutMaps, get an array of promises for all the layout resources.
     * The promise resolves with an array:
     * [{
     *     name,      // extension id
     *     contents,  // file contents
     *     pathKey,   // the path prefix whose layouts we were asked for
     *     type,      // data/source/template - the contract with the component provider
     *     ext,       // the file extension
     *   }]
     * @param {Object} toLoadMap
     * @param {Array<Object>} descriptors
     * @returns {Array<Promise>}
     * @private
     */
    loadLayoutMaps(toLoadMap, descriptors) {
      // now, iterate the list of files for each path 'key', and create a load Proimse
      const promises = [];
      Object.keys(toLoadMap).forEach((pathKey) => {
        const extInfo = toLoadMap[pathKey];
        // this.log.greenInfo('Loading layout extensions for:', pathKey);

        Object.keys(extInfo).forEach((extKey) => {
          const loadInfo = toLoadMap[pathKey][extKey];

          loadInfo.files.forEach((fileWithExt) => {
            // find the matching descriptor
            const descriptor = descriptors
              .find((desc) => desc.extensionFile && desc.extensionFile.endsWith(fileWithExt));
            // if the file in the manifest is not in the descriptor, we won't include it;
            // it is expected that the desired extension files are all represented by the descriptor.
            let fileName = fileWithExt;

            if (descriptor) {
              // check against the extension manifest first before loading the file
              const filePath = `${pathKey}/${fileName}`;
              const extension = this.getExtensionById(loadInfo.id);
              if (extension) {
                try {
                  extension.fileExists(filePath);
                } catch (error) {
                  this.log.info('No', descriptor.property, 'for', fileName, 'skipping.');
                  return null;
                }
              }

              // if loading an individual JS file, we need to strip off .js
              if (descriptor.removeExtRelative) {
                fileName = Utils.removeFileExtension(fileName);
              }

              const loaderPath = `${Constants.EXTENSION_PATH}${loadInfo.id}/${pathKey}/${fileName}`;
              const promise = descriptor
                .extnLoader(loaderPath)
                .then((contents) => ({
                  name: loadInfo.name,
                  contents,
                  pathKey, // the path up to the filename
                  loaderPath,
                  type: descriptor.property, // name for the property passed to the components
                }))
                .catch((e) => {
                  // don't stop on the first failure, return what we can
                  this.log.error('Problem loading layout extensions, skipping:', fileWithExt, 'error:', e);
                  return null;
                });
              // resolve each promise with the contents, and the full name
              promises.push(promise);
            }
          });
        });
      });

      return promises;
    }

    /**
     * Loads all the translation extensions for a specific Bundle given its path.
     * The default implementation which is used by v1 does not support translation extensions so returns
     * an empty Promise.  The v2 implementation in extensionRegistry.js retrieves the translation extensions for
     * the bundle
     * @param  {String} path the path of the Bundle Definition for which we are looking for extensions
     * @param  {Object} bundleDefinition the bundle for which the extensions are being loaded
     * @return {Promise<Array>} a promise to an array of Bundle Extension objects
     */
    // eslint-disable-next-line no-unused-vars
    loadTranslationExtensions(path, bundleDefinition) {
      return Promise.resolve([]);
    }

    /*
     * Retrieve a map of all extensions that define translation bundles.
     * The default implementation which is used by v1 does not understand translations bundles
     * so returns an empty map. The v2 implementation in extensionRegistry.js retrieves
     * a Map of extensions that define a translation bundle.
     * @return {Promise<Map<string,object>>} map of extId to extension for all that define a translation bundle
     */
    getTranslations() {
      return Promise.resolve([]);
    }
  }

  return BaseExtensionRegistry;
});

