'use strict';

// use by requirejs to combine service worker modules into a single file
(function () {

  const config = {
    map: {
      '*': {
        main: 'vbsw/main',
      },
    },
  };

  requirejs.config(config);
}());

define("vbsw/main", function(){});

