'use strict';

define('vb/private/services/boss/responseTransforms',[], () => {
  /**
   * Response Transforms
   */
  class ResponseTransforms {
    /**
     * paginate response transform
     * @param configuration the Response object
     * @returns {Object}
     */
    static paginate(configuration/* , options, context */) {
      const trans = {};

      if (configuration.body) {
        const responseBody = configuration.body;
        if (responseBody.estimateTotalResults >= 0) {
          trans.totalSize = responseBody.estimateTotalResults;
        }
        trans.hasMore = responseBody.hasMore;
      }

      return trans;
    }

    /**
     * A function to create a Request transform object
     *
     * @returns {{paginate: *}} an object representing the Request transforms
     */
    static toObject() {
      return {
        paginate: ResponseTransforms.paginate,
      };
    }
  }

  return ResponseTransforms;
});

