'use strict';

define('vb/helpers/componentFinder',['vb/private/log'], (Log) => {
  const logger = Log.getLogger('/vb/helpers/componentFinder');


  // removing this; see JET-20231. unnecessary with modern custom elements/CCAs,
  // and does not work with new <oj-module> currently
  // this may break use with older data-bind JET components, where the 'bound' element is not the
  // element where the methods are exposed.

  // function getCompWithCheck(element) {
  //   if (element) {
  //     try {
  //       const component = oj.Components.getComponentElementByNode(element);
  //       if (!component) {
  //         logger.info(`no component found for element: '${element}`);
  //       }
  //       return component;
  //     } catch (e) {
  //       const detail = element.id ? `id = ${element.id}` : `${element}`;
  //       logger.error('cannot find a JET component for the found element. ' +
  //         `Consider using document.getElementById/querySelector, or similar: (${element.tagName} ${detail})`);
  //     }
  //   }
  //   return null;
  // }


  /**
   * now, this just wraps document.getElementById/Name.  see (commented) getCompWithCheck above.
   *
   * OLD DESCRIPTION
   * wrapper for document.getElementById / oj.Components.getComponentElementByNode
   *
   * This is useful for finding JET components
   * Users have scope access to this via the variable: $page.components
   *
   * @deprecated use document.getElementById, document.querySelector, instead.
   */
  class ComponentFinder {
    /**
     * @param id id of the component element
     * @returns {Element}
     */
    static byId(id) {
      const element = document.getElementById(id);
      if (!element) {
        logger.info('unable to find element by id:', id);
      }

      // return getCompWithCheck(element);
      return element;
    }

    /**
     *
     * @param selector to find the component element
     * @returns {Element}
     */
    static bySelector(selector) {
      const element = document.querySelector(selector);
      if (!element) {
        logger.info('unable to find element by selector:', selector);
      }

      // return getCompWithCheck(element);
      return element;
    }
  }

  return ComponentFinder;
});

