'use strict';

define('vb/action/builtin/resetVariablesAction',['vb/action/action', 'vb/action/builtin/assignVariablesAction', 'vb/private/utils',
  'vb/private/log'], (Action, AssignVariablesAction, Utils, Log) => {
  const logger = Log.getLogger('/vb/action/builtin/resetVariablesAction');

  /**
   * This action can be used to reset a list of variables to their default values. For examples:
   *
   * parameters: {
   *   "variables": ["$page.variables.var1", "$page.variables.var2"]
   * }
   *
   * Note: If a single variable is provided, it will be treated as an array implicitly.
   *
   * Each expression in the variables array has to resolve to variable or a variable's property if it's a structure.
   * It has to be prefixed with one of the following: $application.variables, $page.variables, $chain.variables,
   * $variables and followed by a variable name or a path to a variable property. For example:
   *
   * $application.variables.a
   * $page.variables.a.b
   * $variables.a.b.c (which is shorthand for $chain.variables.a.b.c)
   *
   */
  class ResetVariablesAction extends AssignVariablesAction {
    constructor(id, label) {
      super(id, label);
      this.log = logger;
    }
    /**
     * @param parameters
     * @returns on success, Outcome {name:"success"}. on failure, throws the first caught exception
     */
    perform(parameters) {
      let exception;
      let variables = parameters.variables || [];

      // Implicitly treat the single variable as an array
      if (!Array.isArray(variables)) {
        variables = [variables];
      }

      variables.some((variable) => {
        try {
          const resetOption = ResetVariablesAction.RESET_OPTION.TO_DEFAULT;
          const targetInfo = this.analyzeTargetExpr(variable,
            { resetOption, availableContexts: this.availableContexts });

          // For now, we need to assign the root value to the variable to trigger a write to the store.
          // TODO: remove this code once we support using the assign action as the reducer for the redux store
          const tRValue = targetInfo.rootValue;
          this.log.info(this.constructor.name, this.id, 'resetting variable', variable, 'to', tRValue);
          targetInfo.rootObj[targetInfo.rootPropName] = tRValue;
          return false; // keep calling some()
        } catch (e) {
          // let the parent logger get this, so it actually has the action id
          // this.log.error(`Error executing action (${this.actionId})`, e);
          exception = e;
          return true; // terminate the some()
        }
      });

      // this should just throw the error
      // return failed ? Action.createOutcome('failure', exception) : Action.createOutcome('success');
      if (exception) {
        throw exception;
      }
      return Action.createSuccessOutcome();
    }
  }

  return ResetVariablesAction;
});


