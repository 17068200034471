'use strict';

define('vbsw/private/plugins/csrfTokenHandlerPlugin',['vbsw/api/fetchHandlerPlugin', 'vbsw/private/utils'], (FetchHandlerPlugin, Utils) => {
  const CSRF_TOKEN_HEADER_NAME = 'X-appbuilder-client-id';
  const INVALID_CSRF_TOKEN_HEADER_NAME = 'X-invalid-appbuilder-client-id';


  /**
   * Handler plugin for handling CSRF tokens.
   */
  class CsrfTokenHandlerPlugin extends FetchHandlerPlugin {
    constructor(context, params = {}) {
      super(context);
      this.vbCsrfToken = params.csrfToken;

      // eslint-disable-next-line max-len
      const reg = (params && params.regex) ? params.regex : Utils.getRegexForAppScope(this.fetchHandler.scope, this.fetchHandler.config);

      this.scopeRegEx = new RegExp(reg);

      // used to skip requests containing base url token since their responses can be cached by the browser
      // and we don't want to be using the CSRF tokens from these cached responses since they can be stale
      this.versionIdRegEx = params.versionId ? new RegExp(params.versionId) : null;
    }

    // used by unit tests
    static get csrfTokenHeader() { return CSRF_TOKEN_HEADER_NAME; }
    static get invalidCsrfTokenHeader() { return INVALID_CSRF_TOKEN_HEADER_NAME; }

    /**
     * Get the CSRF token.
     *
     * @returns {Promise<String>}
     */
    getCsrfToken() {
      // first try loading the token from the cache
      if (!this.vbCsrfToken) {
        return this.stateCache.get(CSRF_TOKEN_HEADER_NAME).then((token) => {
          this.vbCsrfToken = token;
          return token;
        });
      }

      return Promise.resolve(this.vbCsrfToken);
    }

    /**
     * Refresh the CSRF token from a response.
     *
     * @param response the response from which to get the latest CSRF token
     * @returns {Promise}
     */
    refreshCsrfToken(response) {
      const headers = response.headers;
      const token = headers.get(CSRF_TOKEN_HEADER_NAME);

      if (token && token !== this.vbCsrfToken) {
        this.vbCsrfToken = token;

        // cache the token
        return this.stateCache.put(CSRF_TOKEN_HEADER_NAME, token);
      }

      return Promise.resolve();
    }

    /**
     * Append the CSRF token to the header.
     *
     * @param request the request to which to append the CSRF token
     * @returns {Promise}
     */
    handleRequestHook(request) {
      // only append the CSRF token if the request url matches the scope
      if (request.url.match(this.scopeRegEx)
        && (this.versionIdRegEx ? !request.url.match(this.versionIdRegEx) : true)) {
        return this.getCsrfToken()
          .then((token) => {
            request.headers.set(CSRF_TOKEN_HEADER_NAME, token);
          });
      }

      return Promise.resolve();
    }

    /**
     * Check the response if we need to refresh the CSRF token and retry the request if we have an invalid token.
     *
     * @param response the response to check for invalid CSRF token and get new token
     * @returns {Promise.<Boolean>}
     */
    handleResponseHook(response, origRequest, request) {
      // only check for 403 if the original request url matches the scope
      if (request.url.match(this.scopeRegEx)
        && (this.versionIdRegEx ? !request.url.match(this.versionIdRegEx) : true)) {
        if (response.status === 403 && response.headers.get(INVALID_CSRF_TOKEN_HEADER_NAME)) {
          // get the new CSRF token from the response and retry
          return this.refreshCsrfToken(response).then(() => true);
        }

        if (response.ok) {
          // refresh to keep the CSRF token up-to-date
          return this.refreshCsrfToken(response).then(() => false);
        }
      }

      return Promise.resolve(false);
    }
  }

  return CsrfTokenHandlerPlugin;
});

