/* eslint-disable prefer-object-spread */

'use strict';

define('vbc/private/trace/spanContext',[], () => {
  class SpanContext {
    /**
     * @param startSpanOptions a function that returns start span options object
     * @returns {SpanContext} span context for chaining
     */
    addStartSpan(startSpanOptions) {
      this.startSpan = startSpanOptions;
      return this;
    }

    /**
     * @param endSpanOptions a function that returns end span options object
     * @returns {SpanContext} span context for chaining
     */
    addEndSpan(endSpanOptions) {
      this.endSpan = endSpanOptions;
      return this;
    }

    /**
     * @param operationName an operation name for the start span
     * @returns {SpanContext} span context for chaining
     */
    addOperationName(operationName) {
      this.operationName = operationName;
      return this;
    }

    /**
     * @param error an error for the end span
     * @returns {SpanContext}
     */
    addError(error) {
      this.error = error;
      return this;
    }

    /**
     * Adds a function to execute within a span() call
     * @param {} fn Function to execute
     */
    addSpanFunction(fn) {
      this.fn = fn;
      return this;
    }

    /**
     * @returns {Object | undefined} valid start span options or undefined
     */
    startSpanOptions() {
      let startSpanOptions;
      if (typeof this.startSpan === 'function') {
        startSpanOptions = this.startSpan();
        if (this.operationName) {
          startSpanOptions = Object.assign({}, this.startSpan(), { operationName: this.operationName });
        }
      }
      // start span options must have operationName set
      return (startSpanOptions && startSpanOptions.operationName) ? startSpanOptions : undefined;
    }

    /**
     * Returns function to execute within a span() call
     */
    spanFunction() {
      return this.fn;
    }

    /**
     * @returns {Object | undefined} end span options
     */
    endSpanOptions() {
      let endSpanOptions;
      if (typeof this.endSpan === 'function') {
        endSpanOptions = this.endSpan();
        if (endSpanOptions && this.error) {
          endSpanOptions = Object.assign({}, endSpanOptions, { error: this.error });
        }
      }
      return endSpanOptions;
    }
  }
  return SpanContext;
});

