'use strict';

define('vb/private/types/utils/jsonDiffer',[
  'vb/private/utils',
  'vb/private/types/dataProviderConstants',
  'vb/private/types/utils/dataProviderUtils',
  'jsondiff'],
(Utils, DpConstants, DataUtils, JsonDiffPlugin) => {
  /**
   * @see https://github.com/benjamine/jsondiffpatch/blob/master/docs/deltas.md
   * @type {string}
   */
  const JSONDIFFPATCH_ARRAY = '_t'; // _t: 'a', indicates type of object generally, 'a' means array
  const MAGIC_NUMBER_DELETE_OP = 0;

  const globalJsonDiff = JsonDiffPlugin.create();
  /**
   * supports processing deltas between old and new values that are arrays. values that are
   * objects are currently not supported.
   *
   */
  class JsonDiffer {
    /**
     * @param {string|Array<string>} idAttribute value of the primary key attribute
     */
    constructor(idAttribute) {
      this.customPlugin = this.customPlugin || JsonDiffer.setupPlugin();
      this.idAttribute = idAttribute;
    }

    /**
     * Uses jsonDiffpatch plugin to determine delta between old and new value and builds a payload
     * similar to what the DataProvider event expects.
     *
     * @param {Array} oldValue old value of the ADP data
     * @param {Array} newValue new value of the ADP data
     * @return {Object} event payload Object with add/mutate/delete details, or undefined for
     * these if there are no deltas.
     * @see https://github.com/benjamine/jsondiffpatch/blob/master/docs/deltas.md
     * @see http://jet.us.oracle.com/jsdocs/oj.DataProviderOperationEventDetail.html
     */
    processDeltas(oldValue, newValue) {
      const eventPayload = { detail: {} };
      let deltaWithHash = this.diffWithObjectHash(oldValue, newValue);
      let clonedOldValue = Utils.cloneObject(oldValue);
      const removePayload = {};
      const addPayload = {};

      // deltaWithHash is more accurate in reporting removes and adds at the right index, as both can cause shifts
      // in the array that is falsely reported as a updates in other indexes that were untouched
      if (deltaWithHash && deltaWithHash[JSONDIFFPATCH_ARRAY] === 'a') {
        const mutatedIndices = Object.keys(deltaWithHash).filter((index) => index !== JSONDIFFPATCH_ARRAY);

        if (mutatedIndices.length > 0) {
          // first handle all the removed items
          mutatedIndices.forEach((index) => {
            const deltaItem = deltaWithHash[index]; // index is a string - '<number>' or '_<number>'

            if (Number.isNaN(parseInt(index, 10))
              && Array.isArray(deltaItem) && deltaItem.length === 3 && deltaItem[1] === 0
              && deltaItem[2] === MAGIC_NUMBER_DELETE_OP) {
              // removed items are always in the form -
              // '_1': [ // removed index
              //   previousValue,
              //   0, // newValue noop
              //   0  // MAGIC number to indicate delete
              // ]
              const removedItem = deltaItem[0];
              const removedIndex = parseInt(index.substring(1), 10);
              clonedOldValue[removedIndex] = undefined;
              JsonDiffer.buildDeltaItemKeys(removedItem, this.idAttribute, removedIndex, removePayload);
            }
          });
          if (Object.keys(removePayload).length > 0) {
            eventPayload.detail.remove = removePayload;
          }
        }
      }

      // then process all inserts after the deletes
      clonedOldValue = clonedOldValue.filter((item) => item !== undefined);
      deltaWithHash = this.diffWithObjectHash(clonedOldValue, newValue);
      if (deltaWithHash && deltaWithHash[JSONDIFFPATCH_ARRAY] === 'a') {
        const mutatedIndices = Object.keys(deltaWithHash).filter((index) => index !== JSONDIFFPATCH_ARRAY);
        mutatedIndices.forEach((index) => {
          const deltaItem = deltaWithHash[index]; // index is a string - '<number>' or '_<number>'
          if (!Number.isNaN(parseInt(index, 10)) && Array.isArray(deltaItem) && deltaItem.length === 1) {
            // a new item was inserted at this index
            const newItem = deltaItem[0];
            const addedIndex = parseInt(index, 10);
            addPayload.data = addPayload.data || [];
            addPayload.data.push(newItem); // deltaItem[0] is the new item
            clonedOldValue.splice(addedIndex, 0, newItem);

            JsonDiffer.buildDeltaItemKeys(deltaItem, this.idAttribute, addedIndex, addPayload);

            // TODO: support addBeforeKeys
          }
        });

        if (Object.keys(addPayload).length > 0) {
          eventPayload.detail.add = addPayload;
        }
      }

      // rediff using cloned to rule out implicit array shifts due to deletes and adds, that jsonDiff reports, as
      // real moves
      deltaWithHash = this.diffWithObjectHash(clonedOldValue, newValue);

      // array deltas have '_t' with value 'a' indicating array, in addition to member names
      // indicating array indices that are different)
      if (deltaWithHash && deltaWithHash[JSONDIFFPATCH_ARRAY] === 'a') {
        const mutatedIndices = Object.keys(deltaWithHash).filter((index) => index !== JSONDIFFPATCH_ARRAY);
        if (mutatedIndices.length > 0) {
          const updatePayload = {};

          mutatedIndices.forEach((index) => {
            const deltaItem = deltaWithHash[index];
            if (typeof deltaItem === 'object' && !Array.isArray(deltaItem)) {
              // properties in an item at index was mutated
              const updatedIndex = parseInt(index, 10);
              const updatedItem = newValue[updatedIndex];
              updatePayload.data = updatePayload.data || [];
              updatePayload.data.push(updatedItem);
              JsonDiffer.buildDeltaItemKeys(updatedItem, this.idAttribute, updatedIndex, updatePayload);
            }
          });

          if (Object.keys(updatePayload).length > 0) {
            eventPayload.detail.update = updatePayload;
          }
        }
      }

      return eventPayload;
    }

    /**
     * Builds the keys or indexes for the delta item that represents the mutation.
     * @param deltaItem
     * @param idAttribute
     * @param deltaIndex index in the array where the delta was mutated
     * @param pi
     * @return {{}}
     * @private
     */
    static buildDeltaItemKeys(deltaItem, idAttribute, deltaIndex, pi = {}) {
      const payloadItem = pi;
      const idHelper = DataUtils.getIdAttributeHelper(idAttribute);
      const caps = idHelper.getCapabilities() || {};
      if (idAttribute && caps.getKeys !== 'none') {
        const keySet = deltaItem && Array.isArray(deltaItem)
          ? idHelper.getKeys(deltaItem) : idHelper.getKeys([deltaItem]);
        // TODO: const keyArray = [...keySet]; // need polyfill in IE11 for this to work. So use ES5
        const keyArray = [];
        keySet.forEach((x) => { keyArray.push(x); });
        //
        payloadItem.keys = payloadItem.keys || new Set();
        payloadItem.keys.add(keyArray[0]);
      }

      if (deltaIndex >= 0) {
        // if no keys are provided and there is no idAttribute set, then @index is assumed
        payloadItem.indexes = payloadItem.indexes || [];
        payloadItem.indexes.push(deltaIndex);
      }
      return payloadItem;
    }

    /**
     * Returns the change set between the old and new values. Uses the global options with no object hash
     *
     * See: https://github.com/benjamine/jsondiffpatch/blob/master/docs/deltas.md
     *
     * @private
     * @param oldValue The old value
     * @param newValue The new value
     * @returns {boolean} A delta format for what has changed
     */
    // eslint-disable-next-line class-methods-use-this
    diffWithNoObjectHash(oldValue, newValue) {
      return globalJsonDiff.diff(oldValue, newValue);
    }

    /**
     * Diffs values using the idAttribute and a special object hash. see getPluginOptions
     * @param oldValue
     * @param newValue
     * @return {*}
     */
    diffWithObjectHash(oldValue, newValue) {
      this.customPlugin.options(JsonDiffer.getPluginOptions(this.idAttribute, oldValue, newValue));
      return this.customPlugin.diff(oldValue, newValue);
    }

    static getPluginOptions(idAttribute, oldValue, newValue) {
      return {
        /**
         * array diffing requires special options on the jsondiff plugin and an objectHash that
         * provides the differ a simple way to detect changes between source and target arrays.
         * @param idAttribute
         * @see https://github.com/benjamine/jsondiffpatch/blob/master/docs/arrays.md
         */
        objectHash: (obj) => {
          let valueKey;
          // returns a key value for keyAttributes set to '@value'; caches the valueKey once it's determined for the obj
          const fallBackToValueKey = (() => {
            if (valueKey !== undefined) {
              return valueKey;
            }
            const valueIdHelper = DataUtils.getIdAttributeHelper('@value');
            valueKey = valueIdHelper.getKey(obj);
            return JSON.stringify(valueKey);
          });
          const isObjTopLevel = (Array.isArray(oldValue) && oldValue.indexOf(obj) >= 0)
            || (Array.isArray(newValue) && newValue.indexOf(obj) >= 0);

          // the idAttribute only applies to the top-level object. IOW if object has nested
          //  objects the object hash must be @index always. In order to know whether the
          // object, whose hash is being determined, is a top-level item or not we do a strict
          // equals check of the obj against the oldValue and newValue arrays.
          if (obj && idAttribute && isObjTopLevel) {
            if (typeof idAttribute === 'string' || Array.isArray(idAttribute)) {
              // could be the actual string attribute or keywords @value/@index (default) or an
              // Array of key attributes.
              const idHelper = DataUtils.getIdAttributeHelper(idAttribute);
              const key = idHelper.getKey(obj);

              if (idAttribute === '@value' || Array.isArray(idAttribute)) {
                // it's not common for key to be undefined or null but it's generally an array of values
                return JSON.stringify(key);
              }

              // for @index idAttribute key returns a null value; rather than return the index it's better to
              // switch to using the @value idhelper as jsonDiff provides a cleaner diff
              if (idAttribute === '@index') {
                return fallBackToValueKey();
              }

              // for string idAttribute, key value 0 is possible, where attribute value is a number
              if (!(key === undefined || key === null)) {
                return key;
              }
            }
          }

          return fallBackToValueKey();
        },
        arrays: {
          detectMove: false,
        },
        cloneDiffValues: false,
      };
    }

    /**
     * Sets up the JSONDiff plugin with default (no) options. options are set closer to when we
     * need to compare 2 objects.
     */
    static setupPlugin() {
      return JsonDiffPlugin.create();
    }
  }

  return JsonDiffer;
});

