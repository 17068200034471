'use strict';

define('vb/private/stateManagement/context/actionChainContext',[
  'vb/private/stateManagement/context/containerContext',
  'vb/private/constants',
], (ContainerContext, Constants) => {
  /** @type Object */
  const symbol = Symbol('chain-accesssor');
  // todo: when ActionChain subclasses Container, this should also subclass ContainerContext
  class ActionChainContext {
    /**
     * @param chain
     */
    constructor(chain) {
      Object.defineProperty(this, symbol,
        {
          value: {
            chain,
            scope: chain.scope,
          },
        });
    }

    /**
     * $chain.variables
     * same as ContainerContext, but for ActionChain
     */
    get [Constants.VariableNamespace.VARIABLES]() {
      return this[symbol].scope.variableNamespaces.variables;
    }

    /**
     * $chain.metadata
     * same as ContainerContext, but for ActionChain
     */
    get [Constants.VariableNamespace.METADATA]() {
      return this[symbol].scope.variableNamespaces.metadata;
    }

    /**
     * $chain.constants
     * same as ContainerContext, but for ActionChain
     */
    get [Constants.VariableNamespace.CONSTANTS]() {
      return this[symbol].scope.variableNamespaces.constants;
    }

    /**
     * $chain.results
     * same as ContainerContext, but for ActionChain
     */
    get results() {
      return this[Constants.VariableNamespace.VARIABLES][Constants.RESULTS_VARIABLE_KEY];
    }

    // special case, add the getVariable method from Scope
    getVariable(...args) {
      return this[symbol].scope.getVariable(...args);
    }

    static getAvailableContexts(chain, contexts = {}) {
      // todo: when this subclasses ContainerContext, this won't be necessary here
      const availableContexts = ContainerContext.cloneContext.bind(contexts)();

      // We can use "value" instead of "get" here, because expressionContext is always created first.
      Object.defineProperties(availableContexts, {
        $chain: {
          enumerable: true,
          configurable: true,
          value: chain.expressionContext,
        },
        $variables: {
          enumerable: true,
          configurable: true,
          value: chain.expressionContext.variables,
        },
        $metadata: {
          enumerable: true,
          configurable: true,
          value: chain.expressionContext.metadata,
        },
        $constants: {
          enumerable: true,
          configurable: true,
          value: chain.expressionContext.constants,
        },
      });

      return availableContexts;
    }
  }

  return ActionChainContext;
});

