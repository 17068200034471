'use strict';

define('vb/extensions/process/action/builtin/processAction',[
  'vb/action/action',
  'vb/action/builtin/restAction',
], (Action, RestAction) => {
  const context = Object.freeze({ container: Object.freeze({ scopeResolver: Object.freeze([]) }) });

  /**
   * Base class for creating an custom action.
   */
  class ProcessAction extends Action {
    /**
     * Returns the process service endpoint to be used when performing the action type defined by
     * the subclass of ProcessAction.
     *
     * @private
     * @returns string
     */
    static endpoint() {
      throw new Error('Subclass must override');
    }

    /**
     * Returns the type definition that describes the response of this action
     *
     * @private
     * @returns object
     */
    static responseType() {
      throw new Error('Subclass must override');
    }

    /**
     * Returns an object describing the id and description of the RestAction used when
     * performing the action type defined by the subclass of ProcessAction.
     *
     * @private
     * @returns {{id: string, description: string}}
     */
    static restActionIdentification() {
      throw new Error('Subclass must override');
    }

    /**
     * Returns the the ProcessAction outcome which is based on the the RestAction outcome.
     *
     * @private
     * @param restOutcome
     * @return {{result, name}|undefined}
     */
    static computeOutcome(restOutcome) {
      if (!restOutcome) {
        return undefined;
      }

      const result = {
        content: {},
        info: {
          status: restOutcome.result.status,
          headers: restOutcome.result.headers,
        },
      };

      const createFailureOutcome = (detailedSummary, error, payload) => {
        let summary = `Error during ${this.restActionIdentification().id}`;
        if (detailedSummary) {
          const detail = payload && payload.body && typeof payload.body.detail === 'string' && payload.body.detail;
          if (detail) {
            summary = `${summary}\nDetail: ${detail}`;
          }
        }

        const outcome = Action.createFailureOutcome(summary, error, payload);
        outcome.result = Object.assign(result, outcome.result);
        return outcome;
      };

      if (restOutcome.name !== 'success') {
        return createFailureOutcome(true, restOutcome.result.error, restOutcome.result.payload);
      }

      const body = restOutcome.result.body;
      if (Array.isArray(body) || typeof body === 'object') {
        result.content = body;
      } else {
        try {
          result.content = JSON.parse(body);
        } catch (error) {
          return createFailureOutcome(false, error, restOutcome.result);
        }
      }

      result.error = null;
      result.message = { summary: '' };
      return Action.createSuccessOutcome(result);
    }

    /**
     * Sends a request to the endpoint defined by this action's class. This method
     * uses a RestAction and returns the result of invoking restAction.perform.
     *
     * @private
     * @param uriParams the rest action URI parameters
     * @param body the rest action body
     * @param responseType the type definition describing the response
     * @returns {Promise}
     */
    _send(uriParams, body, responseType) {
      const identification = this.constructor.restActionIdentification();

      const restAction = new RestAction(identification.id, identification.description);
      const restParameters = { uriParams, body };

      const type = responseType || this.constructor.responseType();
      if (type) {
        restAction.setContext(context);
        restParameters.responseType = type;
      }

      restParameters.endpoint = this.constructor.endpoint();

      restParameters.headers = { accept: 'application/json' };
      if (body) {
        restParameters.headers['content-type'] = 'application/json';
      }

      return restAction.perform(restParameters)
        .then((outcome) => this.constructor.computeOutcome(outcome));
    }

    /**
     * @param parameters the action parameters
     * @returns {Promise} Outcome {name:"success"}, or {name:"failure"} for error codes, or simply rejects the Promise.
     */
    perform(parameters) {
      return this._send(Object.assign({}, parameters));
    }
  }

  return ProcessAction;
});

