'use strict';

define('vbsw/private/pwa/pwaCacheStrategy',[
  'vbsw/private/pwa/appShell',
  'vbsw/private/cacheStrategy',
  'vbsw/private/pwa/pwaUtils',
  'vbc/private/log',
  'vbsw/private/constants',
], (AppShell, CacheStrategy, PwaUtils, Log, Constants) => {
  const logger = Log.getLogger('/vbsw/private/pwa/pwaCacheStrategy');

  /** @type RequestInit */
  const corsMode = { mode: 'cors' };
  class PwaCacheStrategy extends CacheStrategy {
    constructor(handler) {
      super(handler.config);
      this.handler = handler;
      this.appShell = new AppShell(handler);
      // ignoreSearchOptions can be set to undefined when skipIgnoreSearch is true
      this.ignoreSearchOptions = (handler.config && handler.config.skipIgnoreSearch) ? undefined : {
        ignoreSearch: true,
      };
    }

    getIgnoreSearchOptions(url) {
      return (url.indexOf('?') > 0) ? this.ignoreSearchOptions : undefined;
    }

    /**
     * @returns {Promise<RegExp>} a Promise to a regular expression constructed from string skip cache lookup pattern,
     * if one was specified in appShellCache.json (in skipCacheLookupPattern property).
     */
    getSkipCacheLookupRegEx() {
      return Promise.resolve()
        .then(() => {
          if (!this.skipCacheLookupRegExPromise) {
            this.skipCacheLookupRegExPromise = this.appShell.getSkipCacheLookupPattern()
              .then((skipCacheLookupPattern) => {
                if (skipCacheLookupPattern) {
                  try {
                    logger.info(`PWA: skip cache lookup pattern found: '${skipCacheLookupPattern}'`);
                    const skipCacheLookupRegEx = new RegExp(skipCacheLookupPattern);
                    logger.info(`PWA: skip cache lookup RegEx constructed: ${skipCacheLookupRegEx}`);
                    return skipCacheLookupRegEx;
                  } catch (error) {
                    logger
                      .error(`Could not create skip cache lookup regular expression from '${skipCacheLookupPattern}'`,
                        error);
                  }
                }
                return undefined;
              });
          }
          return this.skipCacheLookupRegExPromise;
        });
    }

    /**
     * Determines if service worker cache lookup should be performed for a given url
     * @param {string} url
     * @returns {Promise<boolean>} a promise that resolves to true for url's for which service worker cache
     * lookup should be performed. Application developers can control which url's fall into this category by
     * adding a skipCacheLookupPattern regular expression pattern in app shell cache. One use case would be to exclude
     * REST calls from service worker cache lookup.
     */
    shouldCheckCache(url) {
      return this.getSkipCacheLookupRegEx()
        .then((skipCacheLookupRegEx) => {
          if (skipCacheLookupRegEx && skipCacheLookupRegEx.test(url)) {
            logger.info(`PWA: skipping cache lookup for ${url}`);
            return Promise.resolve(false);
          }
          return Promise.resolve(true);
        });
    }

    /**
     * @param {string} url the resource url to lookup in the cache
     * @param {string} mode determines whether any optimizations should be performed during cache lookup
     * @returns {Promise<void>} a promise to a cached resource, unless this is a start url and the browser is online.
     * In this case, no cache lookup will be performed so that the latest index.html and consequently vbInitConfig
     * can be picked up.
     */
    checkCache(url, mode) {
      // Due to Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=682677
      // ignoreSearch=true drastically reduces cache lookup performance, so it should only be used when needed -
      // when a resource contains '?' to indicate query string. IgnoreSearch lookup can be skipped altogether by setting
      // vbInitConfig.SERVICE_WORKER_CONFIG.skipIgnoreSearch flag to true
      const options = this.getIgnoreSearchOptions(url);
      // TODO: optimize this so we are not checking state cache for application assets and data requests
      // https://jira.oraclecorp.com/jira/browse/BUFP-25503
      return Promise.resolve()
        .then(() => {
          if (this.handler && this.handler.stateCache) {
            return this.handler.stateCache.getResponse(url, options);
          }
          return undefined;
        })
        .then((response) => {
          if (response) {
            return response;
          }
          return this.getStartUrl()
            .then((startUrl) => {
              // if online, don't return cached version of start url, unless overwritten by LOOKUP_INDEX_MODE mode
              if (startUrl && url.endsWith(startUrl) && (mode !== Constants.LOOKUP_INDEX_MODE)) {
                logger.finer('PWA: checkCache(): skipped cache lookup for', startUrl);
                return undefined;
              }
              if (this.handler.appCache) {
                logger.finer('PWA: checkCache(): cache lookup for', url);
                return this.handler.appCache.getResponse(url, options);
              }
              return undefined;
            });
        })
        .catch((err) => {
          logger.warn('PWA: checkCache(): failed to checkCache for', url, err);
        });
    }

    /**
     *
     * @returns {Promise<string>} a promise to an application's start url, if browser is online, or a promise
     * to undefined if the browser is offline or start url cannot be constructed
     */
    getStartUrl() {
      return Promise.resolve()
        .then(() => {
          if (!PwaUtils.isOnline()) {
            return undefined;
          }
          return PwaUtils.constructRelativeResourcePath(this.config.appPath);
        });
    }

    cacheOnInstall() {
      logger.info('PWA: cacheOnInstall:', this.config);
      return this.appShell.cacheAppShell(this.config)
        .then(() => {
          // reset skipCacheLookupRegExPromise after install
          this.skipCacheLookupRegExPromise = null;
          return undefined;
        });
    }

    /**
     * @returns {Promise<undefined>} a Promise to recreate and update PWA caches in case caches have been cleared that
     * never rejects. The resolved value is undefined.
     */
    cacheOnRequest() {
      return this.detectEmptyCaches()
        .then((empty) => {
          if (empty) {
            logger.warn('PWA: cacheOnRequest:', this.config, ': empty caches detected.');
            return this.handler.openCaches().then(() => this.appShell.cacheAppShell(this.config));
          }
          return undefined;
        })
        .catch((err) => {
          logger.error('PWA: Failed to cacheOnRequest:', err);
        });
    }

    /**
     * @returns {Promise<boolean>} a promise that resolves to true if either state or application cache is empty.
     */
    detectEmptyCaches() {
      return Promise.all([this.handler.appCache.isEmpty(), this.handler.stateCache.isEmpty()])
        .then((results) => results.some((isEmpty) => isEmpty))
        .catch(() => { // eslint-disable-line arrow-body-style
          // ignore, in case of errors, assume caches are empty
          return true;
        });
    }

    /**
     * Dynamic caching is currently only supported for JET content
     * @returns {Promise<Object|undefined>} a promise to a response that was fetched and cached, or undefined, if:
     * - request is not recognized as a JET resource (based on path)
     * - fetch failed
     */
    fetchAndCache(request) {
      return Promise.resolve()
        .then(() => {
          if (PwaUtils.isJetRequest(request.url, this.config)) {
            //
            // JET requests that are result of calling requirejs are issued in no-cors mode, and therefore end up
            // as opaque responses in state cache. Since opaque responses result in cache bloat, as browsers cannot
            // accurately determine response size (see Chrome 7MB limit:
            // https://developers.google.com/web/tools/chrome-devtools/progressive-web-apps#opaque-responses),
            // and JET CDN supports CORS, we need to issue cors mode requests for JET resources.
            //
            let jetRequest = request;
            if (request.mode === 'no-cors') {
              jetRequest = new Request(request.url, corsMode);
            }
            return this._fetchAndCache(jetRequest);
          }
          return undefined;
        })
        .catch((err) => {
          console.log('PWA: Failed to fetchAndCache', request.url, err);
        });
    }

    /**
     * @param {Request} request
     * @returns {Promise<Object>} a promise to fetch a request and cache it in the state cache
     * @private
     */
    _fetchAndCache(request) {
      return fetch(request)
        .then((response) => {
          if (response) {
            logger.finer('PWA: fetchAndCache() request url:', request.url);
            // calling statCache.put ends up modifying response, which used to break for opaque responses from JET CDN
            // JET CDN responses no longer seem opaque, btw, but still there is no reason to modify them
            return this.handler.stateCache.putOpaque(request, response.clone());
          }
          return response;
        });
    }
  }
  return PwaCacheStrategy;
});

