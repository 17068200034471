/* eslint-disable max-classes-per-file */

'use strict';

define('vb/private/stateManagement/switcherRootMixin',[
  'vb/private/stateManagement/router/vbRouter',
  'vb/helpers/mixin',
  'vb/private/stateManagement/switcherFlowMixin',
  'vb/private/stateManagement/switcherAppUiMixin',
  'vb/private/history',
], (VbRouter, Mixin, SwitcherFlowMixin, SwitcherAppUiMixin, History) => {
  /**
   * An implementation of the History class that does not alter the URL
   */
  class SwitcherHistory extends History {
    // eslint-disable-next-line no-unused-vars
    static setUrlParameters(urlParams) {
    }

    // eslint-disable-next-line no-unused-vars
    static addUrlParameter(name, value) {
    }

    // eslint-disable-next-line no-unused-vars
    static setUrlParameter(name, value) {
    }
  }

  class SwitcherRootRouter extends VbRouter.VbRootRouterClass {
    constructor(switcher, switcherElement, fullPath) {
      super(`switcher-${switcherElement.id}`, new VbRouter.HistoryAdapter(`${fullPath}/`));

      this.switcher = switcher;
      this.switcherElement = switcherElement;
      this.history = SwitcherHistory;
    }

    /**
     * Called as a result of a history popstate event
     *
     * @param {Object} details
     */
    handlePopState(details) {
      /**
       * It is important to add a popstate event listener directly because it will be
       * handle after the ojRouter popstate. If we were using popStateCallback in Router
       * it would be executed first and the navigation will be cancelled by ojRouter.
       */
      if (!this.switcher.handlePopState(details)) {
        super.handlePopState(details);
      }
    }

    /**
     * Switcher does not update URL
     */
    updateUrl(newState, replace) {
      this.updateState(null, replace);
    }

    /**
     * Update the browser history with the new state of the switcher
     * Save the page path in the browser history
     * @param  {String}  pagePath the path to the current page
     * @param  {Boolean} replace  a flag to indicate the state should be pushed or replaced
     * @return {Promise} a promise that resolve when the browser state is updated
     */
    updateState(pagePath, replace) {
      return Promise.resolve().then(() => {
        if (!replace) {
          this.history.pushState();
        }

        if (pagePath) {
          this.history.setPagePath(pagePath);
        }

        this.switcherElement.storeInHistory();

        return this.history.sync();
      });
    }
  }
  /**
   * Implementation of the root page for a switcher element
   */
  const SwitcherRootMixin = (superclass) => class extends superclass {
    constructor(switcherElement, switcher) {
      super(`switcher-${switcherElement.id}`, switcher.parent, '', 'SwitcherPage');
      this.switcher = switcher;

      // Replace the moduleConfig of the page with the one used by the ojModule of the switcher
      // Can be enumerated but is readonly and cannot be replaced
      Object.defineProperty(this, 'moduleConfig', {
        get: () => switcher.moduleConfigs[switcherElement.index],
        enumerable: true,
      });

      // This switcher root page does not have a definition, so nothing to initialize
      this.initializePromise = Promise.resolve();

      this._rootRouter = new SwitcherRootRouter(this.switcher, switcherElement, this.fullPath);
    }

    get rootPage() {
      return this;
    }

    get rootRouter() {
      return this._rootRouter;
    }

    /**
     * Determines if the page page has been initialized. This is only true after
     * the root has been navigated successfully the first time and the moduleConfig
     * observable is set to the leaf page.
     *
     * @return {boolean}  True if initialized, False otherwise.
     */
    isInitialized() {
      return this.moduleConfig().pagePath;
    }

    createRouter() {
      return this._rootRouter;
    }

    createFlow(id) {
      if (this.application.doesAppUiExist(id)) {
        const extension = this.application.appUiInfos.getExtension(id);
        const AppUiClass = this.application.constructor.AppUiClass;
        return new (Mixin(AppUiClass).with(SwitcherAppUiMixin))(extension, extension.getAppUiInfo(id), this);
      }

      const FlowClass = this.parent.getParentFlow().constructor.FlowClass;

      // Construct a Flow (either Flow or PackageFlow depending the environment) with the behavior
      // required for working in the switcher component.
      return new (Mixin(FlowClass).with(SwitcherFlowMixin))(id, this);
    }

    /**
     * Retrieve the leaf page instance when navigating between 2 App UIs.
     * See other implementation in application.js
     *
     * Called by navigateOperation
     *
     * @param  {String}  appUiId the id of the App UI to navigate to
     * @param  {Object}   navContext including options with navigate action parameters
     * @return {Promise}  a Promise resolving to the leaf page instance
     */
    getLeafPageInstanceFromAppUiId(appUiId, navContext) {
      // Navigation to an app-flow is done by the root page
      return this.loadContainer(appUiId, navContext)
        .then((appUi) => appUi.loadDefaultPageOrFlow(navContext));
    }

    getParentFlow() {
      // Switcher root page parent is always the page with the switcher component
      return this.parent.getParentFlow();
    }

    buildTitle(title) {
      return this.parent.buildTitle(title);
    }

    // eslint-disable-next-line class-methods-use-this
    hideFromUrl() {
      return false;
    }

    // eslint-disable-next-line class-methods-use-this
    getNavPath() {
      // return this.urlId;
      return '';
    }

    // eslint-disable-next-line class-methods-use-this
    isDefaultFlowId() {
      return true;
    }

    // eslint-disable-next-line class-methods-use-this
    isNavigable() {
      return true;
    }

    dispose() {
      this._rootRouter.dispose();
      super.dispose();
    }
  };

  return SwitcherRootMixin;
});

