'use strict';

define('vb/action/builtin/callComponentMethodAction',['vb/action/action', 'vb/private/log', 'ojs/ojcomponentcore', 'vb/private/utils'], (Action,
  Log, ojCC, Utils) => {
  const logger = Log.getLogger('/vb/private/stateManagement/callComponentMethodAction');

  class CallElementMethod extends Action {
    constructor(id, label) {
      super(id, label);
      this.context = null;
    }

    /**
     * @param {Object} parameters
     * @param {Element} parameters.component
     * @param {string} parameters.method
     * @param {Array} parameters.params
     * @param {String} parameters.selector
     *
     * @returns {Object} outcome
     */
    // eslint-disable-next-line class-methods-use-this
    perform(parameters) {
      let element = null;
      if (parameters.selector) {
        const container = this.context.container;
        if (container && container.parentOJModuleNode) {
          const parentOjModule = container.parentOJModuleNode;
          element = parentOjModule.querySelector(parameters.selector);
        }
        if (element === null) {
          logger.warn('Target element with selector', parameters.selector,
            'is not present inside the container', container.id,
            '. It is recommended that the selector provided with the action be locatable within the current container.',
            'Attempting to search for target element outside the container.');
          element = document.querySelector(parameters.selector);
        }
      } else {
        element = parameters.component;
      }

      const methodName = parameters.method;
      const params = parameters.params || [];

      if (element && methodName) {
        // make sure the parameters are plain objects instead of proxies
        const clonedParams = [];
        if (params) {
          Utils.cloneObject(params, clonedParams);
        }

        let returnValue;
        try {
          returnValue = ojCC.callComponentMethod(element, methodName, ...clonedParams);
        } catch (e) {
          return Action.createFailureOutcome('Error during CallElementMethod', e);
        }

        // handle both sync and async methods
        return Promise.resolve(returnValue)
          .then((result) => Action.createSuccessOutcome(result))
          .catch((e) => Action.createFailureOutcome('Error during CallElementMethod', e));
      }

      const msg = `Missing parameter, element='${element}', methodName='${methodName}'`;
      logger.error(msg);

      return Action.createFailureOutcome('Error during CallElementMethod', new Error(msg)); // default
    }

    setContext(context) {
      this.context = context;
    }
  }

  return CallElementMethod;
});

