'use strict';

define('vb/private/stateManagement/context/containerContext',['vb/private/constants', 'vb/private/translations/translationContext',
], (Constants, TranslationContext) => {
  // A map to keep a reference to the bundle after they are initialized
  const bundleContextMap = new WeakMap();
  // A map to keep a reference to the baseContext after they are initialized
  const baseContextMap = new WeakMap();

  /**
   * a 'base' class for defining a set of properties available for binding, expression, and action contexts.
   * Intended to be subclassed for each of the various container types.
   *
   *
   *                                      ContainerContext
   *                                            |
   *                 +--------------------------+----------------------------+
   *                 |                          |                            |
   *             FlowContext               PageContext                ExtensionContext
   *                 |                                                       |
   *      +----------+-----------+                     +---------------------+--------------------+
   *      |                      |                     |                     |                    |
   * ApplicationContext    AppPackageContext    FlowExtensionContext  PageExtensionContext  LayoutExtensionContext
   *
   */
  class ContainerContext {
    /**
     * base class for subset of scope properties,
     * plus possibly some extra (like 'responsive', 'translations', etc) that doesn't belong in the scope.
     *
     * this is meant to create the bindable properties that are visible to the author.
     *
     * this class is intended to be subclassed.
     *
     * All namesakes like $flow, $application, $page, etc, will be subclasses of ContainerContexts.
     *
     * One 'weird' addition is getVariable(), used by AssignVariableAction.  When creating a top-level
     * namesake ContainerContext, this method is added to allow access to the Scope variables. (This could
     * go in ActionHelpers, but I wasn't sure if we should expose this to application authors creating custom Actions).
     *
     * @param {Container} container
     */
    constructor(container) {
      const propDescriptors = {
        application: {
          get() {
            let app;
            // Depending if the container is in a App UI or not, the content of the application
            // object is different. When in an App UI, the application object is only what is
            // defined in the interface.
            const appPackage = container.package;
            if (appPackage) {
              const extensionApplication = appPackage.application.extensions[appPackage.extensionId];
              app = extensionApplication.expressionContext.base;
            } else {
              app = container.application.expressionContext;
            }

            // and redefine the property as a value for immediate access on the next get
            Object.defineProperty(this, 'application', {
              value: app,
              enumerable: true,
              configurable: true,
            });

            return app;
          },
          enumerable: true,
          configurable: true,
        },
        /**
         * baseContext is available on the expressionContext of any container but it
         * is only exposed by extensions of this container.
         * baseContext is the $base scope on extensions of this container
         * @type {BaseContext}
         */
        baseContext: {
          get() {
            let baseContext = baseContextMap.get(this);
            if (!baseContext) {
              // Lazily create the BaseContext because it's only needed when
              // an extension is using an expression with $base
              baseContext = new (this.constructor.BaseContextType)(container);
              baseContextMap.set(this, baseContext);
            }

            return baseContext;
          },
          enumerable: true,
          configurable: true,
        },
        listeners: {
          get() {
            return container.eventListeners;
          },
          enumerable: true,
          configurable: true,
        },
        functions: {
          get() {
            return container.functions;
          },
          enumerable: true,
          configurable: true,
        },
        chains: {
          get() {
            return container.chains;
          },
          enumerable: true,
          configurable: true,
        },
        enums: {
          get() {
            return container.enums;
          },
          enumerable: true,
          configurable: true,
        },
        [Constants.TRANSLATIONS_CONTEXT]: {
          /**
           * Accessor for the translations
           * The container context .translations exposes the V1 Strings from the BundlesModel.
           * We expose V2 Strings only in the $translations EL context (as well as V1 Strings)
           * @type {Object}
           */
          get() {
            let bundleContext = bundleContextMap.get(this);
            if (!bundleContext) {
              bundleContext = TranslationContext.createV1Context(container.bundles);
              bundleContextMap.set(this, bundleContext);
            }

            return bundleContext;
          },
          /**
           * Setter used by action chain tester to mock translation bundles.
           *
           * @param {Object} bundleContext mock bundle
           */
          set(value) {
            const vbConfig = window.vbInitConfig || {};

            // only override if we are in action chain test mode
            if (vbConfig.TEST_MODE === Constants.TestMode.ACTION_CHAIN) {
              bundleContextMap.set(this, value);
            }
          },
          enumerable: true,
          configurable: true,
        },
      };

      /**
       * Accessor for the variables, metadata and constants namespaces
       */
      [Constants.VariableNamespace.VARIABLES,
        Constants.VariableNamespace.METADATA,
        Constants.VariableNamespace.CONSTANTS,
      ].forEach((nameSpace) => {
        propDescriptors[nameSpace] = {
          get() {
            const { scope } = container;
            // protect again variables not be being initialized
            return scope && scope.variableNamespaces[nameSpace];
          },
          enumerable: true,
          configurable: true,
        };
      });

      Object.defineProperties(this, propDescriptors);

      // special case, add the getVariable method from Scope
      this.getVariable = (...args) => {
        const { scope } = container;

        return scope && scope.getVariable(...args);
      };
    }

    dispose() {
      const baseContext = baseContextMap.get(this);
      if (baseContext) {
        baseContext.dispose();
        baseContextMap.delete(this);
      }

      if (bundleContextMap.has(this)) {
        bundleContextMap.delete(this);
      }

      // Delete every property because components sometime holding onto those properties preventing
      // the parent object from getting garbage collected.
      // eslint-disable-next-line guard-for-in, no-restricted-syntax
      for (const prop in this) { delete this[prop]; }
    }

    /**
     * @param {Object} container
     *
     * this method is static because we need to be able to create the available contexts BEFORE
     * the individual XXXContext objects are creates, because we base the viewModel on the availableContexts object.
     *
     * these static methods still use class hierarchy for inheriting property accessors.
     *
     * @returns {Object}
     */
    static getAvailableContexts(container) {
      // using defineProperties, and accessors for multiple reasons:
      // - make these un-settable
      // - allow us to create the ViewModel from this object, before calling run() (for Page in particular)
      // - for Page, something needs $flow and $application before Page.run(), so we don't actually
      // have an expressionContext the first time getAvailableContexts() is called - we don't want to create the
      // Scope before then. So, rather than create a $flow/$application only version of the available contexts,
      // using accessors allows the 'value' for $page (and its properties) be created after this
      // $todo: see if we can re-work the calls to getAvailableContexts() before run()
      const availableContexts = {
        get $global() {
          return container.expressionContext.application;
        },

        get $application() {
          return container.expressionContext.application;
        },

        get $variables() {
          return container.expressionContext[Constants.VariableNamespace.VARIABLES];
        },

        get $enums() {
          return container.expressionContext.enums;
        },

        get $metadata() {
          return container.expressionContext[Constants.VariableNamespace.METADATA];
        },

        get $constants() {
          return container.expressionContext[Constants.VariableNamespace.CONSTANTS];
        },

        get $chains() {
          return container.expressionContext.chains;
        },

        get $functions() {
          return container.expressionContext.functions;
        },

        get $listeners() {
          return container.expressionContext.listeners;
        },

        get $imports() {
          // imports are not exposed on the container context, instead only export '$imports' to limit scope to current
          // container. This will prevent '$flow.imports', '$application.imports' explicitly
          return container.imports;
        },

        get [`$${Constants.TRANSLATIONS_CONTEXT}`]() {
          // Get the full translations context (V1 and V2 strings) for exposure in $translations
          const bundleContext = TranslationContext.createContext(container.bundles);

          // and redefine the property as a value for immediate access on the next get
          Object.defineProperty(availableContexts, `$${Constants.TRANSLATIONS_CONTEXT}`, {
            value: bundleContext,
            enumerable: true,
          });

          return bundleContext;
        },
      };

      // make these functions non-enumerable so that the viewModel does not have it
      Object.defineProperties(availableContexts, {
        clone: {
          configurable: true,
          value: ContainerContext.cloneContext.bind(availableContexts),
        },
        addAccessor: {
          configurable: true,
          value: ContainerContext.addAccessor.bind(availableContexts),
        },
      });

      if (container.package) {
        Object.defineProperties(availableContexts, {
          // Redefine $application to point to the App UI context
          $application: {
            enumerable: true,
            configurable: true,
            get() {
              return container.package.expressionContext;
            },
          },
          $base: {
            enumerable: true,
            configurable: true,
            get() {
              return container.expressionContext.baseContext;
            },
          },
          $extension: {
            enumerable: true,
            configurable: true,
            value: {
              get base() {
                return container.expressionContext.baseContext;
              },
              [Constants.PATH_VARIABLE]: container.absoluteUrl,
            },
          },
        });

        // $extension.<extId>.translations
        // $base.translations (if 'base' extension in bundles)
        if (container.bundles) {
          TranslationContext.addExtensionsContexts(container.bundles, availableContexts.$extension);

          const baseStringMap = container.bundles.getExtensionV2StringMap(Constants.ExtensionFolders.BASE);
          if (baseStringMap) {
            Object.defineProperty(availableContexts.$base, Constants.TRANSLATIONS_CONTEXT, {
              enumerable: true,
              configurable: true,
              value: baseStringMap,
            });
          }
        }
      }

      return availableContexts;
    }

    /**
     * this is a regular function, so that 'this' is bindable; shared with ActionChainContexts
     *
     * a simple shallow-ish clone, to handle accessors via property descriptors
     * also creates new arrays at ONLY the top level.
     *
     * good enough for contexts, for now, but requirements could change is contexts need more functionality
     */
    static cloneContext() {
      const cloneObj = {};

      const propDescriptors = {
        clone: {
          enumerable: false,
          value: ContainerContext.cloneContext.bind(cloneObj),
        },
        addAccessor: {
          enumerable: false,
          value: ContainerContext.addAccessor.bind(cloneObj),
        },
      };

      Object.keys(this).forEach((propertyName) => {
        const def = Object.getOwnPropertyDescriptor(this, propertyName);
        if (Array.isArray(def.value)) {
          def.value = def.value.slice();
        }
        propDescriptors[propertyName] = def;
      });

      return Object.defineProperties(cloneObj, propDescriptors);
    }

    /**
     * when the container needs to add something that does not already exist, instead of just defining a property
     * on the object, this is how it should be added.  Makes it discoverable, and debuggable.
     * For example, ForEach action adds a $current
     */
    static addAccessor(contextName, accessor) {
      if (contextName && typeof accessor === 'function') {
        Object.defineProperty(this, contextName, {
          enumerable: true,
          configurable: true,
          get: accessor,
        });
      }
    }
  }

  return ContainerContext;
});

