'use strict';

define('vb/private/stateManagement/stateUtils',[
  'vb/private/constants',
  'vb/binding/expression',
  'vb/private/utils',
  'vb/private/stateManagement/scopeResolver',
  'vb/private/log',
  'acorn',
  'acorn-walk',
], (Constants, Expression, Utils, ScopeResolver, Log, Acorn, AcornWalk) => {
  const log = Log.getLogger('/vb/stateManagement/stateUtils');

  class StateUtils {
    /**
     * Checks whether the variable definition type is an instance first and then an instanceFactory Type.
     * @param variableDef
     * @param scopeResolver
     * @return {*}
     * @private
     */
    static isVariableDefInstanceFactoryType(variableDef, scopeResolver) {
      const constructorType = StateUtils.getVariableDefConstructorFactoryType(variableDef, scopeResolver);
      return constructorType && Utils.isTypeDefInstanceFactory(constructorType);
    }

    /**
     * Returns the constructorType defined on the typedef for the variableDef type, if it exists.
     * @param variableDef
     * @param scopeResolver
     * @return {*}
     */
    static getVariableDefConstructorFactoryType(variableDef, scopeResolver) {
      const isInstanceType = Utils.isInstanceType(variableDef.type);
      const typeInfo = isInstanceType && StateUtils.resolveType(variableDef.type, scopeResolver, true);
      const typeDef = typeInfo && typeInfo.type;
      return typeDef && typeDef.type && typeDef.type.constructorType;
    }

    /**
     * Returns the default value of a variable. It uses the type or the definition or the
     * defaultValue property of the variable definition from the descriptor to determine the default
     * value. What is returned can be an object or a function if the defaultValue property is an
     * expression.
     *
     * @param {string}    variableName   the name of the variable
     * @param {Object}    variableDef    the definition of the variable from the descriptor.
     * @param {ScopeResolver} scopeResolver  the scope resolver object (application, page, ...)
     * @param {Scope}     scope          the scope
     * @param {Object}    completeScopes the entire set of scopes in context
     * @param {string}    namespace      the variable namespace
     * @param {*} callerValue value for the variable provided by external caller
     * @returns {Promise} A promise that resolves to the default value
     */
    static createVariableDefaultValue(variableName, variableDef,
                                      scopeResolver = new ScopeResolver(), scope = null, completeScopes = {},
                                      namespace, callerValue) {
      return Promise.resolve().then(() => {
        const isInstanceFactoryType = StateUtils.isVariableDefInstanceFactoryType(variableDef, scopeResolver);
        const isInstanceType = Utils.isInstanceType(variableDef.type);
        if (isInstanceFactoryType) {
          if (callerValue) {
            // if callerValue is a reference to an instance factory type from a calling scope, then use the reference
            // as is without bothering to build a defaultValue.
            // TODO figure a way to match callerValue ref type with variable type!
            return StateUtils.createNonInstanceTypeDefaultValue(variableName,
              { type: 'any', defaultValue: undefined }, scopeResolver, completeScopes);
          }
          // when dealing with instance factory variable create a noop value temporarily. During variable activate()
          // cycle the real instance will be created
          return StateUtils.createNonInstanceTypeDefaultValue(variableName,
            { type: 'any', defaultValue: variableDef.constructorParams }, scopeResolver, completeScopes);
        }

        if (isInstanceType) {
          return StateUtils.instantiateType(variableName, variableDef, scopeResolver, scope, completeScopes, namespace,
            callerValue)
            .catch(() => {
              throw new Error(`Unknown built-in type: ${variableDef.type}.`);
            });
        }

        return StateUtils.createNonInstanceTypeDefaultValue(variableName, variableDef, scopeResolver, completeScopes);
      });
    }

    /**
     * Returns the default value of a variable or a constant when the type is a not an instance type.
     * It uses the type or the definition or the defaultValue property of the variable or constant definition
     * from the descriptor to determine the default value. What is returned can be an object or a function if the
     * defaultValue property is an expression.
     * @param  {String}        name           the name of the variable or constant
     * @param  {Object}        definition     the definition of the variable or constant from the descriptor
     * @param  {ScopeResolver} scopeResolver  the scope resolver object (application, page, ...)
     * @param  {Object}        completeScopes the entire set of scopes in context
     * @return {*}                            the default value
     */
    static createNonInstanceTypeDefaultValue(name, definition, scopeResolver,
                                             completeScopes) {
      const type = StateUtils.getType(name, definition, scopeResolver);

      // perform a type check on the defaultValue
      const typeCheckInfo = {};
      if (!definition.resolved
        && !StateUtils.typeCheck(name, type, definition, definition.defaultValue, typeCheckInfo)) {
        // typeCheckInfo contains info on all the invalid values
        // TODO: Warn the typeCheckInfo for now to avoid logging the error twice. In the future, we should
        // implement a custom exception that can carry the typeCheckInfo
        log.warn(`${typeCheckInfo.message}:`, typeCheckInfo);
        throw new Error(`${typeCheckInfo.message}.`);
      }

      // build the default value using the type and default value definition
      return StateUtils.buildVariableDefaults(name, completeScopes, type, definition.defaultValue);
    }

    /**
     * Returns an object containing 2 properties, both flat array of expressions given a variable config.
     * - original contains the configured expressions.
     * - function contains expressions that are created as Functions. This is to allow Acron to parse
     * @param varName name of the variable
     * @param config
     * @param expressions
     * @returns {{original: [], functions: []}}
     * @private
     */
    static getDepVarExpressions(varName, config, expressions = { original: [], functions: [] }) {
      const createFunctionFromString = (expression) => {
        let funcStr = '';
        funcStr += `return ${expression};`;

        try {
          return (new Function('injectedContext', funcStr));
        } catch (e) {
          log.warn('Unable to parse expression:', expression, 'set on variable', varName, '. The variable will',
            'not have a default value');

          // The return value is expected to be a function. This makes it so that the code doesn't throw again
          return () => undefined;
        }
      };

      if (!config) {
        return expressions;
      }
      if (Expression.isExpression(config)) {
        // if we are dealing with a stringified JSON object we must locate all inner variables. For that we use a
        // the acorn parser, which does better with parsing a function.
        // Example"{{ {\"test\":{\"foo\":[ { \"lucy\": $constants.ONE, \"fred\": $variables.FRED } ] }}"
        const expression = Expression.getExpression(config);
        const func = createFunctionFromString(expression);

        expressions.original.push(config);
        expressions.functions.push(func);
      } else if (typeof config === 'object') {
        if (!Array.isArray(config)) {
          Object.keys(config).forEach((prop) => StateUtils.getDepVarExpressions(varName, config[prop], expressions));
        } else {
          config.forEach((subProp) => StateUtils.getDepVarExpressions(varName, subProp, expressions));
        }
      }
      return expressions;
    }

    /**
     * Builds a dependency graph of all instance factory variables in current scope.
     * - Walks the JSON for each variable and locates properties containing an expression.
     * - converts the expression to AST to locate the actual variable. builds an array of dependent variables.
     * - later in activate stage this array will be used to lazy load variables in using the dependency order
     *
     * @param variablesDef
     * @param scopeResolver
     * @return {Object} containing 2 properties
     * expressions - Array of (variable) expressions that this variable configuration references
     * variables - Array of Objects representing the referenced variable info; where each Object contains the
     * following properties - scopeName, variableName
     * @see https://astexplorer.net/
     */
    static buildVariablesDependenciesGraph(variablesDef, scopeResolver) {
      const variableDepsMap = {};
      // cache AST trees for expressions that have already been exploded, as an optimization
      const fastASTLookupCache = {};
      // isolate variables that are instance factory types.
      Object.keys(variablesDef).forEach((variableName) => {
        const vDef = variablesDef[variableName];
        const isInstanceFactoryType = StateUtils.isVariableDefInstanceFactoryType(vDef, scopeResolver);
        const config = isInstanceFactoryType ? vDef.constructorParams : vDef.defaultValue;
        const depExprs = StateUtils.getDepVarExpressions(variableName, config);
        if (depExprs && depExprs.original.length > 0) {
          variableDepsMap[variableName] = { expressions: depExprs };
        }
      });

      // for each variable, parse expressions to isolate the ones with dependent variables
      Object.keys(variableDepsMap).forEach((vDepItem) => {
        const currentVarDeps = variableDepsMap[vDepItem];
        const expressions = variableDepsMap[vDepItem].expressions;
        const funcExprs = (expressions && expressions.functions) || [];
        funcExprs.forEach((func, index) => {
          let exprAST;
          const origExpr = expressions.original[index];
          const astCacheForExpr = fastASTLookupCache[origExpr];
          try {
            // we may be dealing with stringified JSON that is quite complex. So turn the expression into a function
            // first before sending to parser.
            exprAST = astCacheForExpr || Acorn.parse(func, { ecmaVersion: 'latest' });
            if (!astCacheForExpr) {
              fastASTLookupCache[origExpr] = exprAST;
            }
            AcornWalk.fullAncestor(exprAST, (node, ancestors) => {
              const scopeName = node.name;
              const processNode = (item, parent, grandParent) => {
                if (item.type === 'Identifier' && (Constants.ALL_SCOPES.indexOf(scopeName) !== -1)) {
                  currentVarDeps.variables = currentVarDeps.variables || [];
                  let parentNode;
                  let entry;
                  if (scopeName !== '$variables') {
                    if (parent && parent.type === 'MemberExpression' && parent.object.name === scopeName
                      && parent.property && parent.property.name === 'variables') {
                      entry = { scopeName };
                      parentNode = grandParent;
                    }
                  } else {
                    entry = {};
                    parentNode = parent;
                  }

                  if (parentNode) {
                    if (parentNode && parentNode.type === 'MemberExpression') {
                      const parentNodeProp = parentNode.property;
                      if (parentNodeProp) {
                        if (parentNodeProp.type === 'Identifier') {
                          entry.variableName = parentNodeProp.name;
                        } else if (parentNodeProp.type === 'Literal') {
                          entry.variableName = parentNodeProp.value;
                        }
                        // are there other cases?
                      }

                      if (entry.variableName) {
                        currentVarDeps.variables.push(entry);
                      }
                    }
                  }
                }
              };

              const reverseWalk = (arr = [], fn) => {
                let l = (arr.length - 1);
                l = l < 0 ? 0 : l;
                while (l) {
                  const parent = arr[l - 1] || null;
                  const grandParent = arr[l - 2] || null;
                  fn(arr[l], parent, grandParent);
                  l -= 1;
                }
              };

              reverseWalk(ancestors, processNode);
            });
          } catch (e) {
            log.warn('Unable to parse expression:', origExpr, 'set on variable', vDepItem, 'using Acorn parser!');
          }
        });
      });
      return variableDepsMap;
    }

    /**
     * Parses a string type with the format <'base'|''|extensionId>/<scope|''>:<name> and returns an object
     * with the following properties:
     *  extId: id of the extension where type is defined (if no extension id is provided, this would be undefined)
     *  scope: name of the scope where type is defined (if no scope is provided, this would be set to 'this')
     *  name: type name
     * @param {String} type referenced type
     * @returns {{ extId: string, scope: string, name: string }}
     */
    static parseType(type) {
      let extId;
      let scope;
      const parsed = Utils.parseQualifiedIdentifier(type, { prefixToken: ':', suffixToken: '' });
      const name = parsed.main;

      if (parsed.prefix) {
        let namespace = parsed.prefix;
        // assume 'base' for the extension id, when it starts with a slash
        if (namespace.startsWith('/')) {
          namespace = `${Constants.ExtensionNamespaces.BASE}${namespace}`;
        }
        const parsedPrefix = Utils.parseQualifiedIdentifier(namespace, { prefixToken: '/', suffixToken: '' });
        extId = parsedPrefix.prefix;
        scope = parsedPrefix.main;
      }

      if (!scope) {
        // no scope is provided, so set it to 'this'
        scope = Constants.THIS_PREFIX;
      }

      return { extId, scope, name };
    }

    /**
     * Retrieves the scope in which the given type is defined.
     * @param {Object} scopeResolver a map of scopes object keyed by scope names
     * @param {String} extensionId the extension id in which the given type is defined
     * @param {String} scopeName the name of scope in which the given type is defined
     * @param {String} typeName name of the type definition
     * @returns {null|*} scope in which the given type is defined or null if the scope is not found
     */
    static retrieveScope(scopeResolver, extensionId, scopeName, typeName) {
      const currentContainer = scopeResolver[Constants.THIS_PREFIX];

      if (!extensionId) {
        // no need to traverse, so retrieve the scope
        // types from the global context should only be accessible to extensions if
        // they are defined in the interface section
        const scope = scopeResolver[scopeName];
        if (scopeName === Constants.GLOBAL_PREFIX && currentContainer.package) {
          return (scope && scope.isInterfaceType(typeName)) ? scope : null;
        }

        return scope;
      }

      const extendedContainer = currentContainer.base;

      if (!extendedContainer) {
        return null;
      }

      if (extensionId === Constants.ExtensionNamespaces.BASE || extensionId === extendedContainer.extensionId) {
        // found the container, retrieve the scope only if the type is defined in the
        // interface section as otherwise extensions should not have access to this type:
        const scope = extendedContainer.scopeResolver[scopeName];
        return (scope && scope.isInterfaceType(typeName)) ? scope : null;
      }

      // now need to traverse the extension hierarchy until we find the match
      return StateUtils.retrieveScope(extendedContainer.scopeResolver, extensionId, scopeName, typeName);
    }

    /**
     * Given a type and a resolver object, fetch the type metadata using the resolver.
     * Return a typeInfo object made of two properties:
     *   type: the resolved type or undefined if no resolver matches.
     *   scopeResolver: the object where the type was found
     *
     * The context is needed to recursively evaluate type. For example when a type is
     * flow:foo and foo is complex type that uses a type defined in the flow, this type
     * can only be resolved in the context of the flow.
     *
     * @param  {String} type the type descriptor
     * @param  {Object} scopeResolver a map of scopes object keyed by scope names
     * @param softCheck when true this method does not throw error, which getType does if there is a missing type.
     * Type declarations are missing for extended types for example
     * @return {Object.<type, context>} an object made of the type metadata and the context of the type
     */
    static resolveType(type, scopeResolver = new ScopeResolver(), softCheck = false) {
      const result = StateUtils.parseType(type);
      const scopeName = result.scope;
      const typeName = result.name;
      const scope = StateUtils.retrieveScope(scopeResolver, result.extId, scopeName, typeName);
      let typeFinal;

      if (scope) {
        if (softCheck) {
          typeFinal = scope.hasType(typeName) ? scope.getType(typeName) : undefined;
        } else {
          typeFinal = scope.getType(typeName);
        }

        return {
          scopeResolver: scope.scopeResolver,
          type: typeFinal,
          scopeName,
        };
      }

      return {};
    }

    /**
     * Returns type information from the variable (or event) definition.
     * This will resolve all externalized types and denormalize this into a single structured type.
     *
     * A type can be a primitive, i.e. "string", "boolean", or "number".
     *
     * It can also be an object. In this case, the type will be returned as an object, where the keys are the
     * names of the properties, and the values are the types for those keys. The types for keys can also be objects
     * or any other type.
     *
     * A type can also be an array, in which case it will be represented as a single item array where that item
     * describes the type of each item in the array. If the array is an array of primitives, that will be returned as
     * a string such as "string[]", "boolean[]", or "number[]".
     *
     * A type can be an extended type (meaning it is an instance of some class). In this case, the type is the string
     * representation of the module (i.e. "vb/ServiceDataProvider").
     * (note: Events do not support builtin types, and disallow those, before calling this).
     *
     * Finally, a type can be a wildcard object. In this case the type is the string, "object". If it's an array
     * whose items can be wildcards, this can be explicitly represented as "object[]". Note that "object" can
     * also be an array or an object otherwise, although the platform will default it to an empty object if no
     * other default value is specified.
     *
     * If the type cannot be derived, this will method will throw an exception.
     *
     * @param variableName
     * @param variableDef the variable definition as defined in json. Built in types which have
     * their own definition may also resolve the definition, and set the resolved type under a
     * 'type' property and also set a 'resolved' boolean property, indicating that the type has
     * been resolved.
     * @param scopeResolver
     * @param referencedTypes
     */
    static getType(variableName, variableDef, scopeResolver = new ScopeResolver(), referencedTypes = {}) {
      if (variableDef.resolved) {
        return variableDef.type;
      }

      if (!variableDef || !variableDef.type) {
        throw new Error(`Type for '${variableName}' was not defined.`);
      }

      let type = variableDef.type;

      // check if it's an enum:
      if (type.enumType) {
        // set the actual enumeration type
        type = type.enumType;
      }

      if (typeof type === 'string') {
        type = type.trim();

        if (['string', 'boolean', 'number', 'any'].some((e) => type === e)) {
          return type;
        }

        // check to see if the type is an instance type, e.g., vb/ServiceDataProvider; or if instanceFactoryType
        // return the actual constructorType
        if (Utils.isInstanceType(type)) {
          const constructorType = StateUtils.getVariableDefConstructorFactoryType(variableDef, scopeResolver);
          return (constructorType && Utils.isTypeDefInstanceFactory(constructorType)) ? constructorType : type;
        }

        // run legacy checks
        if (type === 'object' && !variableDef.definition) {
          return type;
        }
        if (type === 'object' || type === 'array') {
          type = StateUtils.resolveLegacyType(variableName, variableDef, scopeResolver);
          log.warn(`Deprecated type definition for variable '${variableName}'.`);
          return type;
        }

        // check for primitive arrays
        const isArray = type.endsWith('[]');
        if (isArray && ['string', 'boolean', 'number', 'any', 'object'].some((e) => type.startsWith(e))) {
          return type;
        }

        // check for a type that needs to be resolved
        let referencedTypeName = type;

        // deal with referenced array types
        if (isArray) {
          referencedTypeName = referencedTypeName.substring(0, referencedTypeName.length - 2);
        }

        let referencedType = referencedTypes[referencedTypeName];
        if (!referencedType) {
          const typeInfo = StateUtils.resolveType(referencedTypeName, scopeResolver);
          const typeDefinition = typeInfo && typeInfo.type;

          if (!typeDefinition) {
            throw new Error(`Type for '${variableName}' references '${referencedTypeName}' which is not defined.`);
          }

          // prevent infinite recursion for cyclic type definitions.
          typeDefinition.constructedType = {};
          referencedTypes[referencedTypeName] = typeDefinition.constructedType; // eslint-disable-line no-param-reassign

          // Drill in getType using the inner resolver returned by the scope resolver.
          // Only passes the referencedTypes references when drilling in the same scope (THIS_PREFIX)
          // since flow or application references only work upward they cannot cycle.
          const isThis = scopeResolver.isThis(typeInfo.scopeName);
          referencedType = StateUtils.getType(variableName, typeDefinition, typeInfo.scopeResolver,
            isThis ? referencedTypes : {});
        }

        if (isArray) {
          return [referencedType];
        }

        return referencedType;
      }

      // handle objects
      if (Utils.isObject(type)) {
        const constructedType = variableDef.constructedType || {};
        const entries = Object.entries(type);
        if (entries.length === 0) {
          throw new Error(`Type for '${variableName}' contains an empty map, did you mean to use 'object'?`);
        }

        entries.forEach((entry) => {
          const key = entry[0];
          const itemTypeDef = entry[1];
          constructedType[key] = StateUtils.getType(key, { type: itemTypeDef },
            scopeResolver, referencedTypes);
        });
        return constructedType;
      }

      // handle arrays
      if (Array.isArray(type)) {
        if (type.length === 0) {
          throw new Error(`Type for '${variableName}' contains an empty array, did you mean to use 'string[]'?`);
        }

        if (type.length > 1) {
          throw new Error(`Type for '${variableName}' contains an array type that contains more than one item.`);
        }

        const arrayTypeDef = type[0];
        if (!Utils.isObjectOrArray(arrayTypeDef)) {
          throw new Error(`Type for '${variableName}' contains an invalid array type, did you mean to use 'string[]'?`);
        }

        const arrayType = StateUtils.getType(variableName, { type: arrayTypeDef }, scopeResolver, referencedTypes);
        return [arrayType];
      }

      throw new Error(`Type for '${variableName}' was not recognized.`);
    }

    /**
     * Returns the type information using the legacy syntax.
     *
     * @TODO remove this
     *
     * @private
     * @param variableName
     * @param variableDef
     * @param scopeResolver
     * @returns {string|*}
     */
    static resolveLegacyType(variableName, variableDef, scopeResolver) {
      const type = variableDef.type;
      const definition = variableDef.definition;
      if (!definition) {
        throw new Error(`The '${variableName}' variable has a complex type but no definition, `
          + 'did you mean \'object\' (\'*\') type?');
      }

      // handle string definition, e.g., boolean, app:type, etc
      if (typeof definition === 'string') {
        let convertedType;
        if (definition === '*') {
          convertedType = 'object';
        } else if (['string', 'boolean', 'number'].some((e) => definition === e)) {
          convertedType = definition;
        } else {
          convertedType = StateUtils.getType(variableName, { type: definition }, scopeResolver);
        }

        if (typeof convertedType === 'string') {
          return type === 'array' ? `${convertedType}[]` : convertedType;
        }

        return type === 'array' ? [convertedType] : convertedType;
      }

      // handle object definition
      // get a type from the definition
      const constructedType = {};
      const entries = Object.entries(definition);
      if (entries.length === 0) {
        return type === 'object' ? 'object' : 'object[]';
      }

      entries.forEach((entry) => {
        const key = entry[0];
        const itemTypeDef = entry[1];
        constructedType[key] = StateUtils.getType(variableName, itemTypeDef, scopeResolver);
      });

      return type === 'object' ? constructedType : [constructedType];
    }

    /**
     * Perform a type check on the given value. All invalid values will be recorded in typeCheckInfo. It will return
     * true if the type check passes and false otherwise.
     *
     * @param name the name of the variable or constant
     * @param type the required type
     * @param definition the definition of the variable or constant from the descriptor
     * @param value the value to check the type against
     * @param typeCheckInfo contains all the invalid values flagged by typeCheck
     * @returns {boolean}
     */
    static typeCheck(name, type, definition, value, typeCheckInfo) {
      const info = typeCheckInfo;
      info.Type = type;
      info.message = `Default value for ${name} does not match its type`;

      // holds all the mismatched values
      const MISMATCHED_VALUES = 'Mismatched Value(s)';
      const DUPLICATE_ARRAY_ENTRIES = 'Duplicate array entries';
      info[MISMATCHED_VALUES] = value;

      let result = true;

      if (!type) {
        result = false;
      } else if ((value === undefined || value === null) || Expression.isExpression(value) || type === 'any') {
        result = true;
      } else if (type === 'any[]') {
        result = Array.isArray(value);
      } else if (type === 'object') {
        result = Utils.isObjectType(value);
      } else if (type === 'object[]') {
        if (!Array.isArray(value)) {
          result = false;
        } else {
          info[MISMATCHED_VALUES] = [];
          value.forEach((item) => {
            const itemInfo = {};
            if (!StateUtils.typeCheck(name, 'object', definition, item, itemInfo)) {
              info[MISMATCHED_VALUES].push(itemInfo);
              result = false;
            }
          });
        }
      } else if (Utils.isObjectType(type) && Utils.isObject(value)) {
        info[MISMATCHED_VALUES] = {};
        Object.keys(value).forEach((key) => {
          const itemInfo = {};
          if (!StateUtils.typeCheck(name, type[key], definition, value[key], itemInfo)) {
            info[MISMATCHED_VALUES][key] = itemInfo;
            result = false;
          }
        });
      } else if (Utils.isArrayType(type) && Array.isArray(value)) {
        if (definition.uniqueItems) {
          // need to check whether the given array contains unique array values
          // this will obviously detect duplicate literal values or
          // duplicate expressions, however it cannot detect whether
          // different expressions evaluate to the same value
          if ((new Set(value)).size !== value.length) {
            delete info[MISMATCHED_VALUES];
            info.message = `Duplicate array values for ${name} have been detected`;
            info[DUPLICATE_ARRAY_ENTRIES] = value;
            return false;
          }
        }
        const itemType = Utils.getArrayRowType(type);
        info[MISMATCHED_VALUES] = [];
        value.forEach((item) => {
          const itemInfo = {};
          if (!StateUtils.typeCheck(name, itemType, definition, item, itemInfo)) {
            info[MISMATCHED_VALUES].push(itemInfo);
            result = false;
          }
        });
      } else {
        const valueType = typeof value;
        // check to make sure a string can be coerced into a number
        if (type === 'number' && valueType === 'string' && value.trim()) {
          result = !isNaN(value);
        } else {
          result = type === valueType;
        }
      }

      return result;
    }

    /**
     * Generates a default value for a type.
     *
     * @param variableName The name of the variable (for logging purposes)
     * @param scopes The complete set of scopes (for expression evaluation)
     * @param type the type for the variable
     * @param defaultValueDefinition The default value as specified in the page model
     * @param recursionDepths used to prevent infinite recursion for cyclic types
     * @returns {*}
     */
    static buildVariableDefaults(variableName, scopes, type, defaultValueDefinition, recursionDepths = {}) {
      // the literal default value definition from the type structure, if its not present, return the initial
      // structure as defined by the type (which could be a structure or undefined)
      if (defaultValueDefinition === undefined) {
        if (type === 'any[]' || type === 'object[]') {
          return [];
        }
        if (type === 'object') {
          return {};
        }
        if (type === 'any') {
          return undefined;
        }
      }

      // deal with expression
      if (Expression.isExpression(defaultValueDefinition)) {
        return StateUtils.getValueOrExpression(defaultValueDefinition, scopes);
      }

      // deal with arrays
      if (Utils.isArrayType(type)) {
        // if it's an array, go through each part of the array and fill in the type
        const rowType = Utils.getArrayRowType(type);
        const defaultValue = [];

        // if there's no definition, simply return []
        if (defaultValueDefinition === undefined) {
          return defaultValue;
        }

        // fill in the array index by index if defaultValueDefinition is an array, otherwise, it should be
        // an expression which will be handled at the end
        if (Array.isArray(defaultValueDefinition)) {
          defaultValueDefinition.forEach((v) => {
            const indexDefaultValue = StateUtils.buildVariableDefaults(variableName, scopes, rowType, v,
              recursionDepths);
            defaultValue.push(indexDefaultValue);
          });
          return defaultValue;
        }
      }

      // deal with objects
      if (Utils.isObjectType(type)) {
        if (defaultValueDefinition === null) {
          return null;
        }

        const defaultValue = {};
        const isWildCardType = StateUtils.isWildCardType(type);

        if (Utils.isObject(defaultValueDefinition)) {
          Object.keys(defaultValueDefinition).forEach((key) => {
            const v = defaultValueDefinition[key];
            const propType = isWildCardType ? 'any' : type[key];

            // get the default value by recursing on this property
            defaultValue[key] = StateUtils.buildVariableDefaults(variableName, scopes, propType, v, recursionDepths);
          });
        }

        // if the type is not a wild card, we need to iterate the type to make sure properties are properly
        // filled if not provided by defaultValueDefinition
        if (!isWildCardType) {
          // look up the current recursion depth for type
          let depth = recursionDepths[type];
          if (!depth) {
            depth = {};
            recursionDepths[type] = depth; // eslint-disable-line no-param-reassign
          }

          Object.keys(type).forEach((key) => {
            if (!defaultValueDefinition || !Object.prototype.hasOwnProperty.call(defaultValueDefinition, key)) {
              // look up the current recursion depth for key
              depth[key] = depth[key] || 0;

              // used to restore to current depth after recursion
              const currentDepth = depth[key];

              // stop the recursion if the depth has reached the maximum recursion depth for key
              if (currentDepth < Constants.MAX_DEFAULT_VALUE_RECURSION_DEPTH) {
                depth[key] += 1;
                defaultValue[key] = StateUtils.buildVariableDefaults(variableName, scopes, type[key],
                  undefined, recursionDepths);

                // restore to the original current depth
                depth[key] = currentDepth;
              } else {
                defaultValue[key] = undefined;
              }
            }
          });
        }

        return defaultValue;
      }

      if (defaultValueDefinition) {
        // coerce a string to number
        if (type === 'number' && typeof defaultValueDefinition === 'string') {
          return Number(defaultValueDefinition);
        }

        // deal with primitive or expression
        return StateUtils.getValueOrExpression(defaultValueDefinition, scopes);
      }

      return defaultValueDefinition;
    }

    /**
     * Returns a value or an expression depending on the value. For expressions, the entire value must be wrapped
     * into either {{ expr }} or [[ expr ]]. At the moment, there is no semantic difference between the two.
     *
     * @private
     * @param value
     * @param scopes The complete set of scopes (for expression evaluation)
     * @return {*} a function evaluation, or the original value (if not a string. array, or object)
     */
    static getValueOrExpression(value, scopes, prohibitWrites = true) {
      if (!value) {
        return value;
      }

      if (typeof value === 'string') {
        const expression = Expression.getExpression(value);
        if (expression) {
          return Expression.createFromString(expression, scopes, prohibitWrites);
        }

        return value.trim();
      }

      if (Array.isArray(value)) {
        return value.map((item) => StateUtils.getValueOrExpression(item, scopes, prohibitWrites));
      }

      if (!Utils.isPrototypeOfObject(value)) {
        return value;
      }

      if (Utils.isObject(value)) {
        const obj = {};
        Object.keys(value).forEach((key) => {
          obj[key] = StateUtils.getValueOrExpression(value[key], scopes, prohibitWrites);
        });
        return obj;
      }

      // if it's not an expression, just return the value as is
      return value;
    }

    /**
     * Recursively evaluate expressions contained in the given value.
     *
     * @param value the value to evaluate
     * @param scopes scopes used for evaluation
     * @returns {*}
     */
    static deepEval(value, scopes) {
      if (!value) {
        return value;
      }

      if (typeof value === 'string') {
        const expression = Expression.getExpression(value);
        if (expression) {
          return Expression.createFromString(expression, scopes)();
        }

        // Need to trim to be consistent with getValueOrExpression
        return value.trim();
      }

      if (Array.isArray(value)) {
        return value.map((item) => StateUtils.deepEval(item, scopes));
      }

      if (!Utils.isPrototypeOfObject(value)) {
        return value;
      }

      if (Utils.isObject(value)) {
        const obj = {};
        Object.keys(value).forEach((key) => {
          obj[key] = StateUtils.deepEval(value[key], scopes);
        });
        return obj;
      }

      // if it's not an expression, just return the value as is
      return value;
    }

    /**
     * Creates an instance of the following types used on a variable -
     *  - vb builtin types (or extended types). For these types the sibling variables are also created. Example
     *  type: 'vb/ServiceDataProvider'
     *  - vb instance factory with constructorParams.
     *  - any random type with no constructorParams. Example: type: 'some/noConstructorClass'
     *
     * @private
     * @param name The name of the variable
     * @param variableDef The variable definition
     * @param scopeResolver An array of type resolver for complex types
     * @param scope The current scope
     * @param completeScopes The entire set of scopes in context
     * @param namespace variable's namespace ('variables' or 'metadata')
     * @return {Promise} the requested object or null if the type is not known.
     */
    static instantiateType(name, variableDef, scopeResolver = new ScopeResolver(), scope,
                           completeScopes, namespace = Constants.VariableNamespace.VARIABLES, callerValue) {
      return new Promise((resolve) => {
        // we are dealing with an extendedType
        if (callerValue && callerValue.isExtendedType && callerValue.isExtendedType()) {
          // if callerValue is a reference to the same extended type from a calling scope, then use the reference
          // as is without bothering to create a new extended sibling variables. When using caller ref ensure
          // that the type expected by variable matches the type of callerValue.
          // TODO verify callerValue matches variable type
          return resolve(callerValue);
        }

        requirejs([variableDef.type], (TypeClass) => {
          // if the type is not an extended type just resolve the type
          const newInstance = new TypeClass();
          if (!Utils.isExtendedType(newInstance)) {
            resolve(newInstance);
            return;
          }

          let createTypePromise = Promise.resolve();
          const typeDefinition = newInstance.getTypeDefinition(variableDef, scopeResolver);
          if (typeDefinition) {
            // create a typeDefinition for default value calculation
            const td = {
              type: typeDefinition.type,
              definition: typeDefinition.definition,
              defaultValue: variableDef.defaultValue,
              persisted: variableDef.persisted,
              resolved: typeDefinition.resolved,
            };
            const isBuiltinType = newInstance.isBuiltinType && newInstance.isBuiltinType();

            // create the default value
            createTypePromise = StateUtils.createVariableDefaultValue(name, td, scopeResolver, scope,
              completeScopes, namespace)
              .then((defaultValue) => Promise.resolve(newInstance.init(name, variableDef, defaultValue,
                scope.container))
                .then(() => defaultValue))
              .then((defaultValue) => {
                // a builtin type variable gets 2 additional variables created on the scope; named <varName>_value &
                // _internalState;
                // create the 'value' variable and add it to the scope. '_value' variable is the value of the variable,
                // and reads and writes to the instance (hoisted**) properties are always done on this variable, not the
                // instance variable.
                const tdType = StateUtils.getType(name, td, scopeResolver);
                const newVariable = StateUtils.addInstancePropertyToScope(newInstance, name, namespace,
                  'value', tdType, defaultValue, scope, td.persisted);
                const currentScope = scope;

                // for niceness, if the type is an object, copy all top-level properties onto the instance
                // itself so it can be directly addressable
                if (Utils.isObject(defaultValue) && !Array.isArray(defaultValue)
                  && Utils.isPrototypeOfObject(defaultValue) && typeDefinition
                  && typeof tdType === 'object' && newInstance.hoistValueObjectProperties()) {
                  // allow builtin types to setup variable properties accessors. Currently this is not supported on
                  // custom (external) types.
                  if (isBuiltinType) {
                    // builtin types using extendedType mixin may override this method to customize getter / setter
                    newInstance.setupVariableProperties(defaultValue, currentScope, namespace, newVariable);
                  } else {
                    StateUtils.setupVariableProperties(newInstance, defaultValue, currentScope, namespace, newVariable);
                  }
                }

                // chain variables don’t fire change events so they don’t have onValueChanged; if they do setup
                // listener to notify value change
                if (newVariable.onValueChanged) {
                  // listen for changes in the variable, and send the event to the TDS
                  newVariable.onValueChanged.add((e) => {
                    newInstance.handlePropertyVariableChangeEvent(e);
                  }, newInstance);

                  // additionally if the builtin type variableDef has configured valueChange
                  // listeners then wire up listeners for the _value variable.
                  scope.container.addValueChangedListeners(newVariable, variableDef, completeScopes);
                }

                // allow for certain actions to be invoked within the extended type
                const handlerDescriptor = {
                  value: {
                    getType(type, description) {
                      return StateUtils.getType(description, { type }, scope.container.scopeResolver);
                    },
                    invokeEvent(eventName, eventPayload /* withBubbling = true */) {
                      // invoking event without bubbling is not recommended as it bypasses event configuration
                      // if (!withBubbling) {
                      //   return scope.container.invokeEvent(eventName, eventPayload);
                      // }
                      return scope.container.invokeEventWithBubbling(eventName, eventPayload);
                    },
                  },
                };
                if (isBuiltinType) {
                  handlerDescriptor.value = Object.assign(handlerDescriptor.value, {
                    callActionChain(chainLocator, params) {
                      return scope.container.callActionChain(chainLocator, params);
                    },
                  });
                }
                Object.defineProperty(newInstance, 'handler', handlerDescriptor);
              });
          }

          // after the value has been created (or not)
          createTypePromise.then(() => {
            // add another variable '_internalState' so that the type instance can stash internal
            // state
            // in the scope
            StateUtils.addInstancePropertyToScope(newInstance, name, namespace, 'internalState',
              'object', {}, scope, variableDef.persisted);

            // return the whole instance
            resolve(newInstance);
          }).catch((e) => {
            log.error(e);
            throw new Error(`Error creating value for variable '${name}'.`);
          });
        });
      });
    }

    /**
     * Sets up accessors for all properties on the variable value. By default the accessors read from and write to the
     * underlying 'value' variable on scope.
     * @param instance
     * @param defaultValue
     * @param currentScope
     * @param namespace
     * @param variable
     */
    static setupVariableProperties(instance, defaultValue, currentScope, namespace, variable) {
      Object.keys(defaultValue).forEach((k) => {
        Object.defineProperty(instance, k, StateUtils.getVariablePropertyDefinition(instance, k, currentScope,
          namespace, variable));
      });
    }

    /**
     * Returns the accessors to read write value for properties on extended type value.
     * @param instance the extended type instance
     * @param prop property of extended type
     * @param cs currentScope where the variable is defined
     * @param namespace
     * @param variable the variable instance
     *
     */
    static getVariablePropertyDefinition(instance, prop, cs, namespace, variable) {
      const currentScope = cs;
      const k = prop;
      return {
        get: () => instance.getValue()[k],
        set: (newValue) => {
          // use the derived name of the instance property variable to lookup its value
          currentScope.variableNamespaces[namespace][variable.name][k] = newValue;
        },
        enumerable: true,
        configurable: true,
      };
    }

    /**
     * Create a property on the instance of the object that is mapped to a variable that
     * is created into the scope. Also creates a variable with the name and propertyName on the
     * scope.
     *
     * @private
     * @param instance The instance of the variable
     * @param name The name of the variable
     * @param namespace of the variable
     * @param propertyName The property to add to the instance
     * @param type The type of the variable (see Variable.getType())
     * @param defaultValue The default value of the property
     * @param scope the scope to insert the state into
     * @param persisted indicate whether the instance property should be persisted in local or session storage
     * @returns {Variable} The newly created variable
     */
    static addInstancePropertyToScope(instance, name, namespace, propertyName, type, defaultValue, scope, persisted) {
      const currentScope = scope;
      // TODO: a potential bug exists where if author creates an SDP variable 'foo', and
      // also creates another variable called 'foo_value' or 'foo_internalState' that this
      // would conflict with the special variables with the same name. Maybe using '_vb_'
      // can have less likelihood for name collision.
      const variableName = `${name}_${propertyName}`; // in redux
      const newVariable = scope.createVariable(variableName, namespace, type,
        defaultValue, undefined, { persisted, writableOptions: instance.getWritableOptions() });

      Object.defineProperty(instance, propertyName, {
        get: () => scope.variableNamespaces[namespace][variableName],
        set: (newValue) => {
          currentScope.variableNamespaces[namespace][variableName] = newValue;
        },
        enumerable: true,
        configurable: true,
      });

      return newVariable;
    }

    /**
     * Creates an initial value for a variable given its default value and a possible initial value.
     * If the initial value exist, a clone of it is returned, otherwise the defaultValue created
     * from the variable definition is used as a prototype for the return value.
     *
     * @private
     * @param  {*} defaultValue The default value created from the variable definition to be used as
     *                          a prototype for the initial value (may be a primitive, struct,
     *                          or expr)
     * @param  {*} initialValue An optional initial value
     * @return {*}              The initial value for the variable
     */
    static createVariableInitialValue(defaultValue, initialValue) {
      if (initialValue !== undefined) {
        return Utils.cloneObject(initialValue);
      }

      if (!Utils.isObjectOrArray(defaultValue) // if it's not an object
        || (!Array.isArray(defaultValue)
          && !Utils.isPrototypeOfObject(defaultValue))) { // or is an instance (!obj.proto)
        return defaultValue;
      }

      return Utils.cloneObject(defaultValue);
    }

    /**
     * For persisted value, since an expression or expressions contained in an object cannot be stored and retrieved
     * from the browser storage, this method will restore them using the default value structure.
     *
     * @param persistedValue the persisted value that may need to be restored
     * @param defaultValue the default value used to restore the persisted value
     * @returns {*}
     */
    static restorePersistedValue(persistedValue, defaultValue) {
      const persVal = persistedValue;
      // restore the persisted value to the default expression
      if (typeof defaultValue === 'function') {
        return defaultValue;
      }

      if (Utils.isObjectOrArray(defaultValue)) {
        Object.keys(defaultValue).forEach((key) => {
          // with generators the persisted value could be quite dissimilar from the defaultValue primarily because
          // the latter is not necessarily complete as a result of its type not being known or fully parse-able.
          if (!Array.isArray(defaultValue) || (persVal && Object.prototype.hasOwnProperty.call(persVal, key))) {
            persVal[key] = StateUtils.restorePersistedValue(persVal[key], defaultValue[key]);
          }
        });
      }

      return persVal;
    }

    /**
     * Returns whether or not the value is a one of the wildcards.
     *
     * @param type The type to test
     * @returns {boolean} True if the value is a boolean
     */
    static isWildCardType(type) {
      return type === 'any' || type === 'object' || type === 'any[]' || type === 'object[]';
    }
  }

  return StateUtils;
});

