'use strict';

/**
 * class LayoutOverlayExtensionModel
 *  extends EmptyModel
 *
 * represents the VB extension overrides for JET global templates
 * https://confluence.oraclecorp.com/confluence/display/JET/Global+Template+Support
 */

define('vb/extensions/dynamic/private/models/layoutOverlayExtensionModel',[
  'vb/extensions/dynamic/private/models/abstractModel',
  'vb/extensions/dynamic/private/models/emptyModel',
], (AbstractModel, EmptyModel) => AbstractModel(EmptyModel, 'field-templates-x.js'));

