'use strict';

define('vb/helpers/restHookHandler',[], () => {
  /**
   * Helper class that can be used to register with the rest helper to listen for pre-specified hooks during
   * the fetch operations (see individual hooks for more information).
   *
   * A RestHookHanlder is registered when using the rest class:
   *
   * <pre>
   *   Rest.get(endpoint).hookHandler(myHookHandlerInstance).fetch().then((response) => {
   *    ...
   *   }
   * </pre>
   */
  /* eslint class-methods-use-this: ["error", { "exceptMethods": ["handlePreFetchHook",
   "handleRequestHook", "handleResponseHook", "handlePostFetchHook", "handlePostFetchErrorHook"] }] */
  class RestHookHandler {
    /**
     * Called before a fetch request is called. The request has not yet been created, nor have the
     * request transformation functions been called.
     *
     * @param restHelper the rest instance performing the fetch
     * @return Promise<null>
     */
    handlePreFetchHook(/* restHelper */) {
      return Promise.resolve();
    }

    /**
     * Called after the request has been created and immediately before the fetch is called. This
     * must return a valid request.
     *
     * @param request the request sent to the fetch API
     * @returns Promise<*> the same or modified request
     */
    handleRequestHook(request) {
      return Promise.resolve(request);
    }

    /**
     * Called immediately after the fetch returns. This must return a valid response.
     *
     * @param response the response returned from the fetch API
     * @returns Promise<*> the same or modfiied response
     */
    handleResponseHook(response) {
      return Promise.resolve(response);
    }

    /**
     * Called with the results of the fetch helper. This will contain both the response as well
     * as the results of response transformation functions.
     *
     * @param result The return value of the rest helper
     */
    handlePostFetchHook(/* result */) {
      return Promise.resolve();
    }

    /**
     * Called with the results of the fetch helper when the rest service returns an error code. This
     * will contain both the response only as transformation functions do not run on error conditions.
     *
     * @param result The return value of the rest helper
     */
    handlePostFetchErrorHook(/* result */) {
      return Promise.resolve();
    }
  }

  return RestHookHandler;
});

