'use strict';

define('vb/private/stateManagement/componentBridge',[
], () => {
  /**
   *
   */
  class ComponentBridge {
    // eslint-disable-next-line class-methods-use-this, no-unused-vars
    connected(context, id) {
    }

    // eslint-disable-next-line class-methods-use-this, no-unused-vars
    disconnected(domNodes, id) {
    }

    // eslint-disable-next-line class-methods-use-this
    get moduleConfig() {
      return null;
    }
  }

  return ComponentBridge;
});

