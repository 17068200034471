'use strict';

define('vb/action/builtin/navigateAction',['vb/action/action'], (Action) => {
  /**
   * A generic way to navigate to page, flow or App UI.
   *
   * @param {Object} options
   * @param {String} options.page - the path to the destination page. The path is absolute starting
   * at the application or relative to the current page. When use in combination with flow or App UI,
   * the path cannot be absolute and it navigates to the page relative to the flow or App UI.
   * @param {String} options.flow - the id of the destination flow. Change the content of the flow displayed
   * in the current page. When used in combination with a page property, navigates to the page in that flow.
   * @param {String} options.application - the id of the destination App UI. Change which App UI
   * is displayed in the host application. When used in combination with a page and flow property, navigates
   * to the page in that App UI.
   * @param {Object<String, String>} options.params - A map of URL parameters. Same as in the navigateToPage
   * action (optional)
   * @param {String} options.history - Effect on the browser history. Allowed value are 'replace', 'skip' or
   * 'push'. If the value is 'replace', the current browser history entry is replaced, meaning that back
   * button will not go back to it. If the value is 'skip', the URL is left untouched.
   * Same as in the navigateToPage action(optional and default is push)
   * Returns the outcome 'success' if there was no error during navigation. If navigation
   * completed, returns the payload { navigated: true }.
   * Returns the outcome 'failure' with the error in the payload.
   */
  class NavigateAction extends Action {
    perform(parameters) {
      return this.helpers.navigate(parameters)
        .then((result) => Action.createSuccessOutcome(result))
        .catch((e) => Action.createFailureOutcome('Error during navigateAction', e));
    }
  }

  return NavigateAction;
});

