'use strict';

define('vb/private/vx/v1/remoteExtensionAdapter',[
  'vb/private/vx/baseExtensionAdapter',
  'vb/private/utils',
  'urijs/URI',
], (BaseExtensionAdapter, Utils, URI) => {
  /**
   * The extension adapter used to load extension from the extension manager
   */
  class RemoteExtensionAdapter extends BaseExtensionAdapter {
    /**
     * Build the request and call the base to make the fetch call
     * @param {String} applicationId
     * @return {Promise}
     */
    initiateFetchManifest(applicationId) {
      return Promise.resolve().then(() => {
        if (!applicationId) {
          throw new Error('Cannot load extension, application id is not defined');
        }

        const version = this.constructor.getExtensionVersion();
        const requestUrl = this.buildUrl(applicationId, version);
        this.log.info('Loading extensions at', requestUrl, 'using extension manager v1.');

        const request = new Request(requestUrl);

        return this.getFetchManifestPromise(request).then((results) => {
          const manifest = results || {};
          manifest.extensions = manifest.extensions || [];

          this.log.info('Found', manifest.extensions.length, 'extension(s) for application',
            applicationId);

          return manifest;
        });
      });
    }

    /**
     * Build the extension URL based on the baseUrl and the extension id
     * A typical extension URL with a sandbox defined looks like:
     * https://<fa-server>/fscmUI/vxmanifest/v1/applications/<app name>/ui-manifest?preferExtensionVersion=$<sandboxId>
     * @param  {String} applicationId the id of the application for which we load an extension
     * @param  {String} version       the preferred extension version
     * @return {String}               the extension URL
     */
    buildUrl(applicationId, version) {
      const uri = new URI(`${Utils.addTrailingSlash(this.registryUrl)}vxmanifest/v1/applications`);

      // Append the applicationId and method
      uri.segmentCoded(applicationId);
      uri.segment('ui-manifest');

      if (version) {
        // Use this syntax because we don't want to encode the $ in sandbox
        uri.search(`preferExtensionVersion=${version}`);
      }

      return uri.href();
    }
  }

  return RemoteExtensionAdapter;
});

