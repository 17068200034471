'use strict';

define('vb/private/types/dataProviders/dynamicMultiServiceDataProvider',[
  'vb/private/log',
  'vb/private/utils',
  'vb/helpers/mixin',
  'vb/types/eventTargetMixin',
  'vb/private/types/dataProviders/multiServiceDataProviderUtils',
  'vb/private/types/utils/serviceDataProviderRestHelperFactory',
], (Log, Utils, Mixin, EventTargetMixin, MultiSDPUtils,
  SDPRestHelperFactory) => {
  const MSDP_PREFIX = 'msdp-';
  const NOOP_FUNC = () => {};
  /**
   * default type definition for ServiceDataProvider2 variable
   * @typedef {object=} MSDPVarTypeDef
   * @property {object=} dataProviders
   * @property {*} dataProviders.fetchFirst
   * @property {*} dataProviders.fetchByKeys
   * @property {*} dataProviders.fetchByOffset
   */
  const TYPEDEF = {
    dataProviders: {
      fetchFirst: 'object',
      fetchByKeys: 'object',
      fetchByOffset: 'object',
    },
  };

  const LOGGER = Log.getLogger('/vb/private/types/dataProviders/DynamicMultiServiceDataProvider');

  /* eslint class-methods-use-this: ["error", { "exceptMethods": ["getDefinitionValue","getIdAttributeProperty",
  "getLifecycleStageChangedSignal","callActionChain","isDisconnected","getTotalSize"]}] */
  class DynamicMultiServiceDataProvider extends Mixin().with(EventTargetMixin) {
    /**
     * @constructor
     * creates a standalone MultiServiceDataProvider instance that is initialized with the state that the caller
     * provides and returns the instance. The instance is a standalone and is not backed by a variable that manages its
     * state. The instance merely fetches the data from Rest and returns the result.
     *
     * @param dataProviderOptions options used to instantiate the data provider instance
     * @param serviceOptions options used to instantiate the RestHelper with.
     * @param vbContext {object} - options that provide the 'vb' context for the current call (this object
     *                                     is typically provided by a VB API or callback mechanism).
     *
     * @todo need to work with JET to define a context, if possible, to determine which extension
     * this is being used in (if any). this is needed to restrict access to other extension services.
     */
    constructor(dataProviderOptions, serviceOptions, vbContext) {
      super();
      this.id = MSDP_PREFIX + Utils.generateUniqueId();
      this.log = LOGGER;
      // whitelist options passed in
      const dpOptions = dataProviderOptions || {};
      const whiteListedOptions = Object.keys(TYPEDEF).reduce((obj, key) => {
        const o = obj;
        const value = dpOptions[key];
        if (value) {
          o[key] = value;
        }
        return o;
      }, {});

      /**
       * represents the state of the DSDP when it was created
       * @type { MSDPVarTypeDef }
       * @private
       */
      this.state = whiteListedOptions;
      this.serviceOptions = serviceOptions;

      // @todo: define extension context @see constructor
      this.createRestHelper = () => SDPRestHelperFactory.get(serviceOptions || {}, vbContext);
      this.sdpRefsByType = undefined;
      this.init();
    }

    /**
     * Initializer called to setup methods based on configuration
     */
    init() {
      const { dataProviders } = this.state;

      // based on the data providers defined, set up the fetch methods correctly.
      if (dataProviders && MultiSDPUtils.isConfigurationValid(dataProviders)) {
        // now that we have some dataProviders set, setup fetchXYZ() methods based on the mapping.
        // always setup a fetchFirst data provider.
        if (dataProviders.fetchFirst && this.fetchFirst === undefined) {
          MultiSDPUtils.initFetchFirstMethods(this);
        }

        // when dataProviders.fetchByKeys is configured the fetchByKeys and containsByKeys are
        // wired up on the MultiServiceDataProvider.
        if (dataProviders.fetchByKeys && this.fetchByKeys === undefined) {
          MultiSDPUtils.initFetchByKeysMethods(this);
        }

        if (dataProviders.fetchByOffset && this.fetchByOffset === undefined) {
          MultiSDPUtils.initFetchByOffsetMethods(this);
        }
      } else {
        this.log.error('MultiServiceDataProvider', this.id, 'has no dataProviders set!', this.state);
      }
    }

    /**
     * return a noop listener because for standalone MultiSDP instances we don't care to know if the referenced
     * variable is disposed. We certainly may care if the SDP instances themselves are disposed but there is
     * currently no lifecycle events for standalone objects and it's the responsibility of the caller that creates
     * the MDP with the referenced SDPs to clean up, or better not call methods on MDP after its references are diposed.
     * @param sdpType
     * @returns {NOOP_FUNC}
     */
    // eslint-disable-next-line no-unused-vars,class-methods-use-this
    getVariableStageChangeCallback(sdpType) {
      return NOOP_FUNC;
    }

    /**
     * Returns the state as the value in the definition.
     * Note: When dealing with MultiSDP variables the defaultValue is its configured value, different from its value
     * when fully evaluated.
     */
    getDefinitionValue() {
      return this.state;
    }

    /**
     * returns a Set of SDP references currently used by this MDP.
     * @return {Set}
     * @private
     */
    getUniqueSDPReferences() {
      const value = this.getValue();
      // see BUFP-32300: when removeListener is called by component, MDP value is already gone!!
      if (!this.sdpRefsByType && value) {
        this.sdpRefsByType = MultiSDPUtils.getSDPReferencesByType(this);
      }

      return new Set(Object.values(this.sdpRefsByType || {}));
    }

    /**
     * Returns the state as its value.
     *
     * @final
     * @returns {*}
     */
    getValue() {
      return this.state;
    }

    /**
     * When subscribers register a listener on MDP this needs to be directly registered with the
     * underlying SDP for every supported capability. This allows components to be notified when
     * the underlying SDP fires a DP event
     * @param eventType
     * @param listener
     */
    addEventListener(eventType, listener) {
      const sdpRefs = this.getUniqueSDPReferences();

      // for each unique SDP reference register the listener for the DataProviderEvent
      // on the referenced SDP
      sdpRefs.forEach((dp) => {
        dp.addEventListener(eventType, listener);
      });
    }

    /**
     * When subscribers remove a listener on MDP this needs to be directly removed on the
     * underlying SDP for every supported capability.
     * @param eventType
     * @param listener
     */
    removeEventListener(eventType, listener) {
      const sdpRefs = this.getUniqueSDPReferences();
      if (sdpRefs.size === 0) {
        this.log.warn('MultiServiceDataProvider', this.id, 'unable to remove listeners'
          + ' registered with dataProvider because the value appears to have been cleared');
      }
      sdpRefs.forEach((dp) => {
        dp.removeEventListener(eventType, listener);
      });
    }

    /**
     * removes all listeners this MDP sets up on the underlying SDP for every supported capability. Usually this is
     * called when the variable is disposed.
     */
    removeAllEventListeners() {
      const sdpRefs = this.getUniqueSDPReferences();

      sdpRefs.forEach((dp) => {
        dp.removeAllEventListeners();
      });
    }
  }

  return DynamicMultiServiceDataProvider;
});

