'use strict';

/**
 * note: this has a dependency on Router; dependencies on classes that are dependant on JET can
 * cause circular requireJS dependencies, if not used carefully!!
 *
 */

define('vb/private/model/modelUtils',[
  'vb/private/helpers/eventHelper',
  'vb/private/stateManagement/router',
  'vb/private/log',
  'vb/private/utils',
], (EventHelper, Router, Log, Utils) => {
  const logger = Log.getLogger('/vb/private/models/ModelUtils');

  // for backward compatibility in 20.10
  // JET was binding the data-description-overlay.json to the Layout model, and apps were using it.
  // the fix to bind to the right model was late in the release, so we need to support the unintended functionality
  // (for one release).

  /**
   * @param model
   * @returns {boolean}
   */
  function hasEmptyFunctions(model) {
    if (model.$functions) {
      // build a list of property names
      const props = Object.getOwnPropertyNames(model.$functions);
      if (props.length) {
        return false;
      }

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const prop in model.$functions) {
        return false;
      }
      return true;
    }
    return true;
  }

  class ModelUtils {
    /**
     * if a JS module has a constructor, the constructor is called, and passed an object that contains accessors
     * for helpers.
     *
     * Currently, the only accessor is getEventHelper() => EventHelper.
     *
     * @param Module if falsy, returns null
     * @param container if undefined, the context should still contain valid accessors for 'helpers', but the helpers
     *   should handle the lack of a container (throw exception, log error, etc.).
     *  If null, there will be no context, but that should not happen.
     * @returns {Object|null}
     */
    static initializeJsModule(Module, container) {
      let newModule = null;
      if (Module) {
        newModule = Module;
        if (typeof newModule === 'function') {
          // use the 'current' page for the context when constructing the metadata provider
          const cont = (container !== undefined) ? container : Router.getCurrentPage();

          if (!cont) {
            logger.warn('No container context for initializing module helpers');
          }

          const moduleContext = this.createModuleContext(cont);

          newModule = new Module(moduleContext);
        }
      }

      return newModule;
    }


    /**
     * passed to the module constructor; has an accessor to the EventHelper
     * The container is assumed to be valid; if somehow, if it is not, the helper should still be
     * accessible by the JS, but it should fail will logging, minimally.
     *
     * @param container can be null/undefined; EventHelper will throw errors when used if not defined.
     * @returns {{getEventHelper: (function(): *)}}
     * @private
     */
    static createModuleContext(container) {
      return {
        getEventHelper() {
          const eventHelper = new EventHelper({ container });
          // Add a way to dispose of the EventHelper when the container is deleted.
          container.unregisterEventArray.push(() => {
            eventHelper.dispose();
          });
          return eventHelper;
        },

      };
    }

    /**
     * workaround for a bug in 20.10 (BUFP-39805), that apps exploited; JSON was bound to the wrong model for $function.
     * Look in the correct model; if it is empty, use the layout.js model.
     *
     * @param rootModel {object}
     * @param layoutModel {object}
     * @param fileNameForLog optional
     * @returns {object} either clone of rootModel with $functions = layout.$functions, if rootModel.$functions
     * has no properties & functions,
     * or the original rootModel if rootModel.$functions has functions or properties
     *
     */
    static chooseCorrectFunctionsModel(rootModel, layoutModel, fileNameForLog) {
      let rootModelDollarName;
      if (fileNameForLog) {
        // convert filename to model name. ex: "data-description.json" to "$dataDescription"
        rootModelDollarName = `$${Utils.hyphenatedToCamelCase(fileNameForLog)}`;
      } else {
        // otherwise, just come up with something reasonable for the log
        // eslint-disable-next-line no-param-reassign
        fileNameForLog = 'appropriate data-description(*).js';
      }

      // if the rootModel doesn't exist, or has no properties or functions, use the layoutModel
      if (hasEmptyFunctions(rootModel)) {
        logger.warn('the metadata functions are empty, using layout.js functions instead. '
          + `You should move your functions to the ${fileNameForLog} source file`);

        // need to 'clone' because $functions is a getter in the original, so we can't assign it
        const newModel = Object.assign({}, rootModel);
        // eslint-disable-next-line no-param-reassign
        newModel.$functions = (layoutModel && layoutModel.$functions);

        // rootModelDollarName should not be undefined (except during test automation, for convenience).
        if (rootModelDollarName && newModel[rootModelDollarName]) {
          newModel[rootModelDollarName].functions = newModel.$functions;
        }

        return newModel;
      }
      return rootModel;
    }
  }

  return ModelUtils;
});

