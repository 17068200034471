'use strict';

define('vb/private/services/boss/transformsUtils',[], () => {
  /**
   * Transforms Utils
   */
  class TransformsUtils {
    /**
     * Find a given key in an object and return the value
     *
     * @param object an JS object
     * @param key a property in the object
     * @returns {*} the key/value pair | null
     */
    static getKeyValue(object, key) {
      return (Object.prototype.hasOwnProperty.call(object, key) && object[key] !== null && object[key] !== undefined)
        ? { key, value: object[key] } : null;
    }

    /**
     * Recursively search a property in a given object
     *
     * @param obj a JS object
     * @param prop a property in the object
     * @returns [*] an array with prop's value | []
     */
    static searchProperty(obj, prop) {
      const keys = Object.keys(obj);
      if (keys.includes(prop)) {
        return [obj[prop]];
      }
      return keys.reduce((acc, key) => (typeof obj[key] === 'object'
        ? acc.concat(TransformsUtils.searchProperty(obj[key], prop)) : acc), []);
    }

    /**
     * Locate 'properties' property in a given object
     *
     * @param object an object to be searched
     * @returns {undefined|*} value of the 'properties' in the object | undefined
     */
    static getPropertiesFromResponse(object) {
      if (object) {
        // Find 'schemas' property in the object
        const schemas = TransformsUtils.searchProperty(object, 'schema');
        if (schemas.length > 0) {
          // Find 'properties' in the schema object
          return TransformsUtils.searchProperty(schemas, 'properties');
        }
      }
      return undefined;
    }

    /**
     * Check if obj is a js object or not
     *
     * @param obj a Javascript object
     * @returns {boolean} true of 'obj' is a js object | false
     */
    static isObject(obj) {
      return (!!obj) && (obj.constructor === Object);
    }
  }

  return TransformsUtils;
});

