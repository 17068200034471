'use strict';

define('vb/private/stateManagement/context/appPackageContext',[
  'vb/private/constants',
  'vb/private/stateManagement/context/flowContext',
], (Constants, FlowContext) => {
  class AppPackageContext extends FlowContext {
    /**
     *
     * @param appPackage
     */
    constructor(appPackage) {
      super(appPackage);

      const propDescriptors = {
        global: {
          get() {
            const extensionApplication = appPackage.application.extensions[appPackage.extensionId];
            return extensionApplication && extensionApplication.expressionContext.application;
          },
          configurable: true,
          enumerable: true,
        },
      };

      /**
       * $application.responsive.XXX
       * $application.user.XXX
       * $application.initParams.XXX
       * $application.currentPage.XXX
       * $application.deployment.XXX
       */
      [Constants.RESPONSIVE_CONTEXT,
        Constants.APPLICATION_USER_VARIABLE,
        Constants.INIT_PARAM_CONTEXT,
        Constants.CURRENT_PAGE_VARIABLE,
        Constants.DEPLOYMENT_CONSTANT,
      ].forEach((property) => {
        propDescriptors[property] = {
          get() {
            // builtins are not extendable, so directly retrieve the value from the application
            // expression context object
            return appPackage.application.expressionContext[property];
          },
          enumerable: true,
          configurable: true,
        };
      });

      Object.defineProperties(this, propDescriptors);
    }

    static getAvailableContexts(appPackage) {
      const availableContexts = super.getAvailableContexts(appPackage);

      delete availableContexts.$flow;

      Object.defineProperties(availableContexts, {
        // Override the $global defined in ContainerContext to point to the extension application
        $global: {
          enumerable: true,
          configurable: true,
          // Retrieve the application extension with the same extension id
          get() {
            return appPackage.expressionContext.global;
          },
        },
      });

      return availableContexts;
    }
  }

  return AppPackageContext;
});

