/**
 * Original @license MIT
 * Forked and adapted from https://github.com/futurist/replace-css-url on Mar 15, 2022 ( tag 1.2.6 ).
 */
/**
 * @fileOverview Replace all url() and @import paths within css text, with custom function map.
 * This module can be loaded from both nodejs as well as requirejs, so it is written as a umd module.
 * This template was adopted from typescript umd generator.
 *
 * RT sources need a requirejs compatible replacePathInCSS. pwaUtils needs a nodejs compatible one.
 *
 * Choice 1: Make a umd module that would load in both cases. Put it in src/vb/private/utils/cssUrlReplacer.js
 * and use it for both cases. The source would look slightly weird but, it should work.
 * Choice 2: Use a regular requirejs module in pwaUtils.js. It is not easy to load Utils.js directly because,
 * even with node amd loader ( https://github.com/ajaxorg/node-amd-loader ), you have to provide a config to
 * load the remaining deps of utils. So, to avoid providing a complete requirejs config, we will have to make
 * cssUrlReplacer a separate standard requirejs module. And load it using amd-loader in pwaUtils.
 * Load directly in RT. Cons: we need to add a dep on node-amd-loader.
 * Choice 3: A variation of 1 where Utils ( src/vb/private/utils.js ) loads  src/vb/private/utils/cssUrlReplacer
 * and provides it as a Utils function. That way, users of replaceCssUrl dont see the dual module (umd)
 * underneath. And pwaUtils can just directly load it.
 * Choice 4: A variation of 2 where Utils ( src/vb/private/utils.js ) loads  src/vb/private/utils/cssUrlReplacer
 * and provides it as a utils function. That way RT can use it conveniently as a Utils function. pwaUtils would
 * still use amd-loader and use  src/vb/private/utils/cssUrlReplacer directly.
 *
 * Current implementation uses (3). Charles agreed that (3) looks OK as well.
 */
(function (factory) {
    if (typeof module === "object" && typeof module.exports === "object") {
        var v = factory(require, exports);
        if (v !== undefined) module.exports = v;
    }
    else if (typeof define === "function" && define.amd) {
        define('vb/private/utils/cssUrlReplacer',["require", "exports"], factory);
    }
})(function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var hasQuote = /^\s*('|")/;

    /**
     * Replaces all url() and @import paths within css text, using a custom function map.
     *
     * @param {string} css The css string whose content would be searched for urls to replace
     * @param {(css: string, quote: string) => string} mapFunc The mapping function takes in the url and returns
     * the replacement for this url
     *
     * @returns {string} The css text with replacements
     */
     function replacePathInCSS(css, mapFunc) {
        return [
            // @import 'whatever'
            /(@import\s+)(')(.+?)(')/gi,
            // @import "whatever"
            // Can't do /(@import\s+)('|")(.+?)('|")/gi or we will
            // match import 'whatever"
            /(@import\s+)(")(.+?)(")/gi,
            // @import url(...) will be covered by the regexps below
            /(url\s*\()(\s*')([^']+?)(')/gi,
            /(url\s*\()(\s*")([^"]+?)(")/gi,
            /(url\s*\()(\s*)([^\s'")].*?)(\s*\))/gi
        ].reduce(function (css, reg /*, index */) {
            return css.replace(reg, function (all, lead, quote1, path, quote2) {
                var ret = mapFunc(path, quote1);
                if (hasQuote.test(ret) && hasQuote.test(quote1)) {
                    quote2 = '';
                    quote1 = quote2;
                }
                return lead + quote1 + ret + quote2;
            });
        }, css);
    }
    exports.default = replacePathInCSS;
});

