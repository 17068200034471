'use strict';

define('vb/private/types/arrayDataProvider',[
  'knockout',
  'vb/private/constants',
  'ojs/ojarraydataprovider',
  'ojs/ojdataprovider',
  'vb/helpers/mixin',
  'vb/private/types/builtinExtendedTypeMixin',
  'vb/private/log',
  'vb/private/utils',
  'vb/private/types/utils/dataProviderUtils',
  'vb/private/types/dataProviderConstants',
  'vb/private/types/utils/jsonDiffer',
  'vb/private/stateManagement/stateUtils',
  'vb/private/types/arrayDataManager',
  'ojs/ojdatasource-common',
],
(ko, Constants, ojArrayDataProvider, ojDataProvider, Mix, BuiltinExtendedTypeMixin, Log, Utils, DataUtils, DPConstants,
  JsonDiffer, StateUtils, ArrayDataManager) => {
  const LOGGER = Log.getLogger('/vb/types/ArrayDataProvider');

  const OJ_REFRESH_EVENT = new ojDataProvider.DataProviderRefreshEvent();

  /**
   * A builtin type that wraps the oj.ArrayDataProvider, so changes to properties on a variable
   * that uses this type can be propagated through to the component layer.
   *
   * There are several ways of configuring the VB ADP based on the nature of the data:
   *
   * 1. data must be stable (once initialized with all the data it rarely changes in its entirety
   * except for mutations - adds/updates/removes).
   *
   * Stable data is often static that is known right away and can be set on the ADP
   * configuration in the following ways:
   *   - inlined in the def via defaultValue
   *   - referenced in the def via an expression pointing to a constant, variable or module function
   *   - populated later in an actionChain called from vbEnter. Generally all data is fetched
   *   once and populated at init time (from an application / flow cache).
   *
   * Mutations on Static Data:
   *  - it's recommended that authors mutate the variable data property directly
   *  rather than use the fireDataProviderEvent (add/update/remove). This ensures that the data
   *  property and the component can be kept in sync.
   *
   *  - data property can be mutated directly using assignVariables action but this generally
   *  requires JS code if rows need to be inserted / removed at specific locations.
   *
   * Refreshing Static data post-init:
   * - generally if data is stable there would be little need for this but if needed the new
   * data can be set on the data property directly.
   * NOTE: It's important to understand that reseting data is an expensive operation as it
   * forces the component to re-render itself with the new data.
   *
   * 2. if data is volatile (no guarantees can be made on the stability of data. Often such data
   * is fetched from Rest endpoint and using an SDP is better suited for these cases). But if
   * ADP is still preferred VB ADP can be configured in the following ways:
   *
   *   - data is an expression that points to a variable that is populated with finite chunk of
   *   data at init time, say from data fetched from a REST endpoint.
   *   - NOTE: in the future we will support calling an external action chain to populate data
   *   automatically.
   *
   *  Mutations on Dynamic Data
   *  - it's recommended that authors mutate the variable data property directly
   *  rather than use the fireDataProviderEvent (add/update/remove). This ensures that the data
   *  property and the component can be kept in sync.
   *  - data property can be mutated directly but with dynamic data this can be tedious
   *  operation to insert at specific index etc. keys are better option because they are
   *  meant to be unique
   *
   * Refreshing Dynamic data post-init:
   * - data property can be mutated directly and will automatically notify the component.
   *
   */
  class ArrayDataProvider extends Mix(ojArrayDataProvider).with(BuiltinExtendedTypeMixin) {
    constructor() {
      const keyAttributes = undefined;

      // JET has deprecated the use of idAttribute and favors keyAttributes. For ADP
      // configuration we continue to use the old name, and use the same value to setup the
      // keyAttributes. Both are currently a String|Array<String> attributes.
      const options = {
        implicitSort: [],
        get idAttribute() {
          return this.keyAttributes;
        },
        set idAttribute(value) {
          this.keyAttributes = value;
        },
        keyAttributes,
      };
      const observableData = ko.observableArray([]);
      super(observableData, options);

      this._dataObservable = observableData;
      this.jsonDiffer = undefined;
      this.adm = undefined;
      this.log = LOGGER;
      this.useKeyAttributes = undefined;
      this.variableLifecycleStage = Constants.VariableLifecycleStage.INIT;
    }

    /**
     * Initialize ADP with initial values for properties.
     */
    activate() {
      // if variable has already been activated return right away. This can happen when a variable reference of this
      // type is passed around (example an already active page passes an ADP reference to a fragment)
      if (this.variableLifecycleStage === Constants.VariableLifecycleStage.ACTIVE) {
        this.log.fine('skipping activation for variable', this.id, 'because it is already active');
        return;
      }

      this.variableLifecycleStage = Constants.VariableLifecycleStage.ACTIVE;
      // TODO: test for entire default value being a constant. value could end up being undefined
      const value = this.getValue() || {};
      const keyAttrsProperty = DPConstants.DataProviderIdAttributeProperty.KEY_ATTRIBUTES;
      const idAttrProperty = DPConstants.DataProviderIdAttributeProperty.ID_ATTRIBUTE;

      // when keyAttributes is set that always wins over idAttribute!
      // when both are set, idAttribute is ignored and a warning logged
      // when idAttribute alone is set, a warning that it is deprecated will be logged.
      // Additionally the value set for idAttribute is used for keyAttributes
      this.useKeyAttributes = true;
      const idAttr = value && value[idAttrProperty];
      if (value && value[keyAttrsProperty]) {
        const keyAttrsVal = DataUtils.unwrapData(value[keyAttrsProperty]);
        this.options[keyAttrsProperty] = keyAttrsVal;

        if (idAttr) {
          this.log.warn('The property \'idAttribute\' with value \'', idAttr, '\' is ignored',
            'as the property \'keyAttributes\'', 'is also configured for the ArrayDataProvider',
            this.id, '. \'idAttribute\' is deprecated and will be removed in future releases.',
            'Use \'keyAttributes\' instead.');
        }
      } else if (idAttr) {
        const idAttrVal = DataUtils.unwrapData(value[idAttrProperty]);
        // idAttribute aliases keyAttributes on this.options
        this.options[idAttrProperty] = idAttrVal;
        this.useKeyAttributes = false;
        this.log.warn('The property \'idAttribute\' is deprecated and will be removed in',
          'future releases. Use the \'keyAttributes\' property instead in the configuration',
          'for ArrayDataProvider', this.id);
      }

      this.jsonDiffer = new JsonDiffer(this.options[this.getIdAttributeProperty()]);

      const { implicitSort } = value;
      if (implicitSort) {
        this.options.implicitSort = DataUtils.unwrapData(implicitSort) || [];
      }

      const { data } = value;
      if (data) {
        const dataEval = DataUtils.unwrapData(data);
        if (dataEval && Array.isArray(dataEval) && dataEval.length > 0) {
          const tmpObsData = ko.utils.unwrapObservable(this._dataObservable);
          ko.utils.arrayPushAll(tmpObsData, dataEval);
          // even when we start with initial data, the ADP instance is created with [] data,
          // so it's important to call valueHasMutated.
          this._dataObservable.valueHasMutated();
        }
      }
    }

    /**
     * keyAttributes is the only supported idAttribute property.
     * @return {string}
     */
    getIdAttributeProperty() {
      return this.useKeyAttributes ? DPConstants.DataProviderIdAttributeProperty.KEY_ATTRIBUTES
        : DPConstants.DataProviderIdAttributeProperty.ID_ATTRIBUTE;
    }

    /**
     * Sets up and returns custom property definition for 'data' property. This is to ensure that
     * the data observable and the 'data' property are kept in sync. JET ArrayDataProviders
     * require a stable reference and it needs to be a ko observable. BUFP-30940
     *
     * the variable 'data' property is always the source of truth and is used to keep the data
     * observable in sync. The data property mutates under the following situations:
     *
     * 1. by assignVars
     *  - normally variable onvalueChanged will get called with the diff, but under certain
     *  situations as explained below under Issues, calls to getValue() come in earlier than
     *  this event, requiring that the data observable be kept in sync sooner.
     *
     * Note: In response to fireDataProviderEventAction vb/ADP does not (never intended to)
     * write directly to the data property, unlike vb/ArrayDataProvider2. It only mutates
     * the data observable. But in the past we did and that could have been a side effect from
     * BUFP-30209 (we continue to allow this (see mode: 'deprecated' in dispatchEvent). We now
     * recommend that page authors using vb/ArrayDataProvider mutate the data property directly and
     * not rely on fireDataProvider event to do so.
     *
     * 2. by component
     *   - component writes are possible today, but not really recommended as a pattern. Component
     *   writes are similar to the behavior of assignVariablesAction (1)
     *
     * 3. at end of init
     *  - when a variable value is fully known and the first call to getValue() at this stage
     * yields the right value.
     *
     * Some issues encountered in the above scenarios:
     * -----------------------------------------------
     * There could be cases where the call to getValue() might happen prematurely requiring
     * checks to keep the observable in sync.
     *
     * A. during VB initialization when the property is updated with its initial value, today
     * there is no event notifying us that the value has changed. BUFP-30444. Until that is
     * fixed we need to ensure that the data observable and the data property values are kept in
     * sync through this method.
     *
     * B. after init() when an action like assignVars mutates the ADP, ko subscribers are
     * notified in synchronous fashion, before the builtin type's listener gets notified of the
     * change (onValueChanged event is delivered async). The subscribers can be any of these
     *
     * i. component bound to ADP var
     * ii. expression used by a page variable or the current action chain variable uses the
     * mutating variable
     * ii. another action chain that perhaps spawned the current chain references the mutating
     * variable.
     *
     * If i...iii does not happen then the handlePropertyVariableChangeEvent listener is
     * called, which handles any deltas
     *
     * @param propKey
     * @param currScope
     * @param variable
     * @param namespace
     * @returns {*}
     */
    getVariablePropertyDefinition(propKey, currScope, namespace, variable) {
      const currentScope = currScope;
      if (propKey === 'data') {
        return {
          get: () => {
            // when data property is requested always return the dataObservable. We also need to
            // ensure that the dataObservable and the 'data' property are kept in sync.
            const value = this.getValue();
            const propValue = value.data;
            // there is a possibility for the getter to be called before variable has been activated, so ensure
            // value is eval-ed only on/or after ADP has been activated.
            if (propValue && (this.variableLifecycleStage !== Constants.VariableLifecycleStage.INIT)) {
              const data = DataUtils.unwrapData(propValue);
              const obsData = ko.utils.unwrapObservable(this._dataObservable);
              if (Array.isArray(propValue)) {
                const eventPayload = this.jsonDiffer.processDeltas(obsData, data);
                if (eventPayload.detail.add || eventPayload.detail.update || eventPayload.detail.remove) {
                  this.dispatchMutationEvent(eventPayload);
                }
              }
            }

            return propValue;
          },
          set: (newValue) => {
            // TODO: ensure that setter called by component does the right thing
            // setter on data is never called directly by component rather; author always writes
            // to the property first, which then events
            currentScope.variableNamespaces[namespace][variable.name][propKey] = newValue;
          },
          enumerable: true,
          configurable: true,
        };
      }
      return super.getVariablePropertyDefinition(propKey, currScope, namespace, variable);
    }

    buildKeys(items) {
      let keyArray;
      const keyAttrs = this.options[DPConstants.DataProviderIdAttributeProperty.KEY_ATTRIBUTES];
      if (keyAttrs) {
        const idHelper = DataUtils.getIdAttributeHelper(keyAttrs);
        keyArray = items && idHelper.getKeys(items);
      }

      return keyArray;
    }

    /**
     * Provide a "definition" of the type including the array items, which applies to the "data" property.
     *
     * @param variableDef actual declaration of the ArrayDataProvider.
     * @param {Object} scopeResolver
     * @return {{type: {data: *, idAttribute: string, keyAttributes: any, itemType: string, implicitSort: [*]},
     * resolved: boolean}}
     */
    // eslint-disable-next-line class-methods-use-this
    getTypeDefinition(variableDef, scopeResolver) {
      let arrayTypeDef;
      if (variableDef.defaultValue && variableDef.defaultValue.itemType) {
        // itemType is specified in the defaultValue
        const { itemType } = variableDef.defaultValue;

        if (typeof itemType === 'string') {
          arrayTypeDef = `${itemType}[]`;
        } else {
          arrayTypeDef = [itemType];
        }
      } else {
        arrayTypeDef = 'any[]';
      }

      return {
        type: {
          data: StateUtils.getType('data', { type: arrayTypeDef }, scopeResolver),
          idAttribute: 'any',
          keyAttributes: 'any',
          itemType: 'any',
          implicitSort: [{
            attribute: 'string',
            direction: 'string',
          }],
        },
        resolved: true,
      };
    }

    /**
     * Called whenever a DataProvider event needs to be raised so listeners can be notified.
     *
     * @param event
     * @param {boolean} external true when called by external callers. default is false.
     */
    dispatchEvent(event, external = false) {
      if (external) {
        if (event.detail && event.detail.refresh
            && (event.detail.add || event.detail.update || event.detail.remove)) {
          this.log.error('unable to dispatch both refresh and mutation events at the same time.',
            event.detail);
          return false;
        }

        // we are here because the event is being raised from an external source
        // (fireDataProviderEvent action e.g.). Log a warning to recommend to remind authors to
        // mutate the data property directly. see bufp-31031
        this.log.warn('It is recommended that changes to the ArrayDataProvider \'data\' ',
          this.id, 'be done using assignVariables action as this event does not mutate the'
          + ' data property. Changing the data property automatically takes care of notifying'
          + ' all registered listeners, such as components of the change.');
        if (event.type === DPConstants.DataProviderEvent.REFRESH
          || (event.detail && event.detail.refresh)) {
          return this.dispatchRefreshEvent(event);
        }
        return this.dispatchMutationEvent(event, ArrayDataManager.DATAPROVIDER_EVENT_MODES.DEPRECATED);
      }
      return super.dispatchEvent(event);
    }

    /**
     * Processes a mutation event payload by updating the (JET ADP) data observable ** directly,
     * which automatically notifies subscribers (UI) of data changes. We don't dispatch an event to
     * super class because JET ADP subscribes to changes on data observable and this is how
     * changes are notified.
     *
     * ** Note: the reason being vb/ADP extends from JET ADP.
     *
     * @param event
     * @param {String} mode - See DATAPROVIDER_EVENT_MODES
     * @private
     */
    dispatchMutationEvent(event, mode = 'default') {
      let dataMutated = false;
      const dataObs = ko.utils.unwrapObservable(this._dataObservable);
      this.adm = (this.adm && this.adm.reInit(dataObs, mode))
        || new ArrayDataManager(this, dataObs, mode);

      dataMutated = this.adm.applyDataMutations(event);

      if (!dataMutated) {
        this.log.error('Unable to dispatch the mutation event due to inadequate information',
          event);
        return false;
      }
      // TODO: BUFP-33170: workaround needs to be removed when BUFP-33286 lands
      this._dataObservable.valueHasMutated();
      // even though this._dataObservable.valueHasMutated() is all that is needed for the
      // component to refresh selectively, the variable creates stale closures with old data,
      // that causes the component to use the same stale data when it writes back subsequently. So
      // when we detect add/remove events (i.e. add/remove items on the data array) we
      // force a refresh.
      // With updates, refresh is still needed, because the edited row data changing also causes
      // the state held by other non-edited rows to go stale.
      // Refresh event causes table to flash, because all rows on the table are re-rendered
      // (bringing state held by each row to the current! This is obviously not desirable and is an
      // temporary until BUFP-33286 is fixed.
      this.dispatchRefreshEvent(OJ_REFRESH_EVENT);
      // end BUFP-33170

      // no need to dispatch event to super because ojArrayDataProvider subscribes to changes on data
      // observable.
      return dataMutated;
    }

    /**
     * Processes a refresh event that
     * @private
     */
    dispatchRefreshEvent(event) {
      // if we are here we assume data has already mutated. So simply dispatch the event.
      this.log.info('Dispatching a REFRESH event, after data mutation');
      return super.dispatchEvent(event);
    }

    /**
     * called when the variable property is changed either directly or its expression
     * re-evaluates.
     *
     * @param e
     */
    handlePropertyVariableChangeEvent(e) {
      if (e.name.endsWith('value')) {
        if (e.diff) {
          if (e.diff.data) {
            this._updateDataObservable(e);
          } else {
            this.log.info('Dispatching a REFRESH event, after for mutation of', e.name);
            this.dispatchEvent(OJ_REFRESH_EVENT);
          }
        }
      }
    }

    /**
     * This method is called when the data variable property mutates requiring the data
     * observable to be updated as well. Here we also check to see implicitSort and idAttribute
     * need to be updated, so we can make all changes enmasse.
     *
     * @param e
     * @private
     */
    _updateDataObservable(e) {
      const { value } = e;
      const obsData = ko.utils.unwrapObservable(this._dataObservable);
      const data = (value && value.data) || [];
      // generally oldValue and the data observable are in sync when this listener is called but
      // it's quite possible for them to get out-of-sync if there is no consumer of the ADP, so
      // its value is never evaluated for them to be kept in sync.
      const eventPayload = this.jsonDiffer.processDeltas(obsData, data);
      const dataChanged = (eventPayload.detail.add
      || eventPayload.detail.update || eventPayload.detail.remove);

      // only when data has changed check whether other properties have changed. why? because
      // any time keys/idAttribute or implicitSort changes new data needs to be provided.
      if (dataChanged) {
        // check if other properties have changed! this should be rare
        if (value.implicitSort && value.implicitSort.length > 0) {
          const oldImplicitSort = Utils.resolveIfObservable(this.options.implicitSort);
          const diff = this.jsonDiffer.diffWithNoObjectHash(value.implicitSort, oldImplicitSort);
          if (diff) {
            this.options.implicitSort = value.implicitSort;
            this.log.info('implicitSort property for ADP variable', this.id,
              'has changed! old value:', oldImplicitSort, 'new data:', value.implicitSort);
          }
        }

        const keyAttrsProp = DPConstants.DataProviderIdAttributeProperty.KEY_ATTRIBUTES;
        const oldKeyAttrs = Utils.resolveIfObservable(this.options[keyAttrsProp]);
        const idAttrProperty = this.getIdAttributeProperty();
        const newKeyAttrs = value[idAttrProperty];
        if (Utils.diff(oldKeyAttrs, newKeyAttrs)) {
          this.options[idAttrProperty] = newKeyAttrs;

          // reset the jsonDiffer
          this.jsonDiffer = new JsonDiffer(newKeyAttrs);
          this.log.info(`${idAttrProperty} property for ADP variable`, this.id,
            'has changed! old value:', oldKeyAttrs, 'new data:', newKeyAttrs);
        }

        this.log.info('data property for ADP variable', this.id,
          'has changed! old data:', e.oldValue, 'new data:', data);

        this.dispatchMutationEvent(eventPayload);
      }
    }
  }

  return ArrayDataProvider;
});

