'use strict';

define('vb/types/instanceFactory/loader',[
  'text!./builtinTypes.json',
  'text!./vbMultiServiceDataProvider2/index.d.ts',
  'text!./vbServiceDataProvider2/index.d.ts',
], () => {
  // without this file the above typescript and json files are not getting picked up by the grunt task
});

