'use strict';

define('vbsw/private/pwa/applicationCache',['vbsw/private/stateCache'], (StateCache) => {
  /**
   * Cache used to store static application assets for PWA's.
   * This cache is not namespaced, and it ignores query parameters on the lookup.
   */
  class ApplicationCache extends StateCache {
    /**
     * Retrieves the cached data for the given url.
     *
     * @param url the url for the cached data
     * @returns {Promise}
     */
    get(url) {
      if (this.swCache) {
        return this.swCache.match(url).then((response) => {
          if (response) {
            return response.json();
          }
          return Promise.resolve();
        });
      }
      return Promise.resolve();
    }

    /**
     * Put the data directly in a cache.
     * @param url the url for where to cache the data
     * @param data the data to cache
     * @returns {Promise<void>}
     */
    put(url, data) {
      if (this.swCache) {
        return this.swCache.put(url, data).catch((err) => {
          console.log('put', url, 'failed with', err);
        });
      }
      return Promise.resolve();
    }

    getResponse(url, options) {
      if (this.swCache) {
        return this.swCache.match(url, options);
      }
      return Promise.resolve();
    }
  }
  return ApplicationCache;
});

