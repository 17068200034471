/* eslint-disable class-methods-use-this */
/* eslint max-classes-per-file: ["error", 2] */

'use strict';

define('vb/types/extendedTypeMixin',['vb/types/extendedType', 'vb/private/stateManagement/stateUtils'], (ExtendedType, StateUtils) => {
  /**
   *********************************************************************************************************************
   * NOTE: Devs who plan to use this mixin or duck type class in this mixin to build their custom extended types must
   * contact the VB runtime team (#help-visualbuilder channel) before starting their work. It is recommended that
   * authors extend the vb/types/ExtendedType class to create new custom types for use in VB applications and not use
   * this mixin class.
   *********************************************************************************************************************
   */

  /**
   * Mixes in prototype methods (including constructor) from src classes onto cls, without overwriting prototype
   * methods that may already be declared in cls.
   * @param cls
   * @param src
   */
  const mixinBehaviors = (cls, ...src) => {
    const clz = cls;
    src.forEach((srcCl) => {
      Object.getOwnPropertyNames(srcCl.prototype).forEach((key) => {
        if (!clz.prototype[key]) {
          clz.prototype[key] = srcCl.prototype[key];
        }
      });
    });
  };

  /**
   * A mixin that provides additional behavior over an extended type. See vb/types/extendedType
   * @deprecated
   */
  class ExtendedTypeAddOnBehavior {
    /**
     * Invokes an action chain with the given ID. The ID may include qualifiers to invoke
     * an action chain in different scopes.
     *
     * The parameters will be passed directly into the chain and may be expressions.
     *
     * A promise will be returned containing the name of the outcome and result from calling
     * that chain. Errors occurring during the chain will result in this promise being rejected.
     *
     * @final
     * @param chainLocator the locator for the chain
     * @param params Parameters sent to the chain, or an empty map
     * @deprecated
     */
    callActionChain(chainLocator, params = {}) {
      return this.handler.callActionChain(chainLocator, params);
    }

    /**
     * Sets up object accessors for all properties that the builtin variable supports. By
     * default they read from and write to the underlying 'value' variable.
     * @param defaultValue
     * @param currentScope
     * @param namespace
     * @param variable
     * @deprecated
     */
    setupVariableProperties(defaultValue, currentScope, namespace, variable) {
      Object.keys(defaultValue).forEach((k) => {
        Object.defineProperty(this, k, this.getVariablePropertyDefinition(k, currentScope, namespace, variable));
      });
    }

    /**
     * Called to setup property accessors for each property with name (k). Subclasses can override
     * this method to have control over accessors for specific properties.
     * @param k
     * @param currScope
     * @param namespace
     * @param variable
     * @returns {{get: (function(): *), set: (function(*)), enumerable: boolean, configurable: boolean}}
     * @deprecated
     */
    getVariablePropertyDefinition(k, currScope, namespace, variable) {
      return StateUtils.getVariablePropertyDefinition(this, k, currScope, namespace, variable);
    }
  }

  /**
   * Mixin class that mixes in methods from ExtendedTypeAddOnBehavior and vb/type/ExtendedType onto the
   * ExtendedTypeMixin.
   * @type {function(*): ExtendedTypeMixed}
   * @deprecated this mixin class will be deprecated in a future release and use of this class is not recommended by
   * authors of custom extended types. Instead use the new vb/types/ExtendedType to extend your implementation of a
   * custom extended type from.
   */
  const ExtendedTypeMixin = ((superclass) => {
    /**
     * A extended type class used to create a new redux and configuration aware type in the system.
     * A custom extended type may use this mixin by importing the 'vb/helpers/mixin' module and defining their
     * class in the following way:
     *
     * <code>
     *   class MyType extends Mix(BaseType).with(ExtendedTypeMixin)
     * </code>
     *
     * where 'Mix' is the module name for the 'vb/helpers/mixin' module,
     * 'BaseType' is the base or root class that you want to start with. If no base or root class is provided a noop
     * class ias assumed - const NOOP_CLASS = class {};
     * 'ExtendedTypeMixin' is this class that extends from 'BaseType' and would be the superclass of 'MyType'.
     *
     * This mixin automatically pulls in the prototype methods defined on vb/types/ExtendedType.
     *
     * By default, types that define modules that do not use this mixin are just instantiated. If configuration is
     * needed in metadata, or the type needs to store internal state, it should use this class.
     *
     * Variables can have a type that points to a class. The runtime discovers this by detecting that the type
     * has a forward slash in the type name (i.e. my/Type).
     *
     * ExtendedTypes also have a value (defined by defaultValue) that can be used for configuration. For example:
     *
     * <code>
     *  "myVariable": {
     *    "type": "vb/Type",
     *    "defaultValue": {
     *      "myPropertyOne": "foo",
     *      "myPropertyTwo": {
     *        "foo: "bar",
     *        "hello": "{{ 'hello' + 'world' }}"
     *      }
     *    }
     *  }
     * </code>
     *
     * The value is created as a variable (and thus has all the semantics of variable, including change events.
     * The type of the value is defined via the 'getTypeDefinition' function, which returns the type as if it
     * were defined directly on the variable (in a typical page model).
     *
     * The value is accessible in expressions via '$scope.variables.theInstance.value' where 'theInstance' is the
     * instance of this type that is created.
     *
     * For this reason, this will overlay any local 'value' properties, so be careful not to use that property
     * internally. This should not be a problem, due to the use of get/setState for internal state storage.
     *
     * Extended types should externalize all their internal state so that it can be captured in redux. To facilitate
     * this, use get/setState convenience methods.
     *
     * Also refer to docs for ExtendedType.
     * @deprecated this mixin class will be deprecated in a future release and use of this class is not recommended by
     * authors of custom extended types. Instead use the new vb/types/ExtendedType class to subclass your custom
     * extended type class from.
     * @class ExtendedTypeMixed
     */
    class ExtendedTypeMixed extends superclass {}
    // mixin prototype methods from ExtendedType first and then ExtendedType
    mixinBehaviors(ExtendedTypeMixed, ExtendedTypeAddOnBehavior, ExtendedType);
    return ExtendedTypeMixed;
  });

  return ExtendedTypeMixin;
});

