'use strict';

define(
  'vbsw/private/plugins/anonymousAuthHandlerPlugin',
  [
    'vbsw/private/plugins/abstractAuthHandlerPlugin',
  ], (AbstractAuthHandlerPlugin) => {


  /**
   * Authentication handler for anonymous users
   */
  class AnonymousAuthHandlerPlugin extends AbstractAuthHandlerPlugin {
    deriveUrlKey(request) {
      return 'anonymous';
    }
  }

  return AnonymousAuthHandlerPlugin;
});

