'use strict';

define('vb/private/stateManagement/navigationContext',[
  'vb/private/log',
  'vb/private/utils',
], (Log, Utils) => {
  const logger = Log.getLogger('/vb/stateManagement/navigationContext');
  const ACTIVE = 'active';
  const CANCELLED = 'cancelled';

  /**
   * Use to hold the context of the current navigation during all the steps of navigation.
   * Most importantly it allows to change the state of the navigation from active to cancelled.
   * When cancelled, navigation process can decided to abort the navigation and return immediately.
   */
  class NavigationContext {
    constructor(container, options) {
      this.status = ACTIVE;
      this.container = container;
      this.options = options;
      this.id = Utils.generateUniqueId();
      this.navigatePromise = null;
      // Keep track of pages created during this navigation chain
      // so that we can clean them if the navigation is cancelled.
      this.pages = [];
    }

    /**
     * Start the navigation process by invoking navigateToPage using the container and the options
     * of this navigation context.
     * Make sure multiple invocation returns the same promise.
     * @return {Promise} the navigation promise
     */
    navigate() {
      this.navigatePromise = this.navigatePromise || this.container.navigateOperation(this.options, this);
      return this.navigatePromise;
    }

    /**
     * Cancel the current navigation
     */
    cancel() {
      logger.info('A new navigation cancelled navigation to', this.options.page);
      this.status = CANCELLED;
    }

    /**
     * Check the status of the navigation and return true if the state is active
     * @return {Boolean} true if the state is active
     */
    isActive() {
      return this.status === ACTIVE;
    }

    /**
     * Check the status of the navigation and return true if the state is cancelled
     * @return {Boolean} true if the state is cancelled
     */
    isCancelled() {
      return this.status === CANCELLED;
    }

    /**
     * Return true is the navigation info passed as an argument has the same container and options
     * @param  {Container} container
     * @param  {Object} options
     * @return {Boolean} true if the same
     */
    equals(container, options) {
      return (this.container === container && !Utils.diff(this.options, options));
    }

    /**
     * Clean up pages created if the navigation was cancelled
     */
    clean() {
      while (this.pages.length) {
        this.pages.pop().dispose();
      }
    }
  }

  return NavigationContext;
});

