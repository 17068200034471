'use strict';

define('vbc/private/performance/performanceCategory',[], () => {
  const EMPTY_ENTRIES_PROMISE = (format) => Promise.resolve(format([]));
  /**
   * A performance category has a name and a promise that resolves to (optionally formatted) entries.
   * If formatCallback is specified, it is applied to each entry individually.
   */
  class PerformanceCategory {
    // Because of a circular dependency between Performance and Log, logger needs to be loaded on demand
    getLogger() {
      if (!this.logger) {
        const Log = requirejs('vbc/private/log');
        this.logger = Log.getLogger('/vbc/private/performance/performanceCategory');
      }
      return this.logger;
    }

    /**
     * @param {string} name category name
     * @param {function} entriesCallback a callback is a promise to a list performance category entries,
     * optionally formatted. If no entries callback is specified, this category will be empty.
     */
    constructor(name, entriesCallback) {
      this.name = name;
      if (typeof entriesCallback === 'function') {
        this.entriesCallback = entriesCallback;
      } else {
        this.getLogger().warn(`A specified entriesCallback: ${entriesCallback} is not a function and won't be used`);
        this.entriesCallback = EMPTY_ENTRIES_PROMISE;
      }
    }

    getName() {
      return this.name;
    }

    /**
     * @param {function} formatCallback formats each category entry individually
     * @returns {Promise} a promise to list of (optionally formatted) entries for this category,
     * or a promise to an empty array, if no entriesCallback has been specified
     */
    getEntries(formatCallback = ((e) => e)) {
      return this.entriesCallback(formatCallback);
    }
  }
  PerformanceCategory.EMPTY_ENTRIES_PROMISE = EMPTY_ENTRIES_PROMISE;
  return PerformanceCategory;
});

