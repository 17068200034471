'use strict';

/**
 * class LayoutOverlayModel
 *  extends EmptyModel
 *
 * represents the VB overrides for JET global templates
 * https://confluence.oraclecorp.com/confluence/display/JET/Global+Template+Support
 */

define('vb/extensions/dynamic/private/models/layoutOverlayModel',[
  'vb/extensions/dynamic/private/models/abstractModel',
  'vb/extensions/dynamic/private/models/emptyModel',
], (AbstractModel, EmptyModel) => AbstractModel(EmptyModel));

