'use strict';

define('vb/action/builtin/callModuleFunctionAction',[
  'vb/action/action',
  'vb/private/utils',
  'vb/private/stateManagement/stateUtils',
  'vb/private/action/assignmentHelper',
  'vb/private/log',
],
(Action, Utils, StateUtils, AssignmentHelper, Log) => {
  /**
   * Invokes a custom javascript method on a container method, where the container can be a flow or a page. This
   * action is the principal method of calling custom javascript code.
   *
   * The parameters to this action are as follows:
   *
   * module:      The module to call the function on (usually $page.functions, $application.functions,
   *              or $flow.functions)
   * funcName:    The name of the function to execute in the module
   * params:      (optional) An array of parameters to pass into the function. This parameter array will be
   *              expanded into individual parameters to the function
   * paramTypes:  (optional) An array of type definitions describing the parameters (in page model format)
   * returnType:  (optional) A type defining the return value (in page model format)
   *
   * If paramTypes are returnType are defined, the parameters or return types will be coerced to that type.
   */
  class CallModuleAction extends Action {
    constructor(id, label) {
      super(id, label);
      this.log = Log.getLogger('/vb/action/action/CallModuleAction');
      this.context = null;
    }

    /**
     * returned Promise is resolved with Outcome (success or failure), or rejected with a message
     * @param parameters
     * @returns {Promise}
     */
    perform(parameters) {
      const module = parameters.module;
      const funcName = parameters.functionName;
      const params = parameters.params;
      const paramTypes = parameters.paramTypes;
      const returnType = parameters.returnType;

      if (params && !Array.isArray(params)) {
        const msg = `Action ${this.logLabel}: Inputs to CallModuleFunctionAction` +
            'should be an array, even if it is a single item.';
        this.log.error(msg);
        return Promise.reject(msg);
      }

      // if param types are specified, do some basic checking, then coerce to the types
      if (paramTypes && (!Array.isArray(paramTypes) || !params || !params.length === paramTypes.length)) {
        const msg = `Action ${this.logLabel}: Parameters to CallModuleFunctionAction does` +
            'not match the parameter types.';
        this.log.error(msg);
        return Promise.reject(msg);
      }
      if (paramTypes) {
        params.forEach((v, i) => {
          const type = paramTypes[i];
          params[i] = this.coerceToType(`${funcName}:param[${i}]`, v, type);
        });
      }

      // to protect custom code from making unpredictable changes, we are going to pass by value
      // instead of by reference. we understand this is controversial to some respects, but it
      // means the behavior of custom code is deterministic and does not have hard to debug side
      // affects. users can still wire up the output of custom code to their original sources - but
      // that is predictable
      const clonedParams = [];
      if (params) {
        Utils.cloneObject(params, clonedParams);
      }

      // RESOLVE: this is breaking variable assignment downstream
      // Utils.deepFreeze(clonedParams);
      if (!module || !module[funcName]) {
        const msg = `Action ${this.logLabel}: The module does not exist or the function '${funcName}'` +
            ' does not exist in the module';
        const cont = this.context && this.context.container;
        // Add location of the error: Page 'main' or Flow 'foo'.
        const detail = cont ? ` for ${cont.className} '${cont.id}'` : '';
        return Promise.reject(`${msg}${detail}.`);
      }

      const func = module[funcName];

      let ret;
      try {
        ret = func.apply(module, clonedParams);
      } catch (e) {
        const msg = `Action ${this.logLabel}: error calling function: ${funcName}(${params})`;
        this.log.error(msg, e);
        return Promise.resolve(Action.createFailureOutcome(msg, e));
      }

      return Promise.resolve(ret)
        .then((result) => {
          let res = result;
          // if we have a return type, we want to form the return value of the function to that type. we start
          // out by resolving the type
          if (returnType) {
            res = this.coerceToType(`${funcName}:returnType`, res, returnType);
          }

          return Action.createSuccessOutcome(res);
        })
        .catch((e) => {
          const msg = `Action ${this.logLabel}: error calling function: ${funcName}(${params || ''})`;
          return Action.createFailureOutcome(msg, e);
        });
    }

    /**
     * Treat the 'module' parameter differently, the expression should writable
     * @param  {String} key
     * @param  {*} value
     * @param  {Object} availableContexts
     * @return {*} the param value
     */
    static buildParamValue(key, value, availableContexts) {
      return (key === 'module')
        // The module object should be writable otherwise the module instance cannot be modified
        // so evaluate the module expression using prohibitWrite = false
        ? StateUtils.getValueOrExpression(value, availableContexts, false)
        : Action.buildParamValue(key, value, availableContexts);
    }

    coerceToType(name, value, type) {
      // pick attributes from the return value into an object that matches the type
      const resolvedType = StateUtils.getType(name, { type }, this.context.container.scopeResolver);
      const newValue = AssignmentHelper.coerceType(value, resolvedType);
      this.log.finer('Action', this.logLabel, name, value, 'was coerced to', newValue);
      return newValue;
    }

    setContext(context) {
      this.context = context;
    }
  }

  return CallModuleAction;
});

