'use strict';

define('vb/action/builtin/returnAction',[
  'vb/action/action',
],
(Action) => {
  /**
   *
   */
  class ReturnAction extends Action {
    perform(parameters) {
      return Action.createOutcome(parameters.outcome, parameters.payload);
    }
  }

  return ReturnAction;
});

