'use strict';

/* eslint-disable class-methods-use-this */

define('vb/extensions/process/helpers/processService',[
  'text!./processModel.json',
  'text!./service.json',
  'vb/private/services/serviceProvider',
  'vb/private/services/servicesManager',
], (
  processModel,
  service,
  ServiceProvider,
  ServicesManager) => {
  const SERVICE_NAME = 'oracle.vb.internal.service.integration.process';
  const SERVICE_PATH = 'vb/extensions/process/helpers/service.json';

  const cache = Symbol('cache');

  class Endpoint {
    constructor(computeFunction) {
      this._computeFunction = computeFunction;
    }

    get deployedProcessCollection() {
      return this._computeFunction('getDeployedProcessCollection');
    }

    get deployedProcess() {
      return this._computeFunction('getDeployedProcess');
    }

    get processInstanceCollection() {
      return this._computeFunction('getProcessInstanceCollection');
    }

    get processInstance() {
      return this._computeFunction('getProcessInstance');
    }

    get taskCollection() {
      return this._computeFunction('getTaskCollection');
    }

    get task() {
      return this._computeFunction('getTask');
    }

    get startProcess() {
      return this._computeFunction('startProcess');
    }

    get performTaskAction() {
      return this._computeFunction('performTaskAction');
    }
  }

  class Definition {
    constructor(model) {
      this[cache] = model;
    }

    get deployedProcessCollection() {
      return this[cache].deployedProcessCollection;
    }

    get deployedProcess() {
      return this[cache].deployedProcess;
    }

    get extendedDeployedProcessCollection() {
      return this[cache].extendedDeployedProcessCollection;
    }

    get extendedDeployedProcess() {
      return this[cache].extendedDeployedProcess;
    }

    get processInstanceCollection() {
      return this[cache].processInstanceCollection;
    }

    get processInstance() {
      return this[cache].processInstance;
    }

    get taskCollection() {
      return this[cache].taskCollection;
    }

    get task() {
      return this[cache].task;
    }
  }

  class ProcessService {
    constructor() {
      this[cache] = {
        processServiceProvider: new ServiceProvider(SERVICE_NAME, SERVICE_PATH, JSON.parse(service)),
        endpoint: new Endpoint(operationId => this.computeEndpoint(operationId)),
        definition: new Definition(JSON.parse(processModel)),
      };

      ServicesManager.addServiceProvider(this[cache].processServiceProvider);
    }

    /**
     * @param operationId The operation id defined in the swagger
     * @returns {String}
     */
    computeEndpoint(operationId) {
      return `${this.getServiceId()}/${operationId}`;
    }

    /**
     * @returns {String} the process service id
     */
    getServiceId() {
      return this[cache].processServiceProvider.getServiceName();
    }

    /**
     * @returns {object} an object that exposes the endpoint ids for the process service
     */
    get endpoint() {
      return this[cache].endpoint;
    }

    /**
     * @returns {object} an object that exposes the type definitions for the process service
     */
    get definition() {
      return this[cache].definition;
    }

    isRequestingExtendedPayload(parameters) {
      if (parameters) {
        const extended = parameters.extended;
        return extended === true || extended === 'true';
      }
      return false;
    }
  }

  return new ProcessService();
});

