'use strict';

define('vbc/private/pwa/pwaUtils',['vbc/private/constants', 'vbc/private/utils'], (Constants, Utils) => {
  /** @type {RequestInit} */
  const requestOptions = {
    method: 'GET',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  class PwaUtils {
    /**
     * @returns {boolean} a boolean indicating whether the browser is online. We cannot
     * use OPT API here, because it has not been loaded yet and it relies on window object.
     */
    static isOnline() {
      return Utils.isOnline();
    }

    /**
     * Checks if vbInitConfig corresponds to a PWA application. Currently, this means that:
     *
     * For VB mobile applications:
     * - the application is not running in page designer or preview mode (vbInitConfig.IS_DT_MODE is false)
     * - a non empty shellPath parameter is present in vbInitConfig.SERVICE_WORKER_CONFIG.
     * For VB web applications:
     * - vbInitConfig.PWA_CONFIG is defined and not disabled
     *
     * @param config vbInitConfig
     * @returns {*|boolean} true, if vbInitConfig is configured for a PWA, false otherwise
     */
    static isPwaConfig(config) {
      return PwaUtils.isMobilePwaConfig(config) || PwaUtils.isWebPwaConfig(config);
    }

    /**
     * @param {*} config vbInitConfig
     * @returns {*|boolean} true, if vbInitConfig is configured for a PWA for a mobile VB application, false otherwise
     */
    static isMobilePwaConfig(config) {
      return !!(config
        && !config.IS_DT_MODE
        && config.SERVICE_WORKER_CONFIG
        && !config.SERVICE_WORKER_CONFIG.disabled
        && PwaUtils.isPwaShellPath(config.SERVICE_WORKER_CONFIG.shellPath));
    }

    /**
     * @param {*} config vbInitConfig
     * @returns {*|boolean} true, if vbInitConfig is configured for a PWA for a web VB application, false otherwise
     */
    static isWebPwaConfig(config) {
      return !!(config && config.PWA_CONFIG && !config.PWA_CONFIG.disabled);
    }

    /**
     * @param {*} config vbInitConfig
     * @returns true, if a PWA application is configured to deliver vbBeforeAppInstallPrompt event. For PWA's based
     * on mobile VB apps, this is always true. For PWA's based on VB web apps, delivery of this event can be skipped
     * by setting PWA_CONFIG.skipBeforeInstallPrompt vbInitConfig parameter to true
     */
    static shouldAddBeforeInstallPromptListener(config) {
      return PwaUtils.isMobilePwaConfig(config)
      || !!(PwaUtils.isWebPwaConfig(config) && !config.PWA_CONFIG.skipBeforeInstallPrompt);
    }

    static isPwaShellPath(shellPath) {
      return typeof shellPath === 'string' && shellPath.trim().length > 0;
    }

    /**
     * @returns {Promise<any>} a Promise to a Service Worker's scope or undefined, if there is no service worker
     * registered for this application or the browser does not support Service Worker
     */
    static getServiceWorkerScope() {
      return Promise.resolve()
        .then(() => navigator.serviceWorker.getRegistration())
        .then((r) => r.scope)
        .catch(() => {});
    }

    /**
     * Load JSON application shell cache file. The file should be in the following structure:
     * {
     * "appFiles" : [
     * ],
     *  "jetFiles" : [
     * ],
     *  "files" : [
     * ],
     * }
     * @param shellPath a location of appShellCache.json file, for example:
     * <i>./mobileApps/testApp/version_1552430187000/appShellCache.json</i>, if called on SW thread or
     * <i>/mobileApps/testApp/version_1552430187000/appShellCache.json</i>, if called on UI thread
     * @param fetchMethod
     * @param fetchContext
     * @returns {Promise<{}>} a promise to application shell cache file. If application shell file fails to load,
     * promise rejects.
     */
    static loadAppShellFile(shellPath, fetchMethod, fetchContext) {
      return Promise.resolve()
        .then(() => {
          if (!shellPath) {
            throw new Error('loadAppShellFile(): empty application shell path specified');
          }
          const request = new Request(shellPath, requestOptions);
          return fetchMethod.call(fetchContext, request);
        })
        .then((result) => {
          if (result && result.ok) {
            return result.text();
          }
          throw new Error(`loadAppShellFile(${shellPath}): invalid response: ${result ? result.status : result}`);
        })
        .then((text) => {
          if (text) {
            return JSON.parse(text);
          }
          throw new Error(`loadAppShellFile(${shellPath}): result.text is empty`);
        });
    }

    /**
     * Append / at the end of a non empty path.
     * @param path original path
     * @returns {string} path with a / at the end, or an empty string if path was empty/undefined
     */
    static postSlash(path) {
      if (path) {
        return path.trim().endsWith(Constants.PATH_SEPARATOR) ? path : `${path.trim()}${Constants.PATH_SEPARATOR}`;
      }
      return '';
    }
  }
  return PwaUtils;
});

