'use strict';

define('vb/private/stateManagement/context/flowContext',[
  'vb/private/constants',
  'vb/private/stateManagement/context/containerContext',
  'vb/private/stateManagement/context/flowBaseContext',
], (Constants, ContainerContext, FlowBaseContext) => {
  class FlowContext extends ContainerContext {
    /**
     *
     * @param flow
     */
    constructor(flow) {
      super(flow);

      const propDescriptors = {};

      /**
       * $flow.currentPage
       * $flow.info
       * $flow.path (or $application.path)
       */
      [Constants.CURRENT_PAGE_VARIABLE, Constants.INFO_CONTEXT, Constants.PATH_VARIABLE].forEach((property) => {
        propDescriptors[property] = {
          get() {
            return flow.scope.variableNamespaces[Constants.VariableNamespace.BUILTIN][property];
          },
          enumerable: true,
          configurable: true,
        };
      });

      Object.defineProperties(this, propDescriptors);
    }

    dispose() {
      super.dispose();
      // To stop the navigation to update this observable property, replace the content with an empty object
      Object.defineProperty(this, Constants.CURRENT_PAGE_VARIABLE, {
        value: {},
        enumerable: true,
        configurable: true,
      });
    }

    static get BaseContextType() {
      return FlowBaseContext;
    }

    static getAvailableContexts(flow) {
      const availableContexts = super.getAvailableContexts(flow);

      Object.defineProperties(availableContexts, {
        $flow: {
          enumerable: true,
          configurable: true,
          get: () => flow.expressionContext,
        },
      });

      return availableContexts;
    }
  }

  return FlowContext;
});

