'use strict';

/**
 * Delegates to a specific instance of service worker manager class.
 */
define('vbsw/private/serviceWorkerManager',['vbsw/private/serviceWorkerManagerClass'], (ServiceWorkerManagerClass) => {
  class ServiceWorkerManager {
    setInstance(instance) {
      this.instance = instance;
    }

    getInstance() {
      this.instance = this.instance || new ServiceWorkerManagerClass();
      return this.instance;
    }
  }
  return new ServiceWorkerManager();
});

