'use strict';

define('vb/extensions/process/action/builtin/getProcessInstanceCollectionAction',[
  'vb/extensions/process/action/builtin/processAction',
  'vb/extensions/process/helpers/processService',
], (
  ProcessAction,
  ProcessService) => {
  /**
   * Retrieves a process instance collection.
   *
   * Details:
   *
   * <ul>
   *   <li>Filters are applied with an "and" logic.</li>
   *
   *   <li>Filters with an array value can also take a single value (so the value of "alias" could be "process01").</li>
   *
   *   <li>The outcome returned by invoking the action is an object
   *   like <i>{ name: "", result: { content: {}, info: {} }</i>, where:
   *   <ul>
   *     <li>The value of "name" is either "success" or "failure"</li>
   *     <li>The value of "content" is a JSON object</li>
   *     <li>The value of "info" is an object with additional information like the status and headers of
   *         any HTTP response handled by the action</li>
   *   </ul></li>
   *
   *   <li>The retrieved collection contains an "items" array with the individual objects and also has other
   *   information like pagination details.</li>
   * </ul>
   *
   * Example:
   *
   * <pre>
   *  "action01": {
   *    "module": "vb/extensions/process/action/builtin/getProcessInstanceCollectionAction",
   *    "parameters": {
   *      "alias": ["process01", "process02"],
   *      "deployedProcessId": ["player~!sada~~ASdasd"],
   *      "state": ["OPEN"],
   *      "priority": [2, 3],
   *      "startUser": ["vl@example.com", "mp@example.com"],
   *      "since": "2018-02-14T11:46:18.000Z",
   *      "until": "2018-02-20T11:46:18.000Z",
   *      "limit": 80,
   *      "offset": 2
   *    }
   *  }
   * </pre>
   */
  class GetProcessInstanceCollectionAction extends ProcessAction {
    static endpoint() {
      return ProcessService.endpoint.processInstanceCollection;
    }

    static responseType() {
      return ProcessService.definition.processInstanceCollection;
    }

    static restActionIdentification() {
      return { id: 'getProcessInstanceCollectionAction', description: 'Get a process instance collection' };
    }
  }

  return GetProcessInstanceCollectionAction;
});

