'use strict';

/**
 * class DataDescriptionExtensionModel
 *  extends TranslationsModel, includes translations.
 */
define('vb/extensions/dynamic/private/models/dataDescriptionExtensionModel',[
  'vb/extensions/dynamic/private/models/abstractModel',
  'vb/extensions/dynamic/private/models/translationsModel',
], (AbstractModel, TranslationsModel) => AbstractModel(TranslationsModel, 'data-description-x.js'));

