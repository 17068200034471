// eslint-disable-next-line max-classes-per-file
/* eslint max-classes-per-file: ["error", 7] */

'use strict';

define('vb/private/types/utils/dataProviderUtils',['knockout', 'vb/private/utils', 'vb/private/log', 'vb/binding/expression',
  'vb/private/types/dataProviderConstants'],
(ko, Utils, Log, Expression, DPConstants) => {
  /**
   * utilities for data providers, custom variables.
   *
   */
  // for static method in ServiceAsyncIterator
  const logger = Log.getLogger('/vb/private/types/utils/dataProviderUtils');

  /**
   * local implementation of oj.Object.compareValues.
   */
  const OJCompareUtils = {
    innerEquals: (obj1, obj2) => {
      if (obj1 === obj2) {
        return true;
      }

      if (!(obj1 instanceof Object) || !(obj2 instanceof Object)) {
        return false;
      }

      if (obj1.constructor !== obj2.constructor) {
        return false;
      }

      const hasOwnProperty = Object.prototype.hasOwnProperty;
      const props1 = Object.keys(obj1);
      let prop;

      // eslint-disable-next-line consistent-return
      props1.forEach((i) => {
        prop = props1[i];

        if (hasOwnProperty.call(obj1, prop)) {
          if (!hasOwnProperty.call(obj2, prop)) {
            return false;
          }

          if (obj1[prop] !== obj2[prop]) {
            if (typeof (obj1[prop]) !== 'object') {
              return false;
            }

            if (!OJCompareUtils.innerEquals(obj1[prop], obj2[prop])) {
              return false;
            }
          }
        }
      });

      const props2 = Object.keys(obj2);
      // eslint-disable-next-line no-plusplus,consistent-return
      props2.forEach((i) => {
        prop = props2[i];

        if (hasOwnProperty.call(obj2, prop) && !hasOwnProperty.call(obj1, prop)) {
          return false;
        }
      });

      if (props1.length === 0 && props2.length === 0) {
        // we are dealing with objects that have no properties like Number or Date.
        return JSON.stringify(obj1) === JSON.stringify(obj2);
      }

      return true;
    },

    compareValues: (obj1, obj2) => {
      if (obj1 === obj2) {
        return true;
      }

      const obj1Type = typeof obj1;
      const obj2Type = typeof obj2;

      if (obj1Type !== obj2Type) {
        // of different type so consider them unequal
        return false;
      }

      // At this point means the types are equal

      // note that if the operand is an array or a null then typeof is an object
      // check if either is null and if so return false [i.e. case where one might be a null and another an object]
      // and one wishes to avoid the null pointer in the following checks. Note that null === null has been already
      // tested
      if (obj1 === null || obj2 === null) {
        return false;
      }

      // now check for constructor since I think by here one has ruled out primitive values and if the constructors
      // aren't equal then return false
      if (obj1.constructor === obj2.constructor) {
        // these are special cases and will need to be modded on a need to have basis
        if (Array.isArray(obj1)) {
          return OJCompareUtils.compareArrayValues(obj1, obj2);
        }
        if (obj1.constructor === Object) {
          // for now invoke innerEquals and in the future if there are issues then resolve them
          return OJCompareUtils.innerEquals(obj1, obj2);
        }
        if (obj1.valueOf && typeof obj1.valueOf === 'function') {
          // test cases for Boolean, String, Number, Date
          // Note if some future JavaScript constructors
          // do not impl it then it's their fault
          return obj1.valueOf() === obj2.valueOf();
        }
      }

      return false;
    },

    compareArrayValues: (array1, array2) => {
      if (array1.length !== array2.length) {
        return false;
      }

      // eslint-disable-next-line no-plusplus
      for (let i = 0, j = array1.length; i < j; i++) {
        // recurse on each of the values, order does matter for our case since do not wish to search
        // for the value [expensive]
        if (!OJCompareUtils.compareValues(array1[i], array2[i])) {
          return false;
        }
      }
      return true;
    },
  };

  /**
   * uses JET compare utility to compare 2 complex keys
   * @return boolean true of keys values match
   */
  function compareKeyValues(key1, key2) {
    return OJCompareUtils.compareValues(key1, key2);
  }

  /**
   * compares 2 key values. Each key value is always either a primitive or Object. When
   * multiple attributes are used then this could be an Array, a Set.
   *
   * When a single attribute is used as an idAttribute, each key value can be:
   * - primitive
   * - Object - this can be whatever that attribute holds - object, array.
   * example:
   * var res = [{ id: {foo: 'a', bar: 'b'}], name: 'whatever'} and idAttribute is "id"
   * // key is {foo: 'a', bar: 'b'}
   *
   * For an idAttribute that is an Array of attributes each key is an array of individual
   * attribute values, where each attribute value can be a primitive or Object.
   * example:
   * var res = [{ id: {foo: 'a', bar: 'b'}], name: 'whatever'} and idAttribute is "[id, name]"
   * // key will be [{foo: 'a', bar: 'b'}, 'whatever']
   *
   * @param {Set|Array} key1
   * @param {Set|Array} key2
   * @return {boolean}
   */
  function compareObjects(key1, key2) {
    let k1 = key1;
    let k2 = key2;
    // when we are asked to compare 2 key values the same type is required.
    if ((k1 instanceof Set && k2 instanceof Set)) {
      // TODO: need polyfill for IE11
      // k1 = [...key1]; // k2 = [...key2];
      k1 = [];
      key1.forEach((x) => { k1.push(x); });
      k2 = [];
      key2.forEach((x) => { k2.push(x); });
    }
    return compareKeyValues(k1, k2);
  }

  const DEFAULT_CAPABILITIES = {
    getKeys: 'auto',
  };

  /**
   * IdAttributeHelper
   * base class for attribute helpers.
   * Calculates keys for a given 'idAttribute' value.
   *
   * When working with a collection, the caller is expected to first call getKeys(), and check for null.
   * If null, the caller my then optionally fallback to calling getIndices(), with an optional callback.
   *
   * This two-step process allows the caller to defer any offset calculations, until its known that its needed.
   */
  class IdAttributeHelper {
    /**
     * Returns the key using the value provided and the idAttribute. If an idAttribute is not
     * set then returns undefined.
     * @param value
     */
    // eslint-disable-next-line class-methods-use-this,no-unused-vars
    getKey(value) { throw new Error('getKey unimplemented'); }

    /**
     * returns a Set of keys
     * @param {Array=} values
     */
    // eslint-disable-next-line class-methods-use-this,no-unused-vars
    getKeys(values) { throw new Error('getKeys unimplemented'); }

    // eslint-disable-next-line class-methods-use-this,no-unused-vars
    getIndices(values, offset) { throw new Error('getIndices unimplemented'); }

    // eslint-disable-next-line class-methods-use-this
    compare(key1, key2) {
      return key1 === key2;
    }

    // eslint-disable-next-line class-methods-use-this
    getCapabilities() {
      return DEFAULT_CAPABILITIES;
    }
  }

  /**
   * SinglePropertyIdAttributeHelper
   *
   * used when idAttribute is a string, representing a property name
   * ex.
   *    "idAttribute": "id",
   */
  class SinglePropertyIdAttributeHelper extends IdAttributeHelper {
    constructor(idAttr) {
      super();
      this.idAttr = idAttr;
    }

    getKey(value) {
      return value[this.idAttr];
    }

    getKeys(values) {
      return new Set(values.map((value) => this.getKey(value)));
    }

    // eslint-disable-next-line class-methods-use-this
    getIndices(values, offset) {
      return values.map((_, index) => index + offset);
    }

    // eslint-disable-next-line class-methods-use-this
    compare(key1, key2) {
      return compareObjects(key1, key2);
    }
  }

  /**
   * MultiplePropertyIdAttributeHelper
   *
   * used when idAttribute is an array of strings, the key will be an Array or Set of property
   * values VB event does not support a Set but components will give us a Set
   *
   * ex.
   *    "idAttribute": ["firstName", "lastName"],
   *
   *   key = ["Jim", "Johnston"]
   */
  class MultiplePropertyIdAttributeHelper extends IdAttributeHelper {
    constructor(idAttrs) {
      super();
      this.idAttrs = idAttrs;
    }

    // like oj.ArrayDataProvider, returns the keys as an array
    getKey(value) {
      return this.idAttrs.map((attrName) => value[attrName]);
    }

    // constructs and returns a Set of new keys for the values provided using the idAttrs property
    getKeys(values) {
      return new Set(values.map((value) => this.getKey(value)));
    }

    // eslint-disable-next-line class-methods-use-this
    getIndices(values, offset) {
      return values.map((_, index) => index + offset);
    }

    // eslint-disable-next-line class-methods-use-this
    compare(key1, key2) {
      return compareObjects(key1, key2);
    }
  }

  /**
   * Similar to MultiplePropertyIdAttributeHelper, but uses all of the objects properties to
   * form the (array) key. This is when the idAttribute is set to '@value'
   *
   * @see MultiplePropertyIdAttributeHelper
   */
  class AllPropertiesIdAttributeHelper extends IdAttributeHelper {
    constructor(idAttrs) {
      super();
      this.idAttrs = idAttrs;
    }

    // eslint-disable-next-line class-methods-use-this
    getKey(value) {
      let names;
      try {
        names = Object.getOwnPropertyNames(value);
      } catch (e) {
        names = Object.getOwnPropertyNames.prototype.call(value);
      }

      return names.map((attrName) => value[attrName]);
    }

    getKeys(values) {
      return new Set(values.map((value) => this.getKey(value)));
    }

    // eslint-disable-next-line class-methods-use-this
    getIndices(values, offset) {
      return values.map((_, index) => index + offset);
    }

    // eslint-disable-next-line class-methods-use-this
    compare(key1, key2) {
      return compareObjects(key1, key2);
    }
  }

  /**
   * IndexIdAttributeHelper
   *
   * default case, does not return keys, but instead returns indices. This is when the idAttribute
   * is set to '@value'
   * This is separate from getKeys() because of the 'offset', and the extra logic that maybe
   * required to get the offset.
   */
  class IndexIdAttributeHelper extends IdAttributeHelper {
    // eslint-disable-next-line class-methods-use-this
    getKey() {
      return null;
    }

    // eslint-disable-next-line class-methods-use-this
    getKeys() {
      return null;
    }

    // eslint-disable-next-line class-methods-use-this
    getIndices(values, offset = 0) {
      return values.map((_, index) => index + offset);
    }

    // eslint-disable-next-line class-methods-use-this
    getCapabilities() {
      return {
        getKeys: 'none',
      };
    }
  }

  /**
   * Duck types JET ItemMetadata.
   */
  class FetchListResultItemMetadata {
    constructor(key) {
      this.key = key;
    }

    /**
     * Usually called when the key on an ItemMetadata instance needs to be fixed up. See
     * fetchByKeysIteration#fetchKeysByIteration method for when this might be needed.
     * @param newKey
     */
    fixupKey(newKey) {
      this.key = newKey;
    }
  }

  const ATTRIBUTE_FILTER_OPS = {
    EQUAL: '$eq',
    NOT_EQUAL: '$ne',
    GREATER_THAN: '$gt',
    GREATER_THAN_EQUAL: '$ge',
    LESS_THAN: '$lt',
    LESS_THAN_EQUAL: '$le',
    STARTS_WITH: '$sw',
    ENDS_WITH: '$ew',
    CONTAINS: '$co',
    PRESENT: '$pr',
  };
  const COMPOUND_FILTER_OPS = {
    AND: '$and',
    OR: '$or',
  };

  /**
   * set of static utility methods. related to data providers
   */
  class DataProviderUtils {
    /**
     * returns an array of ItemMetadata instances given a Set of keys
     * @param ks Set or Array of keys
     * @return {Array}
     */
    static createFetchByKeysResultItemMetadata(ks) {
      const keys = ks || [];
      const itemsMetadata = [];
      keys.forEach((key) => itemsMetadata.push(new FetchListResultItemMetadata(key)));
      return itemsMetadata;
    }

    /**
     * for a given 'idAttribute' value, get the proper helper utility
     * supports string, string[], '@index', and '@value'
     * @param idAttribute
     * @returns {*}
     */
    static getIdAttributeHelper(idAttribute) {
      // empty string is treated as no value
      if (idAttribute && typeof idAttribute === 'string') {
        // from JET ArrayDataProvider
        // @index causes ArrayDataProvider to use index as key and @value will cause ArrayDataProvider
        // to use all attributes as key.
        if (idAttribute === DPConstants.DataProviderIdAttribute.AT_INDEX) {
          // it is up to the caller to call getKeys(), check for null, and call getIndices().
          return new IndexIdAttributeHelper(idAttribute);
        }

        if (idAttribute === DPConstants.DataProviderIdAttribute.AT_VALUE) {
          return new AllPropertiesIdAttributeHelper(idAttribute);
        }

        return new SinglePropertyIdAttributeHelper(idAttribute);
      }

      if (Array.isArray(idAttribute)) {
        return new MultiplePropertyIdAttributeHelper(idAttribute);
      }

      // it is up to the caller to call getKeys(), check for null, and call getIndices().
      return new IndexIdAttributeHelper();
    }

    /**
     * helper to unwrap observables
     * @param data
     * @returns {*}
     */
    static unwrapData(data) {
      let d = data;
      if (ko.isObservable(d)) {
        // variable default value can now reference a constant and so we need to eval that
        d = ko.utils.unwrapObservable(data);
      }
      return d;
    }

    /**
     * Returns the inner object with the object that is located at 'path'.
     * The path can be a tokenized string that when expanded yields an array.
     *
     * Examples:
     * '',
     * 'a[4][5].b.c[6]'
     * 'a.b'
     * '[0][4]'
     * 'a[" "]' //returns error
     *
     *
     * @param object
     * @param path
     * @returns {*|Array}
     */
    static getObjectByPath(object, path = '') {
      let data = object;
      if (path && (path.length > 0)) {
        const dotTokens = path.split('.'); // example a[4][5].b.c[6]
        dotTokens.forEach((dt) => {
          if (data) {
            // each token can include an array index. example a[4][5] or [0][3]
            const arrayTokens = dt.match(/\[(.*?)\]/g);
            if (arrayTokens) {
              let arrRoot;
              arrayTokens.forEach((at, idx) => {
                if (idx === 0) {
                  arrRoot = dt.substr(0, dt.indexOf(at));
                  data = arrRoot ? (data[arrRoot] || []) : data;
                }
                const matches = (at && at.match(/\[(.*?)\]/)) || [];
                // const matches = (at && at.match(/\[[0-9]+\]/)) || [];
                const numericToken = matches[1] ? Number(matches[1]) : null;
                // see polyfills for IE11 support
                if (!Number.isNaN(numericToken)) {
                  data = data[numericToken];
                } else {
                  logger.error('there was an issue evaluating', dt,
                    'within the itemPath', path, 'for the data provided', object);
                  data = undefined;
                }
              });
            } else {
              data = data[dt];
              if (!data) {
                logger.error('there was an issue evaluating', dt,
                  'within the itemPath', path,
                  'for the data provided', object);
                data = undefined;
              }
            }
          }
        });
      }
      return data || [];
    }

    static setObjectByPath(object, path = '', value) {
      let data = object;
      if (path && (path.length > 0)) {
        const dotTokens = path.split('.'); // example a[4][5].b.c[6]
        dotTokens.forEach((dt, index) => {
          const ERR_STR = `there was an issue evaluating '${dt}' within the itemPath `
            + `${path}, for the data provided ${object}`;
          if (data) {
            // each token can include an array index. example a[4][5] or [0][3]
            const arrayTokens = dt.match(/\[(.*?)\]/g);
            if (arrayTokens) {
              let arrRoot;
              arrayTokens.forEach((at, idx) => {
                if (idx === 0) {
                  arrRoot = dt.substr(0, dt.indexOf(at));
                  data = arrRoot ? (data[arrRoot] || []) : data;
                }
                const matches = (at && at.match(/\[(.*?)\]/)) || [];
                // const matches = (at && at.match(/\[[0-9]+\]/)) || [];
                const numericToken = matches[1] ? Number(matches[1]) : null;
                // see polyfills for IE11 support
                if (!Number.isNaN(numericToken)) {
                  if (index === (dotTokens.length - 1)) {
                    data[numericToken] = value;
                  } else {
                    data = data[numericToken];
                  }
                } else {
                  logger.error(ERR_STR);
                  data = undefined;
                }
              });
            } else if (index === (dotTokens.length - 1)) {
              data[dt] = value;
            } else {
              data = data[dt];
              if (!data) {
                logger.error(ERR_STR);
                data = undefined;
              }
            }
          } else {
            logger.error(ERR_STR);
          }
        });
      }
      return data;
    }

    /**
     * Returns the resolved response type, given the response type and the builtin variable
     * instance
     * @param type responseType
     * @param extendedType builtin variable like ServiceDataProvider
     * @param generateDefaultType optional set of options
     * @return {*}
     */
    static getResolvedType(type, extendedType, generateDefaultType = false) {
      // ES5 - don't pass string to Object.keys().  And skip this code if its an empty type object
      if (type && (!Utils.isObjectOrArray(type) || Object.keys(type).length > 0)) {
        return extendedType.getType(type, `${extendedType.getId()}:response`);
      }

      // we are here because a (response) type was not provided, so return a default response
      // type, if requested
      return generateDefaultType ? DPConstants.DEFAULT_ANY_TYPE : type;
    }

    /**
     * Returns true if type definition is incompletely defined where an exploded object or array
     * of object type structure is expected.
     * @param type
     * @return {boolean}
     */
    static isTypeDefIncomplete(type) {
      const incompleteBaseTypes = [DPConstants.DEFAULT_OBJECT_TYPE, DPConstants.DEFAULT_OBJECT_ARRAY_TYPE,
        DPConstants.DEFAULT_ANY_TYPE, DPConstants.DEFAULT_ANY_ARRAY_TYPE];
      return (typeof type === 'string' && incompleteBaseTypes.includes(type));
    }

    /**
     * given a type definition this method returns all leaf (primitive properties) at the
     * current level
     * @param typeDef
     * @return {*}
     */
    static getTypeWithLeafProps(typeDef) {
      let newType;
      if (Array.isArray(typeDef)) {
        newType = newType || [];
        newType[0] = DataProviderUtils.getTypeWithLeafProps(typeDef[0]);
      }
      if (typeof typeDef === 'object') {
        const itemTypeProps = Object.keys(typeDef);
        itemTypeProps.forEach((prop) => {
          if (typeof typeDef[prop] !== 'object') {
            newType = newType || {};
            newType[prop] = typeDef[prop];
          }
        });
      } else if (typeof typeDef === 'string') {
        // for non-object resolved types abort recursing;
        // we could have a string[], boolean[], number[], or worse just primitives which is
        // plain incorrect, in which case use 'any'
        newType = (typeDef.includes(DPConstants.DEFAULT_PRIMITIVE_ARRAY_TYPES))
          ? typeDef || DPConstants.DEFAULT_ANY_TYPE : DPConstants.DEFAULT_ANY_TYPE;
      }
      return newType;
    }

    static hasDefaultKeyword(attrs) {
      const defaultKeyword = attrs.find((attr) => (typeof attr === 'string' && attr === '@default')
      || (typeof attr === 'object' && attr.name === '@default'));
      return !!defaultKeyword;
    }

    /**
     * Builds the filtered typeDef at each level with just the properties requested via attrs.
     * DataProvider fetches can now include an 'attributes' parameter that is arbitrarily
     * complex structure of attributes that must be included in the response type. The
     * frustrating thing is that the attrs structure may not mirror the typeDef structure.
     *
     * @param {Array} attrs the filtered list of attributes to include in the final result. The
     * list of attributes aka fields generally belong to the collection identified by itemsPath.
     * @param {*} type the items type (located generally at itemsPath of SDP), or during a
     * recursive call it is the type for a nested (object) attribute.
     * @returns {*}
     * @see oj.DataProvider
     */
    static getFilteredTypeDef(attrs, type) {
      let newType;
      // 1. process leaf attributes (that are not objects themselves) for the current level

      // a. first check the type is incomplete
      if (DataProviderUtils.isTypeDefIncomplete(type) && attrs.length > 0) {
        newType = type || DPConstants.DEFAULT_ANY_TYPE;
        return newType;
      }

      // we are dealing with a meaningful type definition or a primitive
      if (typeof type === 'object') {
        newType = DataProviderUtils.getTypeWithLeafProps(type);
      } else {
        // for non-object resolved types abort recursing;
        // is it possible to get here? iow, the type is a primitive
        // Yes, if attributes is telling us something is an object but the typeDef for the
        // attribute is a primitive. So in such cases, we flip type to 'any'
        newType = type || DPConstants.DEFAULT_ANY_TYPE;
        return newType;
      }

      // if @default is set at current level then keep all fields from type but remove
      // exclusions if specified in attrs
      // if @default is not set then include just the fields specified in attrs
      const defaultKeyword = DataProviderUtils.hasDefaultKeyword(attrs);
      const flattenedAttrs = DataProviderUtils.getFlattenedAttrs(attrs);

      if (defaultKeyword) {
        const excludedAttrs = flattenedAttrs
          .filter((attr) => (attr && attr.startsWith('!')))
          .map((attr) => (attr.startsWith('!') ? attr.substr(1) : attr));

        if (excludedAttrs && excludedAttrs.length > 0) {
          if (Array.isArray(newType)) {
            newType[0] = DataProviderUtils.getFilteredItemTypeDef(newType[0], excludedAttrs, true);
          } else {
            newType = DataProviderUtils.getFilteredItemTypeDef(newType, excludedAttrs, true);
          }
        }
      } else {
        const includedAttrs = flattenedAttrs
          .filter((attr) => typeof attr === 'string');
        if (includedAttrs && includedAttrs.length > 0) {
          if (Array.isArray(newType)) {
            newType[0] = DataProviderUtils.getFilteredItemTypeDef(newType[0], includedAttrs);
          } else {
            newType = DataProviderUtils.getFilteredItemTypeDef(newType, includedAttrs);
          }
        }
      }

      // 2. process object attributes at current level
      const objectAttrs = attrs.filter((attr) => typeof attr === 'object' && Object.keys(attr).length > 1);
      objectAttrs.forEach((oAttr) => {
        if (oAttr.name && oAttr.attributes && oAttr.attributes.length > 0) {
          let oAttrType;
          if (Array.isArray(type)) {
            oAttrType = type[0][oAttr.name];
            newType[0][oAttr.name] = DataProviderUtils.getFilteredTypeDef(oAttr.attributes, oAttrType);
          } else if (typeof type === 'object') {
            oAttrType = type[oAttr.name];
            newType[oAttr.name] = DataProviderUtils.getFilteredTypeDef(oAttr.attributes, oAttrType);
          } else {
            // sub object has non-object type; use 'any'
            newType[oAttr.name] = oAttrType || DPConstants.DEFAULT_ANY_TYPE;
          }
        }
      });
      return newType;
    }

    /**
     * builds a filtered item type def for the attributes provided. If exclude is true the
     * type corresponding to the attribute is removed from the final filtered type. If exclude
     * is false the type def for the attribute is included in the filtered type. If such a
     * type does not exist then 'any' is set.
     * @param itemTypeDef
     * @param attrList
     * @param exclude
     * @returns {*}
     * @see oj.DataProvider
     * @private
     */
    static getFilteredItemTypeDef(itemTypeDef, attrList, exclude = false) {
      let filteredType;
      if (exclude) {
        filteredType = itemTypeDef;
      }
      Object.values(attrList).forEach((attr) => {
        const foundItem = Object.keys(itemTypeDef).find((itemTypeAttr) => itemTypeAttr === attr);
        if (foundItem) {
          if (!exclude) {
            filteredType = filteredType || {};
            filteredType[foundItem] = itemTypeDef[foundItem];
          } else {
            delete filteredType[foundItem];
          }
        } else if (!exclude) {
          // if (select) attribute that is to be included is not found in itemTypeDef, then set
          // 'any' as the type
          filteredType = filteredType || {};
          filteredType[foundItem] = DPConstants.DEFAULT_ANY_TYPE;
        }
      });

      return filteredType;
    }

    /**
     * Build a flattened list of attribute names at the top level filtering out nested (child)
     * object attributes
     * @param attrs
     * @returns {Array}
     * @see oj.DataProvider
     * @private
     */
    static getFlattenedAttrs(attrs) {
      const flattened = [];
      attrs.forEach((attr) => {
        if (typeof attr === 'string') {
          flattened.push(attr);
        } else if (typeof attr === 'object' && Object.keys(attr).length === 1
          && attr.name && typeof attr.name === 'string') {
          flattened.push(attr.name);
        }
      });
      return flattened;
    }

    /**
     * returns true if it's an attribute criterion with 3 properties - op, attribute and value,
     * where op is one of the supported attribute operators, attribute is valid. We don;t check
     * the value because it can be set to undefined.
     * A 'criteria' property of non-zero length returns false.
     * @param acion
     * @return {*|boolean}
     */
    static isAttributeCriterion(acion) {
      return (acion && typeof acion === 'object'
      && DataProviderUtils.isAttributeOperator(acion.op) && acion.attribute
      && (!acion.criteria || (Array.isArray(acion.criteria) && acion.criteria.length === 0)));
    }

    /**
     * whether the (Filter) criterion is a compound criterion
     *
     * @param sccion
     * @return {boolean}
     */
    static isCompoundCriterion(sccion) {
      if ((!sccion.op || DataProviderUtils.isCompoundOperator(sccion.op))
        && (sccion.criteria && Array.isArray(sccion.criteria) && sccion.criteria.length >= 1)
        && !sccion.attribute && !sccion.value) {
        return true;
      }
      return false;
    }

    /**
     * returns true for supported operators. See oj.AttributeFilterOperator for full list.
     * @param aop
     * @return {boolean}
     */
    static isAttributeOperator(aop) {
      return (aop === ATTRIBUTE_FILTER_OPS.EQUAL
      || aop === ATTRIBUTE_FILTER_OPS.NOT_EQUAL
      || aop === ATTRIBUTE_FILTER_OPS.GREATER_THAN
      || aop === ATTRIBUTE_FILTER_OPS.GREATER_THAN_EQUAL
      || aop === ATTRIBUTE_FILTER_OPS.LESS_THAN
      || aop === ATTRIBUTE_FILTER_OPS.LESS_THAN_EQUAL
      || aop === ATTRIBUTE_FILTER_OPS.STARTS_WITH
      || aop === ATTRIBUTE_FILTER_OPS.ENDS_WITH
      || aop === ATTRIBUTE_FILTER_OPS.CONTAINS
      || aop === ATTRIBUTE_FILTER_OPS.PRESENT);
    }

    static isCompoundOperator(cop) {
      return cop && (cop === COMPOUND_FILTER_OPS.AND || cop === COMPOUND_FILTER_OPS.OR);
    }

    /**
     * Combines the filterCriterion provided by caller with the one that is configured using
     * an AND operator.
     * @param fetchOptionFC the filterCriterion provided by caller
     * @param configuredFC the configured criterion
     * @return the combined FC
     */
    static combineFilterCriterionFromMultipleSources(fetchOptionFC, configuredFC) {
      const coFC = configuredFC;
      const foFC = fetchOptionFC;
      const newFC = {};
      if (coFC && (DataProviderUtils.isAttributeCriterion(coFC)
        || DataProviderUtils.isCompoundCriterion(coFC))) {
        newFC.op = COMPOUND_FILTER_OPS.AND;
        newFC.criteria = [];
        newFC.criteria.push(foFC);
        newFC.criteria.push(coFC);
        return newFC;
      }
      return foFC;
    }
  }

  return DataProviderUtils;
});

