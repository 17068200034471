'use strict';

define('vb/private/action/actionChainRunner',['vb/private/action/codeChainRunner', 'vb/private/action/jsonChainRunner'],
  (CodeChainRunner, JsonChainRunner) => {
    /**
     * Factory class for creating CodeChainRunner for running JS chains or JsonChainRunner for running
     * descriptor-based chains.
     */
    class ActionChainRunner {
      /**
       * The method returns an instance of CodeChainRunner if chainLocator.chain is provided. It returns
       * an instance of JsonChainRunner if chainLocator.chainId is provided.
       *
       * @param chainLocator an object container either chainId (JSON chain) or chain (JS chain)
       * @returns {CodeChainRunner|JsonChainRunner}
       */
      static create(chainLocator) {
        const { chain, chainId } = chainLocator;

        if (chain) {
          return new CodeChainRunner(chain);
        }

        if (chainId) {
          return new JsonChainRunner(chainId);
        }

        throw new Error('Failed to create an action chain runner because no chain/chainId is provided.');
      }
    }

    return ActionChainRunner;
  });

