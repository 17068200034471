'use strict';

define('vb/private/services/servicesManager',[
  'vb/private/services/endpointReference',
  'vb/private/stateManagement/router',
  'vb/private/stateManagement/application',
],
(EndpointReference, Router, Application) => {
  /**
   * this is the interface for getting endpoints for the active container (previously, Services was a singleton).
   * Also where Service Providers are registered (ultimately live in the Application's Services property).
   *
   */
  class ServicesManager {
    /**
     * Get an endpoint
     * First, check the active Page; if it has a services object, and that contains the endpoint, return that.
     * Otherwise, check if the Application has any services registered, and contains the endpoint.
     * @param endpointReference {EndpointReference}
     * @param {object} [container] - not used here (see OperationRefEndpointProvider).
     * @param {object} [serverVariables] - the serverVariables, if any.
     * @return {Promise}
     * @public
     */
    static getEndpoint(endpointReference, container, serverVariables) {
      // eslint-disable-next-line no-underscore-dangle
      return ServicesManager._findEndpoint(endpointReference, serverVariables)
        .then((tuple) => tuple && tuple.endpoint);
    }

    /**
     * @typedef {Object} EndpointDefinitionInfo
     * @property path
     * @property [operationDef]
     * @property [serviceDef]
     * @property configInfo
     * @property requestInit
     */
    /**
     * return the service definition (openapi, swagger),
     * and additional information used to fetch the service
     * @param endpointReference {EndpointReference}
     * @returns {Promise<EndpointDefinitionInfo|*>}
     * @public
     */
    static getDefinitionInfo(endpointReference) {
      return ServicesManager.FindDefinition(endpointReference)
        .then((foundInfo) => (foundInfo ? {
          serviceDef: foundInfo.serviceDef,
          configInfo: foundInfo.configInfo,
          requestInit: foundInfo.requestInit,
        } : null));
    }

    /**
     * Used to programmatically register service definitions. These are registered at the Application level.
     * @param serviceProvider
     * @return {Promise}
     * @public
     */
    static addServiceProvider(serviceProvider) {
      return Application.getServices().addServiceProvider(serviceProvider);
    }

    /**
     * 'traverseCallback' must be a function that takes a services and the endpointReference, and produces a result,
     * synchronously or asynchronously. The promise returned by this method resolves to the handler's result if this is
     * truthy. If the result is not truthy, 'handler' is invoked again for the next services, until there is no more
     * services to traverse - in this case the promise returned by this method resolves to null.
     *
     * @callback traverseCallback
     * @param {Services} services
     * @param {EndpointReference} endpointReference
     * @returns {Promise|*}
     */
    /**
     * Traverses the all services that are appropriate to the specified endpoint, including any fallback service
     * registered to the application. The services are traversed in the most to least specific order.
     *
     * 'handler' must be a function that takes the a services and the endpointReference, and produces a result,
     * synchronously or asynchronously. The promise returned by this method resolves to the handler's result if this is
     * truthy. If the result is not truthy, 'handler' is invoked again for the next services, until there is no more
     * services to traverse - in this case the promise returned by this method resolves to null.
     *
     * If needed, the handler implementation should use the endpointReference passed as argument to it instead of the
     * one passed to this method because it may be different.
     *
     * @param {string|object} endpointReference
     * @param {traverseCallback} handler
     * @return {Promise<*|null>}
     * @private
     */
    static _traverseServices(endpointReference, handler) {
      // if we get a string for some reason, convert it
      if (typeof endpointReference === 'string') {
        // eslint-disable-next-line no-param-reassign
        endpointReference = new EndpointReference(endpointReference);
      }

      const pageOrFlow = Router.getCurrentPage();
      const allServices = (pageOrFlow && pageOrFlow.getAllServices()) || Application.getAllServices();
      const servicesArray = allServices.filter((s) => s.namespace === endpointReference.containerNamespace);

      return servicesArray.reduce(
        (promise, services) => promise.then((result) => result || handler(services, endpointReference)),
        Promise.resolve(),
      );
    }

    /**
     * search declared service definitions for the endpoint (serviceId/operationId)
     * @param endpointReference {EndpointReference}
     * @param {object} [serverVariables] - the serverVariables, if any.
     * @returns {Promise} resolved with {endpoint}
     * @private
     */
    static _findEndpoint(endpointReference, serverVariables) {
      return this._traverseServices(endpointReference,
        (services, er) => services.getEndpoint(er, serverVariables)
          .then((endpoint) => endpoint && { endpoint }));
    }

    /**
     * find the Service object that contains the declaration for the service ID, by getting the container's
     * array of Services objects, which are all Services objects in the container hierarchy.
     * We then search those Services objects for the Service.
     *
     * Services:Container :: one:one, each Services contains a map of Service
     *
     * @param endpointReference {EndpointReference}
     * @returns {Promise} resolved with Services
     */
    static findContainerServices(endpointReference) {
      // Look for the endpointReference on the traversed "services" as well as its delegate services.
      // Using a set to avoid looking for the endpoint reference in the same "services".

      const set = new Set();
      const handler = (services, er) => {
        if (set.has(services)) {
          return false;
        }
        set.add(services);
        return services.containsDeclaration(er)
          .then((result) => (result ? services : services.searchDelegates((delegate) => handler(delegate, er))));
      };

      return this._traverseServices(endpointReference, handler);
    }

    /**
     * Finds definition of EndpointReference, loading it if not already loaded.
     * It returns object with the ServiceDefinition and the Endpoint if it is found.
     *
     * @param endpointReference {EndpointReference}
     * @returns {Promise} resolved with the Swagger/OpenApi from the ServiceDefinition
     * @protected
     */
    static FindDefinition(endpointReference) {
      return Promise.resolve()
        .then(() => {
          if (endpointReference.serviceId) {
            return ServicesManager.findContainerServices(endpointReference);
          }
          // this should NEVER happen
          throw new
          Error(`An EndpointReference with no service ID was used, trying to find a service: ${endpointReference}`);
        })
        .then((services) => services && services.LoadService(endpointReference.serviceId, false, endpointReference))
        .then((serviceDefinition) => Promise.all([serviceDefinition,
          // openApi is OpenApiObjectCommon, and its definition is simple JS Object
          serviceDefinition && serviceDefinition.getDefinition(endpointReference)]))
        .then(([serviceDefinition, serviceDefObject]) => {
          if (serviceDefinition) {
            const serviceDef = serviceDefObject;
            const requestInit = serviceDefinition && serviceDefinition.requestInit;
            const endpoint = serviceDefinition && serviceDefinition.findEndpoint(endpointReference);

            return {
              endpoint,
              service: serviceDefinition,
              serviceDef,
              requestInit,
            };
          }
          return null;
        });
    }

    /**
     * looks for the containing Services in the container hierarchy, and disposes the specific service
     * @param {string} id declared id of the service
     * @returns {Promise<Services>} resolved with Services, or rejected with text message
     */
    static disposeService(id) {
      return Promise.resolve()
        .then(() => ServicesManager.findContainerServices(id))
        .then((services) => {
          if (services) {
            services.disposeService(id);
            return services;
          }

          throw new Error(`unable to find service to dispose: ${id}`);
        });
    }
  }

  return ServicesManager;
});

