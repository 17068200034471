'use strict';

define('vb/private/debug/constants',[], () => {
  const Constants = {};

  // types for messages used for communicating between the debugger and debuggee
  Constants.MessageType = {
    DEBUGGEE: 'vbDebuggeeMessage', // message from the debuggee
    DEBUGGER: 'vbDebuggerMessage', // message from the debugger
  };

  // types for message responses
  Constants.ResponseType = {
    DEBUGGEE: 'vbDebuggeeResponse', // response for a debuggee message
    DEBUGGER: 'vbDebuggerResponse', // response for a debugger message
  };

  // types for debuggee
  Constants.DebuggeeType = {
    APPLICATION: 'application',
    ACTION_CHAIN: 'actionChain',
  };

  // states for the change event sent to the debugger
  Constants.DebugState = {
    CHAIN_STARTING: 'chainStarting',
    CHAIN_FINISHED: 'chainFinished',
    ACTION_STARTING: 'actionStarting',
    ACTION_RUNNING: 'actionRunning',
    ACTION_SUSPENDED: 'actionSuspended',
    ACTION_FINISHED: 'actionFinished',
    DESCRIPTORS_LOADED: 'descriptorsLoaded',
  };

  return Constants;
});

