'use strict';

define('vb/action/actionChain',[], () => {
  /**
   * Base class for code-base action chain.
   */
  class ActionChain {
    /**
     * This method is called to run the action chain.
     *
     * @param {Object} context contains all the scopes accessible to the action chain, i.e., $application,
     * $flow, $page, etc. Note that the scopes are read-only meaning you cannot directly write into them.
     * Use Actions.assignVariable instead to modify variables.
     * @param {Object} context.$global provides access to variables and functions for the unified application
     * @param {Object} context.$application provides access to application/app ui variables and functions
     * @param {Object} context.$flow provides access to flow variables and functions
     * @param {Object} context.$page provides access to page variables and functions
     * @param {Object} context.$fragment provides access to fragment variables and functions
     * @param {Object} context.$layout provides access to layout variables and functions
     * @param {Object} params parameters to the action chain
     * @returns {Promise}
     */
    async run(context, params) {
      return Promise.reject(new Error('Not implemented'));
    }
  }

  return ActionChain;
});

