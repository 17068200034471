'use strict';

define('vb/private/stateManagement/context/fragmentBaseContext',[
  'vb/private/constants',
  'vb/private/stateManagement/context/baseContext'],
(Constants, BaseContext) => {
  /**
   * set of properties to expose in $base for fragment extension
   */
  class FragmentBaseContext extends BaseContext {
    constructor(fragment) {
      super(fragment);

      const propDescriptors = {
        /**
         * $base.fragment.info
         * @returns {*}
         */
        [Constants.INFO_CONTEXT]: {
          get() {
            return fragment.expressionContext[Constants.INFO_CONTEXT];
          },
          enumerable: true,
          configurable: true,
        },
      };

      Object.defineProperties(this, propDescriptors);
    }

    /**
     * $base.fragment
     * @type {FragmentBaseContext}
     */
    get fragment() {
      return this;
    }
  }

  return FragmentBaseContext;
});

