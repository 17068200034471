// eslint-disable-next-line max-classes-per-file
/* eslint max-classes-per-file: ["error", 6] */

'use strict';

define('vb/action/builtin/fireDataProviderEventAction',['ojs/ojdataprovider', 'vb/action/action', 'vb/private/log', 'vb/private/types/utils/dataProviderUtils',
  'vb/private/types/dataProviderConstants', 'vb/private/utils'],
(ojDataProvider, Action, Log, DataUtils, DPConstants, Utils) => {
  const logger = Log.getLogger('/vb/private/stateManagement/fireDataProviderEventAction');
  const REFRESH_EVENT = DPConstants.DataProviderEvent.REFRESH;
  const MUTATION_EVENTS = [DPConstants.DataProviderMutationEvent.ADD,
    DPConstants.DataProviderMutationEvent.UPDATE, DPConstants.DataProviderMutationEvent.REMOVE];
  const OJ_REFRESH_EVENT = new ojDataProvider.DataProviderRefreshEvent();

  /**
   * Duck types ItemMetadata.
   */
  class DataProviderItemMetadata {
    constructor(key) {
      this.key = key;
    }
  }

  /**
   * Mutation operation payload used by update and delete operations. Implements the
   * oj.IteratingDataProviderAddOperationEventDetail interface.
   */
  class DataProviderMutationOperationPayload {
    constructor(metadata, data, indexes, keys) {
      this.metadata = metadata;
      this.data = data;
      this.indexes = indexes;
      this.keys = keys;
    }
  }

  /**
   * Add operation payload. Implements the oj.IteratingDataProviderMutationOperationEventDetail
   */
  class DataProviderAddOperationPayload extends DataProviderMutationOperationPayload {
    constructor(metadata, data, indexes, keys, afterKeys, addBeforeKeys) {
      super(metadata, data, indexes, keys);
      this.afterKeys = afterKeys || [];
      this.addBeforeKeys = addBeforeKeys || [];
    }
  }

  /**
   * Implements the ojDataProvider.DataProviderMutationEventDetail. A single event can be raised
   * that includes multiple operations - add / remove / update and this object represents all
   * operations. Note: The only requirement being that if more than operation is present then the
   * keys have to be disjointed. IOW if you had an add and remove in the same mutation event
   * then the keys must not intersect. This requirement is enforced by JET.
   * @param add
   * @param remove
   * @param update
   * @constructor
   */
  class DataProviderEventDetail {
    constructor() {
      this.add = null;
      this.remove = null;
      this.update = null;
      return this;
    }

    addEvent(add) {
      this.add = add;
      return this;
    }

    updateEvent(update) {
      this.update = update;
      return this;
    }

    removeEvent(remove) {
      this.remove = remove;
      return this;
    }
  }

  /**
   * A class that manages dispatching dataProvider events to iterating data providers like
   * ServiceDataProvider, ArrayDataProvider etc.
   */
  class DataProviderEventDispatcher {
    constructor(dataProvider) {
      this.dataProvider = dataProvider;
      if (Utils.isExtendedType(dataProvider)) {
        this.extendedType = true;
        const dpValue = dataProvider.getValue();
        const idProp = (dataProvider.getIdAttributeProperty && dataProvider.getIdAttributeProperty());
        this.idAttribute = idProp ? dpValue[idProp]
          : dpValue[DPConstants.DataProviderIdAttributeProperty.ID_ATTRIBUTE];
        this.itemsPath = dpValue.itemsPath;
        this.log = logger;
      }
    }

    /**
     * Throws error for invalid payload
     * @param eventType
     * @param err
     */
    static throwError(eventType, err) {
      const errStr = err || `${eventType} event was raised but a valid payload was not provided!`;
      logger.error(errStr);
      throw errStr;
    }

    /**
     * whether payload is present for certain operations
     */
    static isEventWithValidPayload(eventType, payload) {
      if (MUTATION_EVENTS.indexOf(eventType) >= 0) {
        const hasPayload = payload && Object.keys(payload).length > 0;
        if (hasPayload) {
          if (payload.data || payload.keys || payload.indexes) {
            return true;
          }
        }
      }
      return false;
    }

    /**
     * builds keys from data. If it can't be built throws error.
     * @param et
     * @param data
     * @param keys
     * @returns {*}
     * @private
     */
    assembleKeysFromData(et, data, keys) {
      let ks = keys;
      let ksLen = ks && (ks instanceof Set ? ks.size : ks.length);
      if ((!ks || !ksLen) && this.canBuildKeys()) {
        // It is mandatory for keys to be present when data is present.
        this.log.warn('No keys were specified in the event payload for the mutation operation', et,
          '. Attempting to build keys from data.');

        // return an empty key set if data is empty
        if (data && data.length === 0) {
          return new Set();
        }

        ks = this._buildKeys(data);
        ksLen = ks && ks.size;
        if (!ksLen || (ksLen === 0 && data.length > 0)) {
          DataProviderEventDispatcher.throwError(et, `${et} event has no keys set for the data, or `
            + `the keys cannot be determined from the data, ${data}`);
        } else if (ksLen > 0 && (ksLen > data.length)) {
          // if keys is present and data size does not match or exceed keys (which is a Set)
          // for non-remove events, throw an error
          if (et !== DPConstants.DataProviderMutationEvent.REMOVE) {
            DataProviderEventDispatcher.throwError(et, `${et} event has more keys ${keys} than the `
              + `data ${data}`);
          }
        }
      }
      return ks && Array.isArray(ks) ? new Set(ks) : ks;
    }

    /**
     * Dispatches a DataProvider event. It can be a refresh event or a mutation event.
     *
     * @param {object} event - the keys are the supported events - refresh, add, remove, update.
     * The value for the event is as follows:
     * - For 'refresh' there is no payload necessary. it's of type oj.DataProviderRefreshEvent
     * - For 'add' operation it's of type oj.DataProviderAddOperationEventDetail
     * - For 'remove' and 'update' operations it's oj.DataProviderOperationEventDetail
     *
     * The payload for a mutation event can contain at least the following properties. Also this
     * action payload specifies as one atomic event, all the mutation operations which occurred.
     * The keys for each operation must be disjoint from each other, e.g. for example an add and
     * remove cannot occur on the same item. In addition, any indexes specified must be
     * monotonically increasing:
     *  - data: optional Array<Object>; the results of the 'add' operation. Note there can be more
     *      than one rows added.
     *  - addBeforeKeys: optional Array<*> (new in JET 7.0) of keys for items located after the
     *      items involved in the add operation. If null and index not specified then insert at
     *      the end.
     *      http://jet.us.oracle.com/jsdocs/oj.DataProviderAddOperationEventDetail.html#addBeforeKeys
     *  - afterKeys: optional Set<*>; (deprecated in JET 7.0) a Set that is the keys of items
     *      located after the items involved in the operation. If null and index not
     *      specified then insert at the end.
     *  - keys: Set<*>. Since SDP variable is configured with idAttribute this can be
     *      determined by SDP itself from the data
     *  - metadata: optional Array<ItemMetadata<Object>>. Since the SDP variable is configured
     *      with 'idAttribute', this can be determined by SDP itself.
     *  - indexes: optional Array<number>
     *
     * @since JET 7.0.x
     * @public
     */
    fireEvent(event) {
      let ojEvent = null;

      if (Object.keys(event).indexOf(REFRESH_EVENT) >= 0) {
        ojEvent = OJ_REFRESH_EVENT;
      } else {
        let data;
        let metadata;
        let indexes;
        let keys;
        let afterKeys;
        let addBeforeKeys;

        const mutationEventDetail = new DataProviderEventDetail();
        Object.keys(event).forEach((eventType) => {
          const payload = event[eventType];
          // sanity check
          if (!DataProviderEventDispatcher.isEventWithValidPayload(eventType, payload)) {
            DataProviderEventDispatcher.throwError(eventType);
          }

          if (this.extendedType) {
            switch (eventType) {
              case DPConstants.DataProviderMutationEvent.ADD: {
                data = this._getDataItemsArray(payload.data);
                keys = this.assembleKeysFromData(eventType, data, payload.keys);
                ({ metadata } = payload);
                ({ indexes } = payload);
                ({ afterKeys } = payload);
                ({ addBeforeKeys } = payload);

                const afterKeyLen = afterKeys
                  && (afterKeys instanceof Set ? afterKeys.size : afterKeys.length);
                if (afterKeyLen > 0) {
                  this.log.warn('afterKeys parameter is being deprecated on the'
                                  + ' FireDataProviderEventAction. Use addBeforeKeys instead.');
                }
                const operationEventDetail = new
                DataProviderAddOperationPayload(metadata, data, indexes, keys, afterKeys, addBeforeKeys);
                mutationEventDetail.addEvent(operationEventDetail);

                break;
              }
              case DPConstants.DataProviderMutationEvent.UPDATE:
              case DPConstants.DataProviderMutationEvent.REMOVE: {
                data = this._getDataItemsArray(payload.data);
                keys = this.assembleKeysFromData(eventType, data, payload.keys);
                ({ indexes } = payload);
                ({ metadata } = payload);

                const operationEventDetail = new
                DataProviderMutationOperationPayload(metadata, data, indexes, keys);
                if (eventType === DPConstants.DataProviderMutationEvent.UPDATE) {
                  mutationEventDetail.updateEvent(operationEventDetail);
                } else {
                  mutationEventDetail.removeEvent(operationEventDetail);
                }

                break;
              }
              default: {
                const err = `The event ${eventType} is not recognized as a valid DataProvider event`;
                DataProviderEventDispatcher.throwError(eventType, err);
                break;
              }
            }
          } else {
            // for instance types payload is passed-through
            ({ keys } = payload);
            ({ data } = payload);
            ({ metadata } = payload);
            ({ indexes } = payload);
            ({ afterKeys } = payload);
            ({ addBeforeKeys } = payload);
            // keys must be a Set
            keys = keys && Array.isArray(keys) ? new Set(keys) : keys;
            switch (eventType) {
              case DPConstants.DataProviderMutationEvent.ADD: {
                const operationEventDetail = new
                DataProviderAddOperationPayload(metadata, data, indexes, keys, afterKeys, addBeforeKeys);
                mutationEventDetail.addEvent(operationEventDetail);
                break;
              }
              case DPConstants.DataProviderMutationEvent.UPDATE:
              case DPConstants.DataProviderMutationEvent.REMOVE: {
                const operationEventDetail = new
                DataProviderMutationOperationPayload(metadata, data, indexes, keys);
                if (eventType === DPConstants.DataProviderMutationEvent.UPDATE) {
                  mutationEventDetail.updateEvent(operationEventDetail);
                } else {
                  mutationEventDetail.removeEvent(operationEventDetail);
                }
                break;
              }
              default: {
                const err = `The event ${eventType} is not recognized as a valid DataProvider event`;
                DataProviderEventDispatcher.throwError(eventType, err);
                break;
              }
            }
          }
        });

        ojEvent = new ojDataProvider.DataProviderMutationEvent(mutationEventDetail);
      }

      if (ojEvent) {
        // JET throws when something fails processing the dispatched event. VB returns false when there
        try {
          this.dataProvider.dispatchEvent(ojEvent, true);
        } catch (e) {
          const err = `failure occurred when processing dispatched event,
            '${JSON.stringify(event, Utils.setToJSONReplacer, 2)}'.`;
          DataProviderEventDispatcher.throwError(err);
        }
      }
    }

    /**
     * Returns the collection of items in the response data that is located at itemsPath.
     * @param jsonData the response returned from a Rest call.
     * @returns {*|Array}
     * @private
     */
    _getDataItemsArray(jsonData) {
      // TODO: assumes application/json content type
      const { itemsPath } = this;
      let data = jsonData;

      if (itemsPath && (itemsPath.length > 0)) {
        const path = itemsPath.split('.');
        for (let i = 0; i < path.length; i += 1) {
          data = data && data[path[i]];
        }
      }
      // always return a collection because data is
      if (!data || !Array.isArray(data)) {
        data = data ? [data] : [];
      }
      return data;
    }

    /**
     * Returns an Array of ItemMetadata objects.
     * @param items
     * @private
     */
    _getItemsMetadata(items) {
      const idHelper = DataUtils.getIdAttributeHelper(this.idAttribute);
      // todo: does this need to fall back to indices if idAttribute isn't set?
      // it did not fallback before helper change, but it looks weird
      const itemsMetadata = [];
      // getKeys() returns null if idHelper does not support this capability
      const keys = items && (idHelper.getKeys(items) || []);
      keys.forEach((key) => itemsMetadata.push(new DataProviderItemMetadata(key)));
      return itemsMetadata;
    }

    /**
     * Returns an set of keys. This method is called only when the keys need to be assembled
     * from data, using the idAttribute. if an idAttribute was not set or is set to '@index'
     * there is no reason to build a keys
     * @param items
     * @return Set of keys or undefined
     * @private
     */
    _buildKeys(items) {
      let keySet;
      if (this.idAttribute) {
        const idHelper = DataUtils.getIdAttributeHelper(this.idAttribute);
        // getKeys returns null if idAttribute is set to '@index'
        keySet = items && idHelper.getKeys(items);
      }
      return keySet;
    }

    /**
     * Can build keys if dealing with index as the idAttribute
     * @return {boolean}
     * @private
     */
    canBuildKeys() {
      return (!this.idAttribute
      || (this.idAttribute !== DPConstants.DataProviderIdAttribute.AT_INDEX));
    }
  }

  const isIteratingDataProvider = function (dataProvider) {
    if (dataProvider.fetchFirst && dataProvider.getCapability) {
      return true;
    }
    return false;
  };

  /**
   * Dispatches an event on a data provider of type oj.DataProvider.
   * @returns {Object} success outcome when event was processed successfully. failure
   * otherwise with the error. The Object has 2 properties:
   * - name: {string} of the outcome. example 'success' or 'failure'
   * - result: error string if failure outcome. undefined otherwise
   */
  class FireDataProviderEventAction extends Action {
    constructor(id, label) {
      super(id, label);
      this.log = logger;
    }

    /**
     * Called with either a mutation event or a refresh event. A mutation event can include
     * multiple mutation operations as long as the id...values between operations do not
     * intersect. Example you cannot add a record and remove it in the same event.
     *
     * @param parameters
     * @returns {{name, result}|*}
     */
    perform(parameters) {
      const dataProvider = parameters.target || parameters.dataProvider;
      let mutationEvent;
      let refreshEvent;

      MUTATION_EVENTS.forEach((eventName) => {
        if (parameters[eventName]) {
          mutationEvent = mutationEvent || {};
          mutationEvent[eventName] = Utils.cloneObject(parameters[eventName]);
        }
      });

      if (Object.keys(parameters).indexOf(REFRESH_EVENT) >= 0) {
        this.log.finer('payload for', REFRESH_EVENT, 'is ignored, as no payload is required');
        refreshEvent = refreshEvent || {};
        refreshEvent[REFRESH_EVENT] = null;
      }

      if (mutationEvent && refreshEvent) {
        throw new Error(`refresh event cannot be raised along with mutation event. ${parameters}.`);
      } else if (!mutationEvent && !refreshEvent) {
        throw new Error(`either refresh or mutation event needs to be present, not both. ${parameters}.`);
      }

      // we have at least one event.
      const event = refreshEvent || mutationEvent;
      if (refreshEvent) {
        this.log.info('FireDataProviderEventAction called with a refresh event');
      } else {
        this.log.info('FireDataProviderEventAction called with a mutation event for operations:',
          JSON.stringify(Object.keys(event)),
          'and payloads:',
          JSON.stringify(Object.values(event)),
          'respectively.');
      }

      // In minified JET this oj class is not exposed.
      // if (oj.DataProviderFeatureChecker.isIteratingDataProvider())
      if (dataProvider && isIteratingDataProvider(dataProvider)) {
        try {
          const eventDipatcher = new DataProviderEventDispatcher(dataProvider);
          eventDipatcher.fireEvent(event);
          return Action.createSuccessOutcome();
        } catch (e) {
          return Action.createFailureOutcome(`Dispatching data provider event ${event} failed`, e);
        }
      } else {
        throw new Error('a valid DataProvider instance is required in order to dispatch the event');
      }
    }
  }

  return FireDataProviderEventAction;
});

