'use strict';

define('vb/private/stateManagement/context/applicationContext',['vb/private/constants',
  'vb/private/stateManagement/context/flowContext',
  'vb/private/stateManagement/context/applicationBaseContext',
  'vb/private/ui/responsiveUtils'],
(Constants, FlowContext, ApplicationBaseContext, ResponsiveUtils) => {
  // lazy-initialization of JET responsive query observables;
  // only create the observables if someone uses $application.responsive
  let responsiveUtilsInterface = null;

  class ApplicationContext extends FlowContext {
    /**
     * @param application
     */
    constructor(application) {
      super(application);

      const propDescriptors = {
        /**
         * Accessor for the application builtin utils
         */
        builtinUtils: {
          get() {
            return application.builtinUtils;
          },
          enumerable: true,
          configurable: true,
        },
        /**
         * $application.initParams.XXXX. comes from window.vbInitParams or "configuration" declaration.
         * @todo: remove support for window.vbInitParams
         */
        [Constants.INIT_PARAM_CONTEXT]: {
          get() {
            return application.initParams;
          },
          enumerable: true,
          configurable: true,
        },
        /**
         * $application.responsive.XXXX, eg. responsive.SM_UP
         */
        [Constants.RESPONSIVE_CONTEXT]: {
          get() {
            if (!responsiveUtilsInterface) {
              responsiveUtilsInterface = ResponsiveUtils.getResponsiveObservablesInterface();
            }
            return responsiveUtilsInterface;
          },
          enumerable: true,
          configurable: true,
        },
      };

      /**
       * $application.profile, the active app profile
       * $application.deployment
       */
      [Constants.APPLICATION_USER_VARIABLE,
        Constants.GLOBAL_CURRENT_APPUI_VARIABLE,
        Constants.PROFILE_CONSTANT,
        Constants.DEPLOYMENT_CONSTANT,
      ].forEach((property) => {
        propDescriptors[property] = {
          get() {
            return application.scope.variableNamespaces[Constants.VariableNamespace.BUILTIN][property];
          },
          enumerable: true,
          configurable: true,
        };
      });

      Object.defineProperties(this, propDescriptors);
    }

    static get BaseContextType() {
      return ApplicationBaseContext;
    }

    /**
     * get all dollar-vars available to expression binding
     * @param application
     * @returns {*}
     */
    static getAvailableContexts(application) {
      const availableContexts = super.getAvailableContexts(application);

      // Delete the $flow not needed for $application. Also this is not to avoid a conflict
      // between $application.currentPage and @flow.currentPage which are of different type.
      delete availableContexts.$flow;

      // defines additional properties, $builtinUtils and $initParams
      Object.defineProperties(availableContexts, {
        $builtinUtils: {
          enumerable: true,
          configurable: true,
          get: () => application.expressionContext.builtinUtils,
        },
        [Constants.ContextName.INIT_PARAMS]: {
          enumerable: true,
          configurable: true,
          get() {
            return application.expressionContext[Constants.INIT_PARAM_CONTEXT];
          },
        },
      });

      return availableContexts;
    }
  }

  return ApplicationContext;
});

