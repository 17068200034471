/* eslint-disable max-classes-per-file */

'use strict';

define('vb/private/services/readers/openApiObjectCommon',[
  'vb/private/log',
  'vb/private/services/serviceConstants',
  'vb/private/services/swaggerUtils',
], (
  Log,
  ServiceConstants,
  SwaggerUtils,
) => {
  // const swaggerEndpointOperationObjects = ['get', 'put', 'post', 'delete', 'options', 'head', 'patch', 'trace'];
  const VB_HEADERS = 'headers';
  const VB_STATIC_QUERY_PARAMS = 'queryParameters';

  const logger = Log.getLogger('/vb/private/services/readers/openApiObjectCommon');

  /**
   * utility to merge an array of parameters.
   * used to merge Path Objects params with Operation Object params.
   * @param paramArrays
   * @returns {Array}
   */
  function mergeParameters(...paramArrays) {
    /** @type {Array.<Object.<string, any>>}} */
    const maps = [];
    // turn each array into a map, to merge and replace by name
    paramArrays.forEach((paramArray) => {
      /** @type Object.<string, any> */
      const mapParams = {};
      if (paramArray) {
        if (Array.isArray(paramArray)) {
          paramArray.forEach((param) => {
            const key = `${param.name}-${param.in}`;
            mapParams[key] = param;
          });
        } else {
          logger.error(`invalid parameter definition: ${JSON.stringify(paramArray)}`);
        }
      }
      maps.push(mapParams);
    });

    const mergedMap = Object.assign({}, ...maps);
    const mergedArray = Object.values(mergedMap);

    return mergedArray;
  }

  class OperationObject {
    /**
     * @param {string} name
     * @param {Object} pathObject Path itee difinition this operation belongs to
     *
     * @param {Object} operationObjectDef Operation definition this object adopts properties from
     * @param {Object} operationObjectDef.responses
     * @param {Object} operationObjectDef.parameters
     *
     * @param {Object} openApi
     */
    constructor(name, pathObject, operationObjectDef, openApi) {
      /** @type String */
      this.name = name;
      /** @type String */
      this.method = name;

      this.openApi = openApi;

      // for anything we expand lazily, put it in here when we do
      this.expanded = {};

      // copies all properties from the OpenApi's Operation Object (https://swagger.io/specification/#operation-object)
      // to this class
      Object.assign(this, operationObjectDef);

      // now, replace the 'parameters' with a merged version, combining the PathObject and OperationObject parameters
      this.parameters = mergeParameters(pathObject.parameters, this.parameters);

      // return OperationObjectObject.x-vb.headers for now
      this.staticHeaders = Object.assign({},
        this.openApi.getExtensions()[VB_HEADERS] || {},
        this.getExtensions()[VB_HEADERS] || {});
    }

    getExtensions() {
      return this[ServiceConstants.VB_EXTENSIONS] || {};
    }

    /**
     * creates an object with the 'info' level extensions, with top-level properties overridden by the
     * endpoint-level extensions. In other words, a 'shallow' merge.
     * @todo: consider either a real merge, or a more targeted method, in the future;
     * this is currently used for authentication, and really just for the 'info' level extensions.
     * @returns {Object}
     */
    getCombinedExtensions() {
      return Object.assign({}, this.getExtensions(), this.openApi.getExtensions());
    }

    /*
     todo: do we want to keep the same extension sytnax, which is a property in the OperationObject Object,
     or do we want to put an extension in the Header Object?
    */
    getStaticHeaderValues() {
      return this.staticHeaders;
    }

    getStaticQueryParameterValues() {
      return this.getExtensions()[VB_STATIC_QUERY_PARAMS];
    }

    /**
     * @abstract
     */
    // eslint-disable-next-line class-methods-use-this
    getResponseContentTypes() {
      throw new Error('getResponseContentTypes must be implemented!');
    }

    /**
     * @abstract
     */
    // eslint-disable-next-line class-methods-use-this
    getRequestContentTypes() {
      throw new Error('getRequestContentTypes must be implemented!');
    }

    /**
     * lazy-expansion of responses metadata, which includes JSON Schema
     * only does single-file expansion, no external file/url $ref resolution
     *
     * @returns {object}
     */
    getExpandedResponses() {
      if (!this.expanded.responses) {
        this.expanded.responses = SwaggerUtils.resolveReferences(this.openApi.definition, null, this.responses);
      }
      return this.expanded.responses;
    }

    /**
     * Resolves all external references for the expanded responses properties.
     * @returns
     */
    expandRemoteRefs() {
      return Promise.resolve()
        .then(() => {
          // We need to expand responses object before resolving the external references.
          // Expansion ‘at the earliest opportunity’ will resolve any local $refs in it, and
          // ‘make’ the expanded values potential new targets for external reference resolution.
          const expandedRespones = this.getExpandedResponses();
          return SwaggerUtils.resolveRemoteReferences(
            this.openApi, // OpenApi doc used for resolving references
            null /* no filter */,
            expandedRespones, // JSON object to expand
          );
        })
        .then((resolvedResponses) => {
          this.expanded.responses = resolvedResponses;
          return this;
        });
    }
  }

  /**
   *
   * @type {PathObject}
   */
  class PathObject {
    constructor(name, pathObjectDef, openApi) {
      Object.assign(this, pathObjectDef);

      this.name = name;
      this.path = name;
      this.operationObjects = [];

      Object.keys(pathObjectDef || {}).forEach((oprationKey) => {
        this.operationObjects.push(openApi.createOperationObjectObject(oprationKey, pathObjectDef, oprationKey));
      });
    }

    getOperationObjects() {
      return this.operationObjects;
    }
  }

  /**
   * @type {OpenApiObjectCommon}
   */
  class OpenApiObjectCommon {
    /**
     *
     * @param {Object} def
     * @param {Object} context
     */
    constructor(def, context) {
      this.serviceMetadataLoader = context.serviceMetadataLoader;
      // 'definition' will have $refs in parameters resolved (to some reasonable extent).
      const definition = OpenApiObjectCommon.resolveSomeReferences(def);

      this.definition = definition || {};
      this._pathObjects = null;
    }

    get pathObjects() {
      if (!this._pathObjects) {
        this._pathObjects = [];
        const pathObjects = (this.definition.paths) || {};

        Object.keys(pathObjects).forEach((PathObjectName) => {
          this.pathObjects.push(this.createPathObjectObject(PathObjectName, pathObjects[PathObjectName]));
        });
      }
      return this._pathObjects;
    }

    /**
     * factory method; version specific implementations should override as necessary
     * @param name
     * @param PathObjectObjectDefinition
     * @returns {PathObject}
     */
    createPathObjectObject(name, PathObjectObjectDefinition) {
      return new PathObject(name, PathObjectObjectDefinition, this);
    }

    /**
     * factory method; version sepcific implementations should override as necessary
     * @param name
     * @param pathObject
     * @param operationObjectKey
     * @returns {OperationObject}
     */
    createOperationObjectObject(name, pathObject, operationObjectKey) {
      const operationObjectDefinition = pathObject[operationObjectKey];
      return new OperationObject(name, pathObject, operationObjectDefinition, this);
    }

    getInfo() {
      return this.definition.info || {};
    }

    getExtensions() {
      return this.getInfo()[ServiceConstants.VB_EXTENSIONS] || {};
    }

    /**
     * @typedef {Object} ServerVariable An object representing a Server Variable for server URL template substitution.
     * @property {string} [description] An optional description for the server variable.
     * @property {string} default The default value to use for substitution,
     *                            which SHALL be sent if an alternate value is not supplied.
     */

    /**
     * @typedef {Object} Server
     * @property {function(Map<string, string>): string} getUrl Calculates server URL given server variables
     * @property {string} [description] An optional string describing the host designated by the URL.
     * @property {Map<string, ServerVariable>} [variables] A map between a variable name and its value.
     *                                  The value is used for substitution in the server's URL template.
     */

    /**
     * @returns {Server[]}
     * @abstract
     */
    // eslint-disable-next-line class-methods-use-this
    getServers() {
      throw Error('must be implemented by subclass');
    }

    /**
     * return the last server for swagger 2.0; matches the old associated:// behavior
     * this is actually passed 'profile', but doesn't use it
     * @returns {Object} Server Object
     */
    getServerForProfile() {
      const servers = this.getServers();
      // for the old swagger 2.0, needs to be the resolved server URI which is always the last
      return servers[servers.length - 1];
    }

    getPathObjects() {
      return this.pathObjects;
    }

    /**
     * resolve $refs in the Swagger only in some specific areas;
     * resolve all Path Item Objects to one level, then all Operation Object "parameters",
     * but NOT parameter responses or request bodies
     * @param {Object} definition Swagger
     * @returns {Object} new Swagger definition
     */
    static resolveSomeReferences(definition) {
      const newSwagger = Object.assign({}, definition); // why copy??

      // in the 'paths' object, only expand 'parameters', but not 'parameters.schema' (swagger 2.0)
      const filter = (node, context) => {
        // 0 is 'paths', 1 is the actual path
        if (context.names.length === 3) {
          return context.names[2] === 'parameters';
        }
        if (context.names.length === 4) {
          return !(context.names[2] === 'parameters' && context.names[3] === 'schema');
        }

        return true;
      };

      newSwagger.paths = SwaggerUtils.resolveReferences(definition, filter, definition.paths || {});

      return newSwagger;
    }
  }

  // expose these, so subclasses can override
  OpenApiObjectCommon.PathObject = PathObject;
  OpenApiObjectCommon.OperationObject = OperationObject;

  OpenApiObjectCommon.Protocol = {
    HTTP: 'http',
    HTTPS: 'https',
  };

  return OpenApiObjectCommon;
});

