'use strict';

define('vb/extensions/process/action/builtin/getDeployedProcessCollectionAction',[
  'vb/extensions/process/action/builtin/processAction',
  'vb/extensions/process/helpers/processService',
], (
  ProcessAction,
  ProcessService) => {
  /**
   * Retrieves a deployed process collection.
   *
   * Details:
   *
   * <ul>
   *   <li>Filters are applied with an "and" logic.</li>
   *
   *   <li>Filters with an array value can also take a single value (so the value of "alias" could be "process01").</li>
   *
   *   <li>The outcome returned by invoking the action is an object
   *   like <i>{ name: "", result: { content: {}, info: {} }</i>, where:
   *   <ul>
   *     <li>The value of "name" is either "success" or "failure"</li>
   *     <li>The value of "content" is a JSON object</li>
   *     <li>The value of "info" is an object with additional information like the status and headers of
   *         any HTTP response handled by the action</li>
   *   </ul></li>
   *
   *   <li>The retrieved collection contains an "items" array with the individual objects and also has other
   *   information like pagination details.</li>
   * </ul>
   *
   * Example:
   *
   * <pre>
   *  "action01": {
   *    "module": "vb/extensions/process/action/builtin/getDeployedProcessCollectionAction",
   *    "parameters": {
   *      "extended": true,
   *      "showProcessInstanceCount": true,
   *      "suppressUnregistered": true,
   *      "alias": ["process01", "process02"],
   *      "deployedProcessId": ["player~!sada~~ASdasd"]
   *    }
   *  }
   * </pre>
   */
  class GetDeployedProcessCollectionAction extends ProcessAction {
    static endpoint() {
      return ProcessService.endpoint.deployedProcessCollection;
    }

    static responseType() {
      return ProcessService.definition.extendedDeployedProcessCollection;
    }

    static restActionIdentification() {
      return { id: 'getDeployedProcessCollectionAction', description: 'Get a deployed process collection' };
    }

    perform(parameters) {
      const uriParams = Object.assign({}, parameters);

      const resultType = ProcessService.isRequestingExtendedPayload(uriParams) ?
        ProcessService.definition.extendedDeployedProcessCollection :
        ProcessService.definition.deployedProcessCollection;

      return this._send(uriParams, undefined, resultType);
    }
  }

  return GetDeployedProcessCollectionAction;
});

