'use strict';

define('vb/extensions/process/action/builtin/getProcessInstanceAction',[
  'vb/extensions/process/action/builtin/processAction',
  'vb/extensions/process/helpers/processService',
], (
  ProcessAction,
  ProcessService) => {
  /**
   * Retrieves a process instance.
   *
   * Details:
   *
   * <ul>
   *   <li>The outcome returned by invoking the action is an object
   *   like <i>{ name: "", result: { content: {}, info: {} }</i>, where:
   *   <ul>
   *     <li>The value of "name" is either "success" or "failure"</li>
   *     <li>The value of "content" is a JSON object</li>
   *     <li>The value of "info" is an object with additional information like the status and headers of
   *         any HTTP response handled by the action</li>
   *   </ul></li>
   * </ul>
   *
   * Example:
   *
   * <pre>
   *  "action01": {
   *    "module": "vb/extensions/process/action/builtin/getProcessInstanceAction",
   *    "parameters": {
   *      "processInstanceId": "1003"
   *    }
   *  }
   * </pre>
   */
  class GetProcessInstanceAction extends ProcessAction {
    static endpoint() {
      return ProcessService.endpoint.processInstance;
    }

    static responseType() {
      return ProcessService.definition.processInstance;
    }

    static restActionIdentification() {
      return { id: 'getProcessInstanceAction', description: 'Get a process instance' };
    }
  }

  return GetProcessInstanceAction;
});

