'use strict';

define('vb/private/types/capabilities/noOpFetchByKeys',[
  'vb/private/log'],
  (Log) => {
    class NoOpFetchByKeys {
      /**
       * constructor
       *
       * @param params {Object} see oj.FetchByKeysParameters. Contains the following properties
       *  - keys: Set<any> keys of the rows to fetch
       */
      constructor(params) {
        this.options = params;
        this.log = Log.getLogger('/vb/types/ServiceDataProvider.NoOpFetchByKeys');
      }

      fetchByKeys() {
        this.log.info('no results returned from no op fetchByKeys implementation');
        return Promise.resolve({ fetchParameters: this.options, results: {} });
      }

      containsKeys() {
        this.log.info('no results returned from no op containsKeys implementation');
        return Promise.resolve({ containsParameters: this.options, results: [] });
      }
    }

    return NoOpFetchByKeys;
  });

