'use strict';

define('vb/private/services/ramp/expandableField',[], () => {
  /**
   * The plus ("+") character is a special character and must be encoded in the URL.
   * Example: ?fields=%2BSalary
   * @type {string}
   */
  const FIELDS_MINUS_OPERATOR = '-';
  const COMMA_SEPARATOR = ',';
  const SEMICOLON_SEPARATOR = ';';
  const DOT_OPERATOR = '.';
  const COLON_OPERATOR = ':';
  /**
   * Client specifically requests full shape on new resource.
   * The * wildcard cannot be combined with other values for fields. Only allowed usecase is -
   * ?fields=*
   * @type {string}
   */
  const FIELDS_WILDCARD_OPERATOR = '*';

  const isTypeDefGeneric = (type) => {
    const incompleteBaseTypes = ['object', 'object[]', 'any', 'any[]'];
    return (typeof type === 'string' && incompleteBaseTypes.includes(type));
  };

  /**
   * whether @default is present in the list of attributes for the select options
   */
  const isAttributeDefault = (name) => name === '@default';
  /**
   * whether the attribute provided in the select options attributes is to be exlcuded.
   * @param name
   */
  const isAttributeExcluded = (name) => name && name.startsWith('!');

  /**
   * used by 'select' transform to represent an expandable field (or root), and its children
   * @param typeObject
   * @param parent
   * @param parentName
   * @constructor
   */
  class ExpandableField {
    constructor(typeObject, parent, parentName) {
      this.parent = parent;
      this.parentName = parentName;
      this.fields = [];
      this.children = [];
      this.excludes = [];

      if (!isTypeDefGeneric(typeObject)) {
        const type = Array.isArray(typeObject) ? (typeObject[0] || {}) : typeObject;

        Object.keys(type).forEach((name) => {
          const value = type[name];

          if (value && (typeof value === 'object')) {
            // if there is an 'items' array, use it; otherwise, use the current value if its an array
            // also skip 'links' (business objects return 400)
            const valueCollection = value.items || value;
            if (valueCollection && Array.isArray(valueCollection) && name !== 'links') {
              this.children.push(new ExpandableField(valueCollection, this, name));
            }
          } else if (name !== 'links') { // BUFP-20170
            this.fields.push(name);
          }
        }, this);
      }
    }

    /**
     * called with the optional select 'fields' property.
     * 'attributes' is a structure defined by the JET Dynamic Components.
     *
     * if we encounter a "@default" for a field name, we need to construct an inclusive query to
     * get all of the fields (this corresponds to a '@rest' in the layout syntax).
     *
     * Examples:
     *  PrimaryAddress:* - includes all fields
     *  PrimaryAddress:-AddressLine4 - includes all fields EXCEPT AddressLine4
     *
     * Typically, FA would not use @rest because of the large number of fields.
     * (and 'fields' and 'expand' query parameters can not be used together, so we always use 'fields').
     *
     * examples:
     * (1)  “displayProperties”: [“name”, “hireDate”, “email”] =>
     * renderedField: [{“name”: “name”}, {“name”: “hireDate”}, {“name”: “email”}]
     *
     * (2) “displayProperties”: [“name”, “hireDate”, “location”] =>
     * [{“name”: “name”}, {“name”: “hireDate”}, {“name”: “location”, “attributes”: [{“name”: “@default”}]}]
     *
     * (3) “displayProperties”: [“name”, “hireDate”, “location.city”, “location.country”] =>
     * [{“name”: “name”}, {“name”: “hireDate”},
     *   {“name”: “location”, “attributes”: [{“name”: “city”}, {“name”: “country”}]}]
     *
     * (4) “displayProperties”: [“name”, “hireDate”, {“location”: {“displayProperties”: [“city”, “country”]}}] =>
     * [{“name”: “name”}, {“name”: “hireDate”},
     *    {“name”: “location”, “attributes”: [{“name”: “city”}, {“name”: “country”}]}]
     *
     * (5)  “displayProperties”: [“!employeeId”, “!hireDate”, “@rest”] =>
     * [{“name”: “@default”}, {“name”: “!employeeId”}, {“name”: “!hireDate”}]
     *
     * (6) “displayProperties”: [“name”, “hireDate”, “managerId”] => [{“name”: “name”}, {“name”: “hireDate”},
     *    {“name”: “managerId”}]
     *
     * (7)  “displayProperties”: [“name”, “hireDate”, “managerId”, “salary”] =>
     * [{“name”: “name”}, {“name”: “hireDate”}, {“name”: “managerId”},
     *    {“name”: “salary”, “attributes”: [{“name”: “@default”}]}]
     *
     * (8) “displayProperties”: [“name”, “hireDate”, “managerId”, {
     *       “salary”: {“displayProperties”: [“baseSalary”, “bonus”]}
     *    }] =>
     * [{“name”: “name”}, {“name”: “hireDate”}, {“name”: “managerId”},
     *   {“name”: “salary”, “attributes”: [{“name”: “baseSalary”}, {“name”: “bonus”}]}]
     *
     * @param attributes
     */
    addComponentFetchAttributes(attributes) {
      const attrs = Array.isArray(attributes) ? attributes : [attributes || {}];
      attrs.forEach((attr) => {
        if (typeof attr === 'string' || (attr.name && (!attr.attributes || !attr.attributes.length))) {
          const name = (typeof attr === 'string') ? attr : attr.name;

          if (isAttributeDefault(name)) {
            this.hasDefaultAttribute = true;
          } else if (isAttributeExcluded(name)) {
            // put it on a list, to handle later
            this.excludes.push(name.substring(1));
          } else if (this.fields.indexOf(name) < 0) { // look in 'fields'
            this.fields.push(name);
          }
        } else if (attr.name && attr.attributes && attr.attributes.length > 0) {
          if (isAttributeDefault(attr.name)) {
            this.hasDefaultAttribute = true;
          } else if (isAttributeExcluded(attr.name)) {
            // put it on a list, to handle later
            this.excludes.push(attr.name.substring(1));
          } else {
            // look in 'children'
            let expField = this.children.find((child) => child.parentName === attr.name);
            if (!expField) {
              // create it with an empty type, and add the attributes
              expField = new ExpandableField({}, this, attr.name);
              this.children.push(expField);
            }
            expField.addComponentFetchAttributes(attr.attributes);
          }
        }
      });
    }

    getPrefix() {
      let efield = this;
      const names = [];
      while (efield && efield.parentName) {
        names.unshift(efield.parentName);
        efield = efield.parent;
      }
      return names.join(DOT_OPERATOR)
        + (names.length ? COLON_OPERATOR : '');
    }

    // eslint-disable-next-line class-methods-use-this
    getQueryParameterName() {
      // always use 'fields', since +, -, and * are supported. https://jira.oraclecorp.com/jira/browse/JDEVADF-41333
      return 'fields';
    }

    toString() {
      // if there is a "@default" in the attribute list, this implies return default shape defined
      // on resource, removing any exclusions if specified, so create a query term where:
      //
      // for top-level (there would be no parent name),
      //   - if there are no exclusions, an asterisk to include all fields. Example
      //     /employees?fields=* returns full representation (i.e., not just default fields).
      //     TODO:
      //      - the above comment treats @default as '*' (wildcard), which means include all
      //        fields, instead of just default shape, which would be /employees. Revisit with
      //        BUFP-34805
      //      - by using wildcard at the top-level, nested objects are skipped. this could be
      //        a problem if exclusions/inclusions or @default was specified for nested object.
      //        But then it's not clear if there is a way to specify default fields on top-level along
      //        with nested object fields (with default/inclusions/exclusions).
      //
      //   -  if there are exclusions a comma-separated list of -<names> to exclude, example
      //     /employees?fields=-foo, will return the default fields minus foo, if foo was one of
      //     the default fields.
      //
      // for sub-object, the dot-delimited prefix of parent names and EITHER:
      //   - an asterisk to include all fields, if there are no exclusions. Example
      //     /employees?fields=-lastName;departments:*
      //     TODO:
      //     - again we treat @default to mean all fields instead of the defaults. Revisit with
      //        BUFP-34805
      //  - a comma-separated list of -<names> to exclude, example
      //    /employees?fields=departments:-departmentId
      let str;
      if (this.hasDefaultAttribute) {
        str = this.getPrefix() + (this.excludes.length
          ? this.excludes.map((e) => FIELDS_MINUS_OPERATOR + e).join(COMMA_SEPARATOR)
          : FIELDS_WILDCARD_OPERATOR);
      } else {
        // if there is no "@default" in the attribute list, this implies add included fields and
        // remove excluded fields to/from the default shape. this.fields already has no excluded fields
        //
        // for top-level fields
        //   - a comma separated list of fields names with '+', '-', or just the
        //     included attributes, minus exclusions (say 'foo' is removed). example
        //     /employees?fields=firstName,lastName,
        //     TODO: Revisit with BUFP-34805
        //      - Is it better to use? /employees?fields=+firstName,+lastName,-foo, considering
        //     that RAMP throws an error when the '-' '+' operators are combined with old style
        //     fields in the same query string. Example this throws an error
        //     GET ?fields=-PartyId,-PartyName;Address:AddressId,AddressState;
        //
        // for sub-object,
        //   - the dot-delimited prefix of parent names
        //   - a comma-separated list of field names
        //   - a semi-colon between each child, example
        // /employees?fields=PartyId;Address:PartyId,AddressId;Address.AddressPurpose:Purpose,AddressPurposeId
        str = this.fields.length ? this.getPrefix() + this.fields.join(COMMA_SEPARATOR) : '';
      }

      this.children.forEach((child) => {
        const childStr = child.toString();
        if (childStr) {
          str += (str ? SEMICOLON_SEPARATOR : '') + childStr;
        }
      }, this);
      return str;
    }
  }

  return ExpandableField;
});

