'use strict';

define('vb/action/builtin/forEachAction',['vb/action/action', 'vb/private/constants', 'vb/private/action/actionHelper'], (Action, Constants, ActionHelper) => {
  //
  const MODES = {
    SERIAL: 'serial',
    PARALLEL: 'parallel',
  };

  const VALID_MODES = Object.values(MODES);

  class ForEachAction extends Action {
    /**
     * @param parameters {items, actionId, as}
     * - items {Array}
     * - actionId {string} ID of action in current chain to start executing for each loop
     * - as {string} an alias for $current
     * - mode: 'serial' (default) or 'parallel'
     * @returns {Outcome)
     */
    perform(parameters) {
      return Promise.resolve().then(() => {
        const { items, mode, actionId } = parameters;

        // this is a failure of the runtime, not the app
        if (!this.actionChain || !this.availableContexts) {
          throw new Error(`ForEachAction (${this.logLabel}): invalid execution context, internal error`);
        }

        if (!Array.isArray(items)) {
          this.log.error('ForEachAction', this.logLabel, '\'items\' is not an array');
          return Action.createFailureOutcome();
        }
        if (typeof actionId !== 'string') {
          this.log.error('ForEachAction', this.logLabel, 'invalid actionId', actionId);
          return Action.createFailureOutcome();
        }
        if (mode && VALID_MODES.indexOf(mode) === -1) {
          this.log.error('ForEachAction', this.logLabel, 'invalid mode value', mode,
            'must be one of', JSON.stringify(VALID_MODES));
          return Action.createFailureOutcome();
        }

        // set this, before calling createNewContext
        this.alias = parameters.as;

        const promises = [];

        let promise = Promise.resolve(); // initial promise
        items.forEach((data, index) => {
          // the context for the Callee (called) action - need a new one for each iteration
          const actionContext = ActionHelper.createNewContext(this.availableContexts, this);

          // if we are NOT parallel, we need to wait for the previous promise to resolve/reject
          const promiseToWaitFor = (mode === MODES.PARALLEL) ? Promise.resolve() : promise;

          promise = promiseToWaitFor.then(() => {
            // $current may be replaced by sub-actions, but the alias will not
            actionContext[Constants.ContextName.CURRENT] = { data, index };
            return this.actionChain.runActionStep(actionId, actionContext);
          });

          promises.push(promise);

          // don't reject any promises; just put the error in the results
          promise
            .catch((e) => {
              this.log.error('ForEachAction', this.logLabel, e);
              return e; // todo: ok to have the Error (or whatever) in the resolved results?
            });
        });

        // wait for all promises, before completing action
        return Promise.all(promises)
          .then((results) => Action.createSuccessOutcome(results))
          // this catch() should not happen, since we convert each promise to success (see 'don't reject')
          .catch((error) => Action.createFailureOutcome(error));
      });
    }

    /**
     * called by actionChain
     * @param actionChain
     * @param availableContexts
     */
    setContext(actionChain, availableContexts) {
      this.actionChain = actionChain;
      this.availableContexts = availableContexts;
    }
  }

  return ForEachAction;
});

