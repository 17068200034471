'use strict';

define('vb/action/action',[
  'vb/private/constants',
  'vb/private/log',
  'vb/private/stateManagement/stateUtils',
  'vb/private/utils',
], (Constants, Log, StateUtils, Utils) => {
  const logger = Log.getLogger('/vb/action/action');
  /**
   * Base class for creating an custom action.
   */
  class Action {
    constructor(id, label) {
      this.log = logger;

      // add uniqueness for cases where this is invoked in a called action chain (thus
      // run many times)
      this.id = `${id}_${Utils.generateUniqueId()}`;
      this.label = label || this.id;
      this.logLabel = (this.label === this.id) ? this.id : `${this.label} [${this.id}]`;
      this.metadataKey = id; // the original key from the "actions" in the action chain
      /**
       * @type {String}
       */
      this.containerLifecycleState = null; // Defined in actionChain.js

      // options for this action
      this.options = {};
    }

    /**
     * Starts the action and adds default handling.
     *
     * @private
     * @param parameters
     * @returns {Promise}
     */
    start(parameters) {
      // the promise executor function is called immediately (before the Promise constructor returns),
      // so there should be no difference checking isCancelled() outside of the executor
      if (this.isCancelled()) {
        // prevent the action from starting if it's in a cancelled state
        return Promise.reject('cancelled because the page has exited due to navigation');
      }

      // call the action - if its a promise, treat it as such, otherwise, just resolve
      return Promise.resolve().then(() => this.perform(parameters))
        .catch((e) => {
          this.log.error('Action', this.logLabel, 'failed.', e);
          let err = e || new Error('error in executing action');
          if (typeof e === 'string') {
            err = new Error(e);
          }
          throw err;
        });
    }

    /**
     * Performs an action. This can either run synchronsouly (returning an outcome) or return
     * a promise (which resolves to an outcome). The 'createOutcome' utility method can be used
     * to generate the result in either implemenation.
     *
     * If this returns a promise, rejecting without an outcome will result in the action chain
     * terminating (TODO: we dont yet do this =)).
     *
     * @param parameters
     */
    perform(parameters) {
      throw new TypeError('Subclass needs to override execute method.');
    }

    /**
     * Set options for this action.
     *
     * @param {Object} options options to set
     */
    setOptions(options) {
      this.options = Object.assign(this.options, options);
    }

    /**
     * This method is used to check if the action is in a cancelled state. An action is cancelled when the underlying
     * page, for example, has exited due to navigation to a different page. This method is used to immediately reject
     * an action before it starts and thus terminating the action chain. An in-flight action can also use this method
     * to terminate itself to prevent unwanted side effects.
     *
     * @returns {boolean}
     */
    isCancelled() {
      // an action chain is cancelled when the underlying container has exited
      return this.containerLifecycleState === Constants.ContainerState.EXITED;
    }

    /**
     * Possible action outcome name
     */
    static get Outcome() {
      return Constants.ActionOutcomes;
    }

    /**
     * Callback used by actionChain to let action decide how to evaluate their parameters
     * @param  {String} key
     * @param  {*} value
     * @param  {Object} availableContexts
     * @return {*} the param value
     */
    static buildParamValue(key, value, availableContexts) {
      return StateUtils.buildVariableDefaults(key, availableContexts, 'any', value);
    }

    /**
     * Utility method to create an outcome with the name and payload.
     *
     * @param name The name of the outcome - will determine what action occurs next
     * @param result The optional payload for the result
     * @returns {{result: *, name: string}}
     */
    static createOutcome(name, result) {
      return { name, result };
    }

    /**
     * Utility method to create a 'success' outcome.
     *
     * @param result The optional payload for the result
     * @returns {{result: *, name: string}}
     */
    static createSuccessOutcome(result) {
      return { name: this.Outcome.SUCCESS, result };
    }

    /**
     * Utility method to create a 'failure' outcome.
     *
     * @param summary The optional summary error message, used as the 'summary' in the Message object.
     * @param error The optional Error/exception object
     * @param payload The optional payload for the result, (outcome.result.payload)
     * @returns {{name: string, result: { message: {summary}, error, payload}}}
     */
    static createFailureOutcome(summary, error, payload) {
      const result = {
        // todo: this should eventually match the JET Message class {detail, severity, summary}
        message: {
          summary,
        },
        error,
        payload,
      };
      return { name: this.Outcome.FAILURE, result };
    }
  }

  return Action;
});

