'use strict';

define('vb/types/factories/typeFactory',[], () => {
  /**
   * A type factory implementation extends or duck types this factory class to create instances of its type class.
   * @abstract
   */
  class TypeFactory {
    /**
     * Creates a standalone instance not backed by a variable - the instance maintains its own state and does use
     * variable / redux framework for state management.
     * @param options used to instantiate the RestHelper with.
     * @return {Promise} resolves to a standalone instance of the type.
     * @abstract
     */
    // eslint-disable-next-line no-unused-vars
    static createInstance(options) {
      throw new Error('method unsupported!');
    }
  }

  return TypeFactory;
});

