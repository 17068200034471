'use strict';

define('vb/private/stateManagement/stateMonitor',[], () => {
  /**
   * This class is used to monitor the state changes in the runtime.
   */
  class StateMonitor {
    constructor() {
      // define the runtime states
      this.RuntimeState = {
        PAGE_ACTIVATED: 'vbPageActivated',
        PAGE_DEACTIVATED: 'vbPageDeactivated',
        FRAGMENT_ACTIVATED: 'vbFragmentActivated',
        FRAGMENT_DEACTIVATED: 'vbFragmentDeactivated',
        CONTAINER_ACTIVATED: 'vbContainerActivated',
        CONTAINER_DEACTIVATED: 'vbContainerDeactivated',
      };

      this.stateChangeListeners = [];
    }

    /**
     * Record a state change. It will also invoke the listeners registered with the StateMonitor.
     *
     * @param state state to record
     */
    recordStateChange(state, container) {
      // for now, just inform the listeners about the state change
      this.stateChangeListeners.forEach((listener) => {
        listener(state, container);
      });
    }

    /**
     * Register a state change listener. The listener will be invoked whenever recordStateChange is called.
     *
     * @param listener the listener to add
     */
    addStateChangeListener(listener) {
      this.stateChangeListeners.push(listener);
    }

    /**
     * Remove the listener.
     *
     * @param listener the listener to remove.
     */
    removeStateChangeListener(listener) {
      const index = this.stateChangeListeners.indexOf(listener);
      if (index >= 0) {
        this.stateChangeListeners.splice(index, 1);
      }
    }
  }

  return new StateMonitor();
});

