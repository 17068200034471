'use strict';

/**
 * class DataDescriptionModel
 *  extends TranslationsModel, includes translations.
 */

define('vb/extensions/dynamic/private/models/dataDescriptionModel',[
  'vb/extensions/dynamic/private/models/abstractModel',
  'vb/extensions/dynamic/private/models/translationsModel',
], (AbstractModel, TranslationsModel) => AbstractModel(TranslationsModel));

