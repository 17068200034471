'use strict';

define('vb/action/builtin/loginAction',[
  'vb/action/action',
],
(Action) => {
  /**
   * Launch the login process as defined in the Security Provider implementation.
   * It invokes the handleLogin function on the Security Provider with the returnPath argument.
   * The returnPath parameter is used by the login page to return to the application
   * after a successfull login.
   *
   * @param {String} returnPath  the path of the page or flow to go to when login is successful
   *
   * Returns a 'failure' outcome in case of error or the outcome 'success' with the payload true if
   * navigation to the login page was successful.
   *
   */
  class LoginAction extends Action {

    perform(parameters) {
      const securityProvider = this.context.application.securityProvider;

      if (!securityProvider) {
        return Action.createFailureOutcome('Security Provider missing.');
      }

      try {
        return Action.createSuccessOutcome(securityProvider.handleLogin(parameters.returnPath));
      } catch (error) {
        return Action.createFailureOutcome('Error during login operation', error);
      }
    }

    setContext(context) {
      this.context = context;
    }
  }

  return LoginAction;
});

