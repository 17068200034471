'use strict';

define('vb/extensions/dynamic/private/types/containerMetadataProviderDescriptor',[
  'vb/extensions/dynamic/private/types/abstractMetadataProviderDescriptor',
  'vb/extensions/dynamic/private/types/bridges/helperMetadataProviderFactoryBridge',
  'vb/extensions/dynamic/private/constants',
],
(AbstractMetadataProviderDescriptor, HelperMetadataProviderFactoryBridge, Constants) => {
  //
  const TEMPLATE_SUFFIX = '-template';
  /**
   * VB "metadata" variable for JET Dynamic Container support.
   */
  class ContainerMetadataProviderDescriptor extends AbstractMetadataProviderDescriptor {
    /**
     * creates an 'options' object that is used both by the FactoryMetadataProviderDescriptor, and
     * the VB metadata provider helper.
     *
     * Properties used by FactoryMetadataProviderDescriptor:
     *
     * factory:      path to the JET (or VB proxy) metadata provider factory
     * helper.class: path for the VB metadata provider helper, passed to the Metadata provider
     *
     * properties used by the ConfigurableMetadataProviderHelper
     * helper.root:  base path for the resources used by the metadata provider helper; the "page" folder
     * helper.resources: a set of objects that describe the resources served to the JET provider
     *
     * helper.resources.files: all other files, returned by getLayoutResources as an array of named objects.
     *
     * @param value {object}
     * @param container {Container}
     * @returns {Object}
     */
    createOptions(value) {
      let options = super.createOptions(value);

      if (!options.factory) {
        const extensions = {};
        const container = this.container;

        if (!container) {
          throw new Error('Missing container when creating MetadataProvider');
        }

        container.extensionsArray.forEach((containerExt) => {
          extensions[containerExt.extensionId] = {
            container: containerExt,
            property: 'data',
          };
        });

        // no 'metadata' for container, just files
        const descriptors = {
          // 'container.html', lives in the same folder as the page
          files: [{
            property: 'data',
            extensionFile: `${container.path}-x.json`,
            baseContainer: container,
            extensionContainers: extensions,
          }, {
            property: 'template',
            // if the container is a pageExtension, then the baseFile is page-template-x
            baseFile: `${container.fullName}${TEMPLATE_SUFFIX}${container.base ? '-x' : ''}.html`,
            extensionFile: `${container.fullName}${TEMPLATE_SUFFIX}-x.html`,
            prefix: 'text!',
            optional: true,
          }],
        };

        options = Object.assign({}, options, {
          layoutType: Constants.LayoutType.CONTAINER,
          factory: 'oj-dynamic/providers/ContainerMetadataProviderFactory',
          helper: {
            class: 'vb/extensions/dynamic/private/helpers/configurableMetadataProviderHelper',
            root: container.resourceLoc, // should be the resource path relative to the baseUrl
            descriptors,
          },
        });
      }

      return options;
    }

    /**
     * @override
     * @returns {MetadataProviderFactoryBridge}
     */
    createFactoryBridge() {
      return new HelperMetadataProviderFactoryBridge();
    }

    /**
     * @override
     * @returns {{type: {}}}
     */
    // eslint-disable-next-line class-methods-use-this
    getTypeDefinition() {
      return {
        type: 'any', // needed this for some reason, couldn't return null, stateUtils wouldn't call init()
      };
    }
  }

  return ContainerMetadataProviderDescriptor;
});

