'use strict';

define('vb/private/types/capabilities/noOpFetchFirst',[
  'vb/private/log'],
  (Log) => {
    /**
     * An async iterable class that meets the following contract in Typescript notation.
     * interface AsyncIterable {
     *   [Symbol.asyncIterator]() : AsyncIterator;
     * }
     *
     * Symbol.asyncInterator is part of a stage 3 draft proposal. For details see here:
     * https://tc39.github.io/proposal-async-iteration/
     * @constructor
     */
    class NoOpAsyncIterable {
      constructor(fetchContext) {
        this.asyncIter = fetchContext;

        this[Symbol.asyncIterator] = function () {
          return this.asyncIter;
        };
      }
    }

    /**
     * A class that duck types oj.FetchListResult that is set on the IteratorResult which
     * essentially is of the form
     * interface oj.FetchListResult<T, K> {
     *   readonly fetchParameters: FetchListParameters,
     *   readonly data: T[],
     *   readonly metadata: ItemMetadata<K>[]
     * }
     */
    class NoOpFetchListResult {
      constructor(fetchParameters, data, metadata) {
        this.fetchParameters = fetchParameters;
        this.data = data;
        this.metadata = metadata;
      }
    }

    /**
     * An IteratorResult class, an instance of which is returned from a AsyncIterator.next call.
     *
     * interface IteratorResult {
     *   value: oj.FetchListResult<T, K>;
     *   done: boolean;
     * }
     *
     */
    class NoOpAsyncIterator {
      constructor(value, done) {
        this.value = value;
        this.done = done;
      }
    }

    class NoOpFetchFirst {
      /**
       * constructor
       *
       * @param params {Object} see oj.FetchByKeysParameters. Contains the following properties
       *  - keys: Set<any> keys of the rows to fetch
       */
      constructor(params) {
        this.options = params;
        this.log = Log.getLogger('/vb/types/ServiceDataProvider.NoOpFetchFirst');
      }

      fetchFirst() {
        this.log.info('no results returned from no op fetchFirst implementation');
        return new NoOpAsyncIterable(this);
      }

      next() {
        const hasMore = !(this.doneIterating === true);
        const finalResult = new NoOpFetchListResult(this.options, [], []);
        const iteratorResult = new NoOpAsyncIterator(finalResult, !hasMore);
        this.doneIterating = true;
        this.log.info('no results returned from no op fetchFirst implementation:', iteratorResult);
        return Promise.resolve(iteratorResult);
      }

      getCapability() {
        return {
          implementation: 'iteration',
        };
      }
    }

    return NoOpFetchFirst;
  });

