'use strict';

/**
 * for now $application.builtinUtils is a map of utility modules available from expressions and the following modules
 * are exposed
 * AssignmentUtils: $application.builtinUtils.assignmentUtils
 */
define('vb/private/stateManagement/applicationUtils',['vb/private/action/assignmentUtils'], (AssignmentUtils) => ({
  assignmentUtils: AssignmentUtils,
}));

