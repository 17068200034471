'use strict';

define('vb/private/stateManagement/context/pageContext',[
  'vb/private/constants',
  'vb/private/stateManagement/context/containerContext',
  'vb/private/stateManagement/context/pageBaseContext',
  'vb/helpers/componentFinder'],
(Constants, ContainerContext, PageBaseContext, ComponentFinder) => {
  /**
   * set of properties to expose in $page
   */
  class PageContext extends ContainerContext {
    /**
     * @param page
     */
    constructor(page) {
      super(page);
      const accessors = {
        [Constants.COMPONENTS_CONTEXT]: () => ComponentFinder, // VBS-25776, cannot remove this because FA uses it
      };

      const propDescriptors = {
      };

      Object.keys(accessors).forEach((accessorName) => {
        propDescriptors[accessorName] = {
          get: accessors[accessorName],
          enumerable: true,
          configurable: true,
        };
      });

      /**
       * $page.currentFlow
       * $page.info
       */
      [Constants.CURRENT_FLOW_VARIABLE, Constants.INFO_CONTEXT].forEach((property) => {
        propDescriptors[property] = {
          get() {
            return page.scope.variableNamespaces[Constants.VariableNamespace.BUILTIN][property];
          },
          enumerable: true,
          configurable: true,
        };
      });

      Object.defineProperties(this, propDescriptors);
    }

    static get BaseContextType() {
      return PageBaseContext;
    }

    /**
     * see ContainerContext
     *
     * @param page
     * @returns {{$application: *, $variables, $metadata}}
     */
    static getAvailableContexts(page) {
      const availableContexts = super.getAvailableContexts(page);

      Object.defineProperties(availableContexts, {
        $flow: {
          enumerable: true,
          configurable: true,
          get: () => page.parent.expressionContext,
        },
        // everything from here down used to only be created when the Page's Scope was created
        // now, we create the object once, up front, and rely on getters to allow deferred assignment
        // of the expressionContext.  But, because Expression reads all the values, regardless of what is used
        // in the current expression, the properties may be read before expressionContex is created,
        // so we guard against expressionContex being null.  Should not be a problem,
        // since the null pre-Scope values cannot be meaningfully referenced anyway.
        $page: {
          enumerable: true,
          configurable: true,
          get() {
            return page.expressionContext;
          },
        },
      });

      return availableContexts;
    }
  }

  return PageContext;
});

