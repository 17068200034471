'use strict';

define('vb/types/typeUtils',['vb/private/stateManagement/stateUtils'], (StateUtils) => {
  /**
  * set of static utility methods. related to data providers
  */
  class TypeUtils {
    /**
     * Sets a value on a sub-property of a complex value, generally a variable starting at a root property of say a
     * built-in variable.
     * @param o
     * @param path dot limited path to the sub-property whose value is being set
     * @param v value to set
     */
    static setPropertyValue(o, path, v) {
      let obj = o;
      const val = v;
      if (typeof path === 'string') {
        const props = path.split('.');
        props.forEach((prop, index) => {
          if (index + 1 === props.length) {
            obj[prop] = val;
          } else {
            if (!obj[prop]) {
              obj[prop] = {};
            }
            obj = obj[prop];
          }
        });
      } else {
        obj[path] = v;
      }
    }

    /**
     * Returns the value in a (dot limited) path to a complex value, generally a variable sub-property starting at the
     * root property. Example value or internalState
     * @param o
     * @param path dot limited path to the sub-property whose value is being read
     * @returns {*}
     */
    static getPropertyValue(o, path) {
      let obj = o;
      let value;
      if (typeof path === 'string') {
        const props = path.split('.');
        if (props) {
          props.some((prop, index) => {
            if (index + 1 === props.length) {
              value = obj ? obj[prop] : undefined;
              return true;
            }
            if (!obj || !obj[prop]) {
              return true;
            }
            obj = obj[prop];
            return false;
          });
        }
        return value;
      }
      return o[path];
    }

    /**
     * Returns the fully resolved type for the type definition for the type typeName.
     * This will resolve all externalized types and denormalize this into a single structured type.
     *
     * A type can be a primitive, i.e. "string", "boolean", or "number".
     *
     * It can also be an object. In this case, the type will be returned as an object, where the keys are the
     * names of the properties, and the values are the types for those keys. The types for keys can also be objects
     * or any other type.
     *
     * A type can also be an array, in which case it will be represented as a single item array where that item
     * describes the type of each item in the array. If the array is an array of primitives, that will be returned as
     * a string such as "string[]", "boolean[]", or "number[]".
     *
     * A type can be an extended type (meaning it is an instance of some class). In this case, the type is the string
     * representation of the module (i.e. "vb/ServiceDataProvider").
     *
     * Finally, a type can be a wildcard object. In this case the type is the string, "object". If it's an array
     * whose items can be wildcards, this can be explicitly represented as "object[]". Note that "object" can
     * also be an array or an object otherwise, although the platform will default it to an empty object if no
     * other default value is specified. All other wildcard types can be set to "any" or "any[]".
     *
     * If the type cannot be derived, this will method will throw an exception.
     *
     * @param typeName
     * @param typeDef the variable definition as defined in json. Built in types which have their own type
     * definition may also resolve the typeDef on their own, and set the resolved type under a 'type' property and
     * also set a 'resolved' boolean property, indicating that the type has been resolved. See
     * ExtendedType.getTypeDefinition() for details.
     *
     * @param typeName
     * @param typeDef
     * @param scopeResolver
     * @returns {Object<type, context>|{scopeResolver: *, scopeName, type}|{}|{scopeResolver, scopeName, type}}
     */
    static getType(typeName, typeDef, scopeResolver) {
      return StateUtils.getType(typeName, typeDef, scopeResolver);
    }
  }

  return TypeUtils;
});

