'use strict';

define('vb/private/types/dataProviders/serviceDataProviderUtils',['knockout',
  'vb/private/types/dataProviderConstants',
  'vb/private/types/utils/dataProviderUtils',
  'vb/private/types/capabilities/fetchContext',
  'vb/private/types/capabilities/fetchByKeys',
  'vb/private/types/capabilities/fetchByKeysIteration',
  'vb/private/types/capabilities/fetchBySingleKey',
  'vb/private/types/capabilities/containsKeys',
  'vb/private/types/capabilities/fetchByOffset',
  'vb/private/types/capabilities/fetchByOffsetIteration',
], (ko, DPConstants, DPUtils, FetchContext, FetchByKeys, FetchByKeysIteration, FetchBySingleKey, ContainsKeys,
  FetchByOffset, FetchByOffsetIteration) => {
  // disallow multiple fetch capabilities in a single SDP variable
  const FLAG_NONE = 0; // 0000
  const FLAG_FF = 1; // 0001
  const FLAG_FBK = 2; // 0010
  const FLAG_FBO = 4; // 0100

  /**
   * returns FLAG_FIRST or FLAG_NONE for fetchFirst capability
   * @param caps
   * @returns {number}
   */
  const getFFSet = function getFFSet(caps) {
    const ff = DPConstants.CapabilityType.FETCH_FIRST;
    return caps[ff] && caps[ff][DPConstants.CapabilityKeys.FETCH_FIRST_IMPLEMENTATION]
      ? FLAG_FF : FLAG_NONE;
  };
  /**
   *
   * @param caps
   * @returns {number}
   */
  const getFBKSet = function getFBKSet(caps) {
    const fbk = DPConstants.CapabilityType.FETCH_BY_KEYS;
    return caps[fbk] && caps[fbk][DPConstants.CapabilityKeys.FETCH_BY_KEYS_IMPLEMENTATION]
      ? FLAG_FBK : FLAG_NONE;
  };
  /**
   *
   * @param caps
   * @returns {number}
   */
  const getFBOSet = function getFBOSet(caps) {
    const fbo = DPConstants.CapabilityType.FETCH_BY_OFFSET;
    return caps[fbo] && caps[fbo][DPConstants.CapabilityKeys.FETCH_BY_OFFSET_IMPLEMENTATION]
      ? FLAG_FBO : FLAG_NONE;
  };

  /**
   * helper to unwrap observables
   * @param data
   * @returns {*}
   */
  const unwrapData = (data) => {
    let d = data;
    if (ko.isObservable(d)) {
      // variable default value can now reference a constant and so we need to eval that
      d = ko.utils.unwrapObservable(data);
    }
    return d;
  };

  class ServiceDataProviderUtils {
    /**
     * Returns the capabilities for the feature.
     * @param feature
     * @param featureCapability
     * @see oj.DataProvider
     * @return {Object} or null if feature is not recognized
     * @private
     */
    static getCapabilityByFeature(feature, featureCapability) {
      let cap = null;

      switch (feature) {
        case DPConstants.CapabilityType.SORT: {
          cap = {};
          if (featureCapability && featureCapability.attributes) {
            cap.attributes = featureCapability.attributes;
          } else {
            cap.attributes = DPConstants.CapabilityValues.SORT_ATTRIBUTES_MULTIPLE;
          }
          break;
        }

        case DPConstants.CapabilityType.FILTER: {
          cap = {};
          cap.operators = (featureCapability && featureCapability.operators
            && featureCapability.operators.length > 0)
            ? featureCapability.operators : DPConstants.CapabilityValues.FILTER_OPERATORS_OPS;

          // text filtering is considered always supported for now. Both RAMP and 3rd party service authors
          // need to write their transforms for text filtering.
          cap.textFilter = {}; // JET does not define a value! They expect any.

          break;
        }
        case DPConstants.CapabilityType.FETCH_FIRST: {
          cap = {};
          cap[DPConstants.CapabilityKeys.FETCH_FIRST_IMPLEMENTATION] = (featureCapability
            && featureCapability[DPConstants.CapabilityKeys.FETCH_FIRST_IMPLEMENTATION])
            || DPConstants.CapabilityValues.FETCH_FIRST_IMPLEMENTATION_ITERATION;

          // VBS-7070 - for 2107, this private property will always be set by SDP to ensure that components can
          // check for this and not implement the live iterator behavior. See JET-44038
          cap[DPConstants.CapabilityKeys.FETCH_FIRST_ITERATE_AFTER_DONE]
            = DPConstants.CapabilityValues.FETCH_FIRST_ITERATE_AFTER_DONE_NOT_ALLOWED;
          break;
        }
        case DPConstants.CapabilityType.FETCH_BY_KEYS: {
          cap = {};
          const implementation = featureCapability
            && featureCapability[DPConstants.CapabilityKeys.FETCH_BY_KEYS_IMPLEMENTATION];
          const multiKeyLookup = featureCapability
            && featureCapability[DPConstants.CapabilityKeys.FETCH_BY_KEYS_MULTI_KEY_LOOKUP];

          if (featureCapability && implementation) {
            cap[DPConstants.CapabilityKeys.FETCH_BY_KEYS_IMPLEMENTATION] = implementation;
            if (implementation === DPConstants.CapabilityValues.FETCH_BY_KEYS_IMPLEMENTATION_LOOKUP) {
              cap[DPConstants.CapabilityKeys.FETCH_BY_KEYS_MULTI_KEY_LOOKUP] = multiKeyLookup
                || DPConstants.CapabilityValues.FETCH_BY_KEYS_MULTI_KEY_LOOKUP_YES;
            }
          } else {
            // default is {implementation: 'iteration'}
            /* eslint operator-linebreak: [2, "before"] */
            cap[DPConstants.CapabilityKeys.FETCH_BY_KEYS_IMPLEMENTATION]
              = DPConstants.CapabilityValues.FETCH_BY_KEYS_IMPLEMENTATION_ITERATION;
          }
          break;
        }
        case DPConstants.CapabilityType.FETCH_BY_OFFSET: {
          cap = {};

          // TODO: <bug> Even though RAMP/custom BO based services support randomAccess unless provided by caller,
          // the safest choice for now is to assume "iteration"
          cap[DPConstants.CapabilityKeys.FETCH_BY_OFFSET_IMPLEMENTATION] = (featureCapability
            && featureCapability[DPConstants.CapabilityKeys.FETCH_BY_OFFSET_IMPLEMENTATION])
            || DPConstants.CapabilityValues.FETCH_BY_OFFSET_IMPLEMENTATION_ITERATION;
          break;
        }

        default: {
          cap = null;
          break;
        }
      }
      return cap;
    }

    /**
     * Returns a map of fetch capabilities that are configured on the SDP.
     *
     * @param capabilitiesDef the capabilities property of the defaultValue in the SDP definition.
     *
     * @return {Object} map of fetch capabilities that are configured on the SDP, by fetch
     * capability name. Can be undefined if no fetch capabilities are specified
     * @private
     */
    static getConfiguredFetchCapabilities(capabilitiesDef) {
      let fetchCaps;

      // isolate the configured fetch capabilities into fetchCapsDef
      const FETCH_FEATURES = [DPConstants.CapabilityType.FETCH_BY_OFFSET,
        DPConstants.CapabilityType.FETCH_BY_KEYS,
        DPConstants.CapabilityType.FETCH_FIRST];

      const capsValue = DPUtils.unwrapData(capabilitiesDef);

      FETCH_FEATURES.forEach((feature) => {
        if (capsValue[feature]) {
          const featureCap = unwrapData(capsValue[feature]);
          fetchCaps = fetchCaps || {};
          fetchCaps[feature] = featureCap;
        }
      });

      return fetchCaps;
    }

    /**
     * Gets the fetch capabilities fully resolved value. For fetch capabilities that are not
     * fully configured this method returns the default values for the same.
     *
     * @param {String} fetchCapsDef the fetch capabilities definitions - includes 'fetchByKeys',
     * 'fetchByOffset' and 'fetchFirst'
     * @see oj.DataProvider
     * @return {Object} or null if feature is not recognized, keyed in by feature name
     */
    static getResolvedFetchCapabilities(fetchCapsDef) {
      let fetchCaps;

      const FETCH_FEATURES = [DPConstants.CapabilityType.FETCH_BY_OFFSET,
        DPConstants.CapabilityType.FETCH_BY_KEYS,
        DPConstants.CapabilityType.FETCH_FIRST];

      FETCH_FEATURES.forEach((feature) => {
        const featureCap = fetchCapsDef[feature];
        fetchCaps = fetchCaps || {};
        fetchCaps[feature] = ServiceDataProviderUtils.getCapabilityByFeature(feature, featureCap);
      });

      return fetchCaps;
    }

    /**
     * Checks if multiple fetch capabilities are configured on the SDP and if they are, returns
     * false. This only checks the actual configuration of the SDP.
     * @param fetchCaps fetch capabilities def
     * @private
     */
    static hasMultipleFetchCapabilities(fetchCaps) {
      /* eslint no-bitwise: ["error", { "allow": ["|"] }] */
      const configuredVal = (fetchCaps
        && (getFFSet(fetchCaps) | getFBKSet(fetchCaps) | getFBOSet(fetchCaps))) || FLAG_NONE;
      if (configuredVal === FLAG_NONE) {
        // no capabilities set
        return false;
      }

      return !((configuredVal === FLAG_FF) || (configuredVal === FLAG_FBK)
        || (configuredVal === FLAG_FBO));
    }

    /**
     * Initializes the right fetchByKeys methods on the SDP instance based on the configured capabilities.
     * @param sdpInstance
     * @param fetchCaps
     */
    static initFetchByKeysMethods(sdpInstance, fetchCaps) {
      const sdp = sdpInstance;
      let useNonOptimizedFetchByKeysImpl = true;

      if (fetchCaps && fetchCaps[DPConstants.CapabilityType.FETCH_BY_KEYS]) {
        const fbkCaps = fetchCaps[DPConstants.CapabilityType.FETCH_BY_KEYS];

        // setup the fetchByKeys method only when the implementation is 'lookup'. For 'iteration'
        // based implementation fallback to the default fetchFirst implementation
        if (ServiceDataProviderUtils.isImplementationLookup(fbkCaps)) {
          // if implementation is 'lookup' and multiple keys are allowed,
          ServiceDataProviderUtils.initFetchByKeysLookupMethods(sdp, fbkCaps);
          useNonOptimizedFetchByKeysImpl = false;
        }
      }

      if (useNonOptimizedFetchByKeysImpl && typeof sdp.fetchByKeys === 'undefined') {
        // bufp-22977: provide an optimized iteration based fetchByKeys;
        sdp.log.fine('ServiceDataProvider', sdp.id, 'using non-optimized fetchFirst implementation for fetchByKeys,',
          'because a lookup based implementation was not provided!');

        ServiceDataProviderUtils.initFetchByKeysIterationMethods(sdp);
      }
    }

    /**
     * sets up the fetchByKeys methods on the SDP for lookup based implementation, using the capabilities configured.
     * @param sdp
     * @param fbkCaps
     * @private
     */
    static initFetchByKeysLookupMethods(sdpInstance, fbkCaps) {
      const sdp = sdpInstance;
      if (typeof sdp.fetchByKeys === 'undefined') {
        /**
         * delegates to FetchByKeys or FetchBySingleKey implementation
         * @param params similar to oj.FetchByKeysParameters
         * @returns {Promise} resolves with result or rejects with error.
         */
        sdp.fetchByKeys = function fetchByKeys(params) {
          if (ServiceDataProviderUtils.isMultiKeyLookupAllowed(fbkCaps)) {
            return (new FetchByKeys(sdp, params)).fetch();
          }
          return (new FetchBySingleKey(sdp, params)).fetch();
        };

        /**
         * Delegates to the appropriate fetchByKeys lookup based implementation and calls
         * containsByKeys on it.
         * @param params
         * @returns {Promise}
         */
        sdp.containsKeys = function containsKeys(params) {
          // it appears that the params passed might be different between this and
          // fetchByKeys call above.
          let fbkImpl;
          if (ServiceDataProviderUtils.isMultiKeyLookupAllowed(fbkCaps)) {
            fbkImpl = new FetchByKeys(sdp, params);
          } else {
            fbkImpl = new FetchBySingleKey(sdp, params);
          }

          return (new ContainsKeys(fbkImpl, sdp, params)).contains();
        };
      }
    }

    /**
     * set up the fetchByKeys methods for iteration based implementation
     * @param sdpInstance
     * @private
     */
    static initFetchByKeysIterationMethods(sdpInstance) {
      const sdp = sdpInstance;

      if ((typeof sdp.fetchByKeys === 'undefined')) {
        /**
         * delegates to FetchByKeys to do an iteration based lookup.
         * @param params similar to oj.FetchByKeysParameters
         * @returns {Promise} resolves with result or rejects with error.
         */
        sdp.fetchByKeys = function fetchByKeys(params) {
          return (new FetchByKeysIteration(sdp, params)).fetch();
        };

        /**
         * Delegates to the appropriate fetchByKeys iteration based implementation and calls
         * containsByKeys on it.
         * @param params
         * @returns {Promise}
         */
        sdp.containsKeys = function containsKeys(params) {
          // params passed might be different between this and fetchByKeys call above.
          const fbkImpl = new FetchByKeysIteration(sdp, params);
          return (new ContainsKeys(fbkImpl, sdp, params)).contains();
        };
      }
    }

    /**
     * Initializes fetchByOffset methods on the sdpInstance based on the configured fetch capabilities
     * @param sdpInstance
     * @param fetchCaps
     */
    static initFetchByOffsetMethods(sdpInstance, fetchCaps) {
      const sdp = sdpInstance;
      let useNonOptimizedFetchByOffsetImpl = true;

      // setup the fetchByOffset methods required when the fetchByOffset capability is present
      // on the DataProvider configuration. If there is no fetchByKeys capability configured on the SDP, fetchFirst
      // capability is used to provide an optimized fetchByKeys implementation
      if (fetchCaps && fetchCaps[DPConstants.CapabilityType.FETCH_BY_OFFSET]) {
        const fboCaps = fetchCaps[DPConstants.CapabilityType.FETCH_BY_OFFSET];

        // setup the fetchByOffset method only when the implementation is 'randomAccess'. For
        // 'iteration' based implementation fallback to the default fetchFirst implementation (uses an optimized
        // fetchFirst to build the results.)
        if (ServiceDataProviderUtils.isImplementationRandomAccess(fboCaps)) {
          ServiceDataProviderUtils.initFetchByOffsetRandomAccess(sdp);
          useNonOptimizedFetchByOffsetImpl = false;
        }
      }

      if (useNonOptimizedFetchByOffsetImpl && typeof sdp.fetchByOffset === 'undefined') {
        sdp.log.fine('ServiceDataProvider', sdp.id, 'using non-optimized fetchFirst implementation for'
          + ' fetchByOffset, because a randomAccess based implementation was not provided!');

        ServiceDataProviderUtils.initFetchByOffsetIteration(sdp);
      }
    }

    /**
     * Set up the fetchByOffset random access implementation.
     * @param sdpInstance
     * @private
     */
    static initFetchByOffsetRandomAccess(sdpInstance) {
      const sdp = sdpInstance;

      if (typeof sdp.fetchByOffset === 'undefined') {
        /**
         * delegates to FetchByOffset
         * @param params similar to oj.FetchByOffsetParameters
         * @returns {Promise} resolves with result or rejects with error.
         */
        sdp.fetchByOffset = function fetchByOffset(params) {
          return (new FetchByOffset(sdp, params)).fetch();
        };
      }
    }

    /**
     * Set up the fetchByOffset iteration based method implementation.
     * @param sdpInstance
     * @private
     */
    static initFetchByOffsetIteration(sdpInstance) {
      const sdp = sdpInstance;

      if (typeof sdp.fetchByOffset === 'undefined') {
        /**
         * delegates to FetchByOffset to do an iteration based approach to locate page.
         * @param params similar to oj.FetchByKeysParameters
         * @returns {Promise} resolves with result or rejects with error.
         */
        sdp.fetchByOffset = function fetchByOffset(params) {
          return (new FetchByOffsetIteration(sdp, params)).fetch();
        };
      }
    }

    /**
     * Whether a lookup based implementation is used for the fetchByKeys capability
     * @param fbkCaps the fetchBykeys capabilities configuration
     * @return {boolean}
     */
    static isImplementationLookup(fbkCaps) {
      const implCap = fbkCaps[DPConstants.CapabilityKeys.FETCH_BY_KEYS_IMPLEMENTATION];
      return implCap === DPConstants.CapabilityValues.FETCH_BY_KEYS_IMPLEMENTATION_LOOKUP;
    }

    /**
     * whether the fetchByOffset uses ramdomAccess implementation
     * @param fboCaps
     * @return {boolean}
     */
    static isImplementationRandomAccess(fboCaps) {
      const implCap = fboCaps[DPConstants.CapabilityKeys.FETCH_BY_OFFSET_IMPLEMENTATION];
      return implCap
        === DPConstants.CapabilityValues.FETCH_BY_OFFSET_IMPLEMENTATION_RANDOM_ACCESS;
    }

    /**
     * Whether a multiKey lookup is allowed for the fetchByKeys capability
     * @param fbkCaps the fetchBykeys capabilities configuration
     * @return {boolean}
     */
    static isMultiKeyLookupAllowed(fbkCaps) {
      const mklCap = fbkCaps[DPConstants.CapabilityKeys.FETCH_BY_KEYS_MULTI_KEY_LOOKUP];
      return (mklCap === DPConstants.CapabilityValues.FETCH_BY_KEYS_MULTI_KEY_LOOKUP_YES);
    }

    /**
     * Returns true is sdp is a valid ServiceDataProvider instance
     * @param sdp
     * @returns {boolean}
     */
    static isServiceDataProviderInstanceValid(sdp) {
      const IMPLICIT_REQUIRED_PROPS = ['endpoint', 'idAttribute'];
      const EXPLICIT_REQUIRED_PROPS = ['fetchChainId', 'idAttribute'];
      const sdpProps = (sdp && sdp.getDefinitionValue()) || {};
      if (sdpProps) {
        const hasImplicitProps = [];
        const hasExplicitProps = [];

        IMPLICIT_REQUIRED_PROPS.forEach((prop) => {
          if (sdpProps[prop]) {
            hasImplicitProps.push(prop);
          }
        });
        if (hasImplicitProps.length === 0) {
          EXPLICIT_REQUIRED_PROPS.forEach((prop) => {
            if (sdpProps[prop]) {
              hasExplicitProps.push(prop);
            }
          });
        }

        if (hasImplicitProps.length > 0 || hasExplicitProps.length > 0) {
          return true;
        }
      }

      return false;
    }

    /**
     * Returns the total size if 0 or greater, otherwise the default value of -1 (unknown size).
     * @param totalSize
     * @returns {number}
     */
    static getTotalSize(totalSize) {
      return (typeof totalSize === 'number' && totalSize >= 0) ? totalSize : FetchContext.DEFAULT_TOTAL_SIZE;
    }
  }

  return ServiceDataProviderUtils;
});

