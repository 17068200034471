'use strict';

define('vbc/private/performance/performanceReport',[
  'vbc/private/performance/reporter'], (Reporter) => {
  /**
   * Generates a report for specified performance categories
   */
  class PerformanceReport {
    // Because of a circular dependency between Performance and Log, logger needs to be loaded on demand
    getLogger() {
      if (!this.logger) {
        const Log = requirejs('vbc/private/log');
        this.logger = Log.getLogger('/vbc/private/performance/performanceReport');
      }
      return this.logger;
    }

    // eslint-disable-next-line class-methods-use-this
    processCategory(category, reporter, format) {
      const name = category.getName();
      category.getEntries(format)
        .then((entries) => reporter.report(name, entries));
    }

    /**
     * Applies specified reporter to each performance category
     * @param {Array} categories an array of Performance categories to process
     * @param {Reporter} reporter to report on each performance category
     * @param {function} format a function to apply to each category entry before reporting
     * @returns {Promise} a promise that resolves when all categories have been successfully reported
     */
    forEachCategory(categories, reporter, format) {
      const processCategoryPromises = [];
      if (categories) {
        categories.forEach((category) => {
          processCategoryPromises.push(this.processCategory(category, reporter, format));
        });
      }
      return Promise.all(processCategoryPromises);
    }

    /**
     * Reports each performance category using a specified format to the reporter
     * @param {Array} categories an array of Performance categories to process
     * @param {Reporter} reporter a function to report on a category
     * @param {function} format a function to apply to each category entry before reporting
     * @returns {Promise} a promise that resolves when all categories have been successfully reportred,
     * or an immediatelly resolved promise if reported is not enabled
     */
    reportEachCategory(categories, reporter = Reporter.CONSOLE_REPORTER, format = PerformanceReport.SIMPLE_FORMAT) {
      if (reporter.isEnabled()) {
        return this.forEachCategory(categories, reporter, format);
      }
      return Promise.resolve();
    }
  }

  /**
   * When logging performance entries directly to the console as an Object, formatting makes it easier to copy/paste
   * @param {*} entry a performance entry to format
   */
  PerformanceReport.SIMPLE_FORMAT = (entry) => {
    const formattedEntry = {};
    Object.getOwnPropertyNames(entry).forEach((propertyName) => {
      const formattedName = `\r${propertyName}`;
      formattedEntry[formattedName] = entry[propertyName];
    });
    return formattedEntry;
  };

  return PerformanceReport;
});

