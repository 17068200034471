'use strict';

define(
  'vbsw/private/plugins/authenticatedAuthHandlerPlugin',
  [
    'vbsw/private/plugins/abstractAuthHandlerPlugin',
  ], (AbstractAuthHandlerPlugin) => {


  /**
   * Authentication handler for logged in users
   */
  class AuthenticatedAuthHandlerPlugin extends AbstractAuthHandlerPlugin {
    deriveUrlKey(request) {
      return 'authenticated';
    }
  }

  return AuthenticatedAuthHandlerPlugin;
});

