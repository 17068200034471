'use strict';

define('vb/extensions/dynamic/private/types/abstractMetadataProviderDescriptor',[
  'vb/private/types/metadataDescriptor',
  'vb/private/types/factoryMetadataProviderDescriptor',
  'vb/private/utils',
],
(MetadataDescriptor, FactoryMetadataProviderDescriptor, Utils) => {
  /**
   *
   */
  class AbstractMetadataProviderDescriptor extends FactoryMetadataProviderDescriptor {
    constructor() {
      super();

      this.bridge = null;
    }

    /**
     * This method is called during the activation phase of the descriptor. During this phase,
     * it is safe to access values containing expressions to other variables.
     *
     * @returns {Promise<T>}
     */
    activate() {
      return Promise.resolve()
        .then(() => {
          const options = this.createOptions(this.getValue());
          const factoryDeclaration = {
            options,
          };

          // override the default value with factoryDeclaration
          // NOTE: This will overwrite any expression in the default value meaning the metadata
          // descriptor will not react to any changes going forward.
          this.setValue(factoryDeclaration);

          // make sure our bridge is created
          this.getFactoryBridge();

          return super.activate();
        });
    }

    /**
     * from the (resolved) declaration, create an 'options' structure that the FactoryMetadataProviderDescriptor
     * understands.
     * @param value
     * @returns {Object}
     */
    // eslint-disable-next-line class-methods-use-this
    createOptions(value) {
      return value ? Utils.cloneObject(value) : {};
    }

    /**
     * no need to override, sets 'this.bridge'. subclasses should override createFactoryBridge()
     * @returns {MetadataProviderFactoryBridge}
     */
    getFactoryBridge() {
      if (!this.bridge) {
        this.bridge = this.createFactoryBridge();
      }
      return this.bridge;
    }

    /**
     * return the VB factory implementation that will act as a bridge to the JET factory
     * subclasses must override
     */
    // eslint-disable-next-line class-methods-use-this
    createFactoryBridge() {
      throw new Error('do not use directly, createFactoryBridge() must be implemented');
    }

    /**
     * return the VB type definition
     * subclasses must override
     */
    // eslint-disable-next-line class-methods-use-this
    getTypeDefinition() {
      throw new Error('do not use directly, getTypeDefinition() must be implemented');
    }

    /**
     * @override from FactoryMetadataProviderDescriptor, normally uses the 'factory' property.
     * @returns {Promise}
     */
    // eslint-disable-next-line no-unused-vars
    getProviderFactory(factoryPath) {
      return Promise.resolve(this.getFactoryBridge());
    }

    /**
     *
     * duck-types createMetadataProvider from JET factory interface.
     * this is invoked when the Variable type is being used as the "factory" parameter
     * for a FactoryMetadataProviderDescriptor.
     *
     * The FMPD uses this class as a factory, and this method in turns creates a 'bridge' factory if it hasn't been
     * created already, and then delegates creation of the MetadataProvider.
     *
     * The bridge always translated VB-specific options to JET ones.
     *
     * @param options
     * @return {Promise}
     */
    createMetadataProvider(options) {
      // make sure our bridge is created
      return this.getFactoryBridge().createMetadataProvider(options);
    }

    dispose() {
      if (this.bridge) {
        this.bridge.dispose();
      }
      // Call dispose last so that bridge property is not deleted
      super.dispose();
    }
  }

  return AbstractMetadataProviderDescriptor;
});

