'use strict';

define('vb/private/services/definition/openApiDefinitionObject',[
  'vb/private/services/definition/definitionObject',
], (
  DefinitionObject,
) => {
  /**
   * A base class for ServiceDefinition and Endpoint, for assigning private properties based on the 'x-vb' extension.
   * provides a load() method for asynchronously loading anything required by the extension
   *
   * This class only gets extensions from the OpenApi object (OperationDef or ServiceDef) if it exists, and passes it
   * to the base class which merges it from the extensions provided via backends in the catalog.
   */
  class OpenApiDefinitionObject extends DefinitionObject {
    /**
     * @param {string} name
     * @param {OpenApiObjectCommon|OperationObject} openApi Segment of OpenApi document relevant for this instance.
     * @param {ServiceDefinition} parent for ServiceDefinition, this is null.
     *                      For Endpoint, this is the ServiceDefinition, to allow extension inheritance.
     * @param {string} namespace
     * @param {string} relativePath container's path
     * @param catalogInfo possible additions/overrides, local to the app
     * @param {boolean} isUnrestrictedRelative true means, allow using parent folders in paths (only Application)
     */
    constructor(name, openApi, parent, namespace, relativePath, catalogInfo, isUnrestrictedRelative) {
      // combine the 'pure' swagger/openapi3 model extensions with any we layer on from the catalog (or 'other').
      const thisExtensions = openApi ? openApi.getExtensions() : {};

      super(name, thisExtensions, parent, namespace, relativePath, catalogInfo, isUnrestrictedRelative);
    }
  }

  return OpenApiDefinitionObject;
});

