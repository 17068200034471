'use strict';

define('vb/extensions/dynamic/private/helpers/configurableMetadataProviderHelper',[
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/log',
  'vb/private/monitoring/loadMonitorOptions',
  'vb/private/stateManagement/container',
  'vb/private/stateManagement/application',
  'vb/private/model/modelUtils',
  'vb/private/configLoader',
  'vb/extensions/dynamic/private/helpers/baseHelper',
  'vb/extensions/dynamic/private/models/emptyContextModel',
  'vb/extensions/dynamic/private/helpers/metadataHelperFactory',
  'vb/extensions/dynamic/private/constants',
], (Constants, Utils, Log, LoadMonitorOptions, Container, Application,
  ModelUtils, ConfigLoader, BaseHelper, EmptyContextModel, MetadataHelperFactory,
  DynUiConstants) => {
  const logger = Log.getLogger('/vb/extensions/dynamic/private/helpers/configurableMetadataProviderHelper', [
    // Register a custom logger
    {
      name: 'greenInfo',
      severity: 'info',
      style: 'green',
    },
  ]);

  // an array of named objects, containing resources, is passed to the provider; this is the 'base' name
  const BASE_LAYOUT_NAME = 'main';

  // https://confluence.oraclecorp.com/confluence/display/JET/Global+Template+Support
  const GLOBAL_RESOURCES = [{
    property: 'data',
    baseFile: 'field-templates-overlay.json',
    extensionFile: 'field-templates-x.json',
    prefix: 'text!',
    modelClass: 'vb/extensions/dynamic/private/models/layoutOverlayModel',
    extensionModelClass: 'vb/extensions/dynamic/private/models/layoutOverlayExtensionModel',
    optional: true,
  }, {
    property: 'template',
    baseFile: 'field-templates-overlay.html',
    extensionFile: 'field-templates-x.html',
    prefix: 'text!',
    optional: true,
  }];

  // local utility
  function modelName(propertyName) {
    return `${propertyName}Model`;
  }

  function sanitizeViewModel(viewmodel, container) {
    // don't sanitize if the view model is for a dynamic container extension which is
    // an instance of PageExtension
    if (container.className !== 'PageExtension') {
      const v = viewmodel; // assigning to avoid eslint error
      delete v.$application; // not included in base model
      delete v.$global; // not included in base model
    }
    return viewmodel;
  }

  /**
   * The 'metadata provider helper' is an API passed to JET metadata providers, to interface with VB.
   *
   * The ConfigurableMetadataProviderHelper is configured via the 'options.helper.descriptors',
   * to provide resources to the JET metadata providers.
   *
   * This class can be used directly by constructing with options, or by using a subclass to encapsulate the
   * configuration.
   *
   * Resources are returned (to providers) from several functions:
   *
   * from fetch()
   *  - the metadata (openapi3, data-description.json, etc). this resource does NOT have extensions.
   *
   * from getLayoutResources(): an array of objects with properties for various file contents.
   * This array contains the base (named 'main') and any extensions.
   * Each object may have one or more of the following properties:
   *
   * - name:           'main' for the base, or the extension ID for extensions.
   * - data:           a layout file
   * - source:         an JS module, initialized similarly to other VB JS modules
   * - template:       an HTML file
   * - clientMetadata: an 'overlay' for the fetch() metadata (JSON).
   *
   * Depending on the context, any/all of these files may be optional, or not applicable.
   *
   * the 'options.helper.descriptors' object describes each resource:
   * - options.helper.descriptors.metadata:   file returned from the fetch()
   * - options.helper.descriptors.files: all files, except the metadata, to be returned. From base or extensions.
   *
   * A descriptor looks like:
   *   property: the property name to use for this file's contents in the object in the array from getLayoutResources()
   *   isModule: {boolean} true for JS files that should be initialized as VB JS modules
   *   removeExtRelative: {boolean} should be set to true for JS files
   *   optional: {boolean}
   *
   *   ONE OF the following:
   *   baseFile: {string} name of the file in the base application, with file extension.
   *   baseContainer: {Container} Use this when the layout model is defined within an existing VB
   *     container (page/flow), instead of a separate file. Cannot be used with 'baseFile'.
   *
   *   ONE OF the following:
   *   extensionFile: {string} name of the file when in an extension; typically the 'baseFile' with '-x' in the name.
   *   extensionContainers: Array<{property: {string}, container: {Container}}>: an array of objects. Cannot be used
   *      with extensionFile. Similar to baseContainer, used when the extensions are already VB Containers.
   *
   *      The objects in the extensionContainers array have the following properties:
   *      - container: the VB container where the layout model is defined
   *      - property: the resource type (just like the top-level property); for now, this only supports 'data'.
   *
   * The location of these files is determined by the helper, based on layoutRoot (though it may be overridden
   * using deprecated configuration in some cases, though this should no longer happen).
   *
   *
   */
  class ConfigurableMetadataProviderHelper extends BaseHelper {
    /**
     * @param options
     * @param options.id {string}
     * @param options.helper.descriptors.metadata {object}
     * @param options.helper.descriptors.files {Array<object>>}
     * @param vbContext the context created by VB, and passed as 'options.context' to the components/providers
     * @param container the VB container where the dynamic component with this layout will be used
     * @returns {Promise<ConfigurableMetadataProviderHelper>}
     * a promise that resolves to a ConfigurableMetadataProviderHelper instance
     */
    static get(options, vbContext, container) {
      return new ConfigurableMetadataProviderHelper(options, vbContext, container).init();
    }

    /**
     * @param options
     * @param options.id {string}
     * @param options.helper.descriptors.metadata
     * @param options.helper.descriptors.files
     * @param vbContext
     * @param container
     */
    constructor(options, vbContext, container) {
      super();

      this.id = options.id || ''; // should provide an ID

      this.options = options;

      // The root option is only supported for V1 extension apps for loading layouts from an alternate
      // location such as CDN.
      if (this.options.root && Utils.isHostApplication()) {
        logger.warn('Ignoring root option', this.options.root, 'which is not supported for V2 extension applications');

        delete this.options.root;
      }

      // Store the object created by this helper (like models) so we can dispose of them
      this.associatedObjects = [];

      this.context = vbContext;
      this.container = container;

      // indicates the type of layout this helper is responsible for loading, defaults to
      // dynamic component
      this.layoutType = options.layoutType || DynUiConstants;

      this.descriptors = (options.helper && options.helper.descriptors) || {};
      this.descriptors.files = this.descriptors.files || [];
      // find the 'data' resource, and separate it for convenience (this is expected to exist)
      this.descriptors.layout = this.descriptors.files.find((desc) => desc.property === 'data') || {};

      this.urlMapperDisabled = ConfigurableMetadataProviderHelper.getMapperDisabled();

      // 'clone' them, we will add load functions
      this.globalDescriptors = ConfigurableMetadataProviderHelper.getGlobalConfiguration();

      // This is an object that contains all information required by services to locate a service.
      this.vbContext = this.container && typeof this.container.extensionId === 'string'
        ? Object.freeze({ extensionId: this.container.extensionId })
        : undefined;
    }

    /**
     * makes a 'clone'' of the descriptors, so we can add loaders to it, and leave the original intact
     * (in case something else reuses it in the future).
     * @returns {any[]}
     */
    static getGlobalConfiguration() {
      return GLOBAL_RESOURCES.map((d) => Object.assign({}, d));
    }

    /**
     *
     * @returns {Promise<ConfigurableMetadataProviderHelper>}
     */
    init() {
      /**
       * Create the functions needed to load resource from the RTE facade.
       *
       * There are really 3 different cases for determining how we load resources;
       * we are either loading resources for:
       * a) a variable defined in a 'normal' app container, not in an App Package, or
       * b) a variable defined in an in an App Package container, that is NOT an extension, or
       * c) a variable defined in an Extension container.
       *
       * For (a) & (b) we can use the variable's defining container's baseUrl;
       * this is relative to the requireJS baseUrl, and used for loading from the 'normal' app, or App UI.
       * @see container.constructor
       *
       * this.container is always defined; only testing for undefined/null for unit tests
       */
      return this.getExtension()
        .then(() => Utils.getRuntimeEnvironment())
        .then((re) => {
          Object.defineProperties(this, {
            textResourceLoader: {
              value: (loc) => re.getTextResource(loc),
            },
            moduleResourceLoader: {
              value: (loc) => re.getModuleResource(loc),
            },
            extnTextResourceLoader: {
              value: (loc) => re.getExtensionTextResource(loc),
            },
            extnModuleResourceLoader: {
              value: (loc) => re.getExtensionModuleResource(loc),
            },
          });

          // Define on the descriptors which function on the RuntimeEnvironment should
          // be called to load the resource
          const allDescriptors = [...this.descriptors.files, ...this.globalDescriptors];

          allDescriptors.forEach((descriptor) => {
            const desc = descriptor;
            if (descriptor.prefix === 'text!') {
              desc.loader = this.textResourceLoader;
              desc.extnLoader = this.extnTextResourceLoader;
            } else {
              desc.loader = this.moduleResourceLoader;
              desc.extnLoader = this.extnModuleResourceLoader;
              desc.removeExtRelative = true;
            }

            // For layout, the modelClass depends on the container. This is so App UI can
            // use a different implementation
            if (desc.baseFile === 'layout.json' && this.container) {
              // some tests don't defined the container
              desc.modelClass = this.container.layoutModelClass || desc.modelClass;
            }
          });

          return this;
        });
    }

    /**
     * Get the extension from which the layout will be loaded.
     *
     * @returns {Promise<T>}
     */
    getExtension() {
      return Promise.resolve().then(() => {
        if (this.extensionId === Constants.ExtensionFolders.BASE) {
          // create an empty extension for loading layout from the base app
          this.extension = Container.createEmptyExtension();
        } else if (this.extensionId) {
          // look up the extension identified by extensionId
          const extension = Application.extensionRegistry.getExtensionById(this.extensionId);

          if (extension) {
            this.extension = extension;

            // need to explicitly call init since the extension may not have been
            // loaded as part of loading the app ui
            return extension.init();
          }
          return undefined;
        } else {
          // otherwise, use the extension of the calling container
          this.extension = this.container && this.container.extension;
        }

        return undefined;
      });
    }

    /**
     * Return the root path for global field templates.
     *
     * @returns {*}
     */
    getGlobalRoot() {
      if (!this.globalRootPrefix) {
        this.globalRootPrefix = this._calcRoot();
      }
      return this.globalRootPrefix;
    }

    /**
     * Allows override of the default '../../dynamicLayouts' layout root
     * @param path
     * @returns {ConfigurableMetadataProviderHelper}
     */
    setLayoutRoot(path) {
      this.layoutRootPrefix = Utils.addTrailingSlash(path);
      return this;
    }

    /**
     * Initialize and return the layoutRootPrefix
     * @returns {String|string|*}
     */
    getLayoutRoot() {
      if (!this.layoutRootPrefix) {
        const root = this._calcRoot();
        this.setLayoutRoot(root);
      }

      return this.layoutRootPrefix;
    }

    /**
     * Calculate root path base on configuration and options.
     *
     * @returns {string|*}
     * @private
     */
    _calcRoot() {
      // get once for module, no need to re-get for each helper
      const config = ConfigLoader.configurationDeclaration
        && ConfigLoader.configurationDeclaration.dynamicLayouts;

      let root = (config && config.path) || (this.options.helper && this.options.helper.root)
        || this.options.root;
      if (!root) {
        if (!this.extension || this.extension.id === Constants.ExtensionFolders.BASE) {
          root = Constants.DefaultPaths.LAYOUTS;
        } else {
          root = `${Constants.DefaultPaths.LAYOUTS}${Constants.ExtensionFolders.SELF}/`;
        }
      }

      return root;
    }

    /**
     * return the boolean used to disable the UrlMapperClient
     * this is a bit kludgey, to preserve the current behavior of a deprecated method.
     * this checks the same configuration that the UrlMapperClient uses to see if its enabled.
     * (deliberately not making this common, since this should be specific to the UrlMapperClient).
     *
     * @returns {boolean|null}
     */
    static getMapperDisabled() {
      // @todo: JET dynamic UI providers should stop using loadExternalServiceMetadata
      const initConfig = window.vbInitConfig || {};
      const swConfig = initConfig.SERVICE_WORKER_CONFIG || {};
      return swConfig && swConfig.urlMapping && swConfig.urlMapping.disabled;
    }

    /**
     * an array of layouts. the base one will be named 'main'.
     * others are applied in order.
     * @return {Promise} an array of {name, data}
     * @deprecated
     */
    getLayoutParts() {
      let pathPrefix;
      const layouts = [];
      return this._getLayoutPath()
        .then((p) => {
          pathPrefix = p;
          if (!p) {
            throw new Error('No path for dynamic UI resources.', this.id);
          }
          return this._loadResource(pathPrefix, this.descriptors.layout)
            .then((data) => ConfigurableMetadataProviderHelper.transformLayoutModel(data));
        })
        // @todo: what should the 'name' be?
        .then((baseLayoutObject) => {
          layouts.push({
            name: BASE_LAYOUT_NAME,
            data: baseLayoutObject.contents, // ignore the 'model' for the legacy _getlayoutParts
          });
          return this._loadLayoutExtensions(pathPrefix, this.descriptors.files);
        })
        .then((extensionLayouts) => {
          layouts.push(...extensionLayouts);
          return layouts;
        })
        .catch((e) => {
          logger.error('returning empty layouts', e);
          return [];
        });
    }

    /**
     * replacement for getLayoutParts; uses the sample structure, but adds a 'template' and 'source',
     * which may be null.
     * @returns {Promise<[{name: <string>, data: <string>, template: <string>, source: <object>}]>}
     */
    getLayoutResources() {
      const descriptors = this.descriptors.files || [];
      return this._getLayoutPath()
        .then((pathPrefix) => this._getResources(pathPrefix, descriptors));
    }

    /**
     * shared by getGlobalResources and getLayoutResources.
     *
     * Returns various resources (layout, client metadata), based on a set of 'descriptors' that describe
     * how to load, from where, and how the view model is constructed.
     *
     * @param descriptors
     * @returns {*}
     * @private
     */
    _getResources(pathPrefix, descriptors) {
      const layouts = [];
      let modelMap;

      return Promise.resolve()
        .then(() => {
          if (!pathPrefix) {
            throw new Error('No path for dynamic UI resources.', this.id);
          }

          return this.loadResources(pathPrefix, descriptors);
        })
        .then((resourceData) => {
          const resourceMap = resourceData.resourceMap;
          modelMap = resourceData.modelMap;
          layouts.push(resourceMap); // has { name: 'main' } already
          return this._loadLayoutExtensions(pathPrefix, descriptors);
        })
        .then((extensionLayoutTemplates) => {
          // we need to initialize any possible modules, because the extensionRegistry doesn't
          const initModulePromises = extensionLayoutTemplates
            .map((templateFiles) => this._initializeModules(descriptors, templateFiles));
          return Promise.all(initModulePromises);
        })
        // now, create models for the extensions, as needed
        .then((initializedTemplates) => this
          ._createExtensionModels(descriptors, modelMap, initializedTemplates))
        .then((extensionResourceObjects) => {
          // remove "_extInfo" before returning to JET
          const cleanedObjects = extensionResourceObjects.map((obj) => {
            // eslint-disable-next-line no-param-reassign, no-underscore-dangle
            delete obj._extInfo;
            return obj;
          });
          layouts.push(...cleanedObjects);

          if (logger.isInfo) {
            logger.info('Metadata provider resources', this.id,
              JSON.stringify(layouts.map((o) => ({ name: o.name, keys: Object.keys(o) }))));
          }
          return layouts;
        })
        .catch((e) => {
          logger.error('returning empty layouts', e);
          return [];
        });
    }

    /**
     * See if an "extensionModelClass" is defined for the resource, and create the models for each extension,
     * as needed.
     *
     * The result is the passed-in extensionResourceObjects, but each resources object may have
     * had 'models' added to them for JET. The (view)model that JET gets is our Model.expressionContext.
     *
     * @param descriptors
     * @param baseModelMap any Model objects that were created, mapped by descriptor 'property' name.
     *  example:  { clientMetadata: TranslationsModel }
     * @param extensionResourceObjects Array<object> array of extension resource structures, but without (view)models.
     *   example: [ {clientMetadata: "{...}", "template": "<HTML....", etc. }
     *
     * @returns {Promise<Array>} returns the modified extensionResourceObjects array.
     *
     * @private
     */
    _createExtensionModels(descriptors, baseModelMap, extensionResourceObjects) {
      return Promise.resolve()
        .then(() => {
          const promises = [];
          /**
           * for each descriptor, if we have a extensionModelClass, go through all the extensions and add an instance.
           */
          if (extensionResourceObjects.length) {
            descriptors.forEach((descriptor) => {
              const proms = extensionResourceObjects.map((info) => Promise.resolve()
                .then(() => {
                  const extensionResourceObject = info;

                  const baseInfo = baseModelMap[descriptor.property];

                  let modelPromise;
                  // if we have a baseContainer, the model should be its expression context
                  // there are three cases to consider for a viewmodel:

                  if (baseInfo instanceof Container && baseInfo.extensions
                    && Object.keys(baseInfo.extensions).length > 0) {
                    // either...
                    // a) the model for 'base' has an 'extensions' property (ex. Layout)
                    // then we can get the extension model from that.
                    //
                    // but, if the base has a model, and we have extension files (layout-x, etc),
                    // and there are no extension models in the base model ((LayoutExtension, etc),
                    // then use a singleton model that will give us a (non-null).
                    // empty viewmodel (this shouldn't happen).
                    const viewModelProvider = baseInfo.extensions[extensionResourceObject.name]
                      ? baseInfo.extensions[extensionResourceObject.name] : EmptyContextModel;

                    modelPromise = Promise.resolve(viewModelProvider);
                  } else if (descriptor.extensionModelClass && extensionResourceObject[descriptor.property]) {
                    // or
                    // b) the descriptor specifies a (requirejs) class path (ex: vb/extension/dynamic/private/model),
                    // so we load & construct it.

                    const extResource = extensionResourceObject[descriptor.property];

                    // path used to load the extension
                    const extnPathPrefix = Utils
                      // eslint-disable-next-line no-underscore-dangle
                      .removeFileName(extensionResourceObject._extInfo.paths[descriptor.property]);

                    // we are loading extensions
                    const loaders = {
                      text: this.extnTextResourceLoader,
                      module: this.extnModuleResourceLoader,
                    };

                    // this currently assumes anything with a 'model' is JSON (parse).
                    modelPromise = this
                      .loadModelClass(descriptor.property,
                        descriptor.extensionModelClass,
                        extResource || {},
                        extnPathPrefix,
                        descriptor.baseFile, // baseFile only used for the property name
                        loaders,
                        extensionResourceObject.name); // extensionId
                  }

                  if (modelPromise) {
                    // this currently assumes anything with a 'model' is JSON (parse).
                    return modelPromise
                      .then((model) => {
                        if (model) {
                          const modName = modelName(descriptor.property);

                          // add the 'base' Model. before getting the extension expressionContext
                          if (baseInfo && model && model.addChildModel) {
                            model.addChildModel(Constants.ExtensionNamespaces.BASE, baseInfo);
                          }

                          // the 'model' here is really the expression context for the model, not the Model object.
                          return Promise.resolve(model.getViewModel())
                            .then((viewmodel) => {
                              extensionResourceObject[modName] = sanitizeViewModel(viewmodel, model);
                            });
                        }
                        return extensionResourceObject;
                      });
                  }
                  return info; // no model class, just return what we have
                }));

              promises.push(Promise.all(proms));
            });
          }

          // just return all the extension objects
          return Promise.all(promises)
            .then(() => extensionResourceObjects);
        });
    }

    /**
     * Only keep the properties part of the layout model (not the VB layout container properties)
     *
     * @param  {String} layoutJson the layout model JSON
     * @return {String}             the transformed model JSON
     */
    static transformLayoutModel(layoutJson) {
      try {
        const layoutModel = JSON.parse(layoutJson);

        // Remove part of the model that is coming from VB
        delete layoutModel.layoutModelVersion;
        delete layoutModel.constants;
        delete layoutModel.variables;
        delete layoutModel.interface;
        delete layoutModel.chains;
        delete layoutModel.eventListeners;
        delete layoutModel.events;
        delete layoutModel.imports;
        delete layoutModel.translations;
        delete layoutModel.types;

        return JSON.stringify(layoutModel);
      } catch (e) {
        return layoutJson;
      }
    }

    /**
     * Returns the model object for the layout container. The model contains all the accessible $ properties
     * of the layout container. All properties are initialized and ready to be evaluated in expressions.
     * Extensions have also been applied to the layout.
     * The model is bound to the layout view by the JET dynamic component.
     *
     * {
     *   $variables: {}
     *   $constants: {}
     *   $chains: {}
     *   $functions: {}
     *   $listeners: {}
     *   $layout: {}
     * }
     *
     * @return {Promise<model>} a promise that resolve with the model object.
     *
     * @deprecated the viewmodes are available from getLayoutResources, in 'dataModel'.
     * Because this returns the 'viewmodel' and not the Layout, the provider doesn't have access to the 'extensions'.
     */
    getLayoutModel() {
      return this._loadLayout()
        .then((layout) => layout.getViewModel())
        .catch((e) => {
          logger.error('Returning empty model', e);
          return {};
        });
    }

    /**
     * used by JET metadata provider;
     * resolves with the metadata (openapi3 or data-description.json), and a model, if there is one.
     *
     * if there is no 'data' (metadata), there will be no 'dataModel'
     *
     * @returns {Promise<{data: string|null, dataModel: object|null}>}
     */
    getMetadata() {
      return this._loadMetadata()
        .then((resourceObject) => {
          const info = {
            data: resourceObject.contents,
          };
          // if the descriptor includes a "modelClass", the 'model' property
          // returned by _loadResource should be non-null.
          if (resourceObject.model) {
            // note that the 'model' here is really the expression context for the model, not the Model object.
            info[modelName('data')] = resourceObject.model.getViewModel();
          }

          return info;
        });
    }

    /**
     * The API function used by the JET metadata provider to get any (optional) "Global Templates".
     * (wraps a private method).
     *
     * @see ConfigurableMetadataProviderHelper._loadGlobals
     * @returns {Promise<Array<{ name: {string}, data: {object}, dataModel: {object}, template: {string} }>>}
     */
    getGlobalResources() {
      return this._loadGlobals();
    }

    /**
     * fetch the 'metadata' resource, and create a Response object for JET metadata providers
     * @returns {Promise.<Response>|*}
     * @deprecated use getMetadata() instead
     * @see ConfigurableMetadataProviderHelper.getMetadata
     */
    fetch() {
      return this._loadMetadata()
        .then((resourceObject) => new Response(resourceObject.contents)); // we don't include the model, just the JSON
    }

    /**
     *
     * @returns {Promise<string | any>}
     * @private
     */
    _loadMetadata() {
      return Promise.resolve()
        .then(() => {
          if (!this.descriptors.metadata) {
            throw new Error('vbHelper._loadMetadata called when no metadata is defined');
          }
          return this._getLayoutPath()
            .then((prefix) => this._loadResource(prefix, this.descriptors.metadata));
        });
    }

    /**
     * returns a Promise that resolves to the array of global template resources.
     *
     * item [0] name: 'main' (base), items [1..n], name: <extension ID>
     *
     * {
     *   data: {object} parsed field-templates-overlay.json (or extension field-templates-x.json)
     *
     *   dataModel: {object} }view model for field-templates-overlay.json, includes $functions for
     *     field-templates-overlay.js (or -x.js).
     *
     *   template: {string} field-templates-overlay.html (or field-templates-x.html for extension)
     * }
     * @returns {Promise<Array<{ name: {string}, data: {object}, dataModel: {object}, template: {string} }>>}
     * @private
     */
    _loadGlobals() {
      return this._getResources(this.getGlobalRoot(), this.globalDescriptors);
    }

    /**
     * used to optimize layout evaluation
     * @param propertyName should include the '$context.'
     * @returns {Promise<Array>}
     */
    getPossibleValues(propertyName) {
      return Promise.resolve()
        .then(() => {
          const parts = (propertyName || '').split('.');
          if (parts[0] !== '$context') {
            return null;
          }
          parts.shift(); // get rid of $context

          // just return the current roles; no other roles are possible for this session
          if (parts.length > 0 && parts[0]) {
            const subpart = this.context && this.context[parts[0]];
            switch (parts[0]) {
              case 'user':
                // return either
                // - the array, if the value is an array
                // - a single-value array with the scalar, if it has a value
                // - null, if they asked for the 'user' object
                if (parts[1] && subpart && (parts[1] in subpart)) {
                  const value = (subpart && subpart[parts[1]]) || [];
                  return Array.isArray(value) ? value : [value];
                }
                return null;

              case 'responsive':
                // @todo: some of these should have only one possible value for different form-factors
                // hopefully can come from JET (eventually), may need 'device' context support to implement
                if (parts[1] && subpart && (parts[1] in subpart)) {
                  return [true, false];
                }
                return null;

              default:
                return null;
            }
          }
          return null;
        });
    }

    /**
     * a wrapper fir _calcLayoutPath, that also calls 'notify' with the path value
     * @returns {*}
     * @private
     */
    _getLayoutPath() {
      if (!this._pathPromise) {
        this._pathPromise = this._calcLayoutPath()
          .then((path) => {
            this.notify('path', path);
            this.notify('baseUrl', (this.extension && this.extension.baseUrl) || '');

            return path;
          });
      }
      return this._pathPromise;
    }

    /**
     * Get the path for the given operationId, remove all the parameters, and use that as the path.
     * examples: /foo/{foo_id}/bar/{bar_id} => foo/bar
     *
     * @private
     */
    _calcLayoutPath() {
      return Promise.resolve(this.getLayoutRoot());
    }

    /**
     * Prepare an call the loading of the layout.
     * The requirePath is defined dynamically using the result of _getLayoutPath
     * @return {Promise} a promise that resolve when all layout related resources are loaded
     *
     * @private
     */
    _loadLayout() {
      this.loadLayoutPromise = this.loadLayoutPromise || this._getLayoutPath()
        .then((path) => {
          // if a 'baseContainer' is provided, use it as the container for LayoutModel
          const descriptor = this.descriptors.layout;

          let layoutContainer = descriptor.baseContainer;

          // if we we not given an existing container to get the layout model from,
          // create a Layout container, which loads the layout.json, etc.
          if (!layoutContainer) {
            return Utils.getResource(this.container.layoutModelClass)
              .then((Clazz) => {
                // This layout model is created via a deprecated method, loadLayoutModel, in addition
                // to the normal layout model created in loadModelClass. Note that this only happens if the
                // metadata descriptor references the layout using a path instead of an endpoint reference.
                // This is what is causing the duplicate scope name error. To work around the issue, we append
                // the id with _deprecated to avoid duplication.
                const absolutePath = this._buildAbsolutePath(path);
                layoutContainer = new Clazz(`${this.id}_deprecated`, this.container, undefined, absolutePath);

                // save it, so we can dispose
                this.layout = layoutContainer;

                return layoutContainer;
              });
          }

          return layoutContainer;
        });

      return this.loadLayoutPromise;
    }

    /**
     * returns an object that represents the base resources (clientMetadata, template, etc)
     *
     * @param path
     * @param descriptors
     * @returns {Promise<Object>} properties will be the 'property' values from the descriptor
     * @private
     */
    loadResources(path, descriptors) {
      return Promise.resolve()
        .then(() => {
          const promises = descriptors
            .map((descriptor) => this._loadResource(path, descriptor));
          return Promise.all(promises);
        })
        .then((resourceObjects) => {
          // If the container is a PageExtension and the layout is a dynamic container, it means we
          // are loading a nested dynamic container, so we need to set the name of the layout
          // to the extension id of the PageExtension instead of main.
          const resourceMap = {
            name: this.container.className === 'PageExtension'
            && this.layoutType === DynUiConstants.LayoutType.CONTAINER
              ? this.container.extensionId : 'main',
          };
          const modelMap = {};
          const promises = [];

          resourceObjects.forEach((resourceObject, index) => {
            if (resourceObject) {
              const descriptor = descriptors[index];
              resourceMap[descriptor.property] = resourceObject.contents;

              // if the descriptor includes a "modelClass", the 'model' property
              // returned by _loadResource will be non-null.
              if (resourceObject.model) {
                // note that the 'model' here is really the expression context for the model, not the Model object.
                promises.push(Promise.resolve(resourceObject.model.getViewModel())
                  .then((viewmodel) => {
                    resourceMap[modelName(descriptor.property)] = viewmodel;

                    // VBS-1792 call this for the 'base' container (Layout); it will handle the extensions.
                    // The vbEnter event is called ONCE on creation/load, when the component first asks for
                    // the models & files.  A new Layout is loaded once for each instance of the metadata provider.

                    // When dynamic container is used, the model class becomes a Page and the vbEnter event
                    // ends up being invoked more than once, so make sure to only invoke it for the Layout
                    if (resourceObject.model instanceof Container
                      && resourceObject.model.className === 'Layout') {
                      resourceObject.model.invokeEvent(Constants.ENTER_EVENT);
                    }
                  }));
                modelMap[descriptor.property] = resourceObject.model;
              }
            }
          });

          return Promise.all(promises)
            .then(() => ({
              resourceMap,
              modelMap,
            }));
        });
    }

    /**
     * Build the path to the layout resource by adding the base url of the container.
     * @param  {String} path
     * @return {String}
     */
    _buildAbsolutePath(path) {
      // If root is specified, it means this is an alternate layout path, e.g., layouts hosted on CDN,
      // so return the path without prepending the extension base url.
      if (this.options.root) {
        return path;
      }

      let absolutePath = path;
      // No idea if this still need to be supported but it is possible for the path to be set
      // by the DynamicLayoutMetadataProviderDescriptor through defaultValue.path of the variable
      // like in test/functional/fixtures/eventsTest/flows/flow1/pages/main-page.json
      let baseUrl;
      if (this.extension) {
        baseUrl = this.extension.baseUrl;
      } else {
        baseUrl = (this.container && this.container.baseUrl) || '';
      }

      if (baseUrl && absolutePath.indexOf(baseUrl) !== 0) {
        absolutePath = `${baseUrl}${absolutePath}`;
      }

      return absolutePath;
    }

    /**
     * @param path
     * @param descriptor
     * @returns {null|string|any} returns the contexts of the file or JS module
     * @private
     */
    _loadResource(path, descriptor) {
      let contents;

      return Promise.resolve()
        .then(() => {
          // if a 'baseContainer' is provided, use its metadata
          if (descriptor.baseContainer) {
            return Promise.resolve(descriptor.baseContainer.definition); // @todo: is this guaranteed?
          }

          // if it is only for the extension, it will not have a 'baseFile' property
          if (descriptor.baseFile) {
            let fileName = descriptor.baseFile;

            // check against the extension manifest first before loading the file
            const filePath = `${path}${fileName}`;
            if (this.extension) {
              try {
                this.extension.fileExists(filePath);
              } catch (error) {
                // return a default resource if the resource doesn't exist
                if (descriptor.defaultResource) {
                  return descriptor.defaultResource;
                }

                if (descriptor.optional) {
                  logger.info('No', descriptor.property, 'for', fileName, 'skipping.');
                  return undefined;
                }

                throw error;
              }
            }

            // base app is always relative, so always remove the extension (because requireJS doesn't want it).
            if (descriptor.removeExtRelative) {
              fileName = Utils.removeFileExtension(fileName);
            }

            const pathToLoad = `${this._buildAbsolutePath(path)}${fileName}`;
            // if its not 'text!', assume it is a module (todo: JET V2 translations are coming)
            let pLoad = ((descriptor.prefix === 'text!'))
              ? this.textResourceLoader(pathToLoad, descriptor.defaultResource)
              : this.moduleResourceLoader(pathToLoad);

            // for layout.json convert layoutTypes to types
            if (descriptor.baseFile === this.descriptors.layout.baseFile) {
              pLoad = pLoad
                .then((data) => ConfigurableMetadataProviderHelper.transformLayoutModel(data));
            } else if (descriptor.isModule) {
              // if its a function, assume a constructor.
              // if so, create the object, passing the same context we pass to page modules.
              pLoad = pLoad
                // eslint-disable-next-line no-underscore-dangle
                .then((module) => ConfigurableMetadataProviderHelper._initializeModule(module));
            }

            const mo = new LoadMonitorOptions(LoadMonitorOptions.SPAN_NAMES.LOAD_LAYOUT, pathToLoad, this);
            return logger.monitor(mo, (layoutLoadTimer) => pLoad
              .then((result) => {
                logger.greenInfo(pathToLoad, 'loaded.', layoutLoadTimer());
                return result;
              })
              .catch((error) => {
                layoutLoadTimer(error);
                if (descriptor.optional) {
                  logger.info('No', descriptor.property, 'for', path, 'skipping.', error);
                  return undefined;
                }
                throw error;
              }));
          }

          return null;
        })
        .then((r) => {
          contents = r;
          // two different types of sources for the viewmodel to pass to JET.

          // a) if we have a baseContainer, we get the viewmodel from that (the expression context)
          if (descriptor.baseContainer) {
            return descriptor.baseContainer;
          }

          // b) otherwise, if we have a requireJS module name, load it, construct it, etc...
          const loaders = {
            text: this.textResourceLoader,
            module: this.moduleResourceLoader,
          };

          // All layout resources are relative to the application so prefix
          // the path with the container baseUrl
          const absolutePath = this._buildAbsolutePath(path);
          return this
            .loadModelClass(descriptor.property, descriptor.modelClass, contents, absolutePath,
              descriptor.baseFile, loaders);
        })
        .then((model) => ({
          contents,
          model,
        }));
    }

    /**
     * Dynamic UI 'base' layouts are JSON files that (by default) live in a 'dynamicLayouts/' folder.
     * (there are ways to change this location, but this is not currently documented).
     *
     * Layout extensions, which live on the extension registry server, also use a root "dynamicLayouts/" folder,
     * by convention. (this is not currently configurable).
     *
     * This function asks the extension registry (utility) for the extension files, and passes the root folder name.
     *
     * @param path
     * @param descriptors
     * @returns {Promise}
     * @private
     */
    _loadLayoutExtensions(path, descriptors) {
      return Promise.resolve()
        .then(() => {
          if (!path) {
            throw new Error('unexpected empty path');
          }
          const pathPrefix = Utils.removeFileExtension(path);

          // load all the ones that are really a VB container first, then get the rest from the ExtensionRegistry
          const promises = [];
          const extensionRegistryDescriptors = [];

          descriptors.forEach((descriptor) => {
            /**
             * if the descriptor defines the 'extensionContainers' property, use its container as the resource.
             *
             * note that, currently, only a 'data' resource (aka, the layout) may be defined by an existing VB
             * resource. (meaning, 'property' should have a value of 'data' for now).
             */
            if (descriptor.extensionContainers) {
              Object.keys(descriptor.extensionContainers).forEach((extensionId) => {
                const extensionDesc = descriptor.extensionContainers[extensionId];

                // does not assume the extension has been loaded
                const modelPromise = Promise.resolve(extensionDesc.container.getAvailableContexts())
                  .then((viewModel) => sanitizeViewModel(viewModel, extensionDesc.container));

                const containerPromises = [extensionDesc.container.loadDescriptor(), modelPromise];

                const extDescriptorPromise = Promise.all(containerPromises)
                  .then(([contents, model]) => ({
                    name: extensionId,
                    contents,
                    model,
                    property: extensionDesc.property, // resource property type (data, template, etc).
                  }));
                promises.push(extDescriptorPromise);
              });
            } else {
              // extensionContainers not used; it is a file-based extension resource, so add it to a separate list
              extensionRegistryDescriptors.push(descriptor);
            }
          });

          const targetExtId = this.extension ? this.extension.id : 'base';
          const extPromiseAll = Application
            .extensionRegistry.loadLayoutExtensions(pathPrefix, extensionRegistryDescriptors,
              { extensionId: targetExtId });

          // combine the two arrays in the Promise.all() calls
          return Promise.all([Promise.all(promises), extPromiseAll])
            .then((arr) => {
              // combine the objects into one array of objects, containing the resource properties (data, template, etc)
              const fromExistingContainers = arr[0];
              const fromExt = arr[1];

              // for each container-based resource extension, either look for an existing entry in the array
              // for the extension and add the resource property, OR add a new entry.

              fromExistingContainers.forEach((loaded) => {
                let obj = fromExt.find((r) => r.name === loaded.name);
                if (!obj) {
                  obj = {
                    name: loaded.name,
                  };
                  fromExt.push(obj);
                }
                // set the resource contents, and associated viewmodel (if any)
                obj[loaded.property] = loaded.contents;
                if (loaded.model) {
                  obj[modelName(loaded.property)] = loaded.model;
                }
              });
              return fromExt;
            });
        });
    }

    /**
     * 'resourcesInfoMap' has a property for each resource type which may appear in a base app or extension.
     * This function will look for any properties that are 'modules', and initialize them.
     *
     * "Module" is the same as for a page/flow module - a JS requireJS module that optionally returns a constructor;
     * if it is a function, it is called, and passed a VB 'context' parameter.
     *
     * Returns an object with the uninitialized module properties replaced by initialized ones
     *
     * @param descriptors
     * @param resourcesInfoMap {Object}
     * @returns {Promise<Object>}
     * @private
     */
    // eslint-disable-next-line class-methods-use-this
    _initializeModules(descriptors, resourcesInfoMap) {
      const promises = [];
      const resourcesInfo = Object.assign({}, resourcesInfoMap);

      const moduleDescriptors = descriptors.filter((descriptor) => descriptor.isModule);

      moduleDescriptors.forEach((descriptor) => {
        if (resourcesInfo[descriptor.property]) {
          const contents = resourcesInfo[descriptor.property];

          // eslint-disable-next-line no-underscore-dangle
          const p = ConfigurableMetadataProviderHelper._initializeModule(contents)
            .then((module) => {
              // replace the original JS with the constructed object
              resourcesInfo[descriptor.property] = module;
            });
          promises.push(p);
        }
      });
      return Promise.all(promises).then(() => resourcesInfo);
    }

    /**
     * if its a constructor, pass it the same context as the container's module functions
     * @param module
     * @returns {Promise<void>}
     * @private
     */
    static _initializeModule(module) {
      // ModelUtils will use Router for the current container (page).
      return Promise.resolve().then(() => ModelUtils.initializeJsModule(module)); // use 'default' container context
    }

    /**
     * utility to load the specified model class, and call the constructor
     *
     * @param property {string} property name of the current resource; only used for logging.
     * @param className {string} requireJS path
     * @param contents {object} the model declaration
     * @param path {string} may be required by some model, and not others
     * @param filenameOrName {string} the root 'name' of the model, in {{ expressions }}.
     *    This is only used to name the property, not to load the file.
     *    If its a filename, it  is used as the name; if hyphenated, it will be camelCased.
     *
     * @param loaders { text: {Function}, module: {Function} }
     * @returns {Promise<Model|null>}
     */
    loadModelClass(property, className, contents, path, filenameOrName, loaders = false, extensionId = undefined) {
      return Promise.resolve()
        .then(() => {
          if (!className) {
            return null;
          }

          return Utils.getResource(className)
            .then((Clazz) => {
              if (Clazz && contents) {
                try {
                  // create Layout
                  if (Clazz.prototype instanceof Container) {
                    return new Clazz(this.id, this.container, this.extension, path);
                  }

                  const model = new Clazz(JSON.parse(contents), path, filenameOrName, loaders,
                    extensionId || (this.vbContext && this.vbContext.extensionId));

                  // Keep of reference to the model so can dispose of them properly
                  this.associatedObjects.push(model);

                  return model.init()
                    .catch((e) => {
                      logger.warn('Unable to initialize descriptor model class, continuing', className, e);
                      return null;
                    });
                } catch (err) {
                  logger.warn('Unable to parse dynamic UI resource for model, continuing', property, err);
                }
              }
              return null;
            });
        })
        .catch((e) => {
          logger.warn('Unable to create model, continuing', property, e);
          return null;
        });
    }

    /**
     *
     * @param options
     * @returns {Promise}
     */
    getHelper(options) {
      // options, vbContext, container) {
      return MetadataHelperFactory.createHelper(options, this.container, this.id);
    }

    dispose() {
      if (this.layout) {
        this.layout.dispose();
      }

      // Cleanup all associated objects
      this.associatedObjects.forEach((model) => model.dispose());
      this.associatedObjects = [];

      this.container = null;
      this.options = null;
      this.descriptors = null;
      this.context = null;
      this.vbContext = null;

      super.dispose();
    }
  }

  return ConfigurableMetadataProviderHelper;
});

