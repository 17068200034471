'use strict';

define('vbsw/private/plugins/generalHeadersHandlerPlugin',['vbsw/api/fetchHandlerPlugin'], (FetchHandlerPlugin) => {
  /**
   * Handler plugin for adding general headers such as Accept-Language, etc.
   */
  class GeneralHeadersHandlerPlugin extends FetchHandlerPlugin {
    /**
     * Constructor
     *
     * @param context the context for the plugin
     * @param headers an object containing all the headers to be appended to the request
     */
    constructor(context, headers) {
      super(context);
      this.headers = headers || {};
    }

    handleRequestHook(request) {
      Object.keys(this.headers).forEach((key) => {
        // BUFP-31175: don't replace headers that exist in the request
        if (!request.headers.get(key)) {
          request.headers.set(key, this.headers[key]);
        }
      });

      return Promise.resolve();
    }
  }

  return GeneralHeadersHandlerPlugin;
});

