'use strict';

define('vb/private/types/capabilities/noOpFetchByOffset',[
  'vb/private/log'],
  (Log) => {
    class NoOpFetchByOffset {
      /**
       * constructor
       *
       * @param params {Object} see oj.FetchByKeysParameters. Contains the following properties
       *  - keys: Set<any> keys of the rows to fetch
       */
      constructor(params) {
        this.options = params;
        this.log = Log.getLogger('/vb/types/ServiceDataProvider.NoOpFetchByOffset');
      }

      fetchByOffset() {
        this.log.info('no results returned from no op fetchByOffset implementation');
        return Promise.resolve({ fetchParameters: this.options, results: [], done: true });
      }
    }

    return NoOpFetchByOffset;
  });

