'use strict';

define('vb/private/events/eventModel',[
  'vb/private/events/baseEventModel',
  'vb/private/stateManagement/stateUtils',
  'vb/private/events/eventBehaviorFactory',
], (BaseEventModel, StateUtils, EventBehaviorFactory) => {
  /**
   * this represents a standard VB event
   */
  class EventModel extends BaseEventModel {
    constructor(name, registry, definition, container, isInterface = false) {
      super(name, registry, definition, container, isInterface);

      // VB Events must have a behavior
      this.behavior = EventBehaviorFactory.create(name, this, registry);
    }
  }

  return EventModel;
});

