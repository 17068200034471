'use strict';

define('vb/private/stateManagement/context/applicationBaseContext',[
  'vb/private/stateManagement/context/flowBaseContext',
  'vb/private/constants'],
(FlowBaseContext, Constants) => {
  /**
   * set of properties to expose in $base for flow extension
   */
  class ApplicationBaseContext extends FlowBaseContext {
    constructor(application) {
      super(application);

      const applicationContext = application.expressionContext;

      const propDescriptors = {};

      [Constants.APPLICATION_USER_VARIABLE,
        Constants.GLOBAL_CURRENT_APPUI_VARIABLE,
        'builtinUtils',
        Constants.RESPONSIVE_CONTEXT,
        Constants.PROFILE_CONSTANT,
        Constants.DEPLOYMENT_CONSTANT,
        Constants.INIT_PARAM_CONTEXT,
        Constants.PATH_VARIABLE,
        Constants.CURRENT_PAGE_VARIABLE,
        Constants.INFO_CONTEXT,
      ].forEach((property) => {
        propDescriptors[property] = {
          get() {
            return applicationContext[property];
          },
          enumerable: true,
          configurable: true,
        };
      });

      Object.defineProperties(this, propDescriptors);
    }
  }

  return ApplicationBaseContext;
});

