'use strict';

define('vb/private/stateManagement/context/flowBaseContext',[
  'vb/private/stateManagement/context/baseContext'],
(BaseContext) => {
  /**
   * set of properties to expose in $base for flow extension
   */
  class FlowBaseContext extends BaseContext {
    /**
     * $base.flow
     */
    get flow() {
      return this;
    }
  }

  return FlowBaseContext;
});

