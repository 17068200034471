'use strict';

define('vb/private/stateManagement/baseModuleViewModel',[
  'vb/private/constants',
  'vb/private/stateManagement/bridgeFactory',
], (Constants, BridgeFactory) => {
  /**
   * This class represents the oj-module view model for a container that has special behavior around oj-module,
   * like fragment and flow/page. 'viewModel' is a property of oj-module moduleConfig whose value will be an
   * instance of this class.
   */
  class BaseModuleViewModel {
    /**
     * Use the fragment object in a closure so that it's hidden from the viewModel
     *
     * @param {Container} container - usually a Page | Fragment | Layout
     */
    constructor(container) {
      // Cannot use Object.assign because it does not respect the property descriptors,
      // so the properties become writable
      const propDescriptors = Object.getOwnPropertyDescriptors(container.getAvailableContexts());

      // Only enumerable properties are intended to be on the viewModel - properties like 'clone' or 'addAccessor'
      // should not be part of the viewModel
      Object.keys(propDescriptors).forEach((prop) => {
        if (!propDescriptors[prop].enumerable) {
          delete propDescriptors[prop];
        }
      });

      /**
       * [[vbBridge]] is a special property on view model that resolves to the bridge factory when the vbBridge
       * property is accessed
       */
      propDescriptors[Constants.componentBridge] = {
        /**
         * This is for internal use to link the VB CCA with VB ecosystem.
         * This allows a oj-vb-* cca with this property to access its current context it's called from.
         * Example: An oj-vb-fragment within a layout had been using 'dataContext' binding property setup by layout
         * components to access the context or use ko.contextFor. With vbBridge that 'may not be needed' if its 'bridge'
         * property is configured.
         * An oj-vb-switcher uses the configured 'bridge' property always
         * @return {BridgeFactory} instance
         */
        get() {
          const factory = new BridgeFactory(container);
          // Redefine this property, the delete will remove the bridge factory reference.
          Object.defineProperty(this, Constants.componentBridge, {
            value: factory,
            enumerable: true,
            configurable: true,
          });
          return factory;
        },
        enumerable: true,
        configurable: true,
      };

      // Define all properties in one shot
      Object.defineProperties(this, propDescriptors);
    }
  }

  return BaseModuleViewModel;
});

