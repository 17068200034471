'use strict';

/*
 * factored out of log.js to separate JET dependencies
 *
 * Created a custom writer, and installs it to JET; no need to actually use the OjCustomWriter class returned
 */
define('vb/private/logCustomWriter',['ojs/ojlogger', 'vb/private/constants', 'vb/private/log'], (ojLogger, Constants, Log) => {
  const MARKER_REGEX = /%s|%o|%O|%d|%i|%f/;
  /**
   * Custom writer for oj.Logger that delegates to the vb logger.
   */
  class OjCustomWriter {
    constructor() {
      this.logger = Log.getLogger('/oj');
    }

    info(...args) {
      this.logger.info(this.format(args));
    }

    warn(...args) {
      this.logger.warn(this.format(args));
    }

    error(...args) {
      this.logger.error(this.format(args));
    }

    log(...args) {
      this.logger.info(this.format(args));
    }

    /**
     * JET messages use % markers for substitution. This method formats a JET message by replacing all % markers with
     * arguments in args.
     *
     * @param args
     */
    format(args) {
      return args.reduce((accumulator, currentValue) => accumulator.replace(MARKER_REGEX, currentValue), '%s');
    }
  }

  // install the custom log writer
  ojLogger.option('writer', new OjCustomWriter());

  return OjCustomWriter;
});

