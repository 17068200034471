'use strict';

define('vb/private/stateManagement/packageFragmentReferenceable',[
  'vb/private/stateManagement/packageFragment',
  'vb/private/constants',
], (PackageFragment, Constants) => {
  /**
   * PackageFragmentReferenceable class
   * A package fragment that has special behavior when referenced by an extension page
   */
  class PackageFragmentReferenceable extends PackageFragment {
    /**
     * returns the package appUI that this fragment is used in
     * @param parent
     * @return {*}
     */
    // eslint-disable-next-line class-methods-use-this
    getPackage(parent) {
      // for some reason the extension page needs to first access its base to know the appUI
      return parent && parent.base && parent.base.package;
    }

    /**
     * Returns a scope resolver map where keys are scope names ("global" / "fragment" / "this")
     * and value the matching objects. This is used to build the scopeResolver object.
     *
     * @private
     * @return {Object} an object which properties are scope
     */
    getScopeResolverMap() {
      const scopesToInclude = super.getScopeResolverMap();
      if (!scopesToInclude[Constants.GLOBAL_PREFIX]) {
        // for some reason the extension page does not have a reference to global in the scope resolver map. So look
        // for global in its base
        const baseMap = (this.parent.base && this.parent.base.getScopeResolverMap()) || {};
        if (baseMap[Constants.GLOBAL_PREFIX]) {
          scopesToInclude[Constants.GLOBAL_PREFIX] = baseMap[Constants.GLOBAL_PREFIX];
        }
      }
      return scopesToInclude;
    }
  }

  return PackageFragmentReferenceable;
});

