'use strict';

define('vb/private/services/extensionServices',[
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/services/services',
  'vb/private/services/definition/extensionServiceMapFactory',
  'vb/private/services/definition/catalogFactory',
  'vb/private/services/definition/programmaticPluginFactory',
  'vb/private/services/definition/serviceMapFactory',
  'vb/private/services/definition/serviceProviderFactory',
], (
  Constants,
  Utils,
  Services,
  ExtensionServiceMapFactory,
  CatalogFactory,
  ProgrammaticPluginFactory,
  ServiceMapFactory,
  ServiceProviderFactory,
) => {
  /**
   * This class is used to find the openapi3 path for a given service name for an extension.
   *
   * overrides getDefinitionPath() to skip a lot of path processing that isn't necessary;
   *
   * @param name
   * @returns {{path: string}}
   */

  class ExtensionServices extends Services {
    // eslint-disable-next-line class-methods-use-this
    getServiceDefTypes() {
      // order is important as that is how services are being earched for
      // first we load the catalog then add any new service entries to the serviceMap
      return [ProgrammaticPluginFactory.TYPE, CatalogFactory.TYPE, ServiceMapFactory.TYPE, ServiceProviderFactory.TYPE];
    }

    /**
     *
     * @returns {ServiceDefFactory}
     * @protected
     * @override
     */
    createServiceDefFactory(serviceDefType, options = {}) {
      switch (serviceDefType) {
        case ServiceMapFactory.TYPE: {
          return new ExtensionServiceMapFactory(this, options);
        }
        case CatalogFactory.TYPE: {
          return new CatalogFactory(this, options);
        }
        default: return super.createServiceDefFactory(serviceDefType, options);
      }
    }

    /**
     *
     * normally for flows, paths that don't start with './' log a warning, and get prefixed with the
     * container's relative path, because flows aren't allowed to reference services outside of their folder.
     *
     * in our case, we're referencing something in the extension registry, so skip the checks.
     *
     * @param filename {string}
     * @returns {string}
     * @override
     */
    getDefinitionPath(filename) {
      // the extension name is the folder name

      // if the path starts with "." (./some/path/openapi3.json)
      const prefix = filename.startsWith(Constants.RELATIVE_FOLDER_PREFIX)
        ? this._relativePath : this.namespace;

      // if it is an absolute URL (including vb-catalog://), leave it alone
      // vb-catalog://services/foo
      if (Utils.isAbsolutePath(filename)) {
        return filename;
      }

      // EXTENSION_PATH has ending slash
      return `${Constants.EXTENSION_PATH}${prefix}/${filename}`;
    }
  }
  return ExtensionServices;
});

